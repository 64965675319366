import type { ReactNode } from 'react'
import {
  Map as VisualDirectoryIcon,
  CompareArrows as MoveManagerIcon,
  CallSplit as ScenariosIcon,
  Language as PlanningPlatformIcon,
  List as MovesIcon,
  LocationCity as FacilitiesIcon,
  Settings as SettingsIcon,
  Timeline as ReportsIcon,
  Security as AdminIcon,
  AssignmentTurnedIn as RequestManagerIcon,
  PersonPinCircle as BookingManagerIcon,
  VerifiedUser as SafeguardIcon,
} from '@material-ui/icons'
import StackPlanIcon from 'common/components/icons/StackPlanIcon'
import ForecastIcon from 'reports/Icons/Forecast'
import Products from '../../../constants/products'
import type { generalProps, commonProps } from './types'
import ProductItems from './ProductItems'
import FeedbackMenuItem from './FeedbackMenuItem'
import HelpMenuItem from './HelpMenuItem'

const ProductMenuItems = ({
  submenus,
  shouldShowImprovedNavigation = false,
}: generalProps): ReactNode => ({
  [Products.VISUAL_DIRECTORY]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: VisualDirectoryIcon,
      product: Products.VISUAL_DIRECTORY,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
      shouldShowImprovedNavigation,
    }),
  [Products.BOOKING_MANAGER]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: BookingManagerIcon,
      product: Products.BOOKING_MANAGER,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
      shouldShowImprovedNavigation,
    }),
  [Products.MOVE_MANAGER]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: MoveManagerIcon,
      product: Products.MOVE_MANAGER,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
      shouldShowImprovedNavigation,
    }),
  [Products.MOVE_MANAGER_BETA]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: shouldShowImprovedNavigation
        ? PlanningPlatformIcon
        : MoveManagerIcon,
      product: shouldShowImprovedNavigation
        ? Products.PLANNING_PLATFORM
        : Products.MOVE_MANAGER_BETA,
      submenu:
        shouldShowImprovedNavigation && submenus
          ? submenus[Products.PLANNING_PLATFORM]
          : undefined,
      link: props,
      isGuest,
      selected,
      shouldShowImprovedNavigation,
    }),
  [Products.MOVES]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: MovesIcon,
      product: Products.MOVES,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
    }),
  [Products.SCENARIOS]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: ScenariosIcon,
      product: Products.SCENARIOS,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
      shouldShowImprovedNavigation,
    }),
  [Products.GROWTH_FORECAST]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: ForecastIcon,
      product: Products.GROWTH_FORECAST,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
      shouldShowImprovedNavigation,
    }),
  [Products.STACK_PLANS]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: StackPlanIcon,
      product: Products.STACK_PLANS,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
      shouldShowImprovedNavigation,
    }),
  [Products.PREFERENCES]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: SettingsIcon,
      product: Products.PREFERENCES,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
      shouldShowImprovedNavigation,
    }),
  [Products.REPORTS]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: ReportsIcon,
      product: Products.REPORTS,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
    }),
  [Products.REPORTS_BETA]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: ReportsIcon,
      product: Products.REPORTS_BETA,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
      shouldShowImprovedNavigation,
    }),
  [Products.INSIGHTS_HUB]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: ReportsIcon,
      product: Products.INSIGHTS_HUB,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
      shouldShowImprovedNavigation,
    }),
  [Products.FACILITIES]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: FacilitiesIcon,
      product: Products.FACILITIES,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
      shouldShowImprovedNavigation,
    }),
  [Products.ADMIN]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: AdminIcon,
      product: Products.ADMIN,
      submenu:
        shouldShowImprovedNavigation && submenus
          ? submenus[Products.ADMIN]
          : undefined,
      link: props,
      isGuest,
      selected,
      shouldShowImprovedNavigation,
    }),
  [Products.REQUEST_MANAGER]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: RequestManagerIcon,
      product: Products.REQUEST_MANAGER,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
      shouldShowImprovedNavigation,
    }),
  [Products.SAFEGUARD]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: SafeguardIcon,
      product: Products.SAFEGUARD,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
      shouldShowImprovedNavigation,
    }),
  [Products.SEND_FEEDBACK]: FeedbackMenuItem,
  [Products.HELP]: HelpMenuItem,
  [Products.WORKPLACE_PORTAL]: HelpMenuItem,
})

export default ProductMenuItems
