import React from 'react'
import { useTranslation } from 'react-i18next'
import HelpIcon from '@material-ui/icons/Help'

import NavMenuItem from '../NavMenuItem'

type Props = {
  href: string
  target?: string
}
export default ({ href, target = '_blank' }: Props): JSX.Element => {
  const { t } = useTranslation('common', {
    keyPrefix: 'common.home_menu',
  })

  return (
    <NavMenuItem component="a" href={href} LeftIcon={HelpIcon} target={target}>
      {t('help')}
    </NavMenuItem>
  )
}
