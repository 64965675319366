// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */

import moment from 'moment-timezone'
import type { Moment, Duration } from 'moment-timezone'
import { humanizer } from 'humanize-duration'
import type { HumanizerOptions } from 'humanize-duration'

const humanizerOptions: HumanizerOptions = {
  largest: 2,
  language: 'en',
  delimiter: ' ',
  units: ['y', 'mo', 'd', 'h', 'm'],
  maxDecimalPoints: 0,
}

/* eslint-disable id-length */
const briefHumanizer = humanizer({
  ...humanizerOptions,
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    },
  },
  decimal: '.',
  spacer: '',
  language: 'shortEn',
})

export const combineDateTime = (
  date: Moment = moment(),
  time: Moment = moment(),
) => moment.parseZone(`${date.format('YYYY-MM-DD')}T${time.format('HH:mmZ')}`)

export const localTime = (
  dateTime: Moment | string,
  timeZone?: string, // shifts hours to given time zone
) =>
  // then sets browsers zone without additional hours shift
  // (for compatibility with regular moment.js)
  /* @ts-expect-error src: types added to moment-timezone */
  moment(dateTime).tz(timeZone).tz(moment.tz.guess(), true)

export const nowInZone = (timeZone?: string | null) =>
  moment.tz(moment(), timeZone || moment.tz.guess())

/* @ts-expect-error auto-src: strict-conversion */
const timeInZone = (time, timeZone?: string | null) => moment.tz(time, timeZone)

const TIMEZONE_AGNOSTIC_FORMAT = 'YYYY-MM-DDTHH:mm'

/* @ts-expect-error auto-src: strict-conversion */
const agnosticTimeInZone = (time, timeZone?: string | null) =>
  moment(timeInZone(time, timeZone).format(TIMEZONE_AGNOSTIC_FORMAT))

export const agnosticNowInZone = (timeZone?: string | null) =>
  moment(nowInZone(timeZone).format(TIMEZONE_AGNOSTIC_FORMAT))

export const isLessThanAMinute = (duration: Duration) => {
  const durationInMinutes = Math.abs(duration.asMinutes())

  return durationInMinutes < 1
}
export const agnosticTimeOrNowInZone = (
  time: string | null | undefined,
  timeZone?: string | null,
) => {
  if (!time) {
    return agnosticNowInZone(timeZone)
  }

  return agnosticTimeInZone(time, timeZone)
}
export const brieflyHumanizeDuration = (duration: Duration) =>
  briefHumanizer(duration.asMilliseconds())
// '4h 20m'
export const humanizeDuration = (duration: Duration) => {
  if (isLessThanAMinute(duration)) {
    return 'less than a minute'
  }

  return humanizer(humanizerOptions)(duration.asMilliseconds()) // '4 hours 20 minutes'
}
export const applyTimeFromString = (
  time: Moment | string,
  timeString: string,
): Moment => {
  const [hours, minutes] = timeString
    .split(':')
    .map((stringNumber) => parseInt(stringNumber, 10))

  return moment(time).set({
    hours,
    minutes,
    seconds: 0,
    milliseconds: 0,
  })
}

export const validMoment = (dateValue: any) => {
  if (dateValue?.isValid && dateValue.isValid()) {
    return dateValue.clone()
  }

  if (dateValue && moment(dateValue).isValid()) {
    return moment.parseZone(dateValue)
  }

  return undefined
}

export const validLocalMoment = (dateValue: any) => {
  if (dateValue?.isValid && dateValue.isValid()) {
    return dateValue.clone()
  }

  if (dateValue && moment(dateValue).isValid()) {
    return moment(dateValue)
  }

  return undefined
}

export const isSameDay = (dayA: Date | Moment, dayB: Date | Moment): boolean =>
  moment(dayA).tz('UTC', true).isSame(moment(dayB).tz('UTC', true), 'day')
