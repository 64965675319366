import React from 'react'

import Skeleton from './Skeleton'

type Props = {
  loading: boolean
}

const ITEM_COUNT = 5

const LoadingMore = ({ loading }: Props): JSX.Element | null => {
  if (!loading) {
    return null
  }

  return (
    <div data-testid="loading-more-indicator">
      <Skeleton itemCount={ITEM_COUNT} />
    </div>
  )
}

export default LoadingMore
