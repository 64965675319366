import { useEffect } from 'react'
import { componentIdled } from 'common/actions/stageComponents'
import useAppDispatch from 'visual_directory/hooks/useAppDispatch'

type Input = {
  skip: boolean
}

// During investigation the root cause of
// https://officespacesoftware.atlassian.net/browse/CORE-5609
// we found that the floating card jumps into correct position right after
// "Canvas" field of "stageComponents" in Redux changes its value.
// Our assumption is that changing "Canvas" value forces canvas to be updated
// and this in turn causes floating card to recalculate its position.
// That means to fix floating card incorrect position we need to update
// the field explicitly. This hook serves exactly this purpose.
const useAdjustedCardPosition = ({ skip }: Input): void => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!skip) {
      dispatch(componentIdled('Canvas'))
    }
  }, [dispatch, skip])
}

export default useAdjustedCardPosition
