import React from 'react'

import useStyles from './useStyles'

type Props = {
  period: string | undefined
  status: string | null | undefined
  title: string | null | undefined
}

const MeetingDetails = ({ period, status, title }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.status}>{status}</div>
      <div className={classes.details}>
        {period} {title}
      </div>
    </div>
  )
}

export default MeetingDetails
