// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { grey } from '@material-ui/core/colors'
import merge from 'lodash/merge'
import StyleConstants from '../definitions'

const defaultCurtainColor = grey[100]
const defaultCurtainTextColor = grey[700]
const styles = {
  root: {
    height: 94,
    width: '100%',
  },
  backArrowContainer: {
    display: 'inline-block',
    paddingLeft: 33,
    paddingRight: 4,
    paddingTop: 17,
    verticalAlign: 'top',
  },
  backArrowButton: {
    height: 32,
    width: 32,
    verticalAlign: 'top',
    padding: 0,
  },
  backArrow: {
    height: 32,
    width: 32,
  },
  titleContainer: {
    display: 'inline-block',
    fontSize: 32,
    paddingTop: 8,
    verticalAlign: 'top',
  },
  listingsSummaryContainer: {
    display: 'inline-block',
    fontSize: 12,
    fontWeight: StyleConstants.font.weight.light,
    paddingLeft: 16,
    verticalAlign: 'baseline',
  },
  listingsSummaryCount: {
    display: 'inline-block',
    fontWeight: StyleConstants.font.weight.normal,
  },
}

/* @ts-expect-error auto-src: noflow */
export default (options) => {
  const { curtainColor, curtainTextColor } = options.branding.directory
  const brandedStyles = {
    root: {
      color: curtainTextColor || defaultCurtainTextColor,
      backgroundColor: curtainColor || defaultCurtainColor,
    },
    backArrow: {
      color: curtainTextColor || defaultCurtainTextColor,
    },
  }

  return merge({}, styles, brandedStyles)
}
