import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  root: {
    position: 'absolute',
    left: 8,
    bottom: 8,
    height: 20,
    padding: '0 8px',
    borderRadius: 10,
    background: 'rgba(0, 0, 0, 0.5)',
  },
  icon: {
    width: 12,
    height: 12,
    margin: 0,
    color: '#ffffff',
  },
  label: {
    margin: '0 0 0 4px',
    padding: 0,
    fontSize: 10,
    lineHeight: '12px',
    color: '#ffffff',
  },
})
