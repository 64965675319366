import { useMemo } from 'react'

import useAppSelector from 'visual_directory/hooks/useAppSelector'
import type { Site } from './types'

const useSiteByFloorId = (
  floorId: string | number | undefined,
): Site | undefined => {
  const sites = useAppSelector((state) => state.floorListStore.get('sites'))

  return useMemo(() => {
    if (!floorId || !sites) {
      return
    }

    const floorIdInt = Number(floorId)
    const resultSite = sites
      .find((site) =>
        site.get('floors').some((floor) => floor.get('id') === floorIdInt),
      )
      ?.toJS()

    return resultSite && { ...resultSite, id: resultSite.id.toString() }
  }, [sites, floorId])
}

export default useSiteByFloorId
