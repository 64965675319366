import { makeStyles } from '@material-ui/core/styles'

type Args = {
  color: string
}

export default makeStyles({
  root: ({ color }: Args) => ({
    fontWeight: 600,
    color,
  }),
})
