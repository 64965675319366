// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import LabelContainer from 'common/components/LabelContainer'
import {
  getLongColonWeeksOfYear,
  getLongParenthesisWeeksOfYear,
  getMediumWeeksOfYear,
  getShortWeeksOfYear,
} from '../lib'
import type { Props } from '..'

const DEFAULT_WEEKS_LIMIT = 3

const WeeksOfYearLabel = ({
  availability,
  includeName,
  weeks = 'short',
  longWeeksLimit = DEFAULT_WEEKS_LIMIT,
  longWeeksFormat = 'colon',
}: Props) => {
  const getLongWeeks = () => {
    switch (longWeeksFormat) {
      case 'colon':
        return getLongColonWeeksOfYear(availability)

      case 'parenthesis':
        return getLongParenthesisWeeksOfYear(availability)

      default:
        return []
    }
  }

  const getWeeks = () => {
    switch (weeks) {
      case 'short':
        return [getShortWeeksOfYear(availability).join(', ')]

      case 'medium':
        return [getMediumWeeksOfYear(availability).join(', ')]

      case 'long':
        return getLongWeeks().slice(0, longWeeksLimit)
      case 'none':
        return []
      default:
        return []
    }
  }

  if (weeks === 'none') {
    return <LabelContainer>{availability.name}</LabelContainer>
  }

  const labels = includeName
    ? [`${availability.name || ''}:`, ...getWeeks()]
    : getWeeks()
  const displayLabels = weeks === 'long' ? labels : [labels.join(' ')]

  return (
    <>
      {displayLabels.map((line, index) => (
        <LabelContainer key={index}>{line}</LabelContainer>
      ))}
    </>
  )
}

export default WeeksOfYearLabel
