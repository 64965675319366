import { makeStyles } from '@material-ui/styles'
import type { Theme } from '@material-ui/core'

type Props = {
  isDisabled: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    cursor: ({ isDisabled }: Props) => (isDisabled ? 'not-allowed' : 'pointer'),
  },
  button: {
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(7, 61, 81, 0.3)',
    borderRadius: 2,
    boxSizing: 'border-box',
    display: 'flex',
    height: 30,
    justifyContent: 'space-between',
    padding: 0,
    pointerEvents: ({ isDisabled }: Props) => (isDisabled ? 'none' : 'auto'),
    width: '100%',
  },
  content: {
    color: ({ isDisabled }: Props) => (isDisabled ? '#9E9E9E' : '#212121'),
    fontSize: 12,
    fontWeight: 600,
    flexGrow: 1,
    textAlign: 'left',
  },
  icon: {
    flexGrow: 0,
    color:
      /* @ts-expect-error src: Using Theme from @material-ui/core */
      theme.palette?.branding?.navbar?.dateTimePicker.iconColor || '#42A5F5',
    width: 20,
    height: 20,
    margin: '5px 8px 5px 7px',
  },
  expandIcon: {
    marginLeft: 0,
    marginRight: 5,
  },
}))

export default useStyles
