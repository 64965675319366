import Products from '../../../constants/products'

const {
  location: { pathname },
} = window

export const isProductSelected = (
  product: string,
  selected?: string,
  href?: string,
): boolean => {
  if (!selected) {
    return false
  }

  if (href && href === '/insights-hub' && pathname !== href) {
    return false
  }

  return (
    product === selected ||
    (product === Products.PLANNING_PLATFORM &&
      [
        Products.MOVE_MANAGER_BETA,
        Products.MOVES,
        Products.STACK_PLANS,
        Products.SCENARIOS,
      ].includes(selected))
  )
}

export const shouldHideVDSubmenu = (
  product: string,
  selected?: string,
): boolean =>
  product === Products.VISUAL_DIRECTORY &&
  selected !== Products.VISUAL_DIRECTORY

export const isVisualDirectory = (name?: string): boolean =>
  name === Products.VISUAL_DIRECTORY

export const isSelectedItem = (href: string): boolean => {
  const startsWithSection = new RegExp(/^(\/[a-zA-Z-_]+)?\/.+/)
  const matchingPath = pathname.match(startsWithSection)

  if (matchingPath) {
    if (matchingPath[0] !== href && href === '/admin') {
      return false
    }

    return matchingPath.includes(href)
  }

  return href === pathname
}
