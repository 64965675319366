import { makeStyles } from '@material-ui/styles'

/* @ts-expect-error auto-src: non-strict-conversion */
export default makeStyles(({ palette }) => ({
  root: {
    flexGrow: 1,
    padding: '36px 16px',
    borderTop: `1px solid ${palette.grey[300]}`,
    textAlign: 'center',
    background: palette.common.white,
  },
  title: {
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 600,
    color: palette.grey[800],
  },
  subtitle: {
    marginTop: 12,
    fontSize: 14,
    lineHeight: '18px',
    color: palette.grey[600],
  },
  image: {
    maxWidth: '100%',
    marginTop: 28,
  },
}))
