// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense } from 'react'
import { ThemeProvider } from '@material-ui/styles'
import { ApolloProvider } from '@apollo/client'
import createApolloClient from 'common/lib/createApolloClient'
import 'isomorphic-fetch'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import muiTheme from 'common/themes/materialTheme'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import launchDarklyConfig from 'common/launchDarkly/config'
import NavMenu from './NavMenu'

const theme = createMuiTheme(muiTheme)
const LDConfig = launchDarklyConfig()

const StandaloneNavMenu = (props: any) => {
  const apolloClient = createApolloClient()

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<div />}>
          <NavMenu {...props} />
        </Suspense>
      </ThemeProvider>
    </ApolloProvider>
  )
}

/* @ts-expect-error auto-src: noflow */
export default withLDProvider(LDConfig)(StandaloneNavMenu)
