import React, { useState } from 'react'
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'
import { List } from '@material-ui/core'
import VDMenuItems from 'visual_directory/components/nav_items/MenuActions'
import NavMenuItem from '../../NavMenuItem'
import type { NavItemsProps } from '../types'
import useStyles from '../styles'

const VisualDirectoryProductItem = ({
  name,
  Icon,
  isGuest,
  shouldShowImprovedNavigation,
}: NavItemsProps): JSX.Element | null => {
  const [open, setOpen] = useState<boolean>(true)
  const styles = useStyles()

  if (isGuest || isGuest === undefined) {
    return (
      <div key={name} data-testid="home-menu">
        <VDMenuItems isGuest isOpen />
      </div>
    )
  }

  const VDSubmenu = () => {
    if (!open || !shouldShowImprovedNavigation) {
      return null
    }

    return (
      <div key={name} data-testid="home-menu">
        <VDMenuItems />
      </div>
    )
  }

  return (
    <List component="div" disablePadding>
      <NavMenuItem
        isSelected
        LeftIcon={Icon}
        onClick={() => setOpen(!open)}
        RightIcon={
          shouldShowImprovedNavigation &&
          (open ? ExpandLessIcon : ExpandMoreIcon)
        }
      >
        {name}
        <span className={styles.superscript}>®</span>
      </NavMenuItem>
      {VDSubmenu()}
    </List>
  )
}

export default VisualDirectoryProductItem
