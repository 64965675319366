// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Today as MyBookingsIcon } from '@material-ui/icons'

import { myBookingsPath } from 'visual_directory/lib/paths'
import NavMenuItem from 'common/components/navmenu/NavMenuItem'

type Props = {
  floorId?: string | number
  isGuest?: boolean
  onClick: () => void
  showImprovedNavigation?: boolean
}

const MyBookings = ({
  floorId,
  onClick,
  isGuest,
  showImprovedNavigation,
}: Props) => {
  const { t } = useTranslation('visual_directory', {
    keyPrefix: 'visual_directory.home_menu',
  })

  const shouldChangeStyle = !showImprovedNavigation || isGuest

  return (
    <NavMenuItem
      component={Link}
      isBold={shouldChangeStyle}
      LeftIcon={shouldChangeStyle && MyBookingsIcon}
      onClick={onClick}
      to={myBookingsPath(floorId)}
    >
      {t('my_bookings')}
    </NavMenuItem>
  )
}

/* @ts-expect-error auto-src: non-strict-conversion */
export default connect<any, any, any, _, _, _>(
  /* @ts-expect-error auto-src: strict-conversion */
  ({ route }) => ({
    floorId: route.getIn(['match', 'params', 'floorId']),
  }),
  {},
)(MyBookings)
