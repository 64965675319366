import React, { useState } from 'react'
import classNames from 'classnames'
import {
  Button,
  ClickAwayListener,
  Popper,
  Paper,
  MenuItem,
  MenuList,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import useStyles from './styles'

interface MenuItemsProp {
  key: string
  name: string
  url: string
}

interface Props {
  menuItems: Array<MenuItemsProp>
  name: string
  selected: boolean
}

const MenuItemWithDropDown = ({
  menuItems,
  name,
  selected,
}: Props): JSX.Element => {
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)

  const handleRequestClose = () => {
    setOpen(false)
  }

  const handleOnMenuItemSelect = (item: MenuItemsProp) => {
    window.location.replace(item.url)
    handleRequestClose()
  }

  const handleToggleOpen = () => {
    setOpen(!open)
  }

  const renderMenuItem = (item: MenuItemsProp) => (
    <MenuItem
      key={item.key}
      className={classes.menuItem}
      onClick={() => handleOnMenuItemSelect(item)}
    >
      {item.name}
    </MenuItem>
  )

  return (
    <ClickAwayListener onClickAway={handleRequestClose}>
      <div
        ref={(node) => setAnchorEl(node)}
        className={classes.navItemView}
        onClick={handleToggleOpen}
      >
        <Button
          className={classNames({
            [classes.button]: true,
            [classes.buttonHover]: open,
            [classes.buttonSelected]: selected,
            [classes.buttonBorder]: selected && !open,
          })}
        >
          {name}
          <ArrowDropDownIcon className={classes.downArrow} />
        </Button>
        <Popper
          anchorEl={anchorEl}
          className={classes.popper}
          open={open}
          placement="bottom-start"
        >
          <Paper className={classes.menuStyle}>
            <MenuList>{menuItems.map(renderMenuItem)}</MenuList>
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

export default MenuItemWithDropDown
