import type { Moment } from 'moment-timezone'

import { formatTime, hourToTime } from 'common/types/time'

import type { TimeSlot } from './types'

const HOURS_IN_DAY = 24
const SLOTS_PER_HOUR = 2

type Input = {
  disableUnavailableHours: boolean
  isHourAvailable: (hour: number) => boolean
  isToday: boolean
  now: Moment
}

const buildTimePickerSlots = ({
  disableUnavailableHours,
  isHourAvailable,
  isToday,
  now,
}: Input): TimeSlot[] =>
  Array.from({ length: HOURS_IN_DAY * SLOTS_PER_HOUR }, (_, index) => {
    const hour = index / SLOTS_PER_HOUR
    const time = hourToTime(hour, now)

    return {
      value: hour,
      disabled:
        (!isHourAvailable(hour) && disableUnavailableHours) ||
        (time.isBefore(now) && isToday),
      tinted: !isHourAvailable(hour),
      label: formatTime(time),
    }
  }).filter((timeSlot) => !timeSlot.disabled)

export default buildTimePickerSlots
