// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
import React from 'react'
import type { ComponentPropsWithoutRef } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const PeopleGroupIcon = (props: ComponentPropsWithoutRef<typeof SvgIcon>) => (
  <SvgIcon viewBox="0 0 16 10" {...props}>
    <path
      d="M8 0.333496C7.38116 0.333496 6.78767 0.579329 6.35008 1.01691C5.9125 1.4545 5.66667 2.04799 5.66667 2.66683C5.66667 3.28567 5.9125 3.87916 6.35008 4.31675C6.78767 4.75433 7.38116 5.00016 8 5.00016C8.61884 5.00016 9.21233 4.75433 9.64992 4.31675C10.0875 3.87916 10.3333 3.28567 10.3333 2.66683C10.3333 2.04799 10.0875 1.4545 9.64992 1.01691C9.21233 0.579329 8.61884 0.333496 8 0.333496ZM12.6667 2.00016C12.2933 2.00016 11.9467 2.10016 11.6467 2.28016C11.7467 3.2335 11.4667 4.18016 10.8933 4.92016C11.2267 5.56016 11.8933 6.00016 12.6667 6.00016C13.1971 6.00016 13.7058 5.78945 14.0809 5.41438C14.456 5.0393 14.6667 4.5306 14.6667 4.00016C14.6667 3.46973 14.456 2.96102 14.0809 2.58595C13.7058 2.21088 13.1971 2.00016 12.6667 2.00016ZM3.33333 2.00016C2.8029 2.00016 2.29419 2.21088 1.91912 2.58595C1.54405 2.96102 1.33333 3.46973 1.33333 4.00016C1.33333 4.5306 1.54405 5.0393 1.91912 5.41438C2.29419 5.78945 2.8029 6.00016 3.33333 6.00016C4.10667 6.00016 4.77333 5.56016 5.10667 4.92016C4.53333 4.18016 4.25333 3.2335 4.35333 2.28016C4.05333 2.10016 3.70667 2.00016 3.33333 2.00016ZM12.3333 8.8335C12.3333 7.4535 10.3933 6.3335 8 6.3335C5.60667 6.3335 3.66667 7.4535 3.66667 8.8335V10.0002H12.3333V8.8335ZM16 10.0002V9.00016C16 8.0735 14.74 7.2935 13.0333 7.06683C13.4267 7.52016 13.6667 8.14683 13.6667 8.8335V10.0002H16ZM0 10.0002H2.33333V8.8335C2.33333 8.14683 2.57333 7.52016 2.96667 7.06683C1.26 7.2935 0 8.0735 0 9.00016V10.0002Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default PeopleGroupIcon
