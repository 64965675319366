import React from 'react'
import Header from './Header'
import useStyles from './useStyles'
import EmptyState from './GroupedList/EmptyState'

type Props = {
  onClose: () => void
}

const EmptyRoster = ({ onClose }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.root} data-testid="who-is-in-roster">
      <Header dateTimePicker={null} onCloseButtonClick={onClose} />
      <EmptyState
        subtitle="Please select a floor to see who is coming in"
        title="No bookings"
      />
    </div>
  )
}

export default EmptyRoster
