import React from 'react'

import HighlightedTextResult from 'common/components/HighlightedTextResult'
import SplitDescription from 'visual_directory/components/who_is_in/SplitDescription'

type Props = {
  floorLabel: string | null
  seatLabel: string
  highlightedText: string | null
}

// Display at least 13 characters of each seat and floor label.
// 13 characters is approximately half of available space,
// taking into account average uppercase character size and separator symbol.
const TRUNCATE_THRESHOLD = 13

const Location = ({
  floorLabel,
  seatLabel,
  highlightedText,
}: Props): JSX.Element => {
  const truncatePrimaryOverflow = seatLabel.length > TRUNCATE_THRESHOLD
  const truncateSecondaryOverflow =
    String(floorLabel).length > TRUNCATE_THRESHOLD

  return (
    <SplitDescription
      primary={
        <HighlightedTextResult
          bold
          filterText={highlightedText}
          text={seatLabel}
        />
      }
      secondary={
        floorLabel && (
          <HighlightedTextResult
            bold
            filterText={highlightedText}
            text={floorLabel}
          />
        )
      }
      truncatePrimaryOverflow={truncatePrimaryOverflow}
      truncateSecondaryOverflow={truncateSecondaryOverflow}
    />
  )
}

export default Location
