// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import mapValues from 'lodash/mapValues'
import omit from 'lodash/fp/omit'
import some from 'lodash/some'
import gsap from 'gsap'

export default ({ runningAnimations }: { runningAnimations: any }) =>
  ({
    animationKey,
    dispatch,
    dispatchType,
    duration,
    ease,
    fromValues,
    onComplete,
    repeatOptions = {},
    toValues,
  }: {
    animationKey: string
    dispatch: any
    dispatchType: string
    duration: number
    ease: any
    fromValues: any
    onComplete?: any
    repeatOptions?: {
      repeat?: number
      yoyo?: boolean
    }
    toValues: any
  }) => {
    if (some(toValues)) {
      const currentValues = { ...fromValues }
      const previousValues = { ...fromValues }
      const animationParams = {
        ...toValues,
        ...repeatOptions,
        duration,
        ease,
        onUpdate: () => {
          const valuesWithoutGreenSock = omit(['_gsap'])(currentValues)

          dispatch({
            type: dispatchType,
            ...mapValues(
              valuesWithoutGreenSock,
              (value, key) => (value || 0) - (previousValues[key] || 0),
            ),
          })
          Object.assign(previousValues, valuesWithoutGreenSock)
        },
        onComplete: () => {
          // eslint-disable-next-line no-param-reassign
          runningAnimations[animationKey] = undefined

          if (typeof onComplete === 'function') {
            onComplete()
          }
        },
      }

      if (!runningAnimations[animationKey]) {
        // eslint-disable-next-line no-param-reassign
        runningAnimations[animationKey] = gsap.to(
          currentValues,
          animationParams,
        )
      }
    }
  }
