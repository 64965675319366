import { useQuery } from '@apollo/client'

import type { SeatPermissionEnum } from 'generated/graphql'

import { assignmentMethods } from 'common/constants/resources'
import { isBookingRequestRequired } from 'visual_directory/lib/hotDesking'

/* @ts-expect-error auto-src: non-strict-conversion */
import SeatDataQuery from './SeatData.graphql'
import type {
  Booking,
  Seat,
  SeatDesignation,
  RestrictedEmployee,
  SeatPermissionOccupancyPolicy,
} from './types'

type SeatsData = { seats: readonly Seat[] }

type Variables = {
  ids: readonly string[]
  atTime: string | null | undefined
}

type Loading = {
  isLoading: true
}

type Loaded = {
  activeBooking: Booking | null
  designation: SeatDesignation
  isAvailable: boolean
  isByRequestDesk: boolean
  isRequestRequired: boolean
  isLoading: false
  isPermitted: boolean
  isOneToOneRestricted: boolean
  oneToOneEmployee: RestrictedEmployee | null
  label: string
  nextAvailableTime: string | null
  nextBooking: Booking | null
  seatPermissionEmployees: readonly RestrictedEmployee[]
  seatPermissionOccupancyPolicy: SeatPermissionOccupancyPolicy | null
  seatPermissionType: SeatPermissionEnum
  seatAvailability: Seat['seatAvailability']
}

type Result = Loading | Loaded

interface NextBookingArgs {
  todaysBookings: readonly Booking[]
  hasActiveBooking: boolean
}

const nextBooking = ({
  todaysBookings,
  hasActiveBooking,
}: NextBookingArgs): Booking | null => {
  const activeAndUpcomingBookings = todaysBookings || []

  if (activeAndUpcomingBookings.length === 0) {
    return null
  }

  const nextBookingIndex = hasActiveBooking ? 1 : 0

  return activeAndUpcomingBookings[nextBookingIndex]
}

interface OneToOneEmployeeArgs {
  seatPermissionEmployees: readonly RestrictedEmployee[]
  isOneToOneRestricted: boolean
}

const oneToOneEmployee = ({
  seatPermissionEmployees,
  isOneToOneRestricted,
}: OneToOneEmployeeArgs): RestrictedEmployee | null => {
  if (seatPermissionEmployees.length === 0 || !isOneToOneRestricted) {
    return null
  }

  return seatPermissionEmployees[0]
}

const useData = (variables: Variables): Result => {
  const { loading: isLoading, data } = useQuery<SeatsData, Variables>(
    SeatDataQuery,
    {
      fetchPolicy: 'network-only',
      variables,
    },
  )

  const seat = (data?.seats || [])[0] || {}

  if (isLoading) {
    return {
      isLoading,
    }
  }

  const {
    activeBooking,
    assignmentMethod,
    bookingAvailability,
    designation,
    isPermitted,
    isOneToOneRestricted,
    label,
    seatAvailability,
    seatPermissionEmployees: seatPermissionEmployeesData,
    seatPermissionOccupancyPolicy,
    todaysBookings,
    seatPermissionType,
  } = seat

  const isByRequestDesk =
    assignmentMethod === assignmentMethods.BOOKABLE_BY_REQUEST

  const isRequestRequired = isBookingRequestRequired(seat)

  const seatPermissionEmployees = seatPermissionEmployeesData || []

  return {
    activeBooking,
    designation,
    isAvailable: bookingAvailability.available,
    isByRequestDesk,
    isRequestRequired,
    isLoading,
    isPermitted,
    isOneToOneRestricted,
    label,
    oneToOneEmployee: oneToOneEmployee({
      seatPermissionEmployees,
      isOneToOneRestricted,
    }),
    nextAvailableTime: bookingAvailability.nextAvailableTime,
    nextBooking: nextBooking({
      todaysBookings,
      hasActiveBooking: Boolean(activeBooking),
    }),
    seatAvailability,
    seatPermissionType,
    seatPermissionEmployees,
    seatPermissionOccupancyPolicy,
  }
}

export default useData
