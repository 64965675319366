/* @ts-expect-error auto-src: strict-conversion */
import zIndex from 'material-ui-old/styles/zIndex'
import StyleConstants from './definitions'

export const NavInputConstants = {
  inputBackgroundColor: StyleConstants.form.searchInputBackgroundColor,
  inputHeight: 30,
  inputHintColor: 'rgba(0, 0, 0, 0.4)',
  inputIconColor: StyleConstants.icon.iconColor,
  inputTextColor: StyleConstants.font.color.input,
  inputFontSize: '12px',
  closed: {
    inputBackgroundColor: StyleConstants.navbar.input.background,
    inputHintColor: '#FFFFFF',
    inputIconColor: '#FFFFFF',
    inputTextColor: '#FFFFFF',
  },
  open: {
    inputBackgroundColor: '#FFFFFF',
  },
  zIndex: {
    // eslint-disable-next-line no-magic-numbers
    icon: zIndex.drawer + 10,
    drawer: zIndex.drawer + 20, // eslint-disable-line no-magic-numbers
  },
}

export const navInputStyles = {
  hint: {
    color: NavInputConstants.inputHintColor,
    bottom: 'auto',
    boxSizing: 'border-box',
    fontSize: '12px',
    height: NavInputConstants.inputHeight,
    lineHeight: '17px',
    paddingTop: 6,
    paddingBottom: 7,
  },
  // pass into TextField inputStyle attribute, which styles the input element
  inputInternal: {
    boxSizing: 'border-box',
    color: NavInputConstants.inputTextColor,
    fontSize: StyleConstants.font.size.standard,
    height: NavInputConstants.inputHeight,
    lineHeight: '18px',
    paddingBottom: 7,
    paddingTop: 7,
  },
  inputDiv: {
    backgroundColor: NavInputConstants.inputBackgroundColor,
    border: `1px solid ${NavInputConstants.inputBackgroundColor}`,
    borderRadius: '2px',
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.24)',
    display: 'flex',
    height: NavInputConstants.inputHeight,
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
  },
  cancelIcon: {
    // pass into IconButton iconStyle attribute
    iconInternal: {
      color: NavInputConstants.inputIconColor,
      height: 16,
      width: 16,
    },
    // pass into IconButton style attribute
    iconButton: {
      height: NavInputConstants.inputHeight,
      padding: 7,
      width: 30,
    },
    iconButtonWrapper: {
      flex: '0 0 auto',
      width: 30,
    },
  },
  leftIcon: {
    // pass into IconButton iconStyle attribute
    iconInternal: {
      color: NavInputConstants.inputIconColor,
      height: 20,
      width: 20,
    },
    // pass into IconButton style attribute
    iconButton: {
      height: NavInputConstants.inputHeight,
      paddingBottom: 5,
      paddingLeft: 7,
      paddingRight: 6,
      paddingTop: 5,
      width: 33,
    },
    iconButtonWrapper: {
      flex: '0 0 auto',
      width: 33,
    },
  },
}
