import type { ValuesType } from 'utility-types'
import type { Seat } from './seat'

export const Types = {
  DAYS_OF_WEEK: 'SeatAvailabilityType::DaysOfWeek',
  WEEKS_OF_YEAR: 'SeatAvailabilityType::WeeksOfYear',
  ALWAYS: 'SeatAvailabilityType::Always',
  NEVER: 'SeatAvailabilityType::Never',
}
export type SeatAvailability = {
  __typename?: 'SeatAvailability'
  bookableSeatsCount?: number
  color?: string
  firstDayOfWeek?: string
  floorsCount?: number
  id: string
  isEditableByMe?: boolean
  isSystem?: boolean
  managedSeatsCount?: number
  name?: string
  projectedInvalidActiveBookingsCount?: number
  projectedInvalidBookingRequestsCount?: number
  projectedInvalidUpcomingBookingsCount?: number
  schedule?: ReadonlyArray<number> | null | undefined
  seats?: ReadonlyArray<Seat>
  type?: ValuesType<typeof Types>
}
export const emptySeatAvailability: SeatAvailability = {
  id: '',
}
