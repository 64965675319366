export const dialogStyles = {
  paperScrollPaper: {
    maxHeight: 'calc(100% - 8px)',
  },
}
export const titleStyles = {
  root: {
    padding: '8px 24px 4px 24px',
  },
}
export const contentStyles = {
  root: {
    marginBottom: 0,
  },
}
export const actionsStyles = {
  root: {
    padding: '0px 24px 4px 24px',
  },
}
