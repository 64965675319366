import React from 'react'
import type { ReactNode } from 'react'

import useStyles from './useStyles'

type Props = {
  primary?: ReactNode | null
  secondary?: ReactNode | null
  truncatePrimaryOverflow: boolean
  truncateSecondaryOverflow: boolean
}

const separator = <>&nbsp;•&nbsp;</>

const SplitDescription = ({
  primary,
  secondary,
  truncatePrimaryOverflow,
  truncateSecondaryOverflow,
}: Props): React.ReactElement => {
  const classes = useStyles({
    truncatePrimaryOverflow,
    truncateSecondaryOverflow,
  })

  if (!primary && !secondary) {
    return <div />
  }

  if (!primary) {
    return <div className={classes.secondary}>{secondary}</div>
  }

  if (!secondary) {
    return <div className={classes.primary}>{primary}</div>
  }

  return (
    <div className={classes.root}>
      <div className={classes.primary}>{primary}</div>
      {separator}
      <div className={classes.secondary}>{secondary}</div>
    </div>
  )
}

export default SplitDescription
