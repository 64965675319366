// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import type { OutputSelector } from 'reselect'
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'
import { fromJS, List } from 'immutable'
import type { OrderedMap } from 'immutable'
import { isMoveSchedulingAssignment } from 'common/lib/resource'
import {
  reversePointsIfNotClockwise,
  reversePointsIfClockwise,
} from 'common/lib/roomGeometry'
import { emptyFloor } from 'common/types/floor'
import type { Floor } from 'common/types/floor'
import type { Neighborhood } from 'common/types/neighborhood'
import type { Seat } from 'common/types/seat'
import OverlayLabelRecord from 'common/records/overlayLabel'
import BoundingBoxRecord from 'common/records/boundingBox'

type Settings = any
type Designation = {
  color: string
}
// FIXME: Convert this to an exact object
export const getFloorRooms = ({ floor }: { floor: any }) =>
  (floor && floor.rooms) || []
export const getFloorNeighborhoods = ({
  floor,
}: {
  floor: Floor | null | undefined
}) => (floor && floor.neighborhoods) || []

/* @ts-expect-error auto-src: strict-conversion */
const getFloorSeats = ({ floor }) => (floor && floor.seats) || []

/* @ts-expect-error auto-src: strict-conversion */
const getFloorUtilities = ({ floor }) => (floor && floor.utilities) || []

/* @ts-expect-error auto-src: strict-conversion */
const getFloorOverlayLabels = ({ floor }) =>
  List((floor && floor.overlayLabels) || [])

export const addYOffsetToPoints = (
  points: ReadonlyArray<[number, number]>,
  floorYOffset: number,
) => points.map<[number, number]>(([x, y]) => [x, y + floorYOffset])
export const addYOffsetToPointTuple = (
  [x, y]: ReadonlyArray<number>,
  yOffset: number,
) => [x, y + yOffset]
export const employeeSelector = (employee: any) => ({
  ...employee,
  type: 'employee',
})

const formatSeat = (record: Seat, floorYOffset: number) => ({
  floorId: parseInt(record.floor?.id || record.floorId || emptyFloor.id, 10),
  floorLabel: record.floor?.label,
  id: parseInt(record.id, 10),
  // To keep it consistent with resources fetched by JSON until we migrate it to graphql
  siteName: record.site?.name,
  y: (record.y || 0) + floorYOffset,
})

export const prepareSeat = (seat: any, floorYOffset: number) => ({
  ...seat,
  ...formatSeat(seat, floorYOffset),
  employee: seat.employee ? employeeSelector(seat.employee) : undefined,
})
export const prepareUtility = (utility: any, floorYOffset: number) => ({
  ...utility,
  ...formatSeat(utility, floorYOffset),
})

export type RoomAttribute = {
  id: string
  value?: string | undefined | null
  template: { name: string }
}

export type PreparedRoomAttribute = {
  id: string
  value?: string | undefined | null
  name: string
}
export const prepareAttributes = (
  attributes: ReadonlyArray<RoomAttribute>,
): ReadonlyArray<PreparedRoomAttribute> =>
  attributes.map(({ id, value, template: { name } }) => ({
    id,
    value,
    name,
  }))

export const getDesignationColor = (
  designation: Designation | null | undefined,
) => (designation ? designation.color.replace('0x', '#') : undefined)

type ResourceWithCoordinates = {
  center: ReadonlyArray<number>
}

export const getResourceAreaCoordinates = (
  resource: ResourceWithCoordinates,
  floorYOffset: number,
) => {
  const { center } = resource

  return {
    x: center ? center[0] : 0,
    y: center ? center[1] + floorYOffset : 0,
  }
}

export const getNeighborhoodAreaCoordinates = (
  resource: Neighborhood,
  floorYOffset: number,
) =>
  resource?.center
    ? getResourceAreaCoordinates(
        resource as ResourceWithCoordinates,
        floorYOffset,
      )
    : null

// FIXME: Convert this to an exact object
export const getYOffsetFromSettings = ({ settings }: { settings: any }) =>
  settings.floorYOffset

export type PreparedRoom<T> = Omit<T, 'designation' | 'attributes'> & {
  attributes: ReadonlyArray<PreparedRoomAttribute>
  color?: string
  floorId: number
  floorYOffset: number
  id: number
  isDisplayable: boolean
  siteId: string
  type: 'share' | 'room'
}

export type UnpreparedRoom = {
  attributes?: ReadonlyArray<RoomAttribute>
  center: ReadonlyArray<number>
  construct: string
  designation?: Designation | null | undefined
  floorId: string
  holes?: ReadonlyArray<any>
  id: string
  utilities?: ReadonlyArray<any>
  points?: ReadonlyArray<any>
  public: boolean
  seats?: ReadonlyArray<any>
  site: {
    id: string
  }
}

export function prepareRoom<T extends UnpreparedRoom>(
  room: T,
  floorYOffset: number,
): PreparedRoom<T> {
  return {
    ...room,
    id: parseInt(room.id, 10),
    // To keep it consistent with resources fetched by JSON until we migrate it to graphql
    ...getResourceAreaCoordinates(room, floorYOffset),
    center: addYOffsetToPointTuple(room.center, floorYOffset),
    attributes: (room.attributes && prepareAttributes(room.attributes)) || [],
    color: getDesignationColor(room.designation),
    designation: undefined,
    floorId: parseInt(room.floorId, 10),
    floorYOffset,
    holes: room.holes && room.holes.map(reversePointsIfNotClockwise),
    isDisplayable: room.public || room.construct === 'SHARE',
    points:
      room.points &&
      reversePointsIfClockwise(addYOffsetToPoints(room.points, floorYOffset)),
    seats: (room.seats || []).map((seat) => prepareSeat(seat, floorYOffset)),
    siteId: room.site && room.site.id,
    type: room.construct === 'SHARE' ? 'share' : 'room',
    utilities: (room.utilities || []).map((utility) =>
      prepareUtility(utility, floorYOffset),
    ),
  }
}
export const prepareNeighborhood = (
  neighborhood: Neighborhood,
  floorYOffset: number,
) => ({
  ...neighborhood,
  ...getNeighborhoodAreaCoordinates(neighborhood, floorYOffset),
  center: neighborhood.center
    ? addYOffsetToPointTuple(neighborhood.center, floorYOffset)
    : null,
  points:
    neighborhood.points &&
    reversePointsIfClockwise(
      addYOffsetToPoints(neighborhood.points, floorYOffset),
    ),
  shapes:
    neighborhood.shapes &&
    neighborhood.shapes.map((shape) => ({
      center: addYOffsetToPointTuple(shape.center, floorYOffset),
      points:
        shape.points &&
        reversePointsIfClockwise(
          addYOffsetToPoints(shape.points, floorYOffset),
        ),
    })),
  seats: (neighborhood.seats || []).map<any>((seat) =>
    prepareSeat(seat, floorYOffset),
  ),
})
export const roomsSelector: OutputSelector<any, any, any> = createSelector(
  getFloorRooms,
  getYOffsetFromSettings,
  /* @ts-expect-error auto-src: strict-conversion */
  (rooms, floorYOffset) => rooms.map((room) => prepareRoom(room, floorYOffset)),
)
export const neighborhoodsSelector: OutputSelector<any, any, any> =
  createSelector(
    getFloorNeighborhoods,
    getYOffsetFromSettings,
    (neighborhoods, floorYOffset) =>
      neighborhoods.map((neighborhood) =>
        prepareNeighborhood(neighborhood, floorYOffset),
      ),
  )

/* @ts-expect-error auto-src: strict-conversion */
const hashFn = (prev, next) => JSON.stringify(prev) === JSON.stringify(next)

const customCreateSelector = createSelectorCreator(defaultMemoize, hashFn)

export const seatsSelector: OutputSelector<any, any, any> =
  customCreateSelector(
    getFloorSeats,
    getYOffsetFromSettings,
    (
      seats,
      floorYOffset, // TODO: Filter these seats out in the backend.
    ) =>
      //
      // The `construct !== SHARE` check does not break Manager because construct field
      // is always undefined for Manager because Manager does not request that field.
      fromJS(
        seats
          .filter(
            /* @ts-expect-error auto-src: strict-conversion */
            (seat) =>
              seat.room?.construct !== 'SHARE' ||
              !isMoveSchedulingAssignment(seat.assignmentMethod),
          )
          /* @ts-expect-error auto-src: strict-conversion */
          .map((seat) => prepareSeat(seat, floorYOffset)),
      ),
  )
export const utilitiesSelector: OutputSelector<any, any, any> =
  customCreateSelector(
    getFloorUtilities,
    getYOffsetFromSettings,
    (utilities, floorYOffset) =>
      /* @ts-expect-error auto-src: strict-conversion */
      utilities.map((utility) => prepareUtility(utility, floorYOffset)),
  )
export const getAdjustedBoundingBox = ({
  boundingBox,
  floorYOffset,
}: {
  boundingBox: any
  floorYOffset: number
}) => {
  if (!boundingBox) {
    return
  }

  /* @ts-expect-error auto-src: non-strict-conversion */
  return BoundingBoxRecord<any>({
    ...boundingBox,
    yOffset: boundingBox.yOffset + floorYOffset,
  })
}
export const overlayLabelsSelector: OutputSelector<any, any, any> =
  createSelector(
    getFloorOverlayLabels,
    getYOffsetFromSettings,
    (overlayLabels, floorYOffset) =>
      fromJS(
        overlayLabels.map((labelData) =>
          /* @ts-expect-error auto-src: non-strict-conversion */
          OverlayLabelRecord<any>({
            /* @ts-expect-error auto-src: non-strict-conversion */
            ...labelData,
            /* @ts-expect-error auto-src: non-strict-conversion */
            label: labelData.label.replace(/\r/g, '\n', 'g'),
            /* @ts-expect-error auto-src: non-strict-conversion */
            y: labelData.y + floorYOffset,
          }),
        ),
      ),
  )
type SelectedFloorSelectorProps = {
  data: any
  settings: Settings
}
export const getFloor = ({ data }: SelectedFloorSelectorProps) =>
  data.floors && data.floors[0]
export const getLoading = ({ data }: SelectedFloorSelectorProps) => data.loading
export const getSettings = ({ settings }: SelectedFloorSelectorProps) =>
  settings
export const selectedFloorSelector: OutputSelector<any, any, any> =
  createSelector(
    [getFloor, getLoading, getSettings],
    (floor, isLoading, settings) =>
      (
        fromJS({
          ...floor,
          floorOnlyBoundingBox: getAdjustedBoundingBox({
            boundingBox: floor?.floorOnlyBoundingBox,
            floorYOffset: settings.floorYOffset,
          }),
          floorPlanBoundingBox: getAdjustedBoundingBox({
            boundingBox: floor?.floorPlanBoundingBox,
            floorYOffset: settings.floorYOffset,
          }),
          iconScale: floor ? floor.iconScale / 100.0 : 1.0,
          id: floor ? parseInt(floor.id, 10) : undefined,
          isLoading,
          labelZoomThreshold: settings.labelZoomThreshold,
          overlayLabels: overlayLabelsSelector({
            settings,
            floor,
          }),
          rooms: roomsSelector({
            settings,
            floor,
          }),
          seats: seatsSelector({
            settings,
            floor,
          }),
          utilities: utilitiesSelector({
            settings,
            floor,
          }),
        }) as any
      ).set(
        // Avoid using immutable on new elements
        'neighborhoods',
        neighborhoodsSelector({
          settings,
          floor,
        }),
      ) as OrderedMap<string, any>,
  )
