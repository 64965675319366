import { Record } from 'immutable'

interface ClickableResource {
  resourceId: number
  x: number
  y: number
}

export interface SelectedResourceShape {
  floorGroupId?: string
  id: number
  imageFailed?: boolean
  lastClickInShape?: ClickableResource
  processing?: boolean
  type?: string
}

const defaultValues = {
  floorGroupId: undefined,
  id: -1,
  imageFailed: undefined,
  lastClickInShape: undefined,
  processing: undefined,
  type: undefined,
}

const SelectedResource = Record<SelectedResourceShape>(defaultValues)

export default SelectedResource
