import { useMemo } from 'react'
import useWindowResize from 'common/hooks/useWindowResize'
import getViewportCategories from 'common/selectors/viewport'
import type { BrandingState } from 'visual_directory/reducers/branding'
import styles from '../components/styles'
import useBranding from './useBranding'

export type ResourceSheetNavbarStyles = ReturnType<
  typeof styles.resourceSheetNavbar
>

export type BrandedStyles = {
  deskBookingFloorListHelpCard: ReturnType<
    typeof styles.deskBookingFloorListHelpCard
  >
  deskBookingSelectHotDeskHelpCard: ReturnType<
    typeof styles.deskBookingSelectHotDeskHelpCard
  >
  brandedCompactFloorPicker: ReturnType<typeof styles.brandedCompactFloorPicker>
  brandedCompactSitePicker: ReturnType<typeof styles.brandedCompactSitePicker>
  brandedResourceSheet: ReturnType<typeof styles.brandedResourceSheet>
  brandedCompactSearchBar: ReturnType<typeof styles.brandedCompactSearchBar>
  browsePanel: ReturnType<typeof styles.browsePanel>
  compactNavbar: ReturnType<typeof styles.compactNavbar>
  directoryCurtain: ReturnType<typeof styles.directoryCurtain>
  dockedCard: ReturnType<typeof styles.dockedCard>
  employeeAsField: ReturnType<typeof styles.employeeAsField>
  extendedNavbar: ReturnType<typeof styles.extendedNavbar>
  extendedSearchBarClosed: ReturnType<typeof styles.extendedSearchBarClosed>
  extendedSearchBarOpen: ReturnType<typeof styles.extendedSearchBarOpen>
  floorMapNavbar: ReturnType<typeof styles.floorMapNavbar>
  floorPicker: ReturnType<typeof styles.floorPicker>
  moveForm: ReturnType<typeof styles.moveForm>
  navbarCommon: ReturnType<typeof styles.navbarCommon>
  kioskNavbarPortrait: ReturnType<typeof styles.kioskNavbarPortrait>
  primaryFlowNavbar: ReturnType<typeof styles.primaryFlowNavbar>
  requestFormAttachmentsStyles: ReturnType<
    typeof styles.requestFormAttachmentsStyles
  >
  resourceSheetNavbar: ResourceSheetNavbarStyles
  spinner: ReturnType<typeof styles.spinner>
  summarySheetNavbar: ReturnType<typeof styles.summarySheetNavbar>
  typeahead: ReturnType<typeof styles.typeahead>
  unbrandedSearchBar: ReturnType<typeof styles.unbrandedSearchBar>
}

export const brandStyles = (stylesFunctionProps: {
  branding: BrandingState['config']
  viewportCategories: ReturnType<typeof getViewportCategories>
}): BrandedStyles => ({
  deskBookingFloorListHelpCard:
    styles.deskBookingFloorListHelpCard(stylesFunctionProps),
  deskBookingSelectHotDeskHelpCard:
    styles.deskBookingSelectHotDeskHelpCard(stylesFunctionProps),
  brandedCompactFloorPicker:
    styles.brandedCompactFloorPicker(stylesFunctionProps),
  brandedCompactSitePicker:
    styles.brandedCompactSitePicker(stylesFunctionProps),
  brandedResourceSheet: styles.brandedResourceSheet(stylesFunctionProps),
  brandedCompactSearchBar: styles.brandedCompactSearchBar(stylesFunctionProps),
  browsePanel: styles.browsePanel(stylesFunctionProps),
  compactNavbar: styles.compactNavbar(stylesFunctionProps),
  directoryCurtain: styles.directoryCurtain(stylesFunctionProps),
  dockedCard: styles.dockedCard(stylesFunctionProps),
  employeeAsField: styles.employeeAsField(stylesFunctionProps),
  extendedNavbar: styles.extendedNavbar(stylesFunctionProps),
  extendedSearchBarClosed: styles.extendedSearchBarClosed(stylesFunctionProps),
  extendedSearchBarOpen: styles.extendedSearchBarOpen(stylesFunctionProps),
  floorMapNavbar: styles.floorMapNavbar(stylesFunctionProps),
  floorPicker: styles.floorPicker(stylesFunctionProps),
  moveForm: styles.moveForm(stylesFunctionProps),
  navbarCommon: styles.navbarCommon(stylesFunctionProps),
  kioskNavbarPortrait: styles.kioskNavbarPortrait(stylesFunctionProps),
  primaryFlowNavbar: styles.primaryFlowNavbar(stylesFunctionProps),
  requestFormAttachmentsStyles:
    styles.requestFormAttachmentsStyles(stylesFunctionProps),
  resourceSheetNavbar: styles.resourceSheetNavbar(stylesFunctionProps),
  spinner: styles.spinner(stylesFunctionProps),
  summarySheetNavbar: styles.summarySheetNavbar(stylesFunctionProps),
  typeahead: styles.typeahead(),
  unbrandedSearchBar: styles.unbrandedSearchBar(),
})

const useBrandedStyles = (): BrandedStyles => {
  const branding = useBranding()
  const viewportCategories = useWindowResize(getViewportCategories)

  return useMemo(
    () => brandStyles({ branding: branding.config, viewportCategories }),
    [branding, viewportCategories],
  )
}

export default useBrandedStyles
