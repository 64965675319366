// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default (pluralResourceType: string) => {
  switch (pluralResourceType) {
    case 'utilities':
      return 'utility'

    default:
      return pluralResourceType.slice(0, -1)
  }
}
