// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ActionTypes from '../constants/actionTypes'

/* @ts-expect-error auto-src: noflow */
export const acknowledgeNotification = (id) => ({
  type: ActionTypes.Notification.ACKNOWLEDGE,
  id,
})
/* @ts-expect-error auto-src: noflow */
export const acknowledgeNotificationType = (notificationType) => ({
  type: ActionTypes.Notification.ACKNOWLEDGE_TYPE,
  notificationType,
})
/* @ts-expect-error auto-src: noflow */
export const addNotification = (type, params) => ({
  type: ActionTypes.Notification.ADD,
  notificationType: type,
  params,
})
