import React from 'react'
import type { ReactNode } from 'react'

import useStyles from './useStyles'

type Props = {
  children?: ReactNode
}

const GroupHeader = ({ children }: Props): JSX.Element => {
  const classes = useStyles()

  return <div className={classes.root}>{children}</div>
}

export default GroupHeader
