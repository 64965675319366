import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

import SeatAvailabilityLabel from 'common/components/SeatAvailabilityLabel'
import { useWhoIsIn } from 'visual_directory/components/who_is_in'

import Layout, { SecondaryButton } from '../Layout'
import useAdjustedCardPosition from '../useAdjustedCardPosition'
import useData from './useData'

type Props = {
  id: string
}

const MoveSchedulingDeskCard = ({ id }: Props): JSX.Element => {
  const { loading, seat } = useData({ ids: [id] })
  const { close: closeWhoIsIn } = useWhoIsIn()

  useAdjustedCardPosition({ skip: loading })

  if (loading) {
    return <Layout label={<Skeleton />} title={<Skeleton />} />
  }

  const { employee, seatAvailability } = seat

  if (!employee) {
    return <Layout label={seat.label} title="Vacant Desk" />
  }

  const openInfoCard = closeWhoIsIn

  const viewMoreButton = (
    <SecondaryButton onClick={openInfoCard}>VIEW MORE</SecondaryButton>
  )

  return (
    <Layout
      footerRight={viewMoreButton}
      imageUrl={employee.photo}
      label={seat.label}
      title={employee.fullName}
    >
      {employee.title}

      {seatAvailability && (
        <em>
          <SeatAvailabilityLabel availability={seatAvailability} weeks="none" />
        </em>
      )}
    </Layout>
  )
}

export default MoveSchedulingDeskCard
