import React from 'react'
import { resetResource as resetResourceAction } from 'visual_directory/actions/resource'
import useAppDispatch from 'visual_directory/hooks/useAppDispatch'
import {
  useDateTimePicker,
  DropdownLayout,
  formatDate,
} from 'visual_directory/components/DateTimePicker'

import DropdownButton from './Button'

type Props = {
  siteId: string
}

const DesktopRosterDateTimePicker = ({ siteId }: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const {
    actions,
    datePicker,
    handleClose,
    handleOpen,
    infoText,
    isDisabled,
    isNow,
    isOpen,
    isToday,
    selectedDateTime,
    timePicker,
  } = useDateTimePicker({
    location: { type: 'site', id: siteId },
    afterDateTimeSubmit: () => dispatch(resetResourceAction()),
  })

  return (
    <DropdownLayout
      inputButton={
        <DropdownButton
          isDisabled={isDisabled}
          isOpen={isOpen}
          onClick={handleOpen}
        >
          {formatDate(selectedDateTime, { isToday, isNow })}
        </DropdownButton>
      }
      isOpen={isOpen}
      onClose={handleClose}
      tooltipText={infoText}
    >
      {datePicker}
      {timePicker}
      {actions}
    </DropdownLayout>
  )
}

export default DesktopRosterDateTimePicker
