import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import type { MenuProps } from '@material-ui/core/Menu'
import Menu from '@material-ui/core/Menu'
import MoreIcon from '@material-ui/icons/MoreHoriz'

type Props = MenuProps

const ContextMenu = ({ children }: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div data-testid="context-menu">
      <IconButton
        aria-haspopup="true"
        aria-label="menu"
        data-testid="context-menu-button"
        onClick={handleClick}
        size="small"
      >
        <MoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {children}
      </Menu>
    </div>
  )
}

export default ContextMenu
