/* @ts-expect-error auto-src: strict-conversion */
import MuiOldTransitions from 'material-ui-old/styles/transitions' // NOTE: We prefer easeOut from 0.XX over 1.0

const morphDuration = 0.6
const AnimationConstants = {
  alphabetSelector: {
    duration: 1,
    ease: 'expo.out',
  },
  fadeContents: {
    duration: morphDuration,
    ease: MuiOldTransitions.easeOut,
  },
  morphContainer: {
    duration: morphDuration,
    ease: MuiOldTransitions.easeOut,
  },
  slideFloorPlan: {
    duration: 0.3,
    ease: 'power4.out',
  },
}

export default AnimationConstants
