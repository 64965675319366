import { makeStyles } from '@material-ui/styles'

/* @ts-expect-error auto-src: non-strict-conversion */
export default makeStyles(({ palette }) => ({
  root: {
    flexShrink: 0,
    padding: '10px 16px 12px 16px',
    marginBottom: 8,
    background: palette.common.white,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
  },
  heading: {
    height: 24,
  },
  icon: {
    display: 'inline-block',
    verticalAlign: 'top',
    width: 24,
    height: 24,
    color: palette.grey[600],
  },
  title: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginLeft: 8,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '20px',
    color: palette.grey[900],
  },
  closeButton: {
    marginTop: -4,
    padding: 2,
    float: 'right',
  },
  closeIcon: {
    width: 24,
    height: 24,
  },
  subheading: {
    marginTop: 8,
    fontSize: 12,
    lineHeight: '16px',
    color: palette.grey[600],
  },
  dateFilterWrapper: {
    height: 30,
    marginTop: 12,
  },
  locationFilterWrapper: {
    height: 30,
    marginTop: 8,
  },
}))
