import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  root: {
    alignItems: 'center',
    backgroundColor: '#E0E0E0',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  icon: {
    color: '#BDBDBD',
    height: 50,
    width: 50,
  },
})
