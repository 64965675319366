import sortBy from 'lodash/sortBy'

import type { SeatEmployeeT } from './types'

const employeesAssignmentLabel = (
  employees: ReadonlyArray<SeatEmployeeT>,
): string => {
  if (employees.length === 0) {
    return ''
  }

  const prefix = employees.length === 1 ? 'Assigned to' : 'Restricted to'
  const suffix = employees.length > 1 ? ` +${employees.length - 1}` : ''

  const firstByLastNameEmployee = sortBy(employees, 'lastName')[0]

  return `${prefix} ${firstByLastNameEmployee.fullName}${suffix}`
}

export default employeesAssignmentLabel
