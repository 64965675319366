import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'

import { agnosticNowInZone, isSameDay } from 'common/lib/timeUtilities'
import { timeStringToHour, timeStringToTime } from 'common/types/time'

import { useVDTime } from 'visual_directory/components/VDTimeProvider'

import {
  dayAvailabilityCheck,
  dayStartTime,
  hourAvailabilityCheck,
} from './lib'
import useData from './useData'
import type { Location } from './useData'
import DatePicker from './DatePicker'
import TimePicker, { buildTimePickerSlots } from './TimePicker'
import ActionButtons from './ActionButtons'

type Input = {
  location: Location
  afterDateTimeSubmit?: () => void
}

type Output = {
  actions: JSX.Element
  datePicker: JSX.Element
  handleClose: () => void
  handleOpen: () => void
  infoText?: string
  isDisabled?: boolean
  isNow: boolean
  isOpen: boolean
  isToday: boolean
  selectedDateTime: Date
  timePicker: JSX.Element
}

const UNAVAILABLE_TOOLTIP =
  'Viewing future availability is not available ' +
  'for floors with no bookable desks'

const usePickerState = (isInitiallyOpen: boolean) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen)

  return {
    isOpen,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  }
}

const useDateTimePicker = ({
  location,
  afterDateTimeSubmit,
}: Input): Output => {
  const { dateTime, changeDateTime, loading: loadingTimedData } = useVDTime()
  const { data, loading: loadingPickerData } = useData({ location })

  const {
    bookableDeskCount,
    timeZone,
    calendarStartDay,
    limitToOperatingHours = false,
    operatingWeekDays = [],
    startsOfDay = [],
    endsOfDay = [],
  } = data || {}

  const loading = loadingPickerData || loadingTimedData

  const bookingsUnavailable = bookableDeskCount === 0
  const infoText = bookingsUnavailable ? UNAVAILABLE_TOOLTIP : undefined
  const now = agnosticNowInZone(timeZone)
  const selectedDateTimeString = dateTime || now.format('YYYY-MM-DD')
  const deskBookableUntil = data?.deskBookableUntil
    ? new Date(data.deskBookableUntil)
    : undefined

  const handleChange = (dateTimeString: string | null) =>
    changeDateTime(dateTimeString)

  const defaultTime = dayStartTime({
    operatingWeekDays,
    startsOfDay,
    now,
  })
  const isDayAvailable = dayAvailabilityCheck({
    limitToOperatingHours,
    operatingWeekDays,
  })
  const isHourAvailable = hourAvailabilityCheck({
    startsOfDay,
    endsOfDay,
  })

  const [stringDay, stringTime] = selectedDateTimeString.split('T')
  const selectedDateTime = moment(selectedDateTimeString).toDate()
  const [selectedTime, selectTime] = useState<string | null>(stringTime)
  const [selectedDay, selectDay] = useState<Date>(selectedDateTime)

  const handleTimeChange = (time: string) => {
    selectTime(time)
  }
  const handleDayChange = (day: Date) => {
    if (!selectedTime) {
      selectTime(defaultTime(day))
    }

    const defaultTimeString = defaultTime(day)
    const minTime = defaultTimeString
      ? timeStringToHour(defaultTimeString)
      : now

    if (selectedTime && timeStringToHour(selectedTime) < minTime) {
      selectTime(defaultTime(day))
    }

    selectDay(day)
  }

  useEffect(() => {
    selectDay(moment(stringDay).toDate())
    selectTime(stringTime)
  }, [stringDay, stringTime, selectDay])

  const isHourAvailableTheSelectedDay = (hour: number) =>
    isHourAvailable(hour, selectedDay)

  const datePicker = (
    <DatePicker
      firstDayOfWeek={calendarStartDay}
      isDayAvailable={isDayAvailable}
      now={now}
      onChange={handleDayChange}
      selectedDay={selectedDay}
      untilDay={deskBookableUntil}
    />
  )

  const timeSlots = buildTimePickerSlots({
    disableUnavailableHours: limitToOperatingHours,
    isHourAvailable: isHourAvailableTheSelectedDay,
    isToday: isSameDay(selectedDay, now),
    now,
  })

  const timePicker = (
    <TimePicker
      now={now}
      onChange={handleTimeChange}
      placeholder={selectedTime}
      timeSlots={timeSlots}
      value={selectedTime ? timeStringToTime(selectedTime) : null}
    />
  )

  const { isOpen, open: handleOpen, close: handleClose } = usePickerState(false)

  const selectedDateTimeValue = (): string | null => {
    if (!selectedTime) {
      return null
    }

    const date = moment(selectedDay).format('YYYY-MM-DD')

    return `${date}T${selectedTime}`
  }

  const handleSubmit = () => {
    handleChange(selectedDateTimeValue())
    handleClose()

    if (afterDateTimeSubmit) {
      afterDateTimeSubmit()
    }
  }

  const actions = (
    <ActionButtons onCancel={handleClose} onConfirm={handleSubmit} />
  )

  const isInitiallyToday = stringDay === now.format('YYYY-MM-DD')

  return {
    actions,
    datePicker,
    handleClose,
    handleOpen,
    infoText,
    isDisabled: loading || bookingsUnavailable,
    isNow: Boolean(!stringTime),
    isOpen,
    isToday: isInitiallyToday,
    selectedDateTime,
    timePicker,
  }
}

export default useDateTimePicker
