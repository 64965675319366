import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  popover: {
    marginTop: 6,
  },
})

export default useStyles
