import React from 'react'
import Chip from '@material-ui/core/Chip'

import EmployeeAvatar from 'common/components/avatar/EmployeeAvatar'
import SplitDescription from 'visual_directory/components/who_is_in/SplitDescription'
import useStyles from './useStyles'

type Props = {
  label: string
  name?: string
  time: string
  photoUrl?: string
}

const NextBooking = ({
  label,
  name,
  time,
  photoUrl,
}: Props): React.ReactElement => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.label}>{label}</div>
      <Chip
        avatar={
          <EmployeeAvatar
            fallbackPhotoSize="large"
            photo={photoUrl}
            size={22}
          />
        }
        classes={{
          root: classes.chip,
        }}
        label={
          <SplitDescription
            primary={name}
            secondary={time}
            truncatePrimaryOverflow
            truncateSecondaryOverflow={false}
          />
        }
        size="small"
      />
    </div>
  )
}

export default NextBooking
