import { makeStyles } from '@material-ui/core/styles'

const HEIGHT_WITH_IMAGE = 122
const HEIGHT_WITH_FOOTER = 122
const HEIGHT_WITH_LABEL = 64
const HEIGHT_WITH_TITLE_ONLY = 32
const WIDTH_WITH_IMAGE = 332
const WIDTH_WITH_NO_IMAGE = 300
const FOOTER_ELEMENTS_MINIMUM_SPACING = 20

type MinHeightArgs = {
  footerPresent?: boolean
  imagePresent?: boolean
  labelPresent?: boolean
}
type Args = MinHeightArgs

const minHeight = ({
  footerPresent,
  imagePresent,
  labelPresent,
}: MinHeightArgs) => {
  if (imagePresent) {
    return HEIGHT_WITH_IMAGE
  }

  if (labelPresent && !footerPresent) {
    return HEIGHT_WITH_LABEL
  }

  if (footerPresent) {
    return HEIGHT_WITH_FOOTER
  }

  return HEIGHT_WITH_TITLE_ONLY
}

export default makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'stretch',
    minHeight: ({ imagePresent, footerPresent, labelPresent }: Args) =>
      minHeight({
        imagePresent,
        footerPresent,
        labelPresent,
      }),
    width: ({ imagePresent }: Args) =>
      imagePresent ? WIDTH_WITH_IMAGE : WIDTH_WITH_NO_IMAGE,
  },
  body: {
    marginTop: 4,
    fontSize: 10,
    color: palette.grey[600],
  },
  details: {
    padding: '4px 8px 8px 8px',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  content: {
    flex: '1 0 auto',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '&> * + *': {
      marginLeft: FOOTER_ELEMENTS_MINIMUM_SPACING,
    },
  },
  label: {
    fontSize: 10,
    color: palette.grey[900],
    maxWidth: 128,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  title: {
    fontSize: 12,
    fontWeight: 600,
    color: palette.grey[900],
    marginTop: 4,
    lineHeight: '16px',
    paddingRight: 16,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  toolbar: {
    position: 'absolute',
    right: 32,
    top: 0,
    height: 14,
  },
}))
