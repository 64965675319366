import { makeStyles } from '@material-ui/styles'
import StyleDefinitions from 'visual_directory/constants/definitions'

const hideShadowOfNavbar = {
  position: 'relative',
  zIndex: StyleDefinitions.zIndex.navbar + 1,
} as const

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    backgroundColor: '#eeeeee',
    color: '#212121',
    display: 'flex',
    fontSize: 14,
    lineHeight: '20px',
    ...hideShadowOfNavbar,
  },
})

export default useStyles
