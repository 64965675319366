import type { RoomConstructEnum, SeatAssignmentMethod } from 'generated/graphql'
import { isFreeAddressAssignment } from 'common/lib/resource'

export const isInsideOfShare = (seat?: {
  room?: { construct?: RoomConstructEnum }
}): boolean => seat?.room?.construct === 'SHARE'

export const isFreeAddressDesk = (seat: {
  assignmentMethod: SeatAssignmentMethod
}): boolean => isFreeAddressAssignment(seat.assignmentMethod)
