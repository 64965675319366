import { createContext } from 'react'

export type ContextValue = {
  isOpen: boolean
  open: () => void
  close: () => void
}
/* @ts-expect-error auto-src: non-strict-conversion */
const WhoIsInContext = createContext<ContextValue | null | undefined>()

WhoIsInContext.displayName = 'WhoIsInContext'
export default WhoIsInContext
