import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import isEqual from 'lodash/isEqual'

import moment from 'moment-timezone'

import usePrevious from 'common/hooks/usePrevious'
import type { AssignmentMethodEnum } from 'common/constants/resources'
/* @ts-expect-error no types for query */
import whoIsInRosterItemsQuery from './whoIsInRosterItems.graphql'

export type Location = {
  type: 'site' | 'floor' | 'neighborhood'
  id: string
}

type Variables = {
  location: Location
  atTime: string | null
  searchTerm: string | null
  first?: number
}

type Booking = {
  id: string
  isConfirmable: boolean
  confirmedAt: string | null
  startTime: string
  endTime: string
}

type Floor = {
  id: string
  label: string
}

type Seat = {
  id: string
  label: string
  floor: Floor
  assignmentMethod: AssignmentMethodEnum
}

type Node = {
  employee: {
    id: string
    firstName: string
    lastName: string
    photo: string
  }
  booking: Booking | null
  seat: Seat
}

type Edge = { node: Node }

type Nodes = ReadonlyArray<Node>

type Data = {
  deskBookingSettings: {
    checkInEnabled: boolean
  }
  whoIsInRosterItems: {
    edges: ReadonlyArray<Edge>
    expectedCount: number
    checkedInCount: number
    pageInfo: {
      startCursor: string | null
      endCursor: string | null
      hasNextPage: boolean
    }
  }
}

export type RosterItem = {
  firstName: string
  lastName: string
  photo: string
  hasCheckedIn: boolean
  bookingTime: string | null
  seat: Seat
}

type Args = {
  variables: Variables
  skip?: boolean
}

export type Result = {
  checkInEnabled: boolean
  checkedInCount: number
  expectedCount: number
  isLoading: boolean
  loadMore: () => void
  rosterItems: ReadonlyArray<RosterItem>
}

type RosterState = {
  nodes: Nodes
  expectedCount: number
  checkedInCount: number
  checkInEnabled: boolean
}

const bookingTime = (booking: Booking | null) => {
  if (!booking) {
    return null
  }

  const startTimeString = moment.utc(booking.startTime).format('h:mm A')
  const endTimeString = moment.utc(booking.endTime).format('h:mm A')

  return `${startTimeString} – ${endTimeString}`
}

const hasEmployeeCheckedIn = (booking: Booking | null) => {
  if (!booking) {
    return false
  }

  return Boolean(booking.confirmedAt)
}

const nodeToRosterItem = ({ booking, employee, seat }: Node): RosterItem => ({
  firstName: employee.firstName,
  lastName: employee.lastName,
  photo: employee.photo,
  hasCheckedIn: hasEmployeeCheckedIn(booking),
  bookingTime: bookingTime(booking),
  seat: { ...seat },
})

const emptyPageInfo = {
  hasNextPage: false,
  endCursor: '',
}

const defaultRosterState = {
  nodes: [],
  expectedCount: 0,
  checkedInCount: 0,
  checkInEnabled: false,
}

const useData = ({ variables, skip }: Args): Result => {
  const [
    { nodes, expectedCount, checkedInCount, checkInEnabled },
    setRosterState,
  ] = useState<RosterState>(defaultRosterState)
  const previousVariables = usePrevious(variables)
  const variablesHasChanged = !isEqual(variables, previousVariables)

  const { data, fetchMore, loading } = useQuery<Data, Variables>(
    whoIsInRosterItemsQuery,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables,
      skip,
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ whoIsInRosterItems, deskBookingSettings }) => {
        const {
          edges,
          checkedInCount: newCheckedInCount,
          expectedCount: newExpectedCount,
        } = whoIsInRosterItems

        setRosterState({
          checkInEnabled: deskBookingSettings.checkInEnabled,
          checkedInCount: newCheckedInCount,
          expectedCount: newExpectedCount,
          nodes: edges.map(({ node }: Edge) => node),
        })
      },
    },
  )

  const { hasNextPage, endCursor } =
    data?.whoIsInRosterItems.pageInfo || emptyPageInfo

  const loadMore = useCallback(() => {
    if (hasNextPage) {
      fetchMore({ variables: { after: endCursor } })
    }
  }, [fetchMore, hasNextPage, endCursor])

  useEffect(() => {
    if (variablesHasChanged) {
      setRosterState(defaultRosterState)
    }
  }, [setRosterState, variablesHasChanged])

  return {
    isLoading: loading,
    rosterItems: nodes.map(nodeToRosterItem),
    checkedInCount,
    checkInEnabled,
    expectedCount,
    loadMore,
  }
}

export default useData
