import { useQuery } from '@apollo/client'
/* @ts-expect-error auto-src: non-strict-conversion */
import roomScreensConfigurationQuery from './roomScreensConfigurationQuery.graphql'

type DataT = {
  roomScreensConfiguration: {
    configured: boolean
  }
}
type ResultT = {
  configured: boolean
  loading: boolean
}

const useRoomScreensConfiguration = (): ResultT => {
  const { data, loading } = useQuery<DataT, void>(roomScreensConfigurationQuery)
  const configured = data?.roomScreensConfiguration.configured || false

  return {
    configured,
    loading,
  }
}

export default useRoomScreensConfiguration
