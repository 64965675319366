// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import escapeRegExp from 'lodash/escapeRegExp'

/* @ts-expect-error auto-src: noflow */
const highlightText = ({ text = '', filterText, bold }) => {
  if (!filterText) {
    return text
  }

  const tag = bold ? 'span' : 'mark'
  const pattern = new RegExp(escapeRegExp(filterText.trim()), 'gi')

  return text.replace(pattern, (match) => `<${tag}>${match}</${tag}>`)
}

export default highlightText
