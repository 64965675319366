// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import useStyles from './styles'

type Props = {
  children: React.ReactNode
}

const LabelContainer = ({ children }: Props) => {
  const classes = useStyles()

  return <span className={classes.label}>{children}</span>
}

export default LabelContainer
