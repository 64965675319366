import React from 'react'
import type { ReactNode } from 'react'
import useStyles from './useStyles'
import image from './image.png'

type Props = {
  title: string
  subtitle: ReactNode
}

const EmptyState = ({ title, subtitle }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}</div>
      <div className={classes.subtitle}>{subtitle}</div>
      <img alt="title" className={classes.image} src={image} />
    </div>
  )
}

export default EmptyState
