import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Products from '../../constants/products'
import { navMenuStyle } from '../styles/navmenu'

type Props = {
  product: React.ReactNode
  registered: boolean
}
const useStyles = makeStyles({
  navMenuItemTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  classicIcon: {
    height: 18,
    width: 52,
    color: 'rgb(87, 87, 87)',
    borderRadius: 2,
    backgroundColor: 'rgb(224, 224, 224)',
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '18px',
    textAlign: 'center',
    marginLeft: 9,
  },
})

const MenuItemTitle = ({ product, registered }: Props): React.ReactNode => {
  const classes = useStyles()

  return (
    <div className={classes.navMenuItemTitle}>
      {product}
      {/* @ts-expect-error auto-src: non-strict-conversion */}
      {registered && <span style={navMenuStyle.superscript}>®</span>}
      {(product === Products.REPORTS || product === Products.MOVE_MANAGER) && (
        <div className={classes.classicIcon}>Classic</div>
      )}
    </div>
  )
}

export default MenuItemTitle
