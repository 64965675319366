// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import type { CSSProperties, ReactNode } from 'react'
import useNavbarHeight from 'visual_directory/hooks/useNavbarHeight'
import useStyles from './useStyles'

export type NavbarStyles = {
  navbarBackground: CSSProperties
  secondaryBar?: CSSProperties
  title?: CSSProperties
  titleBar?: CSSProperties
}

export type Props = {
  children?: ReactNode
  iconElementLeft?: any
  title?: ReactNode
  iconElementRight?: any
  isPrimary?: boolean
  secondaryBar?: any
  styles: NavbarStyles
}

const BaseNavbar = ({
  children,
  iconElementLeft,
  iconElementRight,
  isPrimary,
  secondaryBar,
  styles,
  title,
}: Props): JSX.Element => {
  const isLeftIconPresent = Boolean(iconElementLeft)
  const navbarHeight = useNavbarHeight()

  const classes = useStyles({ isLeftIconPresent, navbarHeight })

  return (
    <div
      data-testid="navbar"
      id={isPrimary ? 'navbar' : undefined}
      style={styles.navbarBackground}
    >
      <div style={styles.titleBar}>
        {iconElementLeft && (
          <div className={classes.iconLeft}>{iconElementLeft}</div>
        )}
        <div className={classes.title} style={styles.title}>
          {title}
        </div>
        <div className={classes.iconRight}>{iconElementRight}</div>
      </div>
      {secondaryBar && (
        <div className={classes.secondaryBar} style={styles.secondaryBar}>
          {secondaryBar}
        </div>
      )}
      {children}
    </div>
  )
}

export default BaseNavbar
