// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import searchBar from './searchBar'

export default () => {
  const { hint, cancelIcon, leftIcon, inputDiv, inputInternal } = searchBar()

  return {
    location: {
      navbar: {
        hint,
        cancelIcon,
        leftIcon,
        inputDiv: { ...inputDiv, marginRight: '15px' },
        inputInternal,
        outerDiv: {
          alignItems: 'center',
          backgroundColor: '#bdbdbd',
          display: 'flex',
          height: '60px',
          justifyContent: 'left',
          width: '100%',
        },
      },
    },
  }
}
