// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import type { ReactNode } from 'react'
import MenuIcon from '@material-ui/icons/Menu'
import { Divider, SwipeableDrawer } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import isIOS from 'common/lib/isIOS'
import useFlags from 'common/hooks/useFlags'
import NavbarIconButton from 'common/components/navbar/NavbarIconButton'
import { navMenuStyle } from 'common/components/styles/navmenu'

type Props = {
  disabled?: boolean
  footer?: ReactNode
  iconStyle?: any
  isOpen: boolean
  navItems?: ReactNode
  onClose: () => void
  onOpen: () => void
  productLinks?: ReactNode
  userSummary?: ReactNode
}

const SWIPEABLE_AREA_WIDTH = 20

const useStyles = makeStyles({
  buttonWrapper: {
    flexShrink: 0,
    width: 53,
  },
  button: {
    height: 42,
    marginLeft: 6,
    maxWidth: 42,
    padding: 0,
    width: '100%',
  },
})

const UnconnectedBaseNavMenu = ({
  isOpen,
  iconStyle,
  onOpen,
  onClose,
  disabled,
  footer,
  navItems,
  productLinks,
  userSummary,
}: Props): JSX.Element => {
  const classes = useStyles()
  const drawerSwipeableArea = isIOS() ? SWIPEABLE_AREA_WIDTH : 0

  const {
    'hamburger_menu.improved_navigation.enable': showImprovedNavigation,
  } = useFlags()

  return (
    <>
      <div className={classes.buttonWrapper}>
        {}
        <NavbarIconButton
          buttonClasses={{
            root: classes.button,
          }}
          data-testid="nav-drawer-button"
          htmlColor={iconStyle?.color}
          Icon={MenuIcon}
          isDisabled={disabled}
          onClick={onOpen}
        />
      </div>
      <SwipeableDrawer
        anchor="right"
        disableBackdropTransition={!isIOS()}
        disableDiscovery={isIOS()}
        disableSwipeToOpen={false}
        onClose={onClose}
        onOpen={onOpen}
        open={isOpen}
        SwipeAreaProps={{
          style: { minWidth: drawerSwipeableArea, height: 'unset' },
        }}
        swipeAreaWidth={drawerSwipeableArea}
      >
        {/* @ts-expect-error auto-src: non-strict-conversion */}
        <div data-testid="nav-drawer" style={navMenuStyle.container}>
          <div>
            {userSummary}
            {!showImprovedNavigation && navItems && <Divider />}
            {!showImprovedNavigation && navItems}
            {productLinks && <Divider />}
            {productLinks}
          </div>
          <div>
            <Divider />
            {footer}
          </div>
        </div>
      </SwipeableDrawer>
    </>
  )
}

export default UnconnectedBaseNavMenu
