import { SeatPermissionEnum } from 'generated/graphql'

import employeesAssignmentLabel from './employeesAssignmentLabel'

import type { SeatT } from './types'

type SeatPermissionLabelT = string | null

const bookableSeatPermissionLabel = ({
  seatPermissionType,
  designation,
  seatPermissionOccupancyPolicy,
  seatPermissionEmployees,
}: SeatT): SeatPermissionLabelT => {
  switch (seatPermissionType) {
    case SeatPermissionEnum.ByNeighborhood:
      return 'Restricted to Neighborhood Members'

    case SeatPermissionEnum.BySeatDepartment:
      return `Restricted to ${designation.name}`

    case SeatPermissionEnum.ByOccupancyPolicy:
      return seatPermissionOccupancyPolicy
        ? `Restricted to ${seatPermissionOccupancyPolicy?.name}`
        : null

    case SeatPermissionEnum.ByNeighborhoodMembers:
    case SeatPermissionEnum.ByEmployees:
      return employeesAssignmentLabel(seatPermissionEmployees)

    default:
      return ''
  }
}

export default bookableSeatPermissionLabel
