import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  root: {
    flexShrink: 0,
    width: 122,
    height: 122,
    marginRight: 4,
  },
})
