// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import { navbarHeight } from 'common/components/styles/navbar'
import { width as dockedCardWidth } from 'common/constants/dockedCard'
import StyleConstants from 'common/components/styles/definitions'
import Definitions from '../../constants/definitions'

export default ({ viewportCategories: { portraitHandsetOrTablet } }: any) => ({
  container: {
    backgroundColor: '#ffffff',
    bottom: 0,
    boxShadow: StyleConstants.dockedCard.boxShadow,
    height: portraitHandsetOrTablet ? '100%' : undefined,
    maxWidth: portraitHandsetOrTablet ? undefined : dockedCardWidth,
    overflow: portraitHandsetOrTablet ? undefined : 'auto',
    position: 'fixed',
    right: 0,
    top: portraitHandsetOrTablet ? 0 : navbarHeight,
    width: portraitHandsetOrTablet ? '100%' : '50%',
    zIndex:
      Definitions.zIndex[
        portraitHandsetOrTablet ? 'resourceDetailsCard' : 'dockedCard'
      ],
  },
})
