// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

type PropsT = {
  className?: string
}

const GlobalSiteSettingsIcon = ({ className, ...rest }: PropsT) => (
  <SvgIcon {...rest} className={className} viewBox="0 0 48 48">
    <path d="M24 14V6H4V42H44V14H24ZM12 38H8V34H12V38ZM12 30H8V26H12V30ZM12 22H8V18H12V22ZM12 14H8V10H12V14ZM20 38H16V34H20V38ZM20 30H16V26H20V30ZM20 22H16V18H20V22ZM20 14H16V10H20V14ZM40 38H24V34H28V30H24V26H28V22H24V18H40V38ZM36 22H32V26H36V22Z" />
    <rect fill="#F6F8FC" height="16" width="16" x="28" y="26.0004" />
    <path d="M44.1918 36.8171C44.2251 36.5504 44.2501 36.2838 44.2501 36.0004C44.2501 35.7171 44.2251 35.4504 44.1918 35.1838L45.9501 33.8088C46.1084 33.6838 46.1501 33.4588 46.0501 33.2754L44.3834 30.3921C44.2834 30.2088 44.0584 30.1421 43.8751 30.2088L41.8001 31.0421C41.3668 30.7088 40.9001 30.4338 40.3918 30.2254L40.0751 28.0171C40.0501 27.8171 39.8751 27.6671 39.6668 27.6671H36.3334C36.1251 27.6671 35.9501 27.8171 35.9251 28.0171L35.6084 30.2254C35.1001 30.4338 34.6334 30.7171 34.2001 31.0421L32.1251 30.2088C31.9334 30.1338 31.7168 30.2088 31.6168 30.3921L29.9501 33.2754C29.8418 33.4588 29.8918 33.6838 30.0501 33.8088L31.8084 35.1838C31.7751 35.4504 31.7501 35.7254 31.7501 36.0004C31.7501 36.2754 31.7751 36.5504 31.8084 36.8171L30.0501 38.1921C29.8918 38.3171 29.8501 38.5421 29.9501 38.7255L31.6168 41.6088C31.7168 41.7921 31.9418 41.8588 32.1251 41.7921L34.2001 40.9588C34.6334 41.2921 35.1001 41.5671 35.6084 41.7755L35.9251 43.9838C35.9501 44.1838 36.1251 44.3338 36.3334 44.3338H39.6668C39.8751 44.3338 40.0501 44.1838 40.0751 43.9838L40.3918 41.7755C40.9001 41.5671 41.3668 41.2838 41.8001 40.9588L43.8751 41.7921C44.0668 41.8671 44.2834 41.7921 44.3834 41.6088L46.0501 38.7255C46.1501 38.5421 46.1084 38.3171 45.9501 38.1921L44.1918 36.8171ZM38.0001 38.9171C36.3918 38.9171 35.0834 37.6088 35.0834 36.0004C35.0834 34.3921 36.3918 33.0838 38.0001 33.0838C39.6084 33.0838 40.9168 34.3921 40.9168 36.0004C40.9168 37.6088 39.6084 38.9171 38.0001 38.9171Z" />
  </SvgIcon>
)

export default GlobalSiteSettingsIcon
