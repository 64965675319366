import { makeStyles } from '@material-ui/styles'

/* @ts-expect-error auto-src: non-strict-conversion */
export default makeStyles(({ palette }) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: palette.grey[100],
    boxShadow:
      '0px 2px 4px rgb(0 0 0 / 8%), 0px 4px 8px rgb(0 0 0 / 8%), 0px 1px 12px rgb(0 0 0 / 16%)',
  },
}))
