import { makeStyles } from '@material-ui/styles'

/* @ts-expect-error auto-src: non-strict-conversion */
export default makeStyles(({ palette }) => ({
  root: {
    '& + &': {
      borderTop: `1px solid ${palette.grey[300]}`,
    },
  },
}))
