import React from 'react'

import SeatAvailabilityLabel from 'common/components/SeatAvailabilityLabel'
import { Types as SeatAvailabilityTypes } from 'common/types/seatAvailability'
import type { Seat } from '../types'
import useStyles from './useStyles'

type Props = {
  seatAvailability: Seat['seatAvailability']
}

const SeatAvailability = ({ seatAvailability }: Props): JSX.Element | null => {
  const classes = useStyles()

  if (
    !seatAvailability ||
    seatAvailability.type === SeatAvailabilityTypes.ALWAYS
  ) {
    return null
  }

  return (
    <div className={classes.root}>
      <div>Availability:&nbsp;</div>
      <SeatAvailabilityLabel availability={seatAvailability} weeks="none" />
    </div>
  )
}

export default SeatAvailability
