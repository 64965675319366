// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ActionTypes from '../constants/actionTypes'

export const close = () => ({
  type: ActionTypes.NavMenu.CLOSE_REQUESTED,
})
export const open = () => ({
  type: ActionTypes.NavMenu.OPEN_REQUESTED,
})
