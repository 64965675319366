import { useContext } from 'react'
import Context from './Context'
import type { ContextValue } from './Context'

export default (): ContextValue => {
  const context = useContext(Context)

  if (!context) {
    throw new Error('useWhoIsIn must be used within WhoIsInContextProvider')
  }

  return context
}
