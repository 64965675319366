import { useState, useEffect } from 'react'
import type { WindowDimensions } from 'common/types/window'

export default function useWindowResize<T>(
  selector: (arg0: WindowDimensions) => T,
): T {
  const [value, setValue] = useState<T>(selector(window))

  useEffect(() => {
    const handleWindowResize = () => {
      setValue(selector(window))
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [selector])

  return value
}
