// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import CommonDefinitions from 'common/constants/definitions'

/* @ts-expect-error auto-src: strict-conversion */
const employeeImage = (style) => {
  if (style === 'large') {
    return 'image.jpg'
  }

  return `image_${style}.jpg`
}

export default {
  ...CommonDefinitions,
  missingEmployeeImage: (style: string) =>
    `/photos/employees/${style}/missing.png`,
  employeeImageSize: (employeeid: string, style: string) =>
    `/employees/${employeeid}/image/${employeeImage(style)}`,
  searchAutoTypeStartLength: 3,
  spinnerTopDistance: 200,
  zIndex: {
    ...CommonDefinitions.zIndex,
    // Z-indexes for elements that are at the top of their z-index stack:
    floorPlan: 10,
    floorPlanZoomButtons: 12,
    floorPlanSpinner: 13,
    flowSelectorInnerWrapper: 23,
    unseatedEmployeeOverlay: 100,
    dockedCard: 101,
    navbar: 1000,
    browsePanel: 1001,
    resourceDetailsCard: 1001,
    floatingCard: {
      nub: 500,
      body: 501,
    },
    directory: {
      scrollableSheet: 940,
      sheet: 950, // TODO: remove once the legacy info cards are turned off.
    },
  },
}
