import { useEffect, useRef } from 'react'

const usePrevious = <T>(value: T): T => {
  const ref = useRef(value)

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

export const usePreviousButFirst = <T>(value: T): T | null | undefined => {
  const ref = useRef()

  useEffect(() => {
    /* @ts-expect-error auto-src: non-strict-conversion */
    ref.current = value
  })

  return ref.current
}
export default usePrevious
