import { makeStyles } from '@material-ui/styles'

export default makeStyles({
  selectMenu: {
    maxHeight: 336,
  },
  tintedColor: {
    color: '#9E9E9E',
  },
  duration: {
    color: '#979797',
    marginLeft: 8,
  },
})
