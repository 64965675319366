import { makeStyles } from '@material-ui/styles'
import type { Theme } from '@material-ui/core/styles'

export default makeStyles<Theme>(({ palette }) => ({
  root: {
    height: 68,
    padding: '0 16px',
    background: palette.common.white,
    '& + &': {
      height: 61,
      borderTop: `1px solid ${palette.grey[300]}`,
    },
  },
  avatarWrapper: {
    minWidth: 'auto',
    marginRight: 16,
  },
  avatar: {
    width: 44,
    height: 44,
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 36,
    margin: 0,
  },
  primaryText: {
    width: 180,
    maxWidth: '100%',
    height: 16,
    borderRadius: 8,
  },
  secondaryText: {
    width: 120,
    maxWidth: '100%',
    height: 12,
    borderRadius: 6,
  },
}))
