import {
  Assignment as ActiveRequestsIcon,
  AssignmentInd as MyRequestsIcon,
  AssignmentTurnedIn as ClosedRequestsIcon,
  DesktopWindows as KiosksIcon,
  Home as HomeIcon,
  InsertChart as ReportsIcon,
  Layers as FloorsIcon,
  LocationCity as SitesIcon,
  Lock as PermissionsIcon,
  Map as MapsIcon,
  People as UsersIcon,
  Power as ConnectorsIcon,
  Room as DeskBookingIcon,
  Settings as PreferencesIcon,
  Tablet as RoomScreensIcon,
  VpnKey as ApiKeysIcon,
} from '@material-ui/icons'

import GlobalSiteSettingsIcon from 'admin_console/icons/GlobalSiteSettingsIcon'
import LeasesIcon from 'common/components/icons/Leases'
import RoomBookingIcon from 'common/icons/RoomIcon'
import WebhookIcon from 'common/components/icons/Webhook'

const facilitiesIcons = {
  Directories: MapsIcon,
  Floors: FloorsIcon,
  Leases: LeasesIcon,
  Sites: SitesIcon,
}

const adminIcons = {
  'Desk Booking Settings': DeskBookingIcon,
  'Global Site Settings': GlobalSiteSettingsIcon,
  'OfficeSpace API': ApiKeysIcon,
  'Room Booking Settings': RoomBookingIcon,
  'Room Displays': RoomScreensIcon,
  Connectors: ConnectorsIcon,
  Homepages: HomeIcon,
  Kiosks: KiosksIcon,
  Permissions: PermissionsIcon,
  Preferences: PreferencesIcon,
  Users: UsersIcon,
  Webhooks: WebhookIcon,
}

const requestManagerIcons = {
  'My Requests': MyRequestsIcon,
  Active: ActiveRequestsIcon,
  Closed: ClosedRequestsIcon,
  Reports: ReportsIcon,
}

export default { ...facilitiesIcons, ...adminIcons, ...requestManagerIcons }
