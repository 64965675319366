// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import moment from 'moment-timezone'
import type { Moment } from 'moment-timezone'
import { useExactRelativeTimeHour } from 'visual_directory/lib/roomBooking/timeUtils'
/* eslint-disable react-hooks/rules-of-hooks */
/* @ts-expect-error src: types added to moment-timezone */
useExactRelativeTimeHour(moment)
/* eslint-enable react-hooks/rules-of-hooks */
type TimePeriod = {
  start: Moment
  end: Moment
}
type VariantConfig = {
  multiDayFormat: string
  singleDayFormat: string
  todayFormat: string
  showTimePeriod: boolean
}
// Any text inside [] brackets at the date format will be escaped and displayed AS IS
// https://momentjs.com/docs/#/displaying/format/
const FORMAT_CONFIG = {
  date: {
    multiDayFormat: 'MMM D',
    singleDayFormat: 'ddd, MMM D',
    todayFormat: '[Today]',
    showTimePeriod: false,
  },
  time: {
    multiDayFormat: 'MMM D, h:mm A',
    singleDayFormat: '[]',
    todayFormat: '[]',
    showTimePeriod: true,
  },
  datetime: {
    multiDayFormat: 'MMM D, h:mm A',
    singleDayFormat: 'ddd, MMM D,',
    todayFormat: '[]',
    showTimePeriod: true,
  },
}

type Variant = keyof typeof FORMAT_CONFIG

const isWithinSameDayPart = (start: Moment, end: Moment): boolean =>
  start.format('A') === end.format('A')

const formatSingleDayTimePeriod = ({ start, end }: TimePeriod): string => {
  if (isWithinSameDayPart(start, end)) {
    // 10:00 - 11:00 AM
    return `${start.format('h:mm')} - ${end.format('h:mm A')}`
  }

  // 10:00 AM - 1:00 PM
  return `${start.format('h:mm A')} - ${end.format('h:mm A')}`
}

type FormatSingleDayPeriodDateArgs = {
  date: Moment
  currentTime: Moment
  config: {
    todayFormat: string
    singleDayFormat: string
  }
}

const formatSingleDayPeriodDate = ({
  date,
  currentTime,
  config: { todayFormat, singleDayFormat },
}: FormatSingleDayPeriodDateArgs): string => {
  if (currentTime.isSame(date, 'day')) {
    return date.format(todayFormat)
  }

  return date.format(singleDayFormat)
}

type ArgsT = {
  timePeriod: TimePeriod
  variant?: Variant
  variantConfig?: VariantConfig
  currentTime?: Moment
}

export default ({
  timePeriod: { start, end },
  variant = 'datetime',
  variantConfig,
  currentTime = moment(),
}: ArgsT) => {
  const config = variantConfig || FORMAT_CONFIG[variant]

  if (!start.isSame(end, 'day')) {
    return `${start.format(config.multiDayFormat)} - ${end.format(
      config.multiDayFormat,
    )}`
  }

  const formattedDate = formatSingleDayPeriodDate({
    date: start,
    currentTime,
    config,
  })

  const formattedTimePeriod = config.showTimePeriod
    ? formatSingleDayTimePeriod({
        start,
        end,
      })
    : undefined

  return [formattedDate, formattedTimePeriod]
    .filter((el) => el && el.length)
    .join(' ')
}
