import React from 'react'
import Image from './Image'
import useStyles from './useStyles'

type Props = {
  footerLeft?: React.ReactNode
  footerRight?: React.ReactNode
  toolbar?: React.ReactNode
  title?: React.ReactNode
  label?: React.ReactNode
  imageUrl?: string | null | undefined
  imageFallback?: React.ReactNode
  children?: React.ReactNode
}

const Layout = ({
  footerLeft,
  footerRight,
  imageUrl,
  imageFallback,
  label,
  title,
  toolbar,
  children,
}: Props): React.ReactElement => {
  const imagePresent = Boolean(imageUrl || imageFallback)

  const classes = useStyles({
    imagePresent,
    footerPresent: Boolean(footerLeft || footerRight),
    labelPresent: Boolean(label),
  })

  return (
    <div className={classes.root}>
      {toolbar && <div className={classes.toolbar}>{toolbar}</div>}
      <Image fallback={imageFallback} url={imageUrl} />
      <div className={classes.details}>
        <div className={classes.content}>
          {label && <div className={classes.label}>{label}</div>}
          {title && <div className={classes.title}>{title}</div>}
          {children && <div className={classes.body}>{children}</div>}
        </div>
        <div className={classes.footer}>
          {footerLeft}
          {footerRight}
        </div>
      </div>
    </div>
  )
}

export default Layout
