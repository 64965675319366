// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { addNotification } from 'common/actions/notifications'
import { showSnackbar } from 'common/actions/snackbar'
import NotificationConstants from 'common/constants/notification'
import ActionTypes from '../constants/actionTypes'

/* @ts-expect-error auto-src: noflow */
export const receiveHotDeskSummary = (summary) => ({
  type: ActionTypes.DeskBooking.RECEIVE_AVAILABLE_HOT_DESKS,
  summary,
})
/* @ts-expect-error auto-src: noflow */
export function filterFloors(query) {
  return {
    type: ActionTypes.DeskBooking.FILTER_FLOORS,
    query,
  }
}
export const resetBookings = () => ({
  type: ActionTypes.DeskBooking.RESET_BOOKINGS,
})
/* @ts-expect-error auto-src: noflow */
export function selectFloor(floor) {
  return {
    type: ActionTypes.DeskBooking.SELECT_FLOOR,
    floor,
  }
}
/* @ts-expect-error auto-src: noflow */
export function selectDesk(desk) {
  return {
    type: ActionTypes.DeskBooking.SELECT_DESK,
    desk,
  }
}
export function deselectDesk() {
  return {
    type: ActionTypes.DeskBooking.DESELECT_DESK,
  }
}
/* @ts-expect-error auto-src: noflow */
export function setFormValues(formValues) {
  return {
    type: ActionTypes.DeskBooking.SET_FORM_VALUES,
    formValues,
  }
}
/* @ts-expect-error auto-src: noflow */
export function confirmTime(formValues) {
  return {
    type: ActionTypes.DeskBooking.CONFIRM_TIME,
    formValues,
  }
}
export function goBack() {
  return {
    type: ActionTypes.DeskBooking.GO_BACK,
  }
}
export function resetState() {
  return {
    type: ActionTypes.DeskBooking.RESET_STATE,
  }
}
export const showFloorList = () => ({
  type: ActionTypes.DeskBooking.SHOW_FLOOR_LIST,
})
/* @ts-expect-error auto-src: noflow */
export const receiveBooking = (data) => ({
  type: ActionTypes.DeskBooking.RECEIVE_BOOKING,
  data,
})
/* @ts-expect-error auto-src: noflow */
export const endBookingFailed = (error) => {
  const dialogProps = {
    title: error,
    redirectPath: '/bookings',
  }

  return addNotification(
    NotificationConstants.Dialog.REDIRECT_DIALOG,
    dialogProps,
  )
}
/* @ts-expect-error auto-src: noflow */
export const endBookingSucceeded = (booking) => (dispatch) => {
  dispatch(showSnackbar('Your booking has been ended.'))
  dispatch(receiveBooking(booking))
}
/* @ts-expect-error auto-src: noflow */
export const cancelBookingFailed = (error) => {
  const dialogProps = {
    title: error,
    redirectPath: '/bookings',
  }

  return addNotification(
    NotificationConstants.Dialog.REDIRECT_DIALOG,
    dialogProps,
  )
}
/* @ts-expect-error auto-src: noflow */
export const bookingCanceled = (booking) => (dispatch) => {
  dispatch(showSnackbar('Booking has been cancelled.'))
  dispatch(receiveBooking(booking))
}
