import type { Site } from './site'
import type { Room } from './room'
import type { Neighborhood } from './neighborhood'

export type Floor = {
  id: string
  directory?: {
    id: string
  }
  label: string
  managed?: boolean
  rooms?: ReadonlyArray<Room>
  site?: Site
  unbookableReason?: string
  neighborhoods?: ReadonlyArray<Neighborhood>
}
export const emptyFloor = {
  id: '',
  label: '',
}
