import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  root: {
    fontSize: 10,
    padding: '0px 12px',
    height: 24,
    minWidth: 64,
    lineHeight: '24px',
    borderRadius: 2,
    backgroundColor: '#01B572',
    color: '#ffffff',
    flex: 'none',
    '&:hover': {
      backgroundColor: '#01A562',
    },
  },
})
