import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Menu, MenuItem } from '@material-ui/core'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

import OssLogoIconBlue from 'common/components/icons/OssLogoIconBlue'
import { navMenuStyle } from 'common/components/styles/navmenu'
import { logoutPath, logoutAllPath } from 'common/lib/paths'

export type Props = {
  anonymous?: boolean
  avatar?: React.ReactNode
  manageAccountProductLink?: string
  email?: string
  fullName?: string
}
const useStyles = makeStyles({
  menuItemTextWrap: {
    display: 'inline-block',
    maxWidth: '212px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  menuWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
})
const useMenuStyles = makeStyles({
  list: {
    padding: 0,
  },
})
const useMenuItemStyles = makeStyles({
  root: {
    color: 'rgb(12, 107, 236)',
    textAlign: 'left',
    fontSize: '13px',
    minHeight: 34,
  },
})
const useIconStyles = makeStyles({
  root: {
    height: 24,
    width: 24,
  },
})

const UserSummary = ({
  anonymous,
  avatar,
  manageAccountProductLink,
  email,
  fullName,
}: Props): JSX.Element => {
  const { t } = useTranslation('common', {
    keyPrefix: 'common.home_menu.user',
  })

  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()
  const iconClasses = useIconStyles()
  const menuClasses = useMenuStyles()
  const menuItemClasses = useMenuItemStyles()
  const userStyles = navMenuStyle.user

  /* @ts-expect-error auto-src: strict-conversion */
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  /* @ts-expect-error auto-src: strict-conversion */
  const handleMenuItemClick = (href) => {
    window.location.href = href
  }

  return (
    <div style={{ ...userStyles.container, ...userStyles.text }}>
      <div style={userStyles.avatarWrapper}>
        {avatar}
        <OssLogoIconBlue style={userStyles.ossLogo} />
      </div>
      {!anonymous && (
        /* @ts-expect-error auto-src: non-strict-conversion */
        <div style={userStyles.infoSummaryContainer}>
          {/* @ts-expect-error auto-src: non-strict-conversion */}
          <p style={userStyles.name}>{fullName}</p>
          <div className={classes.menuWrapper} onClick={handleOpen}>
            <div className={classes.menuItemTextWrap}>{email}</div>
            <ArrowDropDownIcon classes={iconClasses} />
          </div>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            classes={menuClasses}
            getContentAnchorEl={null}
            keepMounted
            onClose={handleClose}
            open={Boolean(anchorEl)}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem
              classes={menuItemClasses}
              disabled={!manageAccountProductLink}
              onClick={() => handleMenuItemClick(manageAccountProductLink)}
            >
              {t('manage_account')}
            </MenuItem>
            {manageAccountProductLink && <Divider light />}
            <MenuItem
              classes={menuItemClasses}
              onClick={() => handleMenuItemClick(logoutPath)}
            >
              {t('log_out')}
            </MenuItem>
            <MenuItem
              classes={menuItemClasses}
              onClick={() => handleMenuItemClick(logoutAllPath)}
            >
              {t('log_out_all_devices')}
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>
  )
}

export default UserSummary
