// The following eslint-disable was automated from the ts conversion
/* eslint-disable no-magic-numbers,@typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any,max-len */
import endOfWeek from 'date-fns/endOfWeek'
import getWeek from 'date-fns/getWeek'
import moment from 'moment'
import setWeek from 'date-fns/setWeek'
import setYear from 'date-fns/setYear'
import startOfWeek from 'date-fns/startOfWeek'

type DayNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6

const dayNumber = (day: string | DayNumber = 0): DayNumber =>
  /* @ts-expect-error auto-src: non-strict-conversion */
  typeof day === 'number'
    ? day
    : moment
        .weekdays()
        .map((name) => name.toLowerCase())
        .indexOf(day.toLowerCase())

const weekOptions = (start?: string | DayNumber) => ({
  weekStartsOn: dayNumber(start),
  firstWeekContainsDate: 4,
})

export const dateRange = (from: any, to: any, step = 1): ReadonlyArray<any> => {
  const fromDate = moment.parseZone(from?.toString()).startOf('day')
  const toDate = moment.parseZone(to?.toString()).endOf('day')
  const diff = Math.max(toDate.diff(fromDate, 'days') + 1, 0)

  return [...Array(diff)]
    .map((_, index) => moment(fromDate).add(index, 'days'))
    .filter((_, index) => index % step === 0)
}
/* @ts-expect-error auto-src: non-strict-conversion */
export const weekWithStart = (date: moment, start?: string | DayNumber) =>
  /* @ts-expect-error auto-src: non-strict-conversion */
  getWeek(date.toDate(), weekOptions(start))
/* @ts-expect-error auto-src: non-strict-conversion */
export const weekStart = (date: moment, start?: string | DayNumber) =>
  moment(startOfWeek(date.toDate(), weekOptions(start)))
/* @ts-expect-error auto-src: non-strict-conversion */
export const weekEnd = (date: moment, start?: string | DayNumber) =>
  moment(endOfWeek(date.toDate(), weekOptions(start)))
export const setWeekAndYear = (
  week: number,
  year: number,
  start?: string | DayNumber,
  /* @ts-expect-error auto-src: non-strict-conversion */
) => moment(setWeek(setYear(new Date(), year), week, weekOptions(start)))
export const maxWeekOfYear = (
  year: number,
  start?: string | DayNumber, // Dec 28 is always part of the year's last week.
) =>
  // Following days might belong to the next year's first week.
  /* @ts-expect-error auto-src: non-strict-conversion */
  getWeek(moment(`${year}-12-28`).toDate(), weekOptions(start))
