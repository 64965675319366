// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import type { ReactNode } from 'react'
import { connect } from 'react-redux'
import { ThemeProvider } from '@material-ui/core/styles'
import useBrandingMuiTheme from './useBrandingMuiTheme'

type OwnProps = {
  children: ReactNode
}
type Props = OwnProps & {
  config: any
  isFetching: boolean
}

const BrandingThemeProvider = ({ config, children }: Props) => {
  const theme = useBrandingMuiTheme(config)

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

/* @ts-expect-error auto-src: non-strict-conversion */
export default connect<Props, OwnProps, _, _, _, _>(
  /* @ts-expect-error auto-src: strict-conversion */
  ({ branding: { isFetching, config } }) => ({
    isFetching,
    config,
  }),
  {},
)(BrandingThemeProvider)
