// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
import PropTypes from 'prop-types'
import React from 'react'
import { SvgIcon } from '@material-ui/core'
import useFlags from 'common/hooks/useFlags'

/* @ts-expect-error auto-src: noflow */
const OssLogoIconBlue = ({ style, ...rest }) => {
  const {
    'hamburger_menu.improved_navigation.enable': showImprovedNavigation,
  } = useFlags()

  return (
    <SvgIcon
      style={{
        width: '20px',
        height: '26px',
        ...style,
      }}
      viewBox="0 0 20 26"
      {...rest}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M6.16613347 15.5027587l-.0484864 6.6737119 6.07208653-3.2660708.0455605-6.6751057m.8111182-.557194L13 18.3529412l6.0722801-3.2665345.045367-6.674642m-6.072709 10.9139972L13 26l6.0718729-3.2669383.0457742-6.6742382"
          fill={showImprovedNavigation ? '#268EB6' : '#244F7C'}
        />
        <path
          d="M0 3.4153045l6.08181324 3.46704844 6.15348086-3.4153045L6.15200102 0"
          fill={showImprovedNavigation ? '#7DBFD9' : '#3B7AB5'}
        />
        <path
          d="M6.88235294 7.23883392l6.08191836 3.46704848 6.1533758-3.41530455-6.0821298-3.46704844M0 11.0619558l6.08307572 3.467456 6.15221838-3.4157182-6.08286426-3.46663478"
          fill={showImprovedNavigation ? '#7DBFD9' : '#487AB6'}
        />
      </g>
    </SvgIcon>
  )
}

OssLogoIconBlue.propTypes = {
  style: PropTypes.object,
}
export default OssLogoIconBlue
