import Definitions from 'visual_directory/constants/definitions'
import { ellipsis } from 'common/components/styles/shared'

export default {
  dropShadow: {
    light: {
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.12)',
      position: 'relative',
      zIndex: Definitions.zIndex.navbar,
    },
  },
  ellipsis,
  locationContainer: {
    width: '100%',
  },
}
