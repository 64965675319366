// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
export default ({
    calculateCenteringOffset,
    calculatePositionSelector,
    calculateZoomSelector,
  }: any) =>
  (state: any) =>
  ({ x, y }: { x: number; y: number }) => {
    const { current: currentZoom, min: minZoom } = calculateZoomSelector(state)
    const position = calculatePositionSelector(state, {
      centeringOffset: calculateCenteringOffset(state, {
        zoomWhenCentered: minZoom,
      }),
    })

    return {
      x: (x - position.x) / currentZoom,
      y: (y - position.y) / currentZoom,
    }
  }
