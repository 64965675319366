import React from 'react'
import { useTranslation } from 'react-i18next'
import FeedbackIcon from '@material-ui/icons/Feedback'

import NavMenuItem from '../NavMenuItem'
import type { Props as MenuItemProps } from '../NavMenuItem'
import sendFeedbackEmail from '../../../lib/mail/sendFeedback'

type Props = MenuItemProps & {
  email?: string
}
export default ({ email }: Props): JSX.Element => {
  const { t } = useTranslation('common', {
    keyPrefix: 'common.home_menu',
  })

  return (
    <NavMenuItem
      component="a"
      LeftIcon={FeedbackIcon}
      onClick={() => sendFeedbackEmail(email)}
    >
      {t('send_feedback')}
    </NavMenuItem>
  )
}
