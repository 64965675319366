// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import { grey } from '@material-ui/core/colors'
import StyleConstants from './definitions'

const iconSize = 20

export const requestFormStyles = {
  formSection: {
    maxWidth: '350px',
    padding: '6px 25px 15px 24px',
  },
  floatingLabel: {
    color: StyleConstants.font.color.formLabel,
  },
  instructionalText: {
    color: StyleConstants.font.color.minorHeading,
    fontSize: StyleConstants.font.size.standard,
    lineHeight: '24px',
    marginTop: '10px',
    marginLeft: '28px',
    wordWrap: 'break-word',
  },
  underlineFocus: {
    borderColor: StyleConstants.form.underlineColor,
  },
  staticLabel: {
    /* @ts-expect-error auto-src: non-strict-conversion */
    color: StyleConstants.form.formLabel,
    fontSize: '12px',
    lineHeight: '17px',
    paddingBottom: '6px',
  },
  requestorName: {
    color: StyleConstants.font.color.majorHeading,
    fontSize: StyleConstants.font.size.heading,
    height: '24px',
    lineHeight: '1.5',
  },
  requestorEmail: {
    color: StyleConstants.font.color.majorHeading,
    fontSize: StyleConstants.font.size.standard,
    height: '18px',
  },
  formFieldContainer: {
    marginTop: '24px',
  },
  // the text of the input field
  input: {
    color: StyleConstants.font.color.input,
  },
  textFieldStyleNoIcon: {
    width: '311px',
  },
  textFieldStyleWithIcon: {
    width: '277px',
  },
  svgIcon: {
    color: grey[500],
    flex: '0 0 auto',
    height: iconSize,
    paddingRight: '8px',
    width: iconSize,
  },
}
export const completedLocationFieldConstants = {
  textBoxMaxWidth: '80%',
}
export const completedLocationFieldStyles = {
  headerText: {
    color: StyleConstants.font.color.formLabel,
    fontSize: StyleConstants.font.size.formLabelFocus,
  },
  valueText: {
    color: StyleConstants.font.color.majorHeading,
    fontSize: StyleConstants.font.size.heading,
    lineHeight: 1.5,
  },
  floorAndSiteText: {
    color: StyleConstants.font.color.minorHeading,
    fontSize: StyleConstants.font.size.standard,
    lineHeight: 1.5,
  },
  clearLocationIcon: {
    color: StyleConstants.icon.iconColor,
    flex: '0 0 auto',
    height: StyleConstants.icon.iconSize,
    padding: 0,
    width: StyleConstants.icon.iconSize,
  },
  paperContainer: {
    display: 'flex',
    margin: '7px 0',
    padding: '12px',
    position: 'relative',
    zDepth: 1,
  },
  paperContent: {
    display: 'flex',
    flex: '1 1 auto',
    width: '95%',
  },
  iconBox: {
    flex: '0 1 65px',
  },
  locationText: {
    flex: '1 1 auto',
  },
}
// Because of the transform, we should attach padding etc to div containing the attachmentIcon svg
export const attachmentIcon = {
  color: requestFormStyles.svgIcon.color,
  height: iconSize,
  transform: 'rotate(270deg)',
  width: iconSize,
}
const attachmentInstructions = {
  color: StyleConstants.font.color.formLabel,
  fontSize: StyleConstants.font.size.heading,
}

/* @ts-expect-error auto-src: strict-conversion */
function getFileChipLabelMaxWidth({ xsmall, small }) {
  if (xsmall) {
    return '260px'
  }

  if (small) {
    return '500px'
  }

  return '800px'
}

/* @ts-expect-error auto-src: strict-conversion */
function getFileNameMaxWidth({ xsmall, small }) {
  if (xsmall) {
    return '160px'
  }

  if (small) {
    return '180px'
  }

  return '200px'
}

export const attachmentsStyles = ({ viewportCategories }: any) => ({
  notDragging: {
    dropzone: {
      borderStyle: 'none',
      height: 24,
      width: '100%',
    },
    // The instructions text is an odd mashup of styles that doesn't fit the other inputs.
    instructions: {
      ...attachmentInstructions,
      lineHeight: '1.5',
      backgroundColor: '#F5F5F5',
      padding: '6px 10px',
    },
    icon: { ...requestFormStyles.svgIcon },
  },
  dragging: {
    dropzone: {
      border: `dashed 2px ${requestFormStyles.svgIcon.color}`,
      height: '60px',
      width: '100%',
    },
    icon: {
      marginRight: '9px',
    },
    instructions: {
      ...attachmentInstructions,
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  textField: {
    alignItems: 'center',
    display: 'flex',
    paddingBottom: '8px',
  },
  completeAttachment: {
    fileChip: {
      chip: {
        margin: '8px 0px',
        maxWidth: getFileChipLabelMaxWidth(viewportCategories),
        width: 'max-content',
      },
      label: {
        color: '#000000',
        fontSize: StyleConstants.font.size.standard,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  inProgressAttachment: {
    closeIcon: {
      // Applies to button tag
      style: {
        height: '24px',
        marginLeft: '10px',
        padding: '0',
        width: '24px',
      },
      // Applies to svg within button tag
      iconStyle: {
        color: StyleConstants.icon.iconColor,
      },
    },
    container: {
      marginTop: '16px',
      marginBottom: '16px',
    },
    errorMessages: {
      color: StyleConstants.font.color.error,
      fontSize: StyleConstants.font.size.standard,
    },
    fileName: {
      color: '#000000',
      maxWidth: getFileNameMaxWidth(viewportCategories),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    fileNameContainer: {
      fontSize: StyleConstants.font.size.standard,
      width: '100%',
    },
    progressBar: {
      maxWidth: '100px',
      width: '100%',
    },
  },
})
