import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { Map, RecordOf } from 'immutable'

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText as MuiDialogContentText,
} from '@material-ui/core'
import withStyles from '@material-ui/styles/withStyles'
import { makeStyles } from '@material-ui/styles'

import CenteredSpinner from 'common/components/CenteredSpinner'
import useIsSessionExpired from 'common/hooks/useIsSessionExpired'
import useFlags from 'common/hooks/useFlags'
import type { TUser } from 'common/records/user'
import OssFooterLogo from 'common/icons/ossFooterLogo'

import { navMenuStyle, FooterHamburgerMenuStyle } from '../styles/navmenu'

type Props = {
  version?: string
  customIOSAppLink?: string | null | undefined
  isIOSAppEnabled?: boolean
  currentUser?: Map<string, boolean | string> | RecordOf<TUser>
}
/* @ts-expect-error auto-src: strict-conversion */
const useHamburgerMenuStyles = makeStyles(FooterHamburgerMenuStyle)

const { footer: styles } = navMenuStyle
const QR_CODE_SIZE = 260
const MAGIC_LINK_PATH = '/ossmobile/magic_link/qr_code.png'
const MAGIC_LINK_LIFESPAN_MS = 60000
const IOS_APP_LINK_ITUNES =
  'https://itunes.apple.com/us/app/officespace-app/id1446665105'
const ANDROID_APP_LINK_GOOGLE_PLAY =
  'https://play.google.com/store/apps/details?id=com.officespacesoftware.ossmobile'

/* @ts-expect-error auto-src: strict-conversion */
const qrCodePath = (timestamp) =>
  `${MAGIC_LINK_PATH}?size=${QR_CODE_SIZE}&t=${timestamp}`

const DialogContentText = withStyles({
  root: {
    marginBottom: 0,
  },
})(MuiDialogContentText)

function QRCode() {
  const isSessionExpired = useIsSessionExpired()
  const [timestamp, setTimestamp] = useState<number>(Date.now())
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true)

  useEffect(() => {
    const timer = setInterval(() => {
      setTimestamp(Date.now())
    }, MAGIC_LINK_LIFESPAN_MS)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const handleImageLoad = () => {
    setIsImageLoading(false)
  }

  if (isSessionExpired) {
    window.location.reload()
  }

  return (
    /* @ts-expect-error auto-src: non-strict-conversion */
    <div style={styles.iOSApp.qrCodeContainer}>
      <img
        alt="QR code"
        height={QR_CODE_SIZE}
        onLoad={handleImageLoad}
        src={qrCodePath(timestamp)}
        style={styles.iOSApp.qrCode}
        width={QR_CODE_SIZE}
      />
      {isImageLoading && <CenteredSpinner />}
    </div>
  )
}

const useButtonStyles = makeStyles({
  root: {
    fontSize: '12px',
    color: '#0C6BEC',
    fontWeight: 600,
    marginLeft: -8,
    textTransform: 'capitalize',
  },
})

export default function AppMenuFooter({
  version,
  customIOSAppLink,
  isIOSAppEnabled,
  currentUser,
}: Props): JSX.Element {
  const { t } = useTranslation('common', {
    keyPrefix: 'common.home_menu',
  })

  const [isIOSAppModalOpen, setIOSAppModalOpen] = useState(false)
  const buttonClasses = useButtonStyles()

  const handleIOSAppClick = () => setIOSAppModalOpen(true)

  const handleIOSAppModalClose = () => setIOSAppModalOpen(false)

  const isAppMobileVisible =
    isIOSAppEnabled &&
    !!currentUser &&
    /* @ts-expect-error auto-src: non-strict-conversion */
    !currentUser.get('anonymous')

  const {
    'hamburger_menu.improved_navigation.enable': shouldShowImprovedNavigation,
  } = useFlags()
  const hamburgerMenuClasses = useHamburgerMenuStyles()
  // TODO: when improve navigation becomes default we need to clean up this
  // component. A new component MobileAppDialog was created to handle this same
  // behavior.

  if (shouldShowImprovedNavigation) {
    return (
      <div className={hamburgerMenuClasses.container}>
        <div className={hamburgerMenuClasses.content}>
          <OssFooterLogo className={hamburgerMenuClasses.logo} />
          <span className={hamburgerMenuClasses.version}>{version}</span>
        </div>
      </div>
    )
  }

  return (
    <div style={styles.container}>
      {isAppMobileVisible && (
        <Button classes={buttonClasses} onClick={handleIOSAppClick}>
          {t('mobile_app')}
        </Button>
      )}

      {/* @ts-expect-error auto-src: non-strict-conversion */}
      <span style={styles.versionLabel}>{version}</span>

      <Dialog onClose={handleIOSAppModalClose} open={isIOSAppModalOpen}>
        <DialogTitle>Try the OfficeSpace iOS or Android Apps</DialogTitle>
        <DialogContent>
          <DialogContentText>
            1. Download the OfficeSpace app from the{' '}
            <a
              href={customIOSAppLink || IOS_APP_LINK_ITUNES}
              rel="noopener noreferrer"
              target="_blank"
            >
              App Store
            </a>
            {' or '}
            <a
              href={ANDROID_APP_LINK_GOOGLE_PLAY}
              rel="noopener noreferrer"
              target="_blank"
            >
              Google Play
            </a>
            .
          </DialogContentText>
          <br />
          <DialogContentText>
            2. Open the app and choose “Scan QR Code”.
          </DialogContentText>
          <br />
          <DialogContentText>
            3. Point your phone to this screen and capture the code:
          </DialogContentText>
          <QRCode />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleIOSAppModalClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
