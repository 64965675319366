// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import merge from 'lodash/merge'
import { fade } from '@material-ui/core/styles/colorManipulator'

import { width as dockedCardWidth } from 'common/constants/dockedCard'
import StyleConstants from 'common/components/styles/definitions'

import { navInputStyles, NavInputConstants } from './navInput'

const searchBarMobileHeight = 36

// FIXME: pull out the parts that are common to compact/extended and make this just apply to common?
export const commonUnbrandedSearchBar = merge({}, navInputStyles, {
  inputDiv: {
    fontWeight: 'normal',
    lineHeight: 'normal',
  },
  siteSearchWrapper: {
    flex: '1 0 auto',
  },
})

const extendedSearchBarStyles = {
  inputDiv: {
    boxShadow: null,
    backgroundColor: NavInputConstants.open.inputBackgroundColor,
    color: NavInputConstants.closed.inputHintColor,
    fontSize: 12,
    lineHeight: '30px',
    zIndex: NavInputConstants.zIndex.icon,
  },
  inputInternal: {
    fontSize: StyleConstants.font.size.standard,
  },
}

/* @ts-expect-error auto-src: noflow */
export const extendedSearchBarOpen = (options) => {
  const { searchExtended } = options.branding.navbar

  return merge({}, commonUnbrandedSearchBar, extendedSearchBarStyles, {
    container: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: dockedCardWidth,
      boxSizing: 'border-box',
      padding: '6px 8px',
    },
    navbar: {
      backgroundColor: searchExtended.inputBackgroundColor,
      boxShadow: '0 2px 2px 0 rgba(0,0,0,0.24)',
    },
    inputDiv: {
      border: `1px solid ${fade(
        searchExtended.inputBorderColor,
        searchExtended.inputBorderAlpha,
      )}`,
    },
  })
}
/* @ts-expect-error auto-src: noflow */
export const extendedSearchBarClosed = (options) => {
  const { searchExtended } = options.branding.navbar

  return merge({}, commonUnbrandedSearchBar, extendedSearchBarStyles, {
    navbar: null,
    container: {
      flexGrow: 1,
      padding: 0,
    },
    leftIcon: {
      iconInternal: {
        color: searchExtended.iconColor,
      },
    },
    inputDiv: {
      color: searchExtended.placeholderTextColor,
      backgroundColor: searchExtended.inputBackgroundColor,
      border: `1px solid ${fade(
        searchExtended.borderColor,
        searchExtended.borderAlpha,
      )}`,
      zIndex: null,
    },
  })
}

const unbrandedSearchBar = () =>
  merge({}, commonUnbrandedSearchBar, {
    container: {
      width: '100%',
    },
  })

const compactSearchBar = () =>
  merge({}, unbrandedSearchBar(), {
    inputDiv: {
      height: searchBarMobileHeight,
    },
  })

export const brandedCompactSearchBar = (options: any) => {
  const { searchCompact } = options.branding.navbar

  return merge({}, compactSearchBar(), {
    hint: {
      color: searchCompact.placeholderTextColor,
    },
    iconInternal: {
      color: searchCompact.iconColor,
    },
    inputInternal: {
      color: searchCompact.textColor,
    },
    inputDiv: {
      backgroundColor: searchCompact.inputBackgroundColor,
      border: `1px solid ${fade(
        searchCompact.inputBorderColor,
        searchCompact.inputBorderAlpha,
      )}`,
      boxShadow: null,
    },
  })
}

export const brandedCompactFloorPicker = (options: any) => {
  const { floorPicker } = options.branding.navbar

  return merge({}, compactSearchBar(), {
    inputDiv: {
      border: `1px solid ${fade(
        floorPicker.borderColor,
        floorPicker.borderAlpha,
      )}`,
      width: null,
      boxShadow: null,
    },
  })
}

// TODO: generalize brandedCompactFloorPicker
// https://officespacesoftware.atlassian.net/browse/CORE-6472
export const brandedCompactSitePicker = brandedCompactFloorPicker

export default unbrandedSearchBar
