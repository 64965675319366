import { makeStyles } from '@material-ui/styles'

const darkBlue = '#37598b'
const white = '#fff'

export default makeStyles({
  navItemView: {
    width: 'max-content',
    '&:focus': {
      backgroundColor: darkBlue,
    },
    '&:hover': {
      backgroundColor: darkBlue,
    },
  },
  button: {
    background: 'transparent',
    borderRadius: '0px',
    color: 'rgba(255, 255, 255, 0.7)',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 400,
    height: '42px',
    lineHeight: '1.33',
    padding: '2.5px 10px 0 25px',
    '&:hover': {
      background: darkBlue,
    },
    '& span svg': {
      opacity: 0.3,
    },
  },
  downArrow: {
    height: 20,
    marginTop: 0,
    width: 20,
  },
  buttonHover: {
    background: darkBlue,
    color: white,
  },
  buttonSelected: {
    color: white,
    fontWeight: 600,
    '& span svg': {
      opacity: 0.5,
    },
  },
  buttonBorder: {
    '&:hover:first-child:after': {
      borderBottom: '2px solid transparent',
    },
    '&:first-child:after': {
      zIndex: 1,
      content: '""',
      width: '100%',
      borderBottom: '2px solid white',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 40,
      transition: 'all 3s ease-out',
    },
  },
  popper: {
    height: 'auto',
    minWidth: 200,
    zIndex: 1100,
    '& > div:first-child': {
      borderRadius: 0,
      boxShadow: 'none',
    },
  },
  menuStyle: {
    backgroundColor: darkBlue,
    borderRadius: 0,
    minWidth: 138.48,
    width: 'auto',
  },
  menuItem: {
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: 14,
    fontFamily: 'Open Sans',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      color: white,
    },
    '& a': {
      color: white,
      textDecoration: 'none',
    },
    '& a:focus': {
      outline: 'none',
    },
  },
})
