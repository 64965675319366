import React from 'react'
import NeighborhoodIcon from 'common/icons/PeopleGroupIcon'

import useStyles from './useStyles'

type Props = {
  color?: string
}

const ImageFallback = ({ color }: Props): JSX.Element => {
  const classes = useStyles({ color })

  return (
    <div className={classes.root}>
      <NeighborhoodIcon className={classes.icon} />
    </div>
  )
}

export default ImageFallback
