import React from 'react'

type Props = {
  assignedEmployeeName?: string
  concealEmployeeInfo?: boolean
  employeeFullName?: string
  isByRequestDesk: boolean
  isPending: boolean
}

const Title = ({
  assignedEmployeeName,
  concealEmployeeInfo = false,
  employeeFullName,
  isByRequestDesk,
  isPending,
}: Props): JSX.Element => {
  if (isPending && concealEmployeeInfo) {
    return <>Requested</>
  }

  if (employeeFullName) {
    return <>{employeeFullName}</>
  }

  if (assignedEmployeeName) {
    return <>{assignedEmployeeName}</>
  }

  if (isByRequestDesk) {
    return <>Bookable Desk (By Request)</>
  }

  return <>Bookable Desk</>
}

export default Title
