// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { createSelector } from 'reselect'
import type { OutputSelector } from 'reselect'
import getAspectRatio from './aspectRatio'
import { portraitHandset } from '../../constants/viewport'

const selector: OutputSelector<any, any, any> = createSelector(
  getAspectRatio,
  ({ innerWidth }) => innerWidth,
  (aspectRatio, width) =>
    aspectRatio === portraitHandset.aspectRatio &&
    width >= portraitHandset.min &&
    width < portraitHandset.max,
)

export default ({ innerWidth, innerHeight }: any) =>
  selector({
    innerWidth,
    innerHeight,
  })
