// The following eslint-disable was automated from the ts conversion
/* eslint-disable func-names,id-length,@typescript-eslint/no-explicit-any */
import { useMemo } from 'react'
import debounce from 'lodash/debounce'

const DEFAULT_TIMEOUT = 300

type OptionsT = {
  leading?: boolean
  trailing?: boolean
  maxWait?: number
}
const defaultOptions = {} // auto-src: ts-eslint eslint-disable-next-line func-names

export default function <T extends (...A: ReadonlyArray<any>) => any>(
  callback: T,
  delay: number = DEFAULT_TIMEOUT,
  options: OptionsT = defaultOptions,
): T {
  // useMemo instead of useCallback due to https://github.com/facebook/react/issues/19240
  const debouncedCallback = useMemo(
    () => debounce(callback, delay, options),
    [callback, delay, options],
  )

  /* @ts-expect-error auto-src: non-strict-conversion */
  return debouncedCallback
}
