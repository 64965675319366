// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import LabelContainer from 'common/components/LabelContainer'
import { NEVER_LABEL } from '../lib'
import type { Props } from '..'

const NeverLabel = ({ availability, includeName }: Props) => {
  const label = includeName
    ? `${availability.name || ''} (${NEVER_LABEL})`
    : NEVER_LABEL

  return <LabelContainer>{label}</LabelContainer>
}

export default NeverLabel
