import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import PreviousIcon from '@material-ui/icons/ChevronLeft'
import NextIcon from '@material-ui/icons/ChevronRight'
import TodayButton from './TodayButton'

type Props = {
  classNames?: {
    navBar: string
  }
  locale?: string
  month?: Date
  onNextClick?: () => void
  onPreviousClick?: () => void
  onTodayClick?: () => void
  showNextButton?: boolean
  showPreviousButton?: boolean
}

const Navbar = ({
  classNames,
  locale,
  month = new Date(),
  onTodayClick = () => null,
  onNextClick = () => null,
  onPreviousClick = () => null,
  showNextButton,
  showPreviousButton,
}: Props): JSX.Element => (
  <div className={classNames?.navBar}>
    <div className="ossVD-navbar-caption" data-testid="current-month">
      {month.toLocaleDateString(locale, {
        month: 'long',
        year: 'numeric',
      })}
    </div>
    <div className="ossVD-navbar-today">
      <TodayButton onClick={onTodayClick} />
    </div>
    <div className="ossVD-navbar-navigation">
      <IconButton
        className="ossVD-navButton"
        data-testid="previous-month-button"
        disabled={!showPreviousButton}
        onClick={() => onPreviousClick()}
      >
        <PreviousIcon />
      </IconButton>
      <IconButton
        className="ossVD-navButton"
        data-testid="next-month-button"
        disabled={!showNextButton}
        onClick={() => onNextClick()}
      >
        <NextIcon />
      </IconButton>
    </div>
  </div>
)

export default Navbar
