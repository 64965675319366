import { makeStyles } from '@material-ui/styles'

const mediaQueries = {
  smallPhone: '@media (max-width: 430px)',
}

const daySize = 48
const compactDaySize = 32

const useStyles = makeStyles({
  wrapper: {
    outline: 'none',
  },
  body: {
    marginTop: 12,
  },
  navBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 28px',
    borderBottom: '1px solid #E0E0E0',
    height: 76,
    '& .ossVD-navbar-caption': {
      flexGrow: 1,
      fontSize: 20,
      lineHeight: '36px',
      color: '#212121',
    },
    '& .ossVD-navbar-today': {
      flexGrow: 0,
      paddingRight: 8,
    },
    '& .ossVD-navbar-navigation': {
      display: 'flex',
      flexGrow: 0,
      justifyContent: 'space-between',
      width: 64,
      '& .ossVD-navButton': {
        padding: 8,
      },
    },
  },
  caption: {
    display: 'none',
  },
  day: {
    width: daySize,
    height: daySize,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    color: '#212121',
    cursor: 'pointer',
    outline: 'none',
    [mediaQueries.smallPhone]: {
      height: compactDaySize,
      width: compactDaySize,
    },
    '&:not(:first-child)': {
      marginLeft: 12,
    },
    '& .ossVD-dayDesks': {
      fontSize: 8,
      lineHeight: '12px',
      color: '#9E9E9E',
      textTransform: 'uppercase',
      display: 'none', // remove when adding the desks count
    },
    '& .ossVD-dayDate': {
      fontSize: 14,
      lineHeight: '16px',
    },
  },
  month: {
    margin: '28px 20px 20px 20px',
    userSelect: 'none',
  },
  week: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    '&:not(:first-child)': {
      marginTop: 8,
    },
  },
  weekdaysRow: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  weekday: {
    color: '#9E9E9E',
    textAlign: 'center',
    fontSize: 12,
    lineHeight: '16px',
    width: daySize,
    fontWeight: 600,
    [mediaQueries.smallPhone]: {
      width: compactDaySize,
    },
    '& abbr': {
      textDecoration: 'none',
    },
  },
  outside: {
    color: '#212121',
  },
  disabled: {
    color: '#9E9E9E',
    cursor: 'default',
    '& .ossVD-dayDesks': {
      display: 'none',
    },
  },
  today: {
    color: '#42A5F5',
  },
  selected: {
    color: 'white',
    backgroundColor: '#42A5F5',
    '& .ossVD-dayDesks': {
      color: '#E2EDFC',
    },
  },
})

export default useStyles
