import { useQuery } from '@apollo/client'
/* @ts-expect-error auto-src: non-strict-conversion */
import query from './query.graphql'

type Result = {
  color?: string
  description?: string
  imageUrl?: string
  isLoading: boolean
  title?: string
}

type Image = {
  path: string
}

type Neighborhood = {
  id: string
  color: string
  description?: string
  image?: Image
  name: string
}

type Data = {
  neighborhoods: Neighborhood[]
}

const useData = (id: string): Result => {
  const { data, loading } = useQuery<Data>(query, {
    variables: { ids: [id] },
  })

  if (loading) {
    return {
      isLoading: true,
    }
  }

  const neighborhoods = data?.neighborhoods || []
  const { color, name, description, image } = neighborhoods[0]

  const imageUrl = image?.path

  return {
    isLoading: false,
    color,
    imageUrl,
    title: name,
    description,
  }
}

export default useData
