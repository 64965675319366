// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
// Note that these styles are imported into pinCard, so take care when modifying values herein
import StyleConstants from './definitions'

export const defaultHeight = 70
export const portraitHeight = 75
export const iconSize = `${StyleConstants.icon.iconSize}px`
export const sheetBoxShadow = '0px -1px 5px rgba(153,153,153,0.75)'

export const standardFont = {
  color: StyleConstants.font.color.standard,
  fontSize: `${StyleConstants.font.size.standard}px`,
  fontWeight: StyleConstants.font.weight.normal,
}
export const titleFont = {
  color: StyleConstants.font.color.heading,
  fontSize: StyleConstants.font.size.heading,
  fontWeight: StyleConstants.font.weight.semibold,
}
export default ({ viewportCategories: { portraitHandsetOrTablet } }: any) => ({
  container: {
    backgroundColor: '#FFFFFF',
    boxShadow: sheetBoxShadow,
    display: 'flex',
    height: portraitHandsetOrTablet ? portraitHeight : defaultHeight,
    position: 'relative',
    width: '100%',
    zIndex: 100,
  },
  sheetContainer: { ...standardFont, display: 'flex' },
  iconColumn: {
    maxWidth: '56px',
    padding: '15px 16px',
  },
  contentColumn: {
    flex: '1 1 auto',
    padding: '15px 15px 15px 0',
  },
  title: titleFont,
  content: {},
  sheetIcon: {
    color: StyleConstants.icon.instructionSheetIconColor,
  },
})
