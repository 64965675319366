import { TIME_FORMAT } from 'common/types/time'
import Definitions from 'common/constants/definitions'

import timePeriodFormatter from 'visual_directory/lib/timePeriodFormatter'

import { timeZoneAgnosticMoment } from '../lib'

interface LabelTextArgs {
  isByRequestDesk: boolean
  isPending: boolean
  concealEmployeeInfo: boolean
}

interface BookingInfoArgs {
  employeeFullName: string
  employeePhoto: string | null
  concealEmployeeInfo: boolean
  localCheckInTime: string
  localCheckOutScheduled: string
}

const missingPhotoUrl = Definitions.missingEmployeeImage('round')

const photoUrlFor = (url: string | null) => url || missingPhotoUrl

export const labelText = ({
  isByRequestDesk,
  isPending,
  concealEmployeeInfo,
}: LabelTextArgs): string => {
  const isPendingByRequest = isByRequestDesk && isPending

  if (!isPendingByRequest) {
    return 'Next booking:'
  }

  if (concealEmployeeInfo) {
    return 'Requested:'
  }

  return 'Pending booking request:'
}

export const bookingInfo = ({
  employeeFullName,
  employeePhoto,
  concealEmployeeInfo,
  localCheckOutScheduled,
  localCheckInTime,
}: BookingInfoArgs): {
  name: string
  photoUrl: string
  time: string
} => {
  if (concealEmployeeInfo) {
    return {
      name: '',
      photoUrl: missingPhotoUrl,
      time: timePeriodFormatter({
        timePeriod: {
          start: timeZoneAgnosticMoment(localCheckInTime),
          end: timeZoneAgnosticMoment(localCheckOutScheduled),
        },
        variant: 'time',
      }),
    }
  }

  return {
    name: employeeFullName,
    photoUrl: photoUrlFor(employeePhoto),
    time: timeZoneAgnosticMoment(localCheckInTime).format(TIME_FORMAT),
  }
}
