// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/*
Notes: This is a rough approximation of one of the several techniques outlined here:

https://bannedit.github.io/Virtual-Machine-Detection-In-The-Browser.html

So, to reiterate: THIS IS NOT FOOLPROOF. There will be false positives and false negatives.
Over time we hope this function will get more sophisticated with more rules and exceptions.
*/
const REGEXES = [
  /(Google)? SwiftShader/i,
  /VMWare/i,
  /VirtualBox/i,
  /Microsoft Basic Render Driver/i,
  /Software Adapter/i,
]

export default () => {
  const canvas = document.createElement('canvas')
  const gl = canvas.getContext('webgl')

  if (!gl) {
    // Can't get WebGL context. We don't have enough information to determine
    // if it's virtualized, but we return true to force canvas anyway.
    return true
  }

  const debugInfo = gl.getExtension('WEBGL_debug_renderer_info')
  /* @ts-expect-error auto-src: strict-conversion */
  const unmaskedRenderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)

  if (REGEXES.some((re) => unmaskedRenderer.match(re))) {
    // eslint-disable-next-line no-console
    console.warn(
      [
        'Detected a high chance of running in a virtual machine.',
        `Found unmasked renderer "${unmaskedRenderer}".`,
        'For performance reasons, now forcing canvas mode.',
      ].join('\n'),
    )

    return true
  }

  return false
}
