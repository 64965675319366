// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from 'reselect'
import type { OutputSelector } from 'reselect'
import { kioskHasFloorId } from '../lib/kioskHelpers'

/* @ts-expect-error auto-src: strict-conversion */
const getKiosk = ({ kiosk }) => kiosk

/* @ts-expect-error auto-src: strict-conversion */
const getDiscoveryMode = ({ settings: { discoveryMode } }) => discoveryMode

export default createSelector(
  getKiosk,
  getDiscoveryMode,
  (kiosk, discoveryMode) =>
    kioskHasFloorId(kiosk) && discoveryMode === 'browse',
) as OutputSelector<any, any, any>
