import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    borderTop: '1px solid #E0E0E0',
    display: 'flex',
    padding: '20px 28px',
  },
  label: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
  },
  field: {
    marginLeft: 10,
    width: 166,
  },
})

export default useStyles
