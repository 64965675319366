import React from 'react'
import { connect } from 'react-redux'
import { List } from '@material-ui/core'
import useFlags from 'common/hooks/useFlags'
import { close } from 'common/actions/navMenu'
import useInMobileMode from 'visual_directory/components/shared/useInMobileMode'
import * as MenuActions from 'visual_directory/selectors/menuActions'
import type RootState from 'visual_directory/store/rootState'

import useStyles from './styles'
import {
  BookADesk,
  BookARoom,
  BrowseByFloor,
  BrowseDirectoryList,
  CreateARequest,
  MyBookings,
  PrintFloor,
  SafeguardForm,
  WhoIsIn,
  VDMainBrowseByFloor,
} from '.'

type StateProps = {
  shouldShowBookDesk?: boolean
  shouldShowBookRoom?: boolean
  shouldShowBrowseByFloor: boolean
  shouldShowBrowseDirectory: boolean
  shouldShowCreateRequest: boolean
  shouldShowMyBookings: boolean
  shouldShowPrintFloor: boolean
  shouldShowSafeguardForm: boolean
}

type DispatchProps = {
  closeMenu: () => void
}

type OwnProps = {
  isGuest?: boolean
  isOpen?: boolean
}

export type Props = StateProps & DispatchProps & OwnProps

const areAnyTrue = (flagList: Array<boolean>) =>
  !flagList.every((flag) => !flag)

export const UnconnectedMenuActions = (props: Props): JSX.Element | null => {
  const {
    closeMenu,
    isGuest = false,
    isOpen = false,
    shouldShowBookDesk,
    shouldShowBookRoom,
    shouldShowBrowseByFloor,
    shouldShowBrowseDirectory,
    shouldShowCreateRequest,
    shouldShowMyBookings,
    shouldShowPrintFloor,
    shouldShowSafeguardForm,
  } = props
  const flags = useFlags()
  const inMobileMode = useInMobileMode()
  const showImprovedNavigation =
    flags['hamburger_menu.improved_navigation.enable']

  const styles = useStyles()

  const shouldShowWhoIsIn = inMobileMode
    ? flags['visual_directory.mobile_who_is_coming_in.enable']
    : flags['visual_directory.who_is_coming_in.enable']

  if (
    !areAnyTrue([
      shouldShowBookDesk,
      shouldShowBookRoom,
      shouldShowBrowseByFloor,
      shouldShowBrowseDirectory,
      shouldShowCreateRequest,
      shouldShowMyBookings,
      shouldShowPrintFloor,
      shouldShowSafeguardForm,
      shouldShowWhoIsIn,
      showImprovedNavigation,
    ])
  ) {
    return null
  }

  if (showImprovedNavigation) {
    const menuProps = {
      isOpen,
      onClick: closeMenu,
      showImprovedNavigation,
      isGuest,
    }

    return (
      <List className={styles.submenu}>
        <VDMainBrowseByFloor {...menuProps} />
        {shouldShowBrowseByFloor && <BrowseByFloor {...menuProps} />}
        {shouldShowMyBookings && <MyBookings {...menuProps} />}
        {shouldShowBookDesk && <BookADesk {...menuProps} />}
        {shouldShowBookRoom && <BookARoom {...menuProps} />}
        {shouldShowWhoIsIn && <WhoIsIn {...menuProps} />}
        {shouldShowSafeguardForm && <SafeguardForm {...menuProps} />}
        {/* @ts-expect-error auto-src: non-strict-conversion */}
        {shouldShowBrowseDirectory && <BrowseDirectoryList {...menuProps} />}
        {shouldShowCreateRequest && <CreateARequest {...menuProps} />}
        {shouldShowPrintFloor && <PrintFloor {...menuProps} />}
      </List>
    )
  }

  return (
    <List>
      {shouldShowSafeguardForm && <SafeguardForm />}
      {shouldShowBrowseByFloor && <BrowseByFloor onClick={closeMenu} />}
      {shouldShowWhoIsIn && <WhoIsIn onClick={closeMenu} />}

      {/* @ts-expect-error auto-src: non-strict-conversion */}
      {shouldShowBrowseDirectory && <BrowseDirectoryList onClick={closeMenu} />}
      {shouldShowCreateRequest && <CreateARequest onClick={closeMenu} />}
      {shouldShowBookRoom && <BookARoom onClick={closeMenu} />}
      {shouldShowBookDesk && <BookADesk onClick={closeMenu} />}
      {shouldShowMyBookings && <MyBookings onClick={closeMenu} />}
      {/* @ts-expect-error auto-src: Removed connect generics */}
      {shouldShowPrintFloor && <PrintFloor onClick={closeMenu} />}
    </List>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  shouldShowBookDesk: MenuActions.shouldShowBookDesk(state),
  shouldShowBookRoom: MenuActions.shouldShowBookRoom(state),
  shouldShowBrowseByFloor: MenuActions.shouldShowBrowseByFloor(),
  shouldShowBrowseDirectory: MenuActions.shouldShowBrowseDirectory(state),
  shouldShowCreateRequest: MenuActions.shouldShowCreateRequest(state),
  shouldShowMyBookings: MenuActions.shouldShowMyBookings(state),
  shouldShowPrintFloor: MenuActions.shouldShowPrintFloor(state),
  shouldShowSafeguardForm: MenuActions.shouldShowSafeguardForm(state),
})

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  {
    closeMenu: close,
  },
  undefined,
  {
    areStatesEqual: () => false,
  },
)(UnconnectedMenuActions)
