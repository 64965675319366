// The following eslint-disable was automated from the ts conversion
import { useQuery } from '@apollo/client'
/* @ts-expect-error auto-src: non-strict-conversion */
import UtilityQuery from './UtilityData.graphql'

export type Utility = {
  id: string
  label: string
}

type Variables = {
  ids: ReadonlyArray<string>
}
type Data = {
  utilities: ReadonlyArray<Utility>
}

type Result = {
  loading: boolean
  utility: Utility
}

const useData = (variables: Variables): Result => {
  const { loading, data } = useQuery<Data, Variables>(UtilityQuery, {
    variables,
  })
  const utility = (data?.utilities || [])[0]

  return {
    loading,
    utility,
  }
}

export default useData
