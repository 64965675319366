import { Record } from 'immutable'

export type BoundingBoxType = {
  height: number
  width: number
  xOffset: number
  yOffset: number
}

export default Record<BoundingBoxType>({
  height: 0,
  width: 0,
  xOffset: 0,
  yOffset: 0,
})
