import { useQuery } from '@apollo/client'
/* @ts-expect-error auto-src: non-strict-conversion */
import SeatDataQuery from './SeatData.graphql'

type Seat = {
  id: string
  label: string
}

type SeatsData = { seats: ReadonlyArray<Seat> }

type Variables = {
  ids: ReadonlyArray<string>
}

type Result = {
  loading: boolean
  seat: Seat
}

const useData = (variables: Variables): Result => {
  const { loading, data } = useQuery<SeatsData, Variables>(SeatDataQuery, {
    variables,
  })

  const seat = (data?.seats || [])[0]

  return {
    loading,
    seat,
  }
}

export default useData
