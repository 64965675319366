import React, { useState } from 'react'

import { useVDTime } from 'visual_directory/components/VDTimeProvider'
import { useSiteByFloorId } from 'visual_directory/hooks/floorListStore'
import RosterLayout from './Layout'
import useData from './useData'
import Header from './Header'
import DateTimePicker from './Header/DateTimePicker'

const ITEMS_PER_PAGE = 30

type Props = {
  currentFloorId: string
  onClose: () => void
}
const Roster = ({ currentFloorId, onClose }: Props): JSX.Element => {
  const { dateTime: atTime } = useVDTime()
  const site = useSiteByFloorId(currentFloorId)
  const [searchTerm, setSearchTerm] = useState<string | null>(null)

  const {
    isLoading,
    rosterItems,
    expectedCount,
    checkedInCount,
    checkInEnabled,
    loadMore,
  } = useData({
    variables: {
      location: { type: 'site', id: site?.id || '' },
      atTime,
      searchTerm,
      first: ITEMS_PER_PAGE,
    },
    skip: !site,
  })

  const dateTimePicker = site?.futureBookingVisibilityEnabled ? (
    <DateTimePicker siteId={site.id} />
  ) : null

  const header = (
    <Header dateTimePicker={dateTimePicker} onCloseButtonClick={onClose} />
  )

  return (
    <RosterLayout
      checkedInCount={checkedInCount}
      checkInEnabled={checkInEnabled}
      expectedCount={expectedCount}
      header={header}
      items={rosterItems}
      loading={isLoading || !site}
      loadMore={loadMore}
      onSearchTermChange={setSearchTerm}
      searchTerm={searchTerm}
    />
  )
}

export default Roster
