import { makeStyles } from '@material-ui/styles'
import type { Theme } from '@material-ui/core'

export default makeStyles(({ palette }: Theme) => ({
  root: {
    flexShrink: 0,
    height: 48,
    background: palette.common.white,
  },
}))
