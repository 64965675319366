import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PinDrop as PinIcon } from '@material-ui/icons'

import NavMenuItem from 'common/components/navmenu/NavMenuItem'
import { bookDeskPath } from 'visual_directory/lib/paths'
import { resetState } from 'visual_directory/actions/deskBooking'
import useAppSelector from 'visual_directory/hooks/useAppSelector'

type Props = {
  onClick?: () => void
  isGuest?: boolean
  showImprovedNavigation?: boolean
}

const BookADesk = ({
  onClick,
  isGuest,
  showImprovedNavigation,
}: Props): JSX.Element => {
  const { t } = useTranslation('visual_directory', {
    keyPrefix: 'visual_directory.home_menu',
  })

  const dispatch = useDispatch()
  const floorId = useAppSelector(({ route }) =>
    route.getIn(['match', 'params', 'floorId']),
  )

  const handleOnClick = () => {
    dispatch(resetState())

    if (typeof onClick === 'function') {
      onClick()
    }
  }

  const shouldChangeStyle = !showImprovedNavigation || isGuest

  return (
    <NavMenuItem
      component={Link}
      isBold={shouldChangeStyle}
      LeftIcon={shouldChangeStyle && PinIcon}
      onClick={handleOnClick}
      to={bookDeskPath(floorId)}
    >
      {t('book_a_desk')}
    </NavMenuItem>
  )
}

export default BookADesk
