// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import type { $ElementType } from 'utility-types'

export type GraphValidationErrors<T> = ReadonlyArray<{
  attribute: keyof T
  errors: ReadonlyArray<string>
}>
export type GraphGlobalError = {
  code: string
  message: string
} | void
export type GraphGlobalErrors = ReadonlyArray<GraphGlobalError> | void
export type FormError<T> =
  | ReadonlyArray<FormError<$ElementType<T, number>>>
  | boolean
  | string
  | void
export type ValidationErrors<T> = Partial<
  /* @ts-expect-error auto-src: non-strict-conversion */
  $ObjMap<T, <U>(arg0: U) => FormError<NonNullable<T>>>
>
export const toString = (
  errors: FormError<any>,
  {
    defaultMessage = '',
    includeKeys = true,
  }: {
    defaultMessage?: string
    includeKeys?: boolean
  } = {},
): string => {
  if (!errors) {
    return ''
  }

  if (errors === true) {
    return defaultMessage
  }

  if (Array.isArray(errors)) {
    return errors
      .map((err) =>
        toString(err, {
          defaultMessage,
          includeKeys,
        }),
      )
      .filter((message) => message)
      .join('; ')
  }

  if (typeof errors === 'object') {
    return (
      Object.keys(errors)
        /* @ts-expect-error auto-src: strict-conversion */
        .filter((key) => !!errors[key])
        .map((key) => {
          const prefix = includeKeys ? `${key} ` : ''
          /* @ts-expect-error auto-src: strict-conversion */
          const message = toString(errors[key], {
            defaultMessage,
            includeKeys,
          })

          return `${prefix}${message}`
        })
        .filter((message) => message)
        .join('; ')
    )
  }

  return errors
}
/* @ts-expect-error auto-src: strict-conversion */
export const any = (obj: any) => {
  if (!obj) {
    return false
  }

  if (['boolean', 'number', 'string'].includes(typeof obj)) {
    return true
  }

  if (Array.isArray(obj)) {
    return obj.some(any)
  }

  return Object.keys(obj).some((key) => any(obj[key]))
}
