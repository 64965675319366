// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { resourceTypes } from 'common/constants/resources'
import {
  locateNeighborhood,
  locateResource,
  locateRoom,
} from 'visual_directory/actions/floor'
import ActionTypes from 'visual_directory/constants/actionTypes'

export function resetResource() {
  return {
    type: ActionTypes.Resource.RESET,
  }
}
export function bookingFailed() {
  return {
    type: ActionTypes.Resource.BOOKING_FAILED,
  }
}
export function bookingRequestStarted() {
  return {
    type: ActionTypes.Resource.BOOKING_REQUEST_STARTED,
  }
}
export function bookingRequestFinished() {
  return {
    type: ActionTypes.Resource.BOOKING_REQUEST_FINISHED,
  }
}
/* @ts-expect-error auto-src: noflow */
export const clickInShape = (tap, resourceId) => ({
  resourceId,
  type: ActionTypes.Resource.CLICKED_IN_SHAPE,
  x: tap.x,
  y: tap.y,
})

/* @ts-expect-error auto-src: strict-conversion */
export const selectResource = (resource) => {
  const { resourceId, resourceType, floorGroupId } = resource

  /* @ts-expect-error auto-src: noflow */
  return (dispatch, getState) => {
    const shouldCenter = getState().floorStore.get('shouldCenter')

    if (shouldCenter) {
      if (
        resourceType === resourceTypes.ROOM ||
        resourceType === resourceTypes.SHARE
      ) {
        dispatch(locateRoom({ id: resourceId }))
      } else if (resourceType === resourceTypes.NEIGHBORHOOD) {
        dispatch(locateNeighborhood({ id: resourceId }))
      } else if (
        resourceType === resourceTypes.SEAT ||
        resourceType === resourceTypes.UTILITY
      ) {
        dispatch(locateResource({ id: resourceId, type: resourceType }))
      }
    }

    dispatch({
      type: ActionTypes.Resource.SELECTED,
      resourceId,
      resourceType,
      floorGroupId,
    })
  }
}
