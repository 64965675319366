// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,no-param-reassign */
import AnimationConstants from 'common/constants/animations'
import Immutable from 'immutable'
import gsap from 'gsap'
import { compose } from 'redux'
import constant from 'lodash/fp/constant'
import mapValues from 'lodash/mapValues'
import omit from 'lodash/fp/omit'
import some from 'lodash/some'
import { flatten, unflatten } from 'flat'
import { map } from 'common/lib/functionalImmutableHelpers'

export default ({
  deltas = {},
  /* @ts-expect-error auto-src: noflow */
  dispatch,
  /* @ts-expect-error auto-src: noflow */
  dispatchType,
  /* @ts-expect-error auto-src: noflow */
  onComplete,
  /* @ts-expect-error auto-src: noflow */
  stateSlices,
  /* @ts-expect-error auto-src: noflow */
  runningAnimations,
}) => {
  const animationKey = 'zoomTween'
  const { duration, ease } = AnimationConstants.zoomFloorPlan
  // from values
  // We use `flatten` since gsap doesn't tween values in nested objects
  const tweenValues = flatten(
    mapValues(stateSlices, (slice) => ({
      positionIngredients: slice
        .get('positionIngredients', Immutable.Seq.Keyed())
        .toSeq() // .toSeq needed to be able to map over Records
        .map(compose(map(constant(0)), Immutable.Seq.Keyed))
        .toJS(),
      zoomIngredients: slice
        .get('zoomIngredients', Immutable.Seq.Keyed())
        .toSeq()
        .map(constant(0))
        .toJS(),
    })),
  )
  const tweenTarget = flatten(deltas)

  /* @ts-expect-error auto-src: noflow */
  if (some(tweenTarget)) {
    /* @ts-expect-error auto-src: noflow */
    const previousValues = mapValues(tweenTarget, constant(0))
    const animationParams = {
      /* @ts-expect-error auto-src: noflow */
      ...tweenTarget,
      duration,
      ease,
      onUpdate: () => {
        dispatch({
          type: dispatchType,
          ...unflatten(
            mapValues(
              /* @ts-expect-error auto-src: noflow */
              omit(['_gsap'])(tweenValues),
              /* @ts-expect-error auto-src: noflow */
              (value, key) => (value || 0) - (previousValues[key] || 0),
            ),
          ),
        })
        Object.assign(previousValues, tweenValues)
      },
      onComplete: () => {
        // auto-src: ts-eslint eslint-disable-next-line no-param-reassign
        runningAnimations[animationKey] = undefined

        if (typeof onComplete === 'function') {
          onComplete()
        }
      },
    }

    if (runningAnimations[animationKey]) {
      runningAnimations[animationKey].kill()
    }

    // auto-src: ts-eslint eslint-disable-next-line no-param-reassign
    /* @ts-expect-error auto-src: noflow */
    runningAnimations[animationKey] = gsap.to(tweenValues, animationParams)
  } else if (typeof onComplete === 'function') {
    onComplete()
  }
}
