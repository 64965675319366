// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import type { ReactElement } from 'react'
import { List } from '@material-ui/core'
import ProductConstants from 'common/constants/products'
import ProductMenuItems from 'common/components/navmenu/ProductMenuItems'
import useFlags from 'common/hooks/useFlags'

type ProductName = string
type Path = string
type Props = {
  MobileAppDialog: ReactElement
  productLinks: Record<ProductName, Path>
}

const HelpAndFeedback = ({ MobileAppDialog, productLinks }: Props) => {
  const helpPath = productLinks[ProductConstants.HELP]
  const feedbackEmail = productLinks[ProductConstants.SEND_FEEDBACK]
  const workplacePortalPath = productLinks[ProductConstants.WORKPLACE_PORTAL]

  const {
    'hamburger_menu.improved_navigation.enable': shouldShowImprovedNavigation,
  } = useFlags()

  return (
    <List>
      {shouldShowImprovedNavigation && MobileAppDialog}
      {/* @ts-expect-error auto-src: non-strict-conversion */}
      {ProductMenuItems({})[ProductConstants.SEND_FEEDBACK]({
        email: feedbackEmail,
      })}
      {helpPath &&
        /* @ts-expect-error auto-src: non-strict-conversion */
        ProductMenuItems({})[ProductConstants.HELP]({
          href: helpPath,
          target: shouldShowImprovedNavigation ? '_blank' : '_self',
        })}
      {workplacePortalPath &&
        /* @ts-expect-error auto-src: non-strict-conversion */
        ProductMenuItems({})[ProductConstants.WORKPLACE_PORTAL]({
          href: workplacePortalPath,
        })}
    </List>
  )
}

export default HelpAndFeedback
