// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import endsWith from 'lodash/endsWith'
import merge from 'lodash/merge'

const checkDevelopmentHostname = () =>
  window.location.hostname === '127.0.0.1' ||
  window.location.hostname === 'localhost' ||
  endsWith(window.location.hostname, 'ossd.co')

type ConfigOptions = {
  flags?: Record<string, boolean | string>
}

type User = {
  key: string
  anonymous: boolean
  custom: {
    hostname: string
    pendoVisitorReports: ReadonlyArray<string>
  }
}

declare global {
  interface OSS {
    launchDarklyUser?: User
    // eslint-disable-next-line camelcase
    image_url: (args: string) => string
    VD_URL?: string
  }

  interface Window {
    oss: OSS
  }
}

const launchDarklyUser = () => {
  const fallbackUser = { key: null, anonymous: true }

  const userFromBackendConfig = window.oss?.launchDarklyUser || fallbackUser

  const frontendHostname =
    process.env.NODE_ENV === 'development'
      ? 'localhost'
      : window.location.hostname

  return merge(userFromBackendConfig, {
    custom: {
      hostname: frontendHostname,
    },
  })
}

const config = ({ flags }: ConfigOptions = {}) => ({
  clientSideID:
    process.env.NODE_ENV === 'production' && !checkDevelopmentHostname()
      ? '608027c7bcf4820be9b164d0'
      : '608027c7bcf4820be9b164cf',
  flags,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  user: launchDarklyUser(),
})

export default config
