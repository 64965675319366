import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Print as PrintIcon } from '@material-ui/icons'

import NavMenuItem from 'common/components/navmenu/NavMenuItem'
import { makePrintFloorPath } from 'common/lib/paths'
import { resetFloor } from 'common/actions/floor'

type Props = {
  disabled?: boolean
  onClick?: () => void
  isGuest?: boolean
  selectedFloorId?: number
  showImprovedNavigation?: boolean
}

const PrintFloor = ({
  onClick,
  disabled,
  isGuest,
  selectedFloorId,
  showImprovedNavigation,
}: Props) => {
  const { t } = useTranslation('visual_directory', {
    keyPrefix: 'visual_directory.home_menu',
  })

  const path =
    (selectedFloorId &&
      makePrintFloorPath({
        floorId: selectedFloorId,
      })) ||
    ''

  const shouldChangeStyle = !showImprovedNavigation || isGuest

  return (
    <NavMenuItem
      component={Link}
      isBold={shouldChangeStyle}
      isDisabled={disabled}
      LeftIcon={shouldChangeStyle && PrintIcon}
      onClick={onClick}
      to={path}
    >
      {t('print_floor')}
    </NavMenuItem>
  )
}

export default connect(
  /* @ts-expect-error auto-src: strict-conversion */
  ({ selectedFloor }) => ({
    disabled: !selectedFloor.get('isRendered'),
    selectedFloorId: selectedFloor.get('id'),
  }),
  (dispatch, ownProps) => ({
    onClick:
      /* @ts-expect-error auto-src: Removed connect generics */
      typeof ownProps.onClick === 'function'
        ? /* @ts-expect-error auto-src: strict-conversion */
          (event) => {
            /* @ts-expect-error auto-src: Removed connect generics */
            ownProps.onClick(event)
            dispatch(resetFloor())
          }
        : /* @ts-expect-error auto-src: Removed connect generics */
          ownProps.onClick,
  }),
)(PrintFloor)
