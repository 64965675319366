// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'

import moment from 'moment-timezone'
import type { Moment } from 'moment-timezone'
import { toString } from 'common/types/errors'
import type { FormError } from 'common/types/errors'
import { defaultRange } from 'common/types/time'
import type { LabelledTime } from 'common/types/time'
import SelectField from 'visual_directory/components/shared/form/SelectField'
import { timeOptions } from '../lib'
import useStyles from './styles'

type Props = {
  className?: string
  disabled?: boolean
  error?: FormError<any>
  label?: string
  now?: Moment
  onChange: (arg0: Moment) => unknown
  options?: ReadonlyArray<LabelledTime>
  placeholder?: string
  shouldDisableTime?: (time: number) => boolean
  timeZone?: string
  value?: any
}
const MIN_INTERVAL = 15

const TimePicker = ({
  className,
  disabled,
  error,
  label,
  now,
  onChange,
  options = defaultRange,
  placeholder = 'Choose',
  shouldDisableTime = () => false,
  timeZone = moment.tz.guess(),
  value,
}: Props) => {
  const classes = useStyles()
  const momentNow = now ? moment(now) : moment().tz(timeZone)
  const currentValue = moment(value || momentNow.format('YYYY-MM-DD HH:mm'))

  const selectedValue = currentValue
    .minute(Math.round(currentValue.minute() / MIN_INTERVAL) * MIN_INTERVAL)
    .second(0)
    .format('LT')

  const menuItemOptions = timeOptions(
    options.map((option) => ({
      ...option,
      disabled: option.disabled || shouldDisableTime(option.value),
    })),
    currentValue,
  )

  /* @ts-expect-error auto-src: strict-conversion */
  const handleChange = (event) =>
    onChange(moment(`${currentValue.format('L')} ${event.target.value}`))

  const renderValue = () => (value ? currentValue.format('LT') : placeholder)

  return (
    <SelectField
      data-testid="time-picker"
      disabled={disabled}
      displayEmpty
      error={!!error}
      helperText={toString(error)}
      inputProps={{
        classes: {
          selectMenu: className,
        },
      }}
      label={label}
      MenuProps={{
        classes: {
          paper: classes.selectMenu,
        },
        getContentAnchorEl: null,
        /* @ts-expect-error auto-src: Replaced ElementConfig */
        'data-testid': 'time-picker-dropdown',
      }}
      name="time"
      onChange={handleChange}
      renderValue={renderValue}
      value={selectedValue}
    >
      {menuItemOptions.map((option, index) => (
        <MenuItem
          key={index}
          className={option.tinted ? classes.tintedColor : ''}
          disabled={option.disabled}
          value={option.value}
        >
          <span data-testid="time-picker-time-text">{option.label}</span>
          <span className={classes.duration}>{option.formattedDuration}</span>
        </MenuItem>
      ))}
    </SelectField>
  )
}

export default TimePicker
