import React from 'react'
import type { ComponentPropsWithRef } from 'react'

import { open, close } from 'common/actions/navMenu'

import useAppSelector from 'visual_directory/hooks/useAppSelector'
import useAppDispatch from 'visual_directory/hooks/useAppDispatch'

import UnconnectedBaseNavMenu from './UnconnectedBaseNavMenu'

type UnconnectedBaseNavMenuProps = ComponentPropsWithRef<
  typeof UnconnectedBaseNavMenu
>
type Props = Omit<UnconnectedBaseNavMenuProps, 'isOpen' | 'onClose' | 'onOpen'>

const BaseNavMenu = (props: Props): JSX.Element => {
  const isOpen = useAppSelector(({ navMenu }) => navMenu.isOpen)
  const dispatch = useAppDispatch()

  const onOpen = () => dispatch(open())
  const onClose = () => dispatch(close())

  const baseNavMenuProps = {
    isOpen,
    onClose,
    onOpen,
    ...props,
  }

  return <UnconnectedBaseNavMenu {...baseNavMenuProps} />
}

export default BaseNavMenu
