import { makeStyles } from '@material-ui/styles'

export default makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 18,
    height: 18,
    borderRadius: 9,
    border: '1px solid #FFFFFF',
    background: '#D3E9D7',
  },
  icon: {
    width: 10,
    height: 10,
    color: '#35703A',
  },
})
