// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ActionTypes from 'common/constants/actionTypes'

export const componentIdled = (name: string) => ({
  name,
  type: ActionTypes.Stage.COMPONENT_IDLED,
})
export const componentResumed = (name: string) => ({
  name,
  type: ActionTypes.Stage.COMPONENT_RESUMED,
})
