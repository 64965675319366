// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import CheckedInIcon from '../CheckedInIcon'
import useStyles from './useStyles'

type Props = {
  checkInEnabled: boolean
  checkedInCount: number
  expectedCount: number
  loading: boolean
}

const footerText = ({
  checkInEnabled,
  checkedInCount,
  expectedCount,
}: Omit<Props, 'loading'>): string => {
  if (checkInEnabled) {
    return `Checked In: ${checkedInCount} | Expected: ${expectedCount}`
  }

  return `Expected: ${expectedCount}`
}

const Footer = ({
  checkInEnabled,
  checkedInCount,
  expectedCount,
  loading,
}: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.root} data-testid="footer">
      {checkInEnabled ? <CheckedInIcon /> : ''}
      {loading ? (
        <Skeleton className={classes.skeleton} variant="rect" />
      ) : (
        <span className={classes.text}>
          {footerText({ checkInEnabled, checkedInCount, expectedCount })}
        </span>
      )}
    </div>
  )
}

export default Footer
