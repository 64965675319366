// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Layers as FloorIcon } from '@material-ui/icons'

import NavMenuItem from 'common/components/navmenu/NavMenuItem'
import { floorsPath } from 'visual_directory/lib/paths'

type Props = {
  onClick?: () => void
  isGuest?: boolean
  showImprovedNavigation?: boolean
}

const BrowseByFloor = ({ onClick, isGuest, showImprovedNavigation }: Props) => {
  const { t } = useTranslation('visual_directory', {
    keyPrefix: 'visual_directory.home_menu',
  })

  const shouldChangeStyle = !showImprovedNavigation || isGuest

  return (
    <NavMenuItem
      component="a"
      href={`/visual-directory${floorsPath}`}
      isBold={shouldChangeStyle}
      LeftIcon={shouldChangeStyle && FloorIcon}
      onClick={onClick}
    >
      {t('browse_by_floor')}
    </NavMenuItem>
  )
}

export default BrowseByFloor
