import moment from 'moment'
import partition from 'lodash/partition'
import {
  maxWeekOfYear,
  setWeekAndYear,
  weekEnd,
  weekStart,
  weekWithStart,
} from 'common/lib/momentHelpers'
import type { SeatAvailability } from 'common/types/seatAvailability'

/* @ts-expect-error auto-src: non-strict-conversion */
const getDateRangeString = (from: moment, to: moment) => {
  const year = to.year() === moment().year() ? '' : ` ${to.year()}`

  /* @ts-expect-error auto-src: strict-conversion */
  const format = (date) => date.format('MMM D')

  return `${format(from)} – ${format(to)}${year}`
}

const getWeeksOfYear =
  /* @ts-expect-error auto-src: strict-conversion */


    (formatter: (arg0: number, arg1: string) => string, { sortByDate }) =>
    ({
      firstDayOfWeek,
      schedule = [],
    }: SeatAvailability): ReadonlyArray<string> => {
      const maxWeek = maxWeekOfYear(moment().year(), firstDayOfWeek)
      const currentWeek = weekWithStart(moment(), firstDayOfWeek)
      const currentYear = moment().year()
      const nextYear = sortByDate ? currentYear + 1 : currentYear

      /* @ts-expect-error auto-src: strict-conversion */
      const getWeekMoment = (year) => (week) =>
        setWeekAndYear(week, year, firstDayOfWeek)

      const weeks = (schedule || [])
        .map((week) => week + 1)
        .filter((week) => week <= maxWeek)
      const [thisYearWeeks, nextYearWeeks] = partition(
        weeks,
        (week) => week >= currentWeek,
      )

      return [
        ...thisYearWeeks.map(getWeekMoment(currentYear)),
        ...nextYearWeeks.map(getWeekMoment(nextYear)),
      ]
        .sort((w1, w2) => w1.unix() - w2.unix())
        .map((week) => {
          const weekNumber = weekWithStart(week, firstDayOfWeek)
          const weekRange = getDateRangeString(
            weekStart(week, firstDayOfWeek),
            weekEnd(week, firstDayOfWeek),
          )

          return formatter(weekNumber, weekRange)
        })
    }

const getDaysOfWeek =
  /* @ts-expect-error auto-src: strict-conversion */


    (days) =>
    ({ schedule }: SeatAvailability): ReadonlyArray<string> =>
      /* @ts-expect-error auto-src: non-strict-conversion */
      days.filter((_day, index) => (schedule || []).includes(index))

export const getShortWeeksOfYear = getWeeksOfYear(
  (weekNumber) => `W${weekNumber}`,
  {
    sortByDate: false,
  },
)
export const getMediumWeeksOfYear = getWeeksOfYear(
  (weekNumber) => `Week ${weekNumber}`,
  {
    sortByDate: false,
  },
)
export const getLongColonWeeksOfYear = getWeeksOfYear(
  (weekNumber, weekRange) => `Week ${weekNumber}: ${weekRange}`,
  {
    sortByDate: true,
  },
)
export const getLongParenthesisWeeksOfYear = getWeeksOfYear(
  (weekNumber, weekRange) => `Week ${weekNumber} (${weekRange})`,
  {
    sortByDate: true,
  },
)
export const getShortDaysOfWeek = getDaysOfWeek(moment.weekdaysShort())
export const getLongDaysOfWeek = getDaysOfWeek(moment.weekdays())
export const ALWAYS_LABEL = 'No restrictions'
export const NEVER_LABEL = 'Never available'
