import { useFloorDateTimePickerDataQuery as useQuery } from 'generated/graphql'

import type { Result } from './types'

type Args = {
  floorId: string
  skip: boolean
}

const useFloorData = ({ floorId, skip }: Args): Result => {
  const { data, loading } = useQuery({ variables: { floorId }, skip })
  const floor = data?.floors[0]
  const site = floor?.site

  if (!floor || !site) {
    return { data: undefined, loading }
  }

  return {
    data: {
      ...site,
      limitToOperatingHours: site.deskBookingSettings.limitToOperatingHours,
      bookableDeskCount: floor.bookableDeskCount,
    },
    loading,
  }
}

export default useFloorData
