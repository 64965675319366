import { makeStyles } from '@material-ui/styles'

type PropsT = {
  isLeftIconPresent: boolean
  navbarHeight: number
}

const navbarPadding = 16
const titlePadding = 8
const iconsPadding = 3

export default makeStyles({
  iconLeft: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 56,
    justifyContent: 'center',
    flex: '0 0 auto',
  },
  title: ({ isLeftIconPresent }: PropsT) => ({
    paddingLeft: isLeftIconPresent ? 0 : navbarPadding + titlePadding,
    fontSize: '16px',
    fontWeight: 600,
  }),
  iconRight: ({ navbarHeight }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: iconsPadding,
    paddingRight: iconsPadding,
    '& > *': {
      minWidth: navbarHeight - iconsPadding * 2,
      height: navbarHeight - iconsPadding * 2,
    },
  }),
  secondaryBar: {
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'start',
    paddingLeft: navbarPadding,
    paddingRight: navbarPadding,
  },
})
