// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any */
// This file is for helpers that allow functional composition when using Immutable objects.
import {
  mergeDeepWith as immutableMergeDeepWith,
  set as immutableSet,
  setIn as immutableSetIn,
} from 'immutable'
import invokeArgs from 'lodash/fp/invokeArgs'
import { compose } from 'redux'
import rearg from 'lodash/fp/rearg'
import curry from 'lodash/curry'

const setArity = 3
const setInArity = 3
const mergeDeepWithArity = 3

// curry's flow-typed definition only has the single arg variant
export const set = (curry as any)(rearg([2, 0, 1])(immutableSet), setArity)
// curry's flow-typed definition only has the single arg variant
export const setIn = (curry as any)(
  rearg([2, 0, 1])(immutableSetIn),
  setInArity,
)
// invokeArgs' flow-typed definition has the arg array and object reversed
export const map = compose((invokeArgs as any)('map'), Array) as (
  arg0: any,
) => any
export const mergeDeepWith = (curry as any)(
  rearg([0, 2, 1])(immutableMergeDeepWith),
  mergeDeepWithArity,
)
