import moment from 'moment-timezone'
import type { Moment } from 'moment-timezone'
import { timeStringToHour } from 'common/types/time'
import { applyTimeFromString, isSameDay } from 'common/lib/timeUtilities'

const isWithinOperatingHours = (
  hour: number,
  startOfDay: number,
  endOfDay: number,
) => hour >= startOfDay && hour < endOfDay

type DayAvailabilityCheckArgs = {
  limitToOperatingHours: boolean
  operatingWeekDays: ReadonlyArray<number>
}
export const dayAvailabilityCheck =
  ({
    limitToOperatingHours = false,
    operatingWeekDays = [],
  }: DayAvailabilityCheckArgs) =>
  (day: Date): boolean => {
    if (!limitToOperatingHours) {
      return true
    }

    return operatingWeekDays.includes(day.getDay())
  }
type HourAvailabilityCheckArgs = {
  startsOfDay: ReadonlyArray<string>
  endsOfDay: ReadonlyArray<string>
}
export const hourAvailabilityCheck =
  ({ startsOfDay = [], endsOfDay = [] }: HourAvailabilityCheckArgs) =>
  (hour: number, selectedDay: Date): boolean => {
    const weekDay = selectedDay.getDay()
    const weekDayStartTimeString = startsOfDay[weekDay]
    const weekDayEndTimeString = endsOfDay[weekDay]
    const startOfDay = timeStringToHour(weekDayStartTimeString)
    const endOfDay = timeStringToHour(weekDayEndTimeString)

    return isWithinOperatingHours(hour, startOfDay, endOfDay)
  }
type DayStartTimeArgs = {
  operatingWeekDays: ReadonlyArray<number>
  startsOfDay: ReadonlyArray<string>
  now?: Moment
}
export const dayStartTime =
  ({
    operatingWeekDays = [],
    startsOfDay = [],
    now = moment(),
  }: DayStartTimeArgs) =>
  (day: Date): string | null => {
    if (isSameDay(day, now)) {
      return null
    }

    const weekDay = day.getDay()
    const isOperatingDay = operatingWeekDays.includes(weekDay)

    const operatingStartTime = isOperatingDay ? startsOfDay[weekDay] : '8:00'

    /* @ts-expect-error src: types added to moment-timezone */
    return applyTimeFromString(day, operatingStartTime).format('HH:mm')
  }

type FormatDateOptions = {
  isToday: boolean
  isNow: boolean
}
export const formatDate = (
  date: Date,
  { isToday, isNow }: FormatDateOptions,
): string => {
  const momentDate = moment(date)

  if (isToday && isNow) {
    return 'Today, Now'
  }

  if (isToday) {
    return ['Today', momentDate.format('h:mm A')].join(', ')
  }

  return momentDate.format('MMM D, h:mm A')
}
export default {
  formatDate,
}
