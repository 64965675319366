// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import Button from '@material-ui/core/Button'
import type { ButtonProps } from '@material-ui/core/Button'
import useStyles from './useStyles'

const PrimaryButton = (props: ButtonProps) => {
  const classes = useStyles()

  return (
    <Button
      classes={classes}
      data-testid="primary-button"
      size="small"
      {...props}
    />
  )
}

export default PrimaryButton
