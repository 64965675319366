// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { Record, Map } from 'immutable'
import type { RecordOf } from 'immutable'

type EmployeeProps = {
  active?: boolean
  email?: string
  firstName?: string
  id?: number
  lastName?: string
  fullName?: string | null | undefined
  name?: string | null | undefined
}
export type EmployeeRecordType = RecordOf<EmployeeProps>
const defaultEmployee: EmployeeProps = {
  active: undefined,
  email: undefined,
  firstName: undefined,
  id: undefined,
  lastName: undefined,
  fullName: undefined,
  name: undefined,
}
const EmployeeRecord = Record<typeof defaultEmployee>(defaultEmployee)

const employeeName = (employee: any) => {
  if (employee.get('fullName')) {
    return employee.get('fullName')
  }

  if (employee.get('firstName') || employee.get('lastName')) {
    return `${employee.get('firstName')} ${employee.get('lastName')}`
  }

  return null
}

export const employeeRecordFromEmployee = (
  employee: any,
): EmployeeRecordType => {
  const base = employee instanceof Map ? employee : Map(employee)

  return EmployeeRecord({
    /* @ts-expect-error auto-src: non-strict-conversion */
    active: base.get('active'),
    /* @ts-expect-error auto-src: non-strict-conversion */
    email: base.get('email'),
    /* @ts-expect-error auto-src: non-strict-conversion */
    firstName: base.get('firstName'),
    /* @ts-expect-error auto-src: non-strict-conversion */
    id: base.get('id'),
    /* @ts-expect-error auto-src: non-strict-conversion */
    lastName: base.get('lastName'),
    fullName: employeeName(base),
    name: employeeName(base),
  })
}
export default EmployeeRecord
