import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

import Definitions from 'common/constants/definitions'

import bookableSeatPermissionLabel from 'visual_directory/lib/bookableSeatPermissionLabel'
import { useWhoIsIn } from 'visual_directory/components/who_is_in'

import type { Options } from '../types'
import Layout, { ContextMenu, ContextMenuItem } from '../Layout'
import useAdjustedCardPosition from '../useAdjustedCardPosition'

import useData from './useData'
import {
  AvailableDetails as AvailableDeskDetails,
  BookedDetails as BookedDeskDetails,
  UnavailableAssignedDetails as UnavailableAssignedDeskDetails,
} from './DeskDetails'
import Title from './Title'
import UpcomingBooking from './UpcomingBooking'
import ActionButton from './ActionButton'
import { isPending, isPhotoVisible, isToday } from './lib'
import SeatAvailability from './SeatAvailability'
import NextAvailable from './NextAvailable'

type Props = {
  id: string
  options: Options
}

const missingPhotoUrl = Definitions.missingEmployeeImage('large')

const BookableDeskCard = ({ id, options }: Props): JSX.Element | null => {
  const data = useData({
    ids: [id],
    atTime: options.atTime,
  })

  const { close: closeWhoIsIn } = useWhoIsIn()

  useAdjustedCardPosition({ skip: data.isLoading })

  if (data.isLoading) {
    return (
      <Layout label={<Skeleton />} title={<Skeleton />}>
        <Skeleton />
      </Layout>
    )
  }

  const {
    activeBooking,
    designation,
    isAvailable,
    isByRequestDesk,
    isRequestRequired,
    isOneToOneRestricted,
    isPermitted,
    label,
    nextAvailableTime,
    nextBooking,
    oneToOneEmployee,
    seatAvailability,
    seatPermissionEmployees,
    seatPermissionType,
    seatPermissionOccupancyPolicy,
  } = data

  const forbiddenAssignedDesk = isOneToOneRestricted && !isPermitted

  const hasNextBookingInBody =
    nextBooking && isOneToOneRestricted && !activeBooking
  const hasNextBookingInFooter = nextBooking && !hasNextBookingInBody

  const showPermissionsLabel = seatPermissionType && !forbiddenAssignedDesk

  const viewMoreDoesNotFit =
    hasNextBookingInFooter &&
    isPhotoVisible(activeBooking, isOneToOneRestricted) &&
    !isAvailable

  const imageUrl =
    oneToOneEmployee?.photo || activeBooking?.employee.photo || missingPhotoUrl

  const canBook =
    isAvailable || (!activeBooking && isPermitted && Boolean(nextAvailableTime))

  const openInfoCard = closeWhoIsIn

  return (
    <Layout
      footerLeft={
        !viewMoreDoesNotFit && (
          <ActionButton
            canBook={canBook}
            isRequestRequired={isRequestRequired}
            onClick={openInfoCard}
          />
        )
      }
      footerRight={
        nextBooking &&
        hasNextBookingInFooter && (
          <UpcomingBooking
            concealEmployeeInfo={nextBooking.concealEmployeeInfo}
            employeeFullName={nextBooking.employee.fullName}
            employeePhoto={nextBooking.employee.photo}
            isByRequestDesk={isByRequestDesk}
            isPending={isPending(nextBooking.status)}
            localCheckInTime={nextBooking.localCheckInTime}
            localCheckOutScheduled={nextBooking.localCheckOutScheduled}
          />
        )
      }
      imageUrl={
        isPhotoVisible(activeBooking, isOneToOneRestricted) ? imageUrl : ''
      }
      label={label}
      title={
        <Title
          assignedEmployeeName={isPermitted ? '' : oneToOneEmployee?.fullName}
          concealEmployeeInfo={activeBooking?.concealEmployeeInfo}
          employeeFullName={activeBooking?.employee.fullName}
          isByRequestDesk={isByRequestDesk}
          isPending={isPending(activeBooking?.status)}
        />
      }
      toolbar={
        viewMoreDoesNotFit && (
          <ContextMenu open>
            <ContextMenuItem onClick={openInfoCard}>View More</ContextMenuItem>
          </ContextMenu>
        )
      }
    >
      {activeBooking && (
        <BookedDeskDetails
          concealEmployeeInfo={activeBooking.concealEmployeeInfo}
          isPending={isPending(activeBooking.status)}
          isToday={isToday(activeBooking.localCheckInTime, options)}
          localCheckInTime={activeBooking.localCheckInTime}
          localCheckOutScheduled={activeBooking.localCheckOutScheduled}
        />
      )}

      {!activeBooking && isPermitted && !isAvailable && (
        <SeatAvailability seatAvailability={seatAvailability} />
      )}

      {!activeBooking && showPermissionsLabel && (
        <i>
          {bookableSeatPermissionLabel({
            seatPermissionType,
            designation,
            seatPermissionEmployees,
            seatPermissionOccupancyPolicy,
          })}
        </i>
      )}

      {!activeBooking && isPermitted && !isAvailable && nextAvailableTime && (
        <NextAvailable
          isToday={isToday(nextAvailableTime, options)}
          nextAvailableTime={nextAvailableTime}
        />
      )}

      {!activeBooking && isAvailable && (
        <AvailableDeskDetails
          isToday={isToday(nextBooking?.localCheckInTime, options)}
          untilTime={nextBooking?.localCheckInTime}
        />
      )}

      {!activeBooking && forbiddenAssignedDesk && (
        <UnavailableAssignedDeskDetails
          isToday={isToday(nextBooking?.localCheckInTime, options)}
          nextIn={nextBooking?.localCheckInTime}
        />
      )}
    </Layout>
  )
}

export default BookableDeskCard
