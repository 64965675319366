import { utils } from 'pixi.js-legacy'
import isSystemVirtualized from './isSystemVirtualized'

const isWebGLSupported = () => {
  /* @ts-expect-error auto-src: non-strict-conversion */
  if (window.disableWebGLWhenVirtualized && isSystemVirtualized()) {
    return false
  }

  return utils.isWebGLSupported()
}

export default isWebGLSupported()
