import { makeStyles } from '@material-ui/styles'
import type { Theme } from '@material-ui/core'

export default makeStyles(({ palette }: Theme) => ({
  root: {
    height: '100%',
    padding: '0 10px 0 15px',
  },
  input: {
    fontSize: 12,
    color: palette.grey[900],
  },
  icon: {
    width: 20,
    height: 20,
    color: palette.grey[600],
  },
}))
