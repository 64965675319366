// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
import React from 'react'
import type { ComponentPropsWithoutRef } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const PersonClockIcon = (props: ComponentPropsWithoutRef<typeof SvgIcon>) => (
  <SvgIcon viewBox="0 0 23 20" {...props}>
    <path
      d="M9.63 10.1C11.23 6.58 15.38 5.03 18.9 6.63C22.42 8.23 23.97 12.38 22.37 15.9C21.24 18.4 18.75 20 16 20C13.3 20 10.83 18.44 9.67 16H0V14C0.06 12.86 0.84 11.93 2.34 11.18C3.84 10.43 5.72 10.04 8 10C8.57 10 9.11 10.05 9.63 10.1ZM8 0C9.12 0.03 10.06 0.42 10.81 1.17C11.56 1.92 11.93 2.86 11.93 4C11.93 5.14 11.56 6.08 10.81 6.83C10.06 7.58 9.12 7.95 8 7.95C6.88 7.95 5.94 7.58 5.19 6.83C4.44 6.08 4.07 5.14 4.07 4C4.07 2.86 4.44 1.92 5.19 1.17C5.94 0.42 6.88 0.03 8 0ZM16 18C17.3261 18 18.5979 17.4732 19.5355 16.5355C20.4732 15.5979 21 14.3261 21 13C21 11.6739 20.4732 10.4021 19.5355 9.46447C18.5979 8.52678 17.3261 8 16 8C14.6739 8 13.4021 8.52678 12.4645 9.46447C11.5268 10.4021 11 11.6739 11 13C11 14.3261 11.5268 15.5979 12.4645 16.5355C13.4021 17.4732 14.6739 18 16 18ZM15 10H16.5V12.82L18.94 14.23L18.19 15.53L15 13.69V10Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default PersonClockIcon
