// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
import React from 'react'

const OssFooterLogo = ({ ...props }) => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 123 16"
    width="123"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.49084 13.1569C8.78535 13.1569 10.9817 10.7903 10.9817 7.54939C10.9817 4.30854 8.78535 1.95703 5.49084 1.95703C2.19634 1.95703 0 4.30854 0 7.54939C0 10.7903 2.19634 13.1569 5.49084 13.1569ZM5.49084 11.9509C2.66698 11.9509 1.41193 9.87076 1.41193 7.54939C1.41193 5.22804 2.66698 3.16293 5.49084 3.16293C8.31474 3.16293 9.56977 5.22804 9.56977 7.54939C9.56977 9.87076 8.31474 11.9509 5.49084 11.9509Z"
      fill="#373433"
    />
    <path
      d="M13.825 6.86585V3.34714H19.9831V2.13379H12.4219V12.9781H13.825V8.07919H18.7983V6.86585H13.825Z"
      fill="#373433"
    />
    <path
      d="M22.6463 6.86585V3.34714H28.8043V2.13379H21.2432V12.9781H22.6463V8.07919H27.6195V6.86585H22.6463Z"
      fill="#373433"
    />
    <path
      d="M30.0645 2.13379V12.9781H31.5047V2.13379H30.0645Z"
      fill="#373433"
    />
    <path
      d="M41.2376 5.7235H42.6665C42.3967 3.67349 40.6185 1.95508 37.8401 1.95508C34.7283 1.95508 32.585 4.30659 32.585 7.54743C32.585 10.8034 34.7283 13.1549 37.8401 13.1549C40.6344 13.1549 42.4125 11.4365 42.6665 9.05483H41.2376C41.1424 10.4115 40.2216 11.949 37.8401 11.949C35.1411 11.949 34.0139 9.73313 34.0139 7.54743C34.0139 5.37681 35.1411 3.16098 37.8401 3.16098C40.2057 3.16098 41.0312 4.69851 41.2376 5.7235Z"
      fill="#373433"
    />
    <path
      d="M45.5172 6.75967V3.34714H52.0202V2.13379H44.1069V12.9781H52.2082V11.7647H45.5172V7.973H51.268V6.75967H45.5172Z"
      fill="#373433"
    />
    <path
      d="M59.257 6.25809C57.8467 5.88327 57.1416 5.67339 57.1416 5.01367C57.1416 4.41395 57.7996 4.15906 58.646 4.15906C59.7273 4.15906 60.4479 4.63884 60.5731 5.44847H63.393C63.3149 3.27447 61.2621 1.95508 58.646 1.95508C56.2332 1.95508 54.3216 2.86966 54.3216 5.20858C54.3216 7.18767 56.0138 7.9823 57.9409 8.52206C59.5234 8.97186 60.7298 9.16679 60.7298 10.0214C60.7298 10.5611 60.166 10.951 59.1157 10.951C57.8628 10.951 56.8286 10.5311 56.8286 9.39166H54.0083C54.0083 11.7756 56.2016 13.1549 59.1157 13.1549C61.9202 13.1549 63.5498 11.8206 63.5498 9.76648C63.5498 7.47252 61.3718 6.81285 59.257 6.25809Z"
      fill="#373433"
    />
    <path
      d="M64.8101 12.9781H67.6708V9.04987H70.3252C72.6937 9.04987 74.1715 7.86688 74.1715 5.59185C74.1715 3.3168 72.6937 2.13379 70.3252 2.13379H64.8101V12.9781ZM67.6708 4.36332H69.7212C70.7068 4.36332 71.3108 4.68182 71.3108 5.59185C71.3108 6.50186 70.7068 6.82035 69.7212 6.82035H67.6708V4.36332Z"
      fill="#373433"
    />
    <path
      d="M80.6922 10.7182L81.4174 12.9781H84.2532L80.188 2.13379H77.3522L73.2715 12.9781H76.1073L76.8325 10.7182H80.6922ZM77.4781 8.64037L78.7543 4.63632H78.786L80.0622 8.64037H77.4781Z"
      fill="#373433"
    />
    <path
      d="M91.6797 6.18312H94.5144C94.2466 3.69428 92.609 1.95508 89.5377 1.95508C85.9787 1.95508 84.0728 4.32399 84.0728 7.5475C84.0728 10.786 85.9787 13.1549 89.5377 13.1549C92.6246 13.1549 94.2627 11.4157 94.5144 8.89688H91.6797C91.491 10.0963 90.9236 10.951 89.4119 10.951C87.5377 10.951 86.9074 9.5716 86.9074 7.5475C86.9074 5.53844 87.5377 4.15906 89.4119 4.15906C90.908 4.15906 91.4748 5.01368 91.6797 6.18312Z"
      fill="#373433"
    />
    <path
      d="M98.6137 6.2895V4.36332H104.323V2.13379H95.7754V12.9781H104.417V10.7486H98.6137V8.51902H103.534V6.2895H98.6137Z"
      fill="#373433"
    />
    <mask
      height="4"
      id="mask0_457:24881"
      maskUnits="userSpaceOnUse"
      width="4"
      x="105"
      y="10"
    >
      <path
        d="M105.909 12.0073C105.909 12.7095 106.49 13.2832 107.202 13.2832C107.914 13.2832 108.495 12.7095 108.495 12.0073C108.495 11.3044 107.914 10.73 107.202 10.73C106.49 10.73 105.909 11.3044 105.909 12.0073ZM106.184 12.0073C106.184 11.4372 106.624 10.9805 107.202 10.9805C107.773 10.9805 108.221 11.4372 108.221 12.0073C108.221 12.5776 107.773 13.0343 107.202 13.0343C106.624 13.0343 106.184 12.5776 106.184 12.0073ZM106.501 12.0073C106.501 12.4412 106.793 12.7764 107.231 12.7764C107.544 12.7764 107.802 12.5776 107.861 12.2327H107.565C107.542 12.4279 107.403 12.5253 107.216 12.5253C106.942 12.5253 106.776 12.3035 106.776 12.0073C106.776 11.7045 106.924 11.4882 107.202 11.4882C107.394 11.4882 107.53 11.5938 107.565 11.7673H107.84C107.791 11.4253 107.534 11.2375 107.231 11.2375C106.776 11.2375 106.501 11.5657 106.501 12.0073Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_457:24881)">
      <path
        clipRule="evenodd"
        d="M105.009 13.8158H109.395V9.48486H105.009V13.8158Z"
        fill="#373433"
        fillRule="evenodd"
      />
    </g>
    <path
      clipRule="evenodd"
      d="M115.095 9.43898L115.064 13.7134L118.839 11.6216L118.867 7.34619L115.095 9.43898Z"
      fill="#268EB6"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M119.39 7.10043L119.363 11.266L122.973 9.22746L123 5.06201L119.39 7.10043Z"
      fill="#268EB6"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M119.39 11.8347L119.363 15.9999L122.973 13.9611L123 9.7959L119.39 11.8347Z"
      fill="#268EB6"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M111.427 2.10646L115.043 4.24484L118.702 2.13838L115.085 0L111.427 2.10646Z"
      fill="#7DBFD9"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M115.363 4.55568L119.061 6.69406L122.803 4.58759L119.104 2.44922L115.363 4.55568Z"
      fill="#7DBFD9"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M111.146 6.9231L114.845 9.06173L118.586 6.95501L114.888 4.81689L111.146 6.9231Z"
      fill="#7DBFD9"
      fillRule="evenodd"
    />
  </svg>
)

export default OssFooterLogo
