import React from 'react'
import { useParams, generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import NavMenuItem from 'common/components/navmenu/NavMenuItem'
import WhoIsInIcon from 'common/icons/PersonClockIcon'
import { makeFloorPath } from 'common/lib/paths'

import { whoIsInPath, whoIsInSiteRosterPath } from 'visual_directory/lib/paths'
import useAppDispatch from 'visual_directory/hooks/useAppDispatch'
import { hideFlowSelector as closeHomeDrawer } from 'visual_directory/actions/flowSelector'
import useInMobileMode from 'visual_directory/components/shared/useInMobileMode'
import { useSiteByFloorId } from 'visual_directory/hooks/floorListStore'
import { useWhoIsIn } from 'visual_directory/components/who_is_in'

type Props = {
  onClick?: () => void
  isGuest?: boolean
  showImprovedNavigation?: boolean
}

type Params = {
  floorId?: string
}
const WhoIsIn = ({
  onClick,
  isGuest,
  showImprovedNavigation,
}: Props): JSX.Element | null => {
  const { t } = useTranslation('visual_directory', {
    keyPrefix: 'visual_directory.home_menu',
  })

  const { floorId } = useParams<Params>()
  const site = useSiteByFloorId(floorId || '')
  const inMobileMode = useInMobileMode()
  const { open: openWhoIsIn } = useWhoIsIn()
  const dispatch = useAppDispatch()
  const shouldChangeStyle = !showImprovedNavigation || isGuest

  if (!floorId && !inMobileMode) {
    return null
  }

  const getPath = (): string => {
    if (inMobileMode && site) {
      return generatePath(whoIsInSiteRosterPath, { siteId: site.id })
    }
    if (inMobileMode) {
      return whoIsInPath
    }

    return makeFloorPath(floorId)
  }

  const handleClick = () => {
    if (onClick) {
      onClick()
    }

    if (!inMobileMode) {
      dispatch(closeHomeDrawer())
      openWhoIsIn()
    }
  }

  return (
    <NavMenuItem
      component={Link}
      isBold={shouldChangeStyle}
      LeftIcon={shouldChangeStyle && WhoIsInIcon}
      onClick={handleClick}
      to={getPath()}
    >
      {t('who_is_in')}
    </NavMenuItem>
  )
}

export default WhoIsIn
