import React from 'react'
import GroupHeader from '../GroupHeader'
import Skeleton from './Skeleton'
import useStyles from './useStyles'

const GROUPS = ['A', 'B', 'C', 'D', 'E']
const GROUP_ITEM_COUNT = 3

const LoadingState = (): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.root} data-testid="loading-state">
      {GROUPS.map((group) => (
        <div key={group}>
          <GroupHeader>{group}</GroupHeader>
          <Skeleton itemCount={GROUP_ITEM_COUNT} />
        </div>
      ))}
    </div>
  )
}

export default LoadingState
