import React from 'react'

import Item from './Item'

type Props = {
  itemCount?: number
}

const Skeleton = ({ itemCount }: Props): JSX.Element => (
  <>
    {[...Array(itemCount)].map((_, i) => (
      <Item key={i} />
    ))}
  </>
)

export default Skeleton
