// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { createSelector } from 'reselect'
import type { OutputSelector } from 'reselect'
import type { WindowDimensions } from 'common/types/window'
import { medium } from '../../constants/viewport'

type State = {
  innerWidth: number
}

const widthState = (state: State) => state.innerWidth

const mediumOrGreater: OutputSelector<any, any, any> = createSelector<
  State,
  void,
  boolean,
  number
  /* @ts-expect-error auto-src: non-strict-conversion */
>(widthState, (width) => width >= medium.min)

export default ({ innerWidth }: WindowDimensions) =>
  mediumOrGreater({
    innerWidth,
  })
