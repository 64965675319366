// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import merge from 'lodash/merge'
import Definitions from '../../constants/definitions'
import StyleConstants from './definitions'
import { primaryFlowNavbar } from './navbar'

export default (options: any) =>
  merge({}, primaryFlowNavbar(options), {
    navbarBackground: {
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.24)',
      position: 'relative',
      zIndex: Definitions.zIndex.navbar,
    },
    title: {
      fontSize: `${StyleConstants.navbar.dynamicFontSize}px`,
    },
  })
