/* eslint-disable max-len */
// Borrowed from https://github.com/quangbuule/redux-example/blob/632668299bb8836c03939a14ee39c10bd6b5324d/src/js/lib/createReducer.js

/* eslint-enable max-len */
import type { Reducer } from 'redux' // FIXME: Convert A to an exact object

export default function createReducer<
  S,
  A extends {
    type: string
  },
>(
  initialState: S,
  handlers: Record<string, (state: S, action: A) => S>,
): Reducer<S, A> {
  return (state = initialState, action) => {
    if (!action) {
      return state
    }

    const handler = handlers[action.type]

    if (!handler) {
      return state
    }

    const newState = handler(state, action)

    return newState
  }
}
