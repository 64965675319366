import React from 'react'

import formatTime from './formatTime'

type Props = {
  isToday: boolean
  nextIn?: string
}

const UnavailableAssigned = ({ isToday, nextIn }: Props): JSX.Element => (
  <>
    <em>Not currently at location</em>
    {nextIn && <div>Next in: {formatTime(nextIn, isToday)}</div>}
  </>
)

export default UnavailableAssigned
