import browsePanel from './browsePanel'
import directoryCurtain from './directory/curtain'
import dockedCard from './dockedCard'
import floorMapNavbar from './floorMapNavbar'
import floorPicker from './floorPicker'
import moveForm from './moveForm'
import instructionSheet from './instructionSheet'
import navbarCommon, {
  compactNavbar,
  extendedNavbar,
  kioskNavbarPortrait,
  primaryFlowNavbar,
  resourceSheetNavbar,
  summarySheetNavbar,
} from './navbar'
import spinner from './spinner'
import typeahead from './typeahead'
import unbrandedSearchBar, {
  extendedSearchBarOpen,
  extendedSearchBarClosed,
  brandedCompactFloorPicker,
  brandedCompactSitePicker,
  brandedCompactSearchBar,
} from './searchBar'
import { attachmentsStyles as requestFormAttachmentsStyles } from './requestForm'
import { brandedResourceSheet } from './infoCards'
import employeeAsField from './employeeAsField'

const mergedStyles = {
  brandedCompactFloorPicker,
  brandedCompactSitePicker,
  brandedResourceSheet,
  brandedCompactSearchBar,
  browsePanel,
  compactNavbar,
  deskBookingFloorListHelpCard: instructionSheet,
  deskBookingSelectHotDeskHelpCard: instructionSheet,
  directoryCurtain,
  dockedCard,
  employeeAsField,
  extendedNavbar,
  extendedSearchBarClosed,
  extendedSearchBarOpen,
  floorMapNavbar,
  floorPicker,
  moveForm,
  navbarCommon,
  kioskNavbarPortrait,
  primaryFlowNavbar,
  requestFormAttachmentsStyles,
  resourceSheetNavbar,
  spinner,
  summarySheetNavbar,
  typeahead,
  unbrandedSearchBar,
}

export default mergedStyles
