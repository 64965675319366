import React from 'react'
import getStyles from 'visual_directory/selectors/styles'
import Navbar from 'visual_directory/components/navbars/BaseNavbar'
import useAppSelector from 'visual_directory/hooks/useAppSelector'

type Props = {
  title: string
}

const PlainNavbar = ({ title }: Props): JSX.Element => {
  const navbarStyles = useAppSelector(
    (state) => getStyles(state).floorMapNavbar,
  )

  return <Navbar styles={navbarStyles} title={title} />
}

export default PlainNavbar
