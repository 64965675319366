import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  tooltip: {
    /* @ts-expect-error auto-src: non-strict-conversion */
    backgroundColor: theme.palette.common.white,
    /* @ts-expect-error auto-src: non-strict-conversion */
    color: theme.palette.common.black,
    /* @ts-expect-error auto-src: non-strict-conversion */
    boxShadow: theme.shadows[3],
    /* @ts-expect-error auto-src: non-strict-conversion */
    padding: theme.spacing(1),
    borderRadius: 2,
  },
}))
