/* eslint-disable @typescript-eslint/no-explicit-any */
import type { $Call } from 'utility-types'
import moment from 'moment'
import type { RecordOf } from 'immutable'
import { Map, Record } from 'immutable'
import type { AssignmentMethod } from 'common/types/seat'
import type { SeatAvailability } from 'common/types/seatAvailability'
import type { BookingActionInstance } from './bookingAction'
import { bookingActionRecordFromJson } from './bookingAction'
import type { BookingRequestInstance } from './bookingRequest'
import { bookingRequestRecordFromJson } from './bookingRequest'
import { employeeRecordFromEmployee } from './employee'
import { makeResourcePath } from '../lib/paths'

export type JSONSeat = {
  assignmentMethod?: AssignmentMethod | null
  floor?: JSONFloor
  floorId: number
  floorLabel: string
  id: number
  label: string
  site?: BookingJSONSite
  siteName: string
}

type JSONFloor = {
  id: number
  label: string
}

type BookingJSONSite = {
  id: number
  name: string
}

type Action = {
  id: string
  createdAt: string
  message: string
  name: string
  performedBy: string
}
export type BookingJSON = {
  id: string
  actions: ReadonlyArray<BookingActionInstance | Action>
  byRequest?: boolean
  canceledAt: string
  canceledBy: string
  checkInTime: string
  checkOutBy: string
  checkOutScheduled: string
  checkOutTime: string
  confirmedAt: string
  createdAt: string
  createdBy: string
  editedAt?: string
  editedBy?: string
  employee: any
  employeeFirstName: string
  employeeId: number
  employeeLastName: string
  endedAt?: string
  endedBy?: string
  isActive?: boolean
  isCanceled?: boolean
  isConfirmable: boolean
  isUpcoming?: boolean
  key: string
  localCheckInTime: string
  localCheckOutScheduled: string
  localCheckOutTime: string
  localTimeZone: string
  request: boolean | BookingRequestInstance
  seat: JSONSeat
  seatAvailability: SeatAvailability
  seriesId?: string
  seriesSize?: number
  status: string
  updatedAt: string
  updatedBy: string
}
type ExtractReturnType = <R>(arg0: () => R) => R
const bookingShape = {
  id: null as number | null | undefined,
  key: null as BookingJSON['key'] | null | undefined,
  actions: null as BookingJSON['actions'] | null | undefined,
  byRequest: null as BookingJSON['byRequest'] | null | undefined,
  /* @ts-expect-error auto-src: non-strict-conversion */
  canceledAt: null as BookingJSON['canceledAt'] | moment | null,
  canceledBy: null as BookingJSON['canceledBy'] | null | undefined,
  /* @ts-expect-error auto-src: non-strict-conversion */
  checkIn: null as moment | null | undefined,
  /* @ts-expect-error auto-src: non-strict-conversion */
  checkOut: null as moment | null | undefined,
  /* @ts-expect-error auto-src: non-strict-conversion */
  confirmedAt: null as moment | null | undefined,
  /* @ts-expect-error auto-src: non-strict-conversion */
  createdAt: null as moment | null | undefined,
  createdBy: null as BookingJSON['createdBy'] | null | undefined,
  /* @ts-expect-error auto-src: non-strict-conversion */
  editedAt: null as BookingJSON['editedAt'] | moment | null,
  editedBy: null as BookingJSON['editedBy'] | null | undefined,
  employee: null /* @ts-expect-error auto-src: non-strict-conversion */ as
    | $Call<ExtractReturnType, typeof employeeRecordFromEmployee>
    | null
    | undefined,
  employeeFirstName: null as
    | BookingJSON['employeeFirstName']
    | null
    | undefined,
  employeeId: null as BookingJSON['employeeId'] | null | undefined,
  employeeLastName: null as BookingJSON['employeeLastName'] | null | undefined,
  /* @ts-expect-error auto-src: non-strict-conversion */
  endedAt: null as BookingJSON['endedAt'] | moment | null,
  endedBy: null as BookingJSON['endedBy'] | null | undefined,
  floorId: null as number | null | undefined,
  floorLabel: null as JSONSeat['floorLabel'] | null | undefined,
  isActive: null as BookingJSON['isCanceled'] | null | undefined,
  isCanceled: null as BookingJSON['isCanceled'] | null | undefined,
  isUpcoming: null as BookingJSON['isUpcoming'] | null | undefined,
  isConfirmable: null as BookingJSON['isConfirmable'] | null | undefined,
  /* @ts-expect-error auto-src: non-strict-conversion */
  localCheckIn: null as moment | null | undefined,
  /* @ts-expect-error auto-src: non-strict-conversion */
  localCheckOut: null as moment | null | undefined,
  /* @ts-expect-error auto-src: non-strict-conversion */
  localEndedAt: null as moment | null | undefined,
  localTimeZone: null as string | null | undefined,
  request: null /* @ts-expect-error auto-src: non-strict-conversion */ as
    | $Call<ExtractReturnType, typeof bookingRequestRecordFromJson>
    | null
    | undefined,
  seat: null as BookingJSON['seat'] | null | undefined,
  seatAvailability: null as BookingJSON['seatAvailability'] | null | undefined,
  seatId: null as number | null | undefined,
  seatLabel: null as JSONSeat['label'] | null | undefined,
  seatPath: null /* @ts-expect-error auto-src: non-strict-conversion */ as
    | $Call<ExtractReturnType, typeof makeResourcePath>
    | null
    | undefined,
  seriesId: null as BookingJSON['seriesId'] | null | undefined,
  seriesSize: null as BookingJSON['seriesSize'] | null | undefined,
  siteLabel: null as JSONSeat['siteName'] | null | undefined,
  status: null as BookingJSON['status'] | null | undefined,
}

export type BookingRecordType = RecordOf<typeof bookingShape>
const BookingRecord = Record<any>(bookingShape)

export const BookingFactory = Record<typeof bookingShape>(bookingShape)

const bookingInstance = BookingFactory()

const floorLabel = (seat: JSONSeat) =>
  seat.floorLabel || (seat.floor && seat.floor.label)

/* @ts-expect-error auto-src: strict-conversion */
const siteLabel = (seat) => seat.siteName || (seat.site && seat.site.name)

// FIXME: This type is actually JSON | common/types/seatOpenBooking
type SeatOpenBooking = any
export const bookingRecordFromJson = (
  json: SeatOpenBooking,
  seat: JSONSeat = json.seat,
): BookingRecordType =>
  BookingFactory({
    id: parseInt(json.id, 10),
    actions:
      Array.isArray(json.actions) &&
      /* @ts-expect-error auto-src: strict-conversion */
      json.actions.map((action) => bookingActionRecordFromJson(action)),
    byRequest: Boolean(json.request),
    canceledAt: json.canceledAt && moment(json.canceledAt),
    canceledBy: json.canceledBy,
    checkIn: moment(json.checkInTime),
    checkOut: moment(json.checkOutScheduled),
    confirmedAt: json.confirmedAt && moment(json.confirmedAt),
    createdAt: moment(json.createdAt),
    createdBy: json.createdBy,
    editedAt: json.updatedAt && moment(json.updatedAt),
    editedBy: json.updatedBy,
    employee: employeeRecordFromEmployee(json.employee),
    employeeFirstName: json.employeeFirstName,
    employeeId: json.employeeId,
    employeeLastName: json.employeeLastName,
    endedAt: json.checkOutTime && moment(json.checkOutTime),
    endedBy: json.checkOutBy,
    /* @ts-expect-error auto-src: non-strict-conversion */
    floorId: parseInt(seat.floorId, 10),
    floorLabel: floorLabel(seat),
    isActive: json.isActive,
    isCanceled: json.isCanceled,
    isConfirmable: json.isConfirmable,
    isUpcoming: json.isUpcoming,
    key: json.key,
    localCheckIn: moment(json.localCheckInTime),
    localCheckOut: moment(json.localCheckOutScheduled),
    localEndedAt: moment(json.localCheckOutScheduled),
    localTimeZone: json.localTimeZone,
    request: bookingRequestRecordFromJson(json.request),
    seat: json.seat,
    seatAvailability: json.seatAvailability,
    /* @ts-expect-error auto-src: non-strict-conversion */
    seatId: parseInt(seat.id, 10),
    seatLabel: seat.label,
    seatPath: makeResourcePath(Map({ ...seat, type: 'seat' })),
    seriesId: json.seriesId,
    seriesSize: json.seriesSize,
    siteLabel: siteLabel(seat),
    status: json.status,
  })
export default BookingRecord
export type Booking = typeof bookingInstance
