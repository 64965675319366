import React from 'react'
import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import useStyles from './useStyles'

const Item = (): JSX.Element => {
  const classes = useStyles()

  return (
    <ListItem className={classes.root} disableGutters>
      <ListItemAvatar className={classes.avatarWrapper}>
        <Skeleton className={classes.avatar} variant="circle" />
      </ListItemAvatar>
      <ListItemText
        className={classes.text}
        disableTypography
        primary={<Skeleton className={classes.primaryText} variant="rect" />}
        secondary={
          <Skeleton className={classes.secondaryText} variant="rect" />
        }
      />
    </ListItem>
  )
}

export default Item
