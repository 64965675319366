// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import Definitions from './definitions'
import unbrandedSearchBar from './searchBar'

const tabLabelIcon = {
  height: 20,
  paddingRight: 6,
  width: 20,
}

export const alphabetSelectorWidth = 44
export default ({ branding }: any) => ({
  muiTabs: {
    tabItemContainer: {
      backgroundColor: branding.browsePanel.tabBackgroundColor,
      flex: 'none',
      height: 44,
    },
    indicator: {
      backgroundColor: branding.browsePanel.underlineColor,
    },
  },
  tab: {
    labelInnerContainer: {
      alignItems: 'center',
      display: 'flex',
      marginLeft: -8,
      marginTop: -2,
    },
    labelSelected: {
      color: branding.browsePanel.selectedTabTextColor,
    },
    labelUnselected: {
      color: branding.browsePanel.unselectedTabTextColor,
    },
    labelIconSelected: {
      ...tabLabelIcon,
      color: branding.browsePanel.selectedTabTextColor,
    },
    labelIconUnselected: {
      ...tabLabelIcon,
      color: branding.browsePanel.unselectedTabTextColor,
    },
  },
  filterInput: {
    ...unbrandedSearchBar(),
    siteSearchWrapper: {
      flex: 'none',
      backgroundColor: branding.browsePanel.tabBackgroundColor,
      padding: '6px 10px',
    },
  },
  filterResultsCount: {
    backgroundColor: branding.browsePanel.tabBackgroundColor,
    boxSizing: 'border-box',
    color: branding.browsePanel.selectedTabTextColor,
    flex: 'none',
    fontSize: Definitions.font.size.standard,
    height: 50,
    lineHeight: '18px',
    paddingBottom: 16,
    paddingLeft: 20,
    paddingTop: 16,
  },
  alphabetSelector: {
    container: {
      backgroundColor: '#FFFFFF',
      bottom: 90,
      overflow: 'auto',
      position: 'absolute',
      right: 0,
      textAlign: 'center',
      top: 0,
      width: alphabetSelectorWidth,
    },
    list: {
      color: branding.browsePanel.alphabetSelectorLetterColor,
      display: 'flex',
      flex: 'none',
      flexDirection: 'column',
      fontSize: Definitions.font.size.standard,
      fontWeight: Definitions.font.weight.bold,
      height: '100%',
      lineHeight: '11px',
      margin: 0,
      maxHeight: '962px',
      minHeight: '624px',
      padding: 0,
      userSelect: 'none',
    },
    letter: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flex: '1 1 auto',
    },
  },
})
