// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { createStructuredSelector } from 'reselect'
import landscapeHandset from './landscapeHandset'
import landscapeTablet from './landscapeTablet'
import large from './large'
import largeOrGreater from './largeOrGreater'
import largeOrLess from './largeOrLess'
import medium from './medium'
import mediumOrGreater from './mediumOrGreater'
import mediumOrLess from './mediumOrLess'
import portraitHandset from './portraitHandset'
import portraitHandsetOrTablet from './portraitHandsetOrTablet'
import portraitTablet from './portraitTablet'
import small from './small'
import smallOrGreater from './smallOrGreater'
import smallOrLess from './smallOrLess'
import xsmall from './xsmall'
import xlarge from './xlarge'

/* @ts-expect-error auto-src: non-strict-conversion */
export const selector = createStructuredSelector<any, any, _>({
  landscapeHandset,
  landscapeTablet,
  large,
  largeOrGreater,
  largeOrLess,
  medium,
  mediumOrGreater,
  mediumOrLess,
  portraitHandset,
  portraitHandsetOrTablet,
  portraitTablet,
  small,
  smallOrGreater,
  smallOrLess,
  xsmall,
  xlarge,
})
export default ({ innerWidth, innerHeight }: any) =>
  /* @ts-expect-error auto-src: non-strict-conversion */
  selector({
    innerWidth,
    innerHeight,
  })
