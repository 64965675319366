import { makeStyles } from '@material-ui/styles'
import type { Theme } from '@material-ui/core/styles'

const ITEM_HEIGHT = 68

type Props = {
  selected: boolean
}

export default makeStyles<Theme, Props>(({ palette }) => ({
  root: {
    height: ITEM_HEIGHT,
    padding: '0 12px 0 16px',
    background: ({ selected }: Props) =>
      selected ? palette.grey[200] : palette.common.white,
    cursor: 'pointer',
  },
  avatarWrapper: {
    position: 'relative',
    minWidth: 'auto',
    marginRight: 16,
  },
  avatar: {
    width: 44,
    height: 44,
    border: `1px solid ${palette.grey[300]}`,
  },
  badge: {
    position: 'absolute',
    right: -4,
    bottom: 0,
  },
  text: {
    margin: 0,
  },
  primaryText: {
    fontSize: 13,
    lineHeight: '20px',
    color: palette.grey[900],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  secondaryText: {
    fontSize: 12,
    lineHeight: '16px',
    color: palette.grey[600],
    whiteSpace: 'nowrap',
  },
}))
