import React, { useCallback, useEffect, useState } from 'react'
import { Route } from 'react-router'
import { useRouteMatch } from 'react-router-dom'

import useAppDispatch from 'visual_directory/hooks/useAppDispatch'
import { resetResource } from 'visual_directory/actions/resource'

import Context from './Context'
import Drawer from './Drawer'

type Props = {
  children: React.ReactNode
}

const ROUTE_PATH = {
  exact: true,
  path: [
    '/',
    '/floors/:floorId(\\d+)',
    '/floors/:floorId(\\d+)/:resourceType/:resourceId(\\d+)',
  ],
} as const

const ContextProvider = ({ children }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const open = useCallback(() => setIsOpen(true), [setIsOpen])

  const close = useCallback(() => {
    setIsOpen(false)
    dispatch(resetResource())
  }, [setIsOpen, dispatch])

  const whoIsInRouteMatch = useRouteMatch(ROUTE_PATH)

  useEffect(() => {
    if (!whoIsInRouteMatch) {
      setIsOpen(false)
    }
  }, [whoIsInRouteMatch, setIsOpen])

  return (
    <Context.Provider value={{ isOpen, open, close }}>
      {children}
      <Route key="VisualDirectoryFloorPlan" {...ROUTE_PATH}>
        <Drawer isOpen={isOpen} onClose={close} />
      </Route>
    </Context.Provider>
  )
}

export default ContextProvider
