// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ActionTypes from '../constants/actionTypes'

export const showSnackbar = (message: string) => ({
  type: ActionTypes.Snackbar.SHOW,
  message,
})
export const closeSnackbar = () => ({
  type: ActionTypes.Snackbar.CLOSE,
})
