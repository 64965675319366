import useSiteData from './useSiteData'
import useFloorData from './useFloorData'
import type { Result, Location } from './types'

type Args = {
  location: Location
}

const emptyResult = {
  data: undefined,
  loading: false,
}

const useData = ({ location: { type, id } }: Args): Result => {
  const siteResult = useSiteData({
    siteId: id,
    skip: type !== 'site' || !id,
  })

  const floorResult = useFloorData({
    floorId: id,
    skip: type !== 'floor' || !id,
  })

  if (type === 'site') {
    return siteResult
  }

  if (type === 'floor') {
    return floorResult
  }

  return emptyResult
}

export default useData
