import { makeStyles } from '@material-ui/styles'
import { width as dockedCardWidth } from 'common/constants/dockedCard'

export default makeStyles({
  paper: {
    top: 0,
    width: dockedCardWidth,
    bottom: 0,
    backgroundColor: '#fff',
    zIndex: 1299,
  },
})
