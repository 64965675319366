// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import merge from 'lodash/merge'
import { navbarIcon } from 'common/components/styles/navmenu'

import navbarHeight from 'visual_directory/constants/navbarHeight'

import StyleConstants from './definitions'
import Definitions from '../../constants/definitions'
import SharedStyles from './shared'

const logoIconHeight = `${StyleConstants.navbar.logoIcon.height}px`

/* @ts-expect-error auto-src: strict-conversion */
const brandedStyles = ({ branding }) => ({
  navbarBackground: {
    flex: '0 0 auto',
    background:
      `linear-gradient(to bottom, ${branding.navbar.backgroundColorTop} 0%,` +
      ` ${branding.navbar.backgroundColorBottom} 100%)`,
    width: '100%',
  },
  title: {
    color: branding.navbar.textColor,
  },
  titleBar: {
    boxSizing: 'border-box',
  },
})

export const compactNavbar = ({
  viewportCategories: { mediumOrGreater },
}: any) => {
  const height = mediumOrGreater ? navbarHeight.desktop : navbarHeight.mobile

  return {
    titleBar: {
      display: 'flex',
      height,
      alignItems: 'center',
    },
    secondaryBar: {
      height,
    },
    title: {
      flex: '1 1 0%',
      letterSpacing: '0px',
      margin: '0px',
      overflow: 'hidden',
      paddingTop: '0px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  }
}

export const primaryFlowNavbar = (options: any) =>
  merge({}, brandedStyles(options), compactNavbar(options), {
    navbarBackground: SharedStyles.dropShadow.light,
  })

const detailsNavbarStyles = {
  navbarBackground: {
    background: StyleConstants.navbar.details.background,
    color: StyleConstants.navbar.details.fontColor,
  },
  icon: {
    // applies to the button tag
    style: {},
    // applies to svg within the button tag
    iconStyle: {
      color: StyleConstants.navbar.details.iconColor,
    },
  },
}

export const summarySheetNavbar = (options: any) =>
  merge({}, compactNavbar(options), {
    icon: {
      style: navbarIcon.style,
    },
    navbarBackground: detailsNavbarStyles.navbarBackground,
    title: {
      color: StyleConstants.navbar.details.fontColor,
      flex: '1',
    },
  })

// typically imported as "navbarCommon", but it should be called brandedNavbar */
export default ({
  branding,
  viewportCategories: { portraitHandsetOrTablet },
}: any) => ({
  standardNav: {
    icon: {
      style: {
        paddingTop: 0,
        paddingBottom: 0,
        height: navbarHeight.desktop,
        width: navbarHeight.desktop,
      },
      // applies to button tag
      iconStyle: {
        color: branding.navbar.iconColor,
      },
    },
  },
  logo: {
    // Used in the home navbar
    compact: {
      boundingBox: {
        left: parseInt(branding.logo.xCompact, 10) || 0,
        maxHeight: StyleConstants.navbar.height,
        maxWidth: StyleConstants.navbar.height,
        position: 'relative',
        top: parseInt(branding.logo.yCompact, 10) || 0,
      },
    },
    responsive: {
      boundingBox: {
        left:
          parseInt(
            branding.logo[portraitHandsetOrTablet ? 'xCompact' : 'xExtended'],
            10,
          ) || 0,
        maxHeight: StyleConstants.navbar.height,
        maxWidth: StyleConstants.navbar.height,
        position: 'relative',
        top:
          parseInt(
            branding.logo[portraitHandsetOrTablet ? 'yCompact' : 'yExtended'],
            10,
          ) || 0,
      },
    },
  },
  logoIcon: {
    boundingBox: {
      left: parseInt(branding.logoIcon.x, 10) || 0,
      maxHeight: logoIconHeight,
      maxWidth: logoIconHeight,
      position: 'relative',
      top: parseInt(branding.logoIcon.y, 10) || 0,
    },
  },
  // the grey navbar used on Info, Details and the Mini Info Card
  detailsNav: detailsNavbarStyles,
  // Used for the Request and Desk Booking flows.
  // Merge it with compactNavbarStyles in your component.
  secondaryFlowNav: {
    navbarBackground: {
      background: StyleConstants.colorPalette.secondaryFlow,
      ...SharedStyles.dropShadow.light,
    },
    title: {
      color: StyleConstants.navbar.standard.fontColor,
    },
    titleBar: {
      display: 'flex',
    },
    icon: {
      // this applies to the button tag
      style: {
        height: StyleConstants.navbar.height,
        padding: 0,
        width: 'auto',
      },
      // this applies to the svg tag
      iconStyle: {
        color: StyleConstants.navbar.standard.iconColor,
        height: StyleConstants.icon.iconSize,
        width: StyleConstants.icon.iconSize,
      },
    },
  },
  // Used in secondary flow floor list navbars to override styles of secondaryFlowNav
  // (Create a request, book a desk)
  secondaryFlowRenderedFloorNav: {
    navbarBackground: {
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.24)',
      position: 'relative',
      zIndex: Definitions.zIndex.navbar,
    },
  },
  // My bookings
  bookingsNav: {
    navbarBackground: {
      background: StyleConstants.color.bookingsTheme,
    },
    title: {
      color: StyleConstants.navbar.standard.fontColor,
    },
  },
  // styles for the CompactInfoCardTopBar
  miniCardAppBar: {
    navbarBackground: {
      position: 'static',
      boxShadow: 'none',
    },
  },
  // material-ui component used in most navbars
  appBar: {
    title: {
      color: StyleConstants.navbar.standard.fontColor,
    },
    iconStyle: {
      marginTop: 0,
    },
    iconStyleRight: {
      marginRight: '-15px',
    },
  },
})

export const resourceSheetNavbarPaddingRight = '15px'

export const resourceSheetNavbar = (options: any) =>
  merge({}, compactNavbar(options), {
    navbarBackground: {
      background: StyleConstants.navbar.details.background,
    },
    titleBar: {
      paddingRight: resourceSheetNavbarPaddingRight,
    },
    title: {
      color: StyleConstants.navbar.details.fontColor,
      lineHeight: '40px',
      margin: '5px 0px 0px',
    },
    // this overrides styles passed into the div surrounding iconElementRight in BaseNavbar.
    // Would rather not have anything but need to override default
    icon: {
      // this applies to the button tag
      style: {
        height: 36,
        width: 36,
        marginRight: -6,
      },
      // this applies to the svg tag
      iconStyle: {
        height: StyleConstants.icon.iconSize,
        width: StyleConstants.icon.iconSize,
        color: StyleConstants.icon.iconColor,
      },
    },
  })

export const extendedNavbar = (options: any) =>
  merge({}, brandedStyles(options), {
    navbarBackground: {
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.24)',
      minHeight: navbarHeight.desktop,
      // override Material UI default height for Toolbar
      position: 'relative',
      zIndex: Definitions.zIndex.navbar,
    },
    toolbarGroup: {
      height: navbarHeight.desktop, // specify height to fix bug in safari
    },
  })

export const kioskNavbarPortrait = (options: any) =>
  merge(extendedNavbar(options), {
    navbarBackground: {
      minHeight: navbarHeight.kioskPortrait,
    },
  })
