import React from 'react'

import { NextBooking } from '../../Layout'
import { labelText, bookingInfo } from './lib'

type Props = {
  concealEmployeeInfo: boolean
  employeeFullName: string
  employeePhoto: string | null
  isByRequestDesk: boolean
  isPending: boolean
  localCheckInTime: string
  localCheckOutScheduled: string
}

const UpcomingBooking = ({
  concealEmployeeInfo,
  employeeFullName,
  employeePhoto,
  isByRequestDesk,
  isPending,
  localCheckInTime,
  localCheckOutScheduled,
}: Props): JSX.Element => {
  const label = labelText({
    isByRequestDesk,
    isPending,
    concealEmployeeInfo,
  })

  const { name, photoUrl, time } = bookingInfo({
    employeeFullName,
    employeePhoto,
    concealEmployeeInfo,
    localCheckInTime,
    localCheckOutScheduled,
  })

  return (
    <NextBooking label={label} name={name} photoUrl={photoUrl} time={time} />
  )
}

export default UpcomingBooking
