const defaultFlags: Record<string, string | boolean> = {
  'acs.access.enable': false,
  'admin_console.settings.desk_booking.enable': false,
  'admin_console.settings.room_booking.enable': false,
  'apigee.api.key.ui.enable': false,
  'connectors.acs.brivo.events.enable': false,
  'connectors.acs.extended.ui.enable': false,
  'connectors.acs.kisi.enable': false,
  'connectors.acs.openpath.enable': false,
  'connectors.new.ui': false,
  'hamburger_menu.improved_navigation.enable': false,
  'insights_hub.core_product.data_access.value': '',
  'insights_hub.portfolio_dashboard.enable': false,
  'insights_hub.room_booking_dashboard.enable': false,
  'insights_hub.space_booking.data_access.value': '',
  'insights_hub.working_trends_dashboard_v2.enable': false,
  language: 'en',
  'reports.landing_page.enable': false,
  'seating_needs.sync.enable': false,
  'settings.sites.room_booking.enable': false,
  'shifts.enable': false,
  'stack_plans.enhanced_creation.enable': false,
  'stack_plans.stacking.enable': false,
  'stack_plans.stacking.sandbox.enable': false,
  'stats_panel.bookable_desk.enable': false,
  'visual_directory.date_picker': false,
  'visual_directory.desk_status_refresh.enable': false,
  'visual_directory.enhanced_info_cards.enable': false,
  'visual_directory.html_tooltips.enable': false,
  'visual_directory.mobile_who_is_coming_in.enable': false,
  'visual_directory.search.elasticsearch.enable': false,
  'visual_directory.who_is_coming_in.enable': false,
}

export default defaultFlags
