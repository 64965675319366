// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import { Map, Seq } from 'immutable'
import { compose } from 'redux'
import constant from 'lodash/constant'
import mapValues from 'lodash/fp/mapValues'
import multiply from 'lodash/fp/multiply'
import {
  makeCalculateStageDimensions,
  makeCenterPin,
  makeDoubleClick,
  makeMountDockingLayer,
  makePan,
  makePanEnd,
  makePinch,
  makePinchEnd,
  makeReceiveSelectedFloor,
  makeUnmountDockingLayer,
  makeUpdateDockingLayer,
  makeWheel,
  makeWindowResize,
  makeZoomInButtonClick,
  makeZoomOutButtonClick,
  resetAllAnimations,
  runningAnimations,
  selectFloor as commonSelectFloor,
} from 'common/actions/floor'
import tweenViewIngredientsByDeltas from 'common/lib/tweenViewIngredientsByDeltas'
import isZoomAboveLabelsThresholdSelector from 'common/selectors/isZoomAboveLabelsThreshold'
import { map } from 'common/lib/functionalImmutableHelpers'
import { resetResource } from 'visual_directory/actions/resource'
import {
  calculateCenteringOffset,
  calculatePositionSelector,
  calculateZoomSelector,
} from 'visual_directory/selectors/floor'
import VDActionTypes from 'visual_directory/constants/actionTypes'
import CommonActionTypes from 'common/constants/actionTypes'
import AnimationConstants from 'common/constants/animations'
import isMediumOrGreater from 'common/selectors/viewport/mediumOrGreater'
/* @ts-expect-error auto-src: non-strict-conversion */
import NeighborhoodFocus from 'common/lib/getFocusDeltas/NeighborhoodFocus.graphql'
/* @ts-expect-error auto-src: non-strict-conversion */
import RoomFocus from 'common/lib/getFocusDeltas/RoomFocus.graphql'
import createTweenValuesByUpdateDifference from 'common/lib/tweenValuesByUpdateDifference'
import getFocusDeltas from 'common/lib/getFocusDeltas'
import iconFocusModes, {
  iconPadding,
} from 'visual_directory/constants/iconFocusModes'
import * as resourceSeatOrUtilityQueries from './resourceQueries'
import * as resourceFloorIdQueries from './resourceFloorIdQueries'

const tweenValuesByUpdateDifference = createTweenValuesByUpdateDifference({
  runningAnimations,
})

export const receiveSelectedFloor = makeReceiveSelectedFloor(
  calculateCenteringOffset,
  calculatePositionSelector,
  calculateZoomSelector,
)
export const doubleClick = makeDoubleClick(
  calculateCenteringOffset,
  calculatePositionSelector,
  calculateZoomSelector,
)
export const pan = makePan(
  calculateCenteringOffset,
  calculatePositionSelector,
  calculateZoomSelector,
)
export const panEnd = makePanEnd(
  calculateCenteringOffset,
  calculatePositionSelector,
  calculateZoomSelector,
)
export const pinch = makePinch(calculateZoomSelector)
export const pinchEnd = makePinchEnd(calculateZoomSelector)
export const wheel = makeWheel(
  calculateCenteringOffset,
  calculatePositionSelector,
  calculateZoomSelector,
)
export const zoomInButtonClick = makeZoomInButtonClick(
  calculateCenteringOffset,
  calculatePositionSelector,
  calculateZoomSelector,
)
export const zoomOutButtonClick = makeZoomOutButtonClick(
  calculateCenteringOffset,
  calculatePositionSelector,
  calculateZoomSelector,
)
type LocateResourceOptions = {
  id: string
  type: string
}
export const locateResource =
  ({ id, type }: LocateResourceOptions) =>
  async (dispatch: any, getState: any, { apolloClient }: any) => {
    dispatch({
      type: CommonActionTypes.Floor.LOCATE_RESOURCE_REQUESTED,
      id,
      resourceType: type,
    })
    const state = getState()
    const {
      current: currentZoom,
      max: maxZoom,
      min: minZoom,
    } = calculateZoomSelector(state)
    /* @ts-expect-error auto-src: non-strict-conversion */
    const position = calculatePositionSelector(state, {
      /* @ts-expect-error auto-src: non-strict-conversion */
      centeringOffset: calculateCenteringOffset(state, {
        zoomWhenCentered: minZoom,
      }),
    })
    const halfStageWidth = (state.floorStore.get('stageWidth') || 0) / 2
    const halfStageHeight = (state.floorStore.get('stageHeight') || 0) / 2
    const stageCenter = {
      /* @ts-expect-error auto-src: non-strict-conversion */
      x: halfStageWidth - position.x,
      /* @ts-expect-error auto-src: non-strict-conversion */
      y: halfStageHeight - position.y,
    }
    const {
      data: {
        resource: [{ floorId: resourceFloorId, ...resource }],
      },
    } = await apolloClient.query({
      // auto-src: ts-eslint eslint-disable-next-line import/namespace
      /* @ts-expect-error auto-src: strict-conversion */
      query: resourceSeatOrUtilityQueries[type],
      variables: {
        ids: id,
      },
    })
    const resourceX =
      resource.x == null ? resource.center && resource.center[0] : resource.x
    const rawResourceY =
      resource.y == null ? resource.center && resource.center[1] : resource.y
    const zoomedResourceX = resourceX * currentZoom
    const resourceY = rawResourceY + state.settings.floorYOffset
    const zoomedResourceY = resourceY * currentZoom
    const offset = {
      x: stageCenter.x - zoomedResourceX,
      y: stageCenter.y - zoomedResourceY,
    }
    const resourceFocusDeltas = getFocusDeltas({
      currentViewIngredients: Map({
        positionIngredients: state.positionIngredients.get(
          'positionIngredients',
        ),
        zoomIngredients: state.zoomIngredients.get('zoomIngredients'),
      }),
      currentZoom,
      padding: iconPadding,
      maxZoom,
      minZoom,
      /* @ts-expect-error auto-src: non-strict-conversion */
      position,
      points: [[resourceX, rawResourceY]],
      stageHeight: state.floorStore.get('stageHeight'),
      stageWidth: state.floorStore.get('stageWidth'),
      yOffset: state.settings.floorYOffset,
    })
    const isZoomAboveLabelsThreshold = isZoomAboveLabelsThresholdSelector(
      state,
      {
        currentZoom,
        maxZoom,
        minZoom,
      },
    )
    const isOnThisFloor =
      (state.selectedFloor.get('id') ?? '').toString() === resourceFloorId

    if (isOnThisFloor) {
      if (state.selectedFloor.get('isRendered')) {
        // tween to location
        if (isZoomAboveLabelsThreshold) {
          const animationKey = 'centerTo'
          const { duration, ease } = AnimationConstants.centerFloorPlan

          resetAllAnimations()
          const toValues = {
            deltaX: offset.x,
            deltaY: offset.y,
          }
          const fromValues = mapValues(constant(0))(toValues)

          tweenValuesByUpdateDifference({
            animationKey,
            dispatch,
            dispatchType:
              CommonActionTypes.Floor.private.LOCATE_RESOURCE_TWEEN_STEP,
            duration,
            ease,
            fromValues,
            toValues,
          })
        } else if (
          (isMediumOrGreater(window) &&
            state.settings.vdIconFocusModeDesktop === iconFocusModes.IN) ||
          (!isMediumOrGreater(window) &&
            state.settings.vdIconFocusModeCompact === iconFocusModes.IN)
        ) {
          resetAllAnimations()
          /* @ts-expect-error auto-src: non-strict-conversion */
          tweenViewIngredientsByDeltas({
            dispatch,
            dispatchType: CommonActionTypes.Floor.private.BOX_FOCUS_TWEEN_STEP,
            stateSlices: {
              positionIngredients: state.positionIngredients,
              zoomIngredients: state.zoomIngredients,
            },
            runningAnimations,
            deltas: {
              positionIngredients: {
                /* @ts-expect-error auto-src: non-strict-conversion */
                positionIngredients: resourceFocusDeltas.positionIngredients,
              },
              zoomIngredients: {
                /* @ts-expect-error auto-src: non-strict-conversion */
                zoomIngredients: resourceFocusDeltas.zoomIngredients,
              },
            },
          })
        } else {
          const tweenValues = {
            positionIngredients: state.positionIngredients
              .update(
                'positionIngredients',
                compose(map(compose(map(multiply(-1)), Seq.Keyed)), Seq.Keyed),
              )
              /* @ts-expect-error auto-src: strict-conversion */
              .filter((_, key) => key === 'positionIngredients')
              .toJS(),
            zoomIngredients: state.zoomIngredients
              .update('zoomIngredients', compose(map(multiply(-1)), Seq.Keyed))
              /* @ts-expect-error auto-src: strict-conversion */
              .filter((_, key) => key === 'zoomIngredients')
              .toJS(),
          }

          resetAllAnimations()
          /* @ts-expect-error auto-src: non-strict-conversion */
          tweenViewIngredientsByDeltas({
            dispatch,
            dispatchType: CommonActionTypes.Floor.private.BOX_FOCUS_TWEEN_STEP,
            stateSlices: {
              positionIngredients: state.positionIngredients,
              zoomIngredients: state.zoomIngredients,
            },
            runningAnimations,
            deltas: {
              positionIngredients: tweenValues.positionIngredients,
              zoomIngredients: tweenValues.zoomIngredients,
            },
          })
        }
      } else {
        // Jump directly to location
        dispatch({
          type: CommonActionTypes.Floor.private.LOCATE_RESOURCE_TWEEN_STEP,
          deltaX: offset.x,
          deltaY: offset.y,
        })
      }
    }
  }

const withLocateShape =
  /* @ts-expect-error auto-src: strict-conversion */


    (callback) =>
    async (dispatch: any, getState: any, { apolloClient }: any) => {
      const locateShape = ({ floorId, points }: any) => {
        const state = getState()
        const {
          current: currentZoom,
          max: maxZoom,
          min: minZoom,
        } = calculateZoomSelector(state)
        const isOnThisFloor = state.floorStore.get('id').toString() === floorId

        if (isOnThisFloor && state.selectedFloor.get('isRendered')) {
          resetAllAnimations()
          /* @ts-expect-error auto-src: non-strict-conversion */
          const position = calculatePositionSelector(state, {
            /* @ts-expect-error auto-src: non-strict-conversion */
            centeringOffset: calculateCenteringOffset(state, {
              zoomWhenCentered: minZoom,
            }),
          })
          const modePostfix = isMediumOrGreater(window) ? 'Desktop' : 'Compact'
          const padding = state.settings.get(`vdRoomFocusPadding${modePostfix}`)
          const focusDeltas = getFocusDeltas({
            currentViewIngredients: Map({
              positionIngredients: state.positionIngredients.get(
                'positionIngredients',
              ),
              zoomIngredients: state.zoomIngredients.get('zoomIngredients'),
            }),
            currentZoom,
            maxZoom,
            minZoom,
            padding,
            points,
            /* @ts-expect-error auto-src: non-strict-conversion */
            position,
            stageHeight: state.floorStore.get('stageHeight'),
            stageWidth: state.floorStore.get('stageWidth'),
            yOffset: state.settings.floorYOffset,
          })

          /* @ts-expect-error auto-src: non-strict-conversion */
          tweenViewIngredientsByDeltas({
            dispatch,
            dispatchType: CommonActionTypes.Floor.private.BOX_FOCUS_TWEEN_STEP,
            stateSlices: {
              positionIngredients: state.positionIngredients,
              zoomIngredients: state.zoomIngredients,
            },
            runningAnimations,
            deltas: {
              positionIngredients: {
                /* @ts-expect-error auto-src: non-strict-conversion */
                positionIngredients: focusDeltas.positionIngredients,
              },
              zoomIngredients: {
                /* @ts-expect-error auto-src: non-strict-conversion */
                zoomIngredients: focusDeltas.zoomIngredients,
              },
            },
          })
        }
      }

      callback(locateShape, apolloClient)
    }

export const locateNeighborhood = ({ id }: any) =>
  /* @ts-expect-error auto-src: strict-conversion */
  withLocateShape(async (locateShape, apolloClient) => {
    const {
      data: {
        neighborhoods: [neighborhood],
      },
    } = await apolloClient.query({
      query: NeighborhoodFocus,
      variables: {
        ids: id,
      },
    })

    locateShape(neighborhood)
  })
export const locateRoom = ({ id }: any) =>
  /* @ts-expect-error auto-src: strict-conversion */
  withLocateShape(async (locateShape, apolloClient) => {
    const {
      data: {
        rooms: [room],
      },
    } = await apolloClient.query({
      query: RoomFocus,
      variables: {
        ids: id,
      },
    })

    locateShape(room)
  })
export const calculateStageDimensions = makeCalculateStageDimensions(
  calculateCenteringOffset,
  calculateZoomSelector,
)
export const mountDockingLayer = makeMountDockingLayer(
  calculateCenteringOffset,
  calculateZoomSelector,
)
export const unmountDockingLayer = makeUnmountDockingLayer(
  calculateCenteringOffset,
  calculateZoomSelector,
)
export const updateDockingLayer = makeUpdateDockingLayer(
  calculateCenteringOffset,
  calculateZoomSelector,
)
export const windowResize = makeWindowResize(
  calculateCenteringOffset,
  calculateZoomSelector,
)
export const centerPin = makeCenterPin(calculateZoomSelector)
export const selectFloor =
  (id: number, floorGroupId: number | null | undefined) =>
  async (dispatch: any, getState: any, { apolloClient }: any) => {
    const { selectedFloor, selectedResource } = getState()

    if (selectedFloor.get('id') === id) {
      return
    }

    if (selectedResource.get('id') !== -1) {
      const {
        data: {
          resources: [{ floorId }],
        },
      } = await apolloClient.query({
        // auto-src: ts-eslint eslint-disable-next-line import/namespace
        /* @ts-expect-error auto-src: strict-conversion */
        query: resourceFloorIdQueries[selectedResource.get('type')],
        variables: {
          ids: selectedResource.get('id'),
        },
      })

      if (id !== parseInt(floorId, 10)) {
        dispatch(resetResource())
      }
    }

    dispatch(commonSelectFloor(id, floorGroupId))
  }
export const fadeStart = ({ currentOpacity }: { currentOpacity: number }) => ({
  type: VDActionTypes.Floor.FADE_STARTED,
  currentOpacity,
})
export const fadeEnd = ({ currentOpacity }: { currentOpacity: number }) => ({
  type: VDActionTypes.Floor.FADE_ENDED,
  currentOpacity,
})
export const fadeCancel = ({ currentOpacity }: { currentOpacity: number }) => ({
  type: VDActionTypes.Floor.FADE_CANCELED,
  currentOpacity,
})
export const outerDivMounted = ({ computedStyle }: any) => ({
  type: VDActionTypes.Floor.OUTER_DIV_MOUNTED,
  computedStyle,
})
export const outerDivStyleChanged = ({ computedStyle }: any) => ({
  type: VDActionTypes.Floor.OUTER_DIV_STYLE_CHANGED,
  computedStyle,
})
