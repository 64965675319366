// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import type { Collection } from 'immutable'
import { Map, isImmutable } from 'immutable'
import { SeatPermissionEnum } from 'generated/graphql'
import type { Room } from 'generated/graphql'
import type { AssignmentMethod } from 'common/types/seat'
import { assignmentMethods } from 'common/constants/resources'
import { isInsideOfShare as plainJSIsInsideOfShare } from './nativeResourceRecords'

export const isShare = (resource: Collection<any, any>) =>
  resource.get('type') === 'share'

export const isRoom = (resource: Collection<any, any>) =>
  resource.get('__typename') === 'Room' ||
  resource.get('type') === 'room' ||
  isShare(resource)

export const isNeighborhood = (
  resource:
    | {
        __typename: string
      }
    | Map<string, any>,
) =>
  /* @ts-expect-error auto-src: non-strict-conversion */
  resource.__typename === 'Neighborhood' ||
  /* @ts-expect-error auto-src: non-strict-conversion */
  (resource instanceof Map && resource.get('type') === 'neighborhood')

export const isSeat = (resource: Collection<unknown, unknown>) =>
  resource.get('__typename') === 'Seat' || resource.get('type') === 'seat'

export const isUtility = (resource: Map<string, any>) =>
  resource.get('__typename') === 'Utility' || resource.get('type') === 'utility'

export const isInsideOfShare = (
  resource:
    | Map<string, any>
    | {
        room?: { construct?: Room['construct'] }
      },
): boolean =>
  isImmutable(resource)
    ? (resource.getIn(['room', 'construct']) ||
        resource.get('roomConstruct')) === 'SHARE'
    : plainJSIsInsideOfShare(resource)

export const isMoveSchedulingAssignment = (
  assignmentMethod: (AssignmentMethod | null | undefined) | string,
) => assignmentMethod === assignmentMethods.MOVE_SCHEDULING

export const isBookableByRequestAssignment = (
  assignmentMethod: (AssignmentMethod | null | undefined) | string,
) => assignmentMethod === assignmentMethods.BOOKABLE_BY_REQUEST

export const isBookableBySelfServiceAssignment = (
  assignmentMethod: (AssignmentMethod | null | undefined) | string,
) => assignmentMethod === assignmentMethods.BOOKABLE_BY_SELF_SERVICE

export const isBookableAssignment = (
  assignmentMethod: (AssignmentMethod | null | undefined) | string,
) =>
  isBookableBySelfServiceAssignment(assignmentMethod) ||
  isBookableByRequestAssignment(assignmentMethod)

export const isFreeAddressAssignment = (
  assignmentMethod: (AssignmentMethod | null | undefined) | string,
) => assignmentMethod === assignmentMethods.FREE_ADDRESS

export const isFreeAddressDesk = (resource: Map<string, any>) =>
  isFreeAddressAssignment(resource.get('assignmentMethod'))

export const isMoveSchedulingSeat = (resource: Map<string, any>) =>
  isMoveSchedulingAssignment(resource.get('assignmentMethod'))

export const addNewLines = (string: string) => string.replace(/\n/g, '<br />')

export const replaceLineEndCharsWithLineBreakTags: typeof addNewLines =
  addNewLines

export const isRestrictedToSingleNeighborhoodMember = ({
  seatPermissionType,
  seatPermissionEmployees = [],
}: {
  seatPermissionType: SeatPermissionEnum | undefined | null
  seatPermissionEmployees:
    | ReadonlyArray<{ __typename: 'Employee' }>
    | undefined
    | null
}) =>
  seatPermissionType === SeatPermissionEnum.ByNeighborhoodMembers &&
  (seatPermissionEmployees || []).length === 1

export const hasUserWithManageMoves = (
  resource: Map<string, any>,
  employeeId: string,
) => {
  const captainships = resource.getIn(['neighborhood', 'captainships']) || []
  const captainship = captainships.find(
    /* @ts-expect-error auto-src: strict-conversion */
    (item) => item.employeeId === employeeId,
  )

  return captainship?.canManageMoves
}

export const hasUserWithManageMembers = (
  resource: Map<string, any>,
  employeeId: string,
) => {
  const captainships = resource.getIn(['neighborhood', 'captainships']) || []
  const captainship = captainships.find(
    /* @ts-expect-error auto-src: strict-conversion */
    (item) => item.employeeId === employeeId,
  )

  return captainship?.canManageMembers
}

export const hasEmployeeAsMember = (
  resource: Map<string, any>,
  draggingResource?: Map<string, any> | null | undefined,
) => {
  if (
    resource.getIn(['neighborhood', 'id']) ===
    draggingResource?.getIn(['neighborhood', 'id'])
  ) {
    return true
  }

  const assignedMembers =
    resource.getIn(['neighborhood', 'assignedMembers', 'edges']) || []
  const matchedMembers =
    resource.getIn(['neighborhood', 'matchedMembers', 'edges']) || []
  const allMembers = assignedMembers
    .concat(matchedMembers)
    /* @ts-expect-error auto-src: strict-conversion */
    .map((edge) => edge.node.id)
  const draggingEmployeeId = draggingResource?.getIn(['employee', 'id'])

  return allMembers.includes(draggingEmployeeId)
}
