// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */

import React from 'react'
import type { ReactNode, ComponentPropsWithoutRef } from 'react'
import classNames from 'classnames'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MuiSelect from '@material-ui/core/Select'
import useStyles from './styles'

export type Props = ComponentPropsWithoutRef<typeof MuiSelect> & {
  children: ReactNode
  error?: boolean | string
  helperText?: string
  InputProps?: any
  name: string
  label?: string
  classes?: {
    formControlRoot: string
    select: string
  }
}

const NoIcon = () => null

// In case we have PropTypes warnings related to invalid value
// (and we are using moment object here) - we can ignore it
// util material ui is updated to v3.9.2
// Issue is fixed in https://github.com/mui-org/material-ui/pull/13661
const SelectField = ({
  name,
  error,
  helperText,
  InputProps,
  children,
  classes,
  label,
  ...props
}: Props) => {
  const baseClasses = useStyles({
    label,
  })
  const text = typeof error === 'string' ? error : helperText

  return (
    <FormControl className={baseClasses.formControlRoot} error={!!error}>
      {label && (
        <InputLabel htmlFor={name} shrink>
          {label}
        </InputLabel>
      )}
      <MuiSelect
        data-testid="select-field"
        IconComponent={NoIcon}
        input={
          <Input
            classes={{
              root: baseClasses.input,
            }}
            id={name}
            name={name}
            {...InputProps}
          />
        }
        name={name}
        {...props}
        classes={{
          ...classes,
          select: classNames(baseClasses.select, classes?.select),
        }}
      >
        {children}
      </MuiSelect>
      {text && <FormHelperText>{text}</FormHelperText>}
    </FormControl>
  )
}

export default SelectField
