import { useQuery } from '@apollo/client'

/* @ts-expect-error auto-src: non-strict-conversion */
import BookableRoomDataQuery from './BookableRoomData.graphql'
import type { Site, Room, Meeting } from './types'

type Args = {
  id: string
  atTime: string | null | undefined
}

type Variables = {
  ids: ReadonlyArray<string>
  atTime: string | null | undefined
}

type Loading = {
  isLoading: true
}

type Loaded = {
  availableAt: string | null
  isBookable: boolean
  capacity: number | null
  imageUrl?: string
  isLoading: false
  label: string
  site: Site
  scheduledBooking: Meeting | null
  nextScheduledBooking: Meeting | null
}

type Result = Loading | Loaded

type Data = {
  rooms: ReadonlyArray<Room>
}

const useData = ({ id, atTime }: Args): Result => {
  const { loading, data } = useQuery<Data, Variables>(BookableRoomDataQuery, {
    fetchPolicy: 'network-only',
    variables: { ids: [id], atTime },
  })

  if (loading) {
    return { isLoading: true }
  }

  const room = (data?.rooms || [])[0]

  const {
    availableAt,
    bookable,
    capacity,
    images,
    label,
    nextScheduledBooking,
    scheduledBooking,
    site,
  } = room

  const imageUrl = images[0]?.url

  return {
    availableAt,
    isBookable: bookable,
    capacity,
    imageUrl,
    isLoading: false,
    label,
    site,
    nextScheduledBooking,
    scheduledBooking,
  }
}

export default useData
