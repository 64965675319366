// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import { createMuiTheme, darken } from '@material-ui/core/styles'
import { mainTheme } from 'common/themes/materialTheme'
import merge from 'lodash/merge'

export const colors = {
  cornflowerBlue: '#42A5F5',
  grayBackground: '#F5F5F5',
  greenJade: '#00b572',
  lightGray: '#757575',
  persianRed: '#cc372c',
  sanMarino: '#4971ab',
  white: '#ffffff',
}
const tonalOffset = 0.2
const palette = {
  primary: {
    main: colors.greenJade,
    dark: darken(colors.greenJade, tonalOffset),
    contrastText: colors.white,
  },
  secondary: {
    main: colors.cornflowerBlue,
    dark: darken(colors.cornflowerBlue, tonalOffset),
    contrastText: colors.white,
  },
  contrastThreshold: 3,
  tonalOffset,
}
const muiTheme = {
  overrides: {
    MuiMenu: {
      paper: {
        borderRadius: 2,
      },
    },
    MuiMenuItem: {
      root: {
        color: '#424242',
        fontSize: 16,
        lineHeight: '17px',
        // IE11 workaround
        // Defining height fixes 'align-items: center' not working when minHeight is used
        // If height < minHeight then minHeight is used as if height was not defined
        height: 0,
        '@media (min-width: 600px)': {
          minHeight: 48,
        },
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: '#f5f5f5',
        color: '#424242',
        fontSize: 14,
        letterSpacing: 0.5,
        borderRadius: 2,
        boxShadow: '0 0 2px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.2)',
      },
    },
    MuiButtonGroup: {
      contained: {
        backgroundColor: '#f5f5f5',
        color: '#424242',
        fontSize: 14,
        letterSpacing: 0.5,
        borderRadius: 2,
        boxShadow: '0 0 2px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.2)',
      },
      groupedContained: {
        '&:not(:last-child)': {
          borderRightColor: '#e0e0e0',
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.lightGray,
        '&$focused': {
          color: colors.lightGray,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 10,
        '&$error': {
          fontSize: 10,
        },
      },
    },
    MuiInput: {
      inputMultiline: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: 2,
        color: colors.lightGray,
        fontSize: 12,
        padding: '6px 10px',
        marginBottom: -5,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#eeeeee',
      },
    },
    MuiListItemIcon: {
      root: {
        marginRight: 0,
      },
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    h6: {
      fontSize: 24,
    },
  },
}
const muiGreyFormTheme = {
  palette: merge({}, mainTheme.palette, palette),
  overrides: {
    MuiCheckbox: {
      root: {
        padding: '6px 12px',
      },
    },
    MuiInputBase: {
      root: {
        background: '#f5f5f5',
        color: '#424242',
        marginTop: 6,
        padding: 0,
        '&$disabled': {
          background: '#F9F9F9',
        },
      },
      input: {
        lineHeight: '24px',
        marginTop: '0 !important',
        minHeight: 24,
        padding: '6px 8px',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottomColor: '#f5f5f5',
        },
        '&:after': {
          borderBottomColor: '#42A5F5',
        },
      },
      formControl: {
        'label + &': {
          marginTop: 20,
        },
      },
      inputMultiline: {
        backgroundColor: 'none',
        color: 'inherit',
        fontSize: 'inherit',
        borderRadius: 2,
        padding: 8,
        marginBottom: -5,
        marginTop: 0,
        boxSizing: 'border-box',
        lineHeight: '19px',
      },
      input: {
        marginTop: 6,
      },
    },
    MuiMenuItem: {
      root: {
        '@media (min-width: 600px)': {
          minHeight: 48,
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '1rem',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
}

export const greyFormTheme = (parentTheme: any) =>
  createMuiTheme(merge({}, parentTheme, muiGreyFormTheme))
export const officeSpaceTheme = createMuiTheme(muiTheme)
