import merge from 'lodash/merge'
import commonActionTypes from 'common/constants/actionTypes'

export default merge({}, commonActionTypes, {
  Branding: {
    FAILURE: 'REQUEST_BRANDING_FAILURE',
    RECEIVE: 'RECEIVE_BRANDING',
    REQUEST: 'REQUEST_BRANDING',
  },
  BrowsePanel: {
    FILTER_CLEARED: 'BROWSE_PANEL/FILTER_CLEARED',
    FILTER_CHANGED: 'BROWSE_PANEL/FILTER_CHANGED',
    ITEM_SELECTED: 'BROWSE_PANEL/ITEM_SELECTED',
    SCROLL_REQUESTED: 'BROWSE_PANEL/SCROLL_REQUESTED',
    SCROLL_STARTED: 'BROWSE_PANEL/SCROLL_STARTED',
    TAB_CHANGED: 'BROWSE_PANEL/TAB_CHANGED',
    TWEEN_TO_INITIAL_ENDED: 'BROWSE_PANEL/TWEEN_TO_INITIAL_ENDED',
    TWEEN_TO_INITIAL_STARTED: 'BROWSE_PANEL/TWEEN_TO_INITIAL_STARTED',
    private: {
      ITEM_SELECTED_TWEEN_STEP: 'BROWSE_PANEL/ITEM_SELECTED_TWEEN_STEP',
    },
  },
  Calendar: {
    SELECT_DATE: 'SELECT_CALENDAR_DATE',
    SELECT_MONTH: 'SELECT_CALENDAR_MONTH',
    SELECT_TIME: 'SELECT_CALENDAR_TIME',
    SET_MONTH_VISIBILITY: 'SET_CALENDAR_MONTH_VISIBILITY',
    UPDATE_DAY_VIEW_PULL_Y: 'UPDATE_CALENDAR_DAY_VIEW_PULL_Y',
    CANCEL_DAY_VIEW_PULL: 'CANCEL_CALENDAR_DAY_VIEW_PULL',
  },
  EmployeeLocations: {
    RECEIVE: 'RECEIVE_EMPLOYEE_LOCATIONS',
  },
  Floor: {
    FADE_CANCELED: 'RENDERED_FLOOR/FADE_CANCELED',
    FADE_ENDED: 'RENDERED_FLOOR/FADE_ENDED',
    FADE_STARTED: 'RENDERED_FLOOR/FADE_STARTED',
    FILTER_CHANGE: 'FLOORS_FILTER_CHANGE',
    OUTER_DIV_MOUNTED: 'RENDERED_FLOOR/OUTER_DIV_MOUNTED',
    OUTER_DIV_STYLE_CHANGED: 'RENDERED_FLOOR/OUTER_DIV_STYLE_CHANGED',
    REQUEST: 'REQUEST_FLOORS',
  },
  FloorGroupId: {
    SET: 'SET_FLOOR_GROUP_ID',
  },
  FloorPicker: {
    ITEM_SELECTED: 'FLOOR_PICKER/ITEM_SELECTED',
  },
  InboundMoveStatus: {
    CLEAR: 'CLEAR_INBOUND_MOVES',
    RECEIVE: 'RECEIVE_INBOUND_MOVES',
  },
  Kiosk: {
    RECEIVE: 'RECEIVE_KIOSK',
    REQUEST: 'REQUEST_KIOSK',
  },
  ViewControls: {
    CLOSE_COMPACT: 'LEGEND/CLOSE_COMPACT',
    TOGGLE_COMPACT: 'LEGEND/TOGGLE_COMPACT',
    TOGGLE: 'LEGEND/TOGGLE',
    TOGGLE_SHOW_PHOTOS: 'LEGEND/TOGGLE_SHOW_PHOTOS',
    TOGGLE_SHOW_DISTANCING_RADIUS: 'LEGEND/TOGGLE_SHOW_DISTANCING_RADIUS',
    TOGGLE_SEAT_AVAILABILITY_VISIBILITY:
      'LEGEND/TOGGLE_SEAT_AVAILABILITY_VISIBILITY',
  },
  Pin: {
    RECEIVE: 'RECEIVE_PIN',
    RESET: 'RESET_PIN',
  },
  Resource: {
    BOOKING_FAILED: 'BOOKING_FAILED',
    BOOKING_REQUESTED: 'BOOKING_REQUESTED',
    BOOKING_REQUEST_STARTED: 'BOOKING_REQUEST_STARTED',
    BOOKING_REQUEST_FINISHED: 'BOOKING_REQUEST_FINISHED',
    BOOKING_SUCCEEDED: 'BOOKING_SUCCEEDED',
    CLICKED_IN_SHAPE: 'CLICKED_IN_SHAPE',
    RESET: 'RESET_SELECTED_RESOURCE',
    SELECTED: 'RESOURCE_SELECTED',
    RECEIVE: 'RECEIVE_RESOURCE',
  },
  ResourceFields: {
    INIT: 'INIT_RESOURCE_FIELDS',
  },
  Resources: {
    CLEAR: 'CLEAR_RESOURCES',
    RECEIVE: 'RECEIVE_RESOURCES',
  },
  Route: {
    RECEIVE_LOCATION: 'ROUTE/RECEIVE_LOCATION',
    RECEIVE_MATCH: 'ROUTE/RECEIVE_MATCH',
  },
  Screen: {
    RESIZE: 'SCREEN_RESIZE',
  },
  Search: {
    EXTENDED_RESULT_SELECTED: 'SEARCH/EXTENDED_RESULT_SELECTED',
    REQUEST: 'REQUEST_SEARCH_RESULTS',
    RECEIVE: 'RECEIVE_SEARCH_RESULTS',
    RESET: 'RESET_SEARCH_RESULTS',
    CHANGE_FOCUS: 'SET_SEARCH_RESULTS_ARE_FOCUSED',
    CHANGE_RESULTS_OPEN: 'SET_SEARCH_RESULTS_RESULTS_OPEN',
    RESET_TYPEAHEAD: 'RESET_SEARCH_RESULTS_TYPEAHEAD',
  },
  RequestTypes: {
    RECEIVE: 'RECEIVE_REQUEST_TYPES',
  },
  RequestAttachments: {
    SET_IS_DRAGGING_ATTACHMENT: 'SET_IS_DRAGGING_ATTACHMENT',
    ADD_UPLOAD_FAILURE: 'ADD_ATTACHMENT_UPLOAD_FAILURE',
    CANCEL_IN_PROGRESS_ATTACHMENT: 'CANCEL_IN_PROGRESS_ATTACHMENT',
    RECEIVE_ATTACHMENT: 'RECEIVE_ATTACHMENT',
    REMOVE_ATTACHMENT: 'REMOVE_ATTACHMENT',
    REQUEST_ATTACHMENT: 'REQUEST_ATTACHMENT',
    SET_UPLOAD_PROGRESS: 'SET_ATTACHMENT_UPLOAD_PROGRESS',
  },
  Typeahead: {
    ACTIVATED: 'ACTIVATED_REQUEST_TYPEAHEAD',
    DEACTIVATED: 'DEACTIVATED_REQUEST_TYPEAHEAD',
    REQUEST: 'REQUEST_TYPEAHEAD_RESULTS',
    RECEIVE: 'RECIEVE_TYPEAHEAD_RESULTS',
    RESET: 'RECEIVE_TYPEAHEAD_RESULTS',
    CHANGE_FOCUS: 'SET_TYPEAHEAD_RESULTS_ARE_FOCUSED',
  },
  Wizard: {
    CANCEL: 'CANCEL_WIZARD',
    CLEAR_FIELD: 'CLEAR_WIZARD_FIELD',
    DESTROY: 'DESTORY_WIZARD',
    FINISH: 'FINISH_WIZARD',
    SET_FIELD: 'SET_WIZARD_FIELD',
    SET_STEP: 'SET_WIZARD_STEP',
    START: 'START_WIZARD',
  },
  DeskBooking: {
    CONFIRM_TIME: 'CONFIRM_BOOKING_TIME',
    DESELECT_DESK: 'DESELECT_DESK_BOOKING_DESK',
    FILTER_FLOORS: 'FILTER_BOOKING_FLOORS',
    GO_BACK: 'PREVIOUS_BOOKING_STEP',
    RECEIVE_AVAILABLE_HOT_DESKS: 'RECEIVE_AVAILABLE_HOT_DESKS_PER_FLOOR',
    RECEIVE_BOOKING: 'RECEIVE_SINGLE_BOOKING',
    RECEIVE_BOOKINGS: 'RECEIVE_BOOKING_HISTORY',
    RESET_BOOKINGS: 'RESET_BOOKING_HISTORY',
    RESET_STATE: 'RESET_BOOKING_STATE',
    SAVE_BOOKING_CHANGES: 'SAVE_BOOKING_CHANGES',
    SELECT_DESK: 'SELECT_DESK_BOOKING_DESK',
    SELECT_FLOOR: 'SELECT_DESK_BOOKING_FLOOR',
    SET_FORM_VALUES: 'SET_FORM_VALUES',
    SHOW_FLOOR_LIST: 'SHOW_DESK_BOOKING_FLOOR_LIST',
  },
  RoomBooking: {
    SET_STEP: 'ROOM_BOOKING/SET_STEP',
    SELECT_FLOOR: 'ROOM_BOOKING/SELECT_FLOOR',
    SELECT_ROOM: 'ROOM_BOOKING/SELECT_ROOM',
    DESELECT_ROOM: 'ROOM_BOOKING/DESELECT_ROOM',
  },
  ProductLinks: {
    RECEIVE: 'RECEIVE_PRODUCT_LINKS',
  },
  FlowSelector: {
    HIDE: 'FLOW_SELECTOR/HIDE',
    SHOW: 'FLOW_SELECTOR/SHOW',
    SET: 'FLOW_SELECTOR/SET',
  },
  Settings: {
    SET_SCENARIO_SETTINGS: 'SETTINGS/SET_SCENARIO_SETTINGS',
  },
})
