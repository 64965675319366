import { enUS, es } from 'date-fns/locale'

import moment from 'moment-timezone'
import type { Moment, MomentInput } from 'moment-timezone'

import { hourToTime, defaultRange } from 'common/types/time'
import portraitHandsetOrTablet from 'common/selectors/viewport/portraitHandsetOrTablet'
import type { LabelledTime } from 'common/types/time'

const MOD = 1000

type LocaleMap = { [key: string]: typeof enUS }

const localeMap: LocaleMap = {
  en: enUS,
  es,
}

type Language = keyof LocaleMap
type Locale = typeof enUS

export const DATE_FORMAT = 'ddd, MMM D'
export const TIME_INTERVAL = 0.25

export const timeOptions = (
  times: ReadonlyArray<LabelledTime> = defaultRange,
  referenceTime: Moment = moment(),
): ReadonlyArray<{
  disabled?: boolean
  formattedDuration?: string
  label: string
  tinted?: boolean
  value: string
}> =>
  times.map((interval) => ({
    disabled: interval.disabled,
    formattedDuration:
      interval.formattedDuration && `(${interval.formattedDuration})`,
    label: interval.label,
    tinted: interval?.tinted,
    value: hourToTime(interval.value, referenceTime).format('LT'),
  }))

export const fromUnix = (seconds: number): Moment => moment(seconds * MOD)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const modeOverrideProps = () =>
  portraitHandsetOrTablet(window)
    ? {
        okLabel: '',
        variant: 'dialog',
        DialogProps: {
          'data-testid': 'date-select-calendar',
        },
      }
    : {
        disableToolbar: true,
        variant: 'inline',
        PopoverProps: {
          'data-testid': 'date-select-calendar',
        },
      }

export const toMoment = (
  value: MomentInput,
  placeholder: string,
  timeZone?: string,
): Moment | undefined => {
  const agnosticValue =
    typeof value === 'string' ? moment(value, 'ddd, MMM D') : moment(value)

  if (value === placeholder) {
    return undefined
  }

  if (!timeZone) {
    return agnosticValue
  }

  return moment.tz(agnosticValue.format('YYYY-MM-DD'), timeZone)
}

export const fromMoment = (value?: Moment): Date | undefined =>
  value && new Date(moment(value).format('L'))

const getLocale = (language: Language | undefined = 'en') => localeMap[language]

export const locale = (
  calendarStartDay?: number,
  language?: Language,
): Locale => {
  const baseLocale = getLocale(language)

  if (calendarStartDay === undefined) {
    return baseLocale
  }

  return {
    ...baseLocale,
    options: { ...baseLocale.options, weekStartsOn: calendarStartDay },
  } as Locale
}
