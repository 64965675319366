// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Record } from 'immutable'
import type { Map, RecordOf } from 'immutable'
import type { CurrentUserState } from 'visual_directory/reducers/currentUser'

export const ANYBODY = 'anybody'
export const NOBODY = 'nobody'
export const SELF = 'self'
type BookingPermission = typeof ANYBODY | typeof NOBODY | typeof SELF
type DeskBookingUserPermissionsShape = {
  book: BookingPermission
  request: BookingPermission
}
const DeskBookingUserPermissions = Record<DeskBookingUserPermissionsShape>({
  book: NOBODY,
  request: NOBODY,
})

export type DeskBookingUserPermissionsType =
  RecordOf<DeskBookingUserPermissionsShape>
export const canBookOthers = (
  deskBookingUserPermissions: DeskBookingUserPermissionsType,
) => deskBookingUserPermissions.book === ANYBODY
export const canRequestBookingsForOthers = (
  deskBookingUserPermissions: DeskBookingUserPermissionsType,
) => deskBookingUserPermissions.request === ANYBODY
// seat.get('bookable') looks at the value of settings.permissions_bookableseats, so you only
// need to check whether the seat is bookable. you don't need to check the user.
export const canShowBookButton = (seat: Map<string, unknown>) =>
  seat.get('bookable')
export const canShowDeskBookingForm = (
  deskBookingUserPermissions: DeskBookingUserPermissionsType,
) => deskBookingUserPermissions.book !== NOBODY
export const canShowMyBookingsList = (user: CurrentUserState) =>
  !user.get('anonymous') && user.get('linkedEmployee') !== null
export default DeskBookingUserPermissions
