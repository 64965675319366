import React from 'react'
import Alert from '@material-ui/lab/Alert'
import UpdateIcon from '@material-ui/icons/Update'

import useStyles from './useStyles'

type Props = {
  children: React.ReactNode
  Icon?: React.ComponentType<unknown>
}

const InfoBar = ({ Icon: CustomIcon, children }: Props): React.ReactElement => {
  const classes = useStyles()

  const Icon = CustomIcon || UpdateIcon

  return (
    <Alert
      classes={classes}
      icon={<Icon fontSize="inherit" />}
      severity="info"
      variant="filled"
    >
      {children}
    </Alert>
  )
}

export default InfoBar
