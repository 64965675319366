import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

import { sanitizeHTML } from 'common/lib/stripHTMLTags'
import { addNewLines } from 'common/lib/resource'

import Layout from '../Layout'
import useAdjustedCardPosition from '../useAdjustedCardPosition'

import ImageFallback from './ImageFallback'
import useData from './useData'

type Props = {
  id: string
}

const NeighborhoodCard = ({ id }: Props): JSX.Element => {
  const { imageUrl, color, title, description = '', isLoading } = useData(id)

  useAdjustedCardPosition({ skip: isLoading })

  if (isLoading) {
    return (
      <Layout imageFallback={<ImageFallback />} title={<Skeleton />}>
        <Skeleton />
      </Layout>
    )
  }

  return (
    <Layout
      imageFallback={<ImageFallback color={color} />}
      imageUrl={imageUrl}
      title={title}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizeHTML(addNewLines(description)),
        }}
      />
    </Layout>
  )
}

export default NeighborhoodCard
