import { makeStyles } from '@material-ui/styles'

/* @ts-expect-error auto-src: non-strict-conversion */
export default makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    height: 36,
    padding: '0 16px',
    borderTop: `1px solid ${palette.grey[300]}`,
    background: palette.common.white,
  },
  text: {
    marginLeft: 4,
    fontSize: 12,
    color: palette.grey[800],
  },
  skeleton: {
    width: 120,
    maxWidth: '100%',
    height: 16,
    marginLeft: 4,
    borderRadius: 8,
  },
}))
