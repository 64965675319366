import { useQuery } from '@apollo/client'
import ProductConstants from 'common/constants/products'
import type { SubmenuGroup } from 'common/types/submenu'
import type { Path, ProductName, ProductLinks } from './types'
/* @ts-expect-error auto-src: non-strict-conversion */
import productLinksQuery from './productLinks.graphql'

type DataT = {
  crossProductLinks: ProductLinks
  submenus: SubmenuGroup
}
type VariablesT = {
  product: ProductName
}
type UseProductLinks = (arg0: ProductName) => {
  productLinks: ProductLinks
  submenus: SubmenuGroup
  manageAccountProductLink: Path
}

/* @ts-expect-error auto-src: strict-conversion */
const useProductLinks: UseProductLinks = (selectedProduct) => {
  const { data } = useQuery<DataT, VariablesT>(productLinksQuery, {
    variables: {
      product: selectedProduct,
    },
  })
  const productLinks = data?.crossProductLinks
  const submenus = data?.submenus
  const manageAccountProductLink =
    (productLinks && productLinks[ProductConstants.MANAGE_ACCOUNT]) || ''

  return {
    productLinks,
    submenus,
    manageAccountProductLink,
  }
}

export default useProductLinks
