import React from 'react'
import type { ReactNode } from 'react'
import useStyles from './useStyles'

type Props = {
  children?: ReactNode
}

const ItemWrapper = ({ children, ...props }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div {...props} className={classes.root}>
      {children}
    </div>
  )
}

export default ItemWrapper
