import { createSelector } from 'reselect'
import getViewportCategories from 'common/selectors/viewport'
import type { BrandingState } from 'visual_directory/reducers/branding'
import { brandStyles } from 'visual_directory/hooks/useBrandedStyles'
import type { BrandedStyles } from 'visual_directory/hooks/useBrandedStyles'

type State = {
  branding: BrandingState
}

type Props = { viewportCategories: ViewportCategories }

type ViewportCategories = ReturnType<typeof getViewportCategories>

const getBranding = ({ branding: { config } }: State) => config

const viewportCategoriesSelector = (
  _: unknown,
  { viewportCategories }: Props,
): ViewportCategories => viewportCategories

const getBrandedStyles = createSelector<
  State,
  Props,
  BrandingState['config'],
  ViewportCategories,
  BrandedStyles
>(getBranding, viewportCategoriesSelector, (branding, viewportCategories) =>
  brandStyles({ branding, viewportCategories }),
)

export default (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  state: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => // FIXME: Use BrandedStyles to type return type
  getBrandedStyles(state as State, {
    viewportCategories: getViewportCategories(window),
  })
