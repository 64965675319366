import React from 'react'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'

import useStyles from './useStyles'

type Props = {
  onCancel: (event: React.MouseEvent<HTMLElement>) => void
  onConfirm: (event: React.MouseEvent<HTMLElement>) => void
}

const ActionButtons = ({ onCancel, onConfirm }: Props): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation('common', {
    keyPrefix: 'common.calendar.actions',
  })

  return (
    <div className={classes.root}>
      <Button onClick={onCancel}>{t('cancel')}</Button>
      <Button
        className={classes.submitButton}
        color="primary"
        data-testid="submit-button"
        onClick={onConfirm}
        variant="contained"
      >
        {t('ok')}
      </Button>
    </div>
  )
}

export default ActionButtons
