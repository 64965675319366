// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import Definitions from 'common/constants/definitions'

export const logoutPath = '/logout'
export const logoutAllPath = '/logout?from_all_sessions=1'
const printFloorPath = '/print'

export const openPath =
  (path: string, target = '_self') =>
  () => {
    window.open(path, target)
  }
export const calendarSyncEverythingPath =
  '/connectors/calendar_sync/sync_everything'
export function makeRoomBookPath({
  floorId,
  roomId,
}: {
  floorId: string
  roomId: string // FIXME: Convert this to an exact object
}) {
  return `/visual-directory/floors/${floorId}/rooms/${roomId}/details`
}
export function makeRoomCalendarPath({
  floorId,
  roomId,
}: {
  floorId: string
  roomId: string // FIXME: Convert this to an exact object
}) {
  return `/visual-directory/floors/${floorId}/rooms/${roomId}/calendar`
}
export function makeFloorPath(floorId: number | string | void) {
  return floorId === undefined ? '/floors' : `/floors/${floorId}`
}
export function makeResourcePath(
  floorId: number | string,
  resourceType: string,
  resourceId: number | string,
) {
  return floorId === undefined
    ? '/floors'
    : `/floors/${floorId}/${resourceType}/${resourceId}`
}
export function makePrintFloorPath({ floorId }: { floorId: number | string }) {
  return `${printFloorPath}/${floorId}`
}
export function makeFloorPdfjsPath({
  floorId,
  printoutPath,
}: {
  floorId: string
  printoutPath: string // FIXME: Convert this to an exact object
}) {
  return `${printoutPath}/pdfjs/${floorId}`
}
export function makeFloorPrintoutPath({
  floorId,
  format,
  printoutPath,
  printJobId,
}: {
  floorId: string
  format: string
  printoutPath: string
  printJobId?: string
}) {
  const query = printJobId ? `?printJobId=${printJobId}` : ''

  return `${printoutPath}/floors/${floorId}/printouts.${format}${query}`
}
export function makeFloorLegendSvgPath({
  floorId,
  printoutPath,
}: {
  floorId: string
  printoutPath: string
}) {
  return `${printoutPath}/floors/${floorId}/printouts/legends.svg`
}
export function makeFloorHeaderSvgPath({
  floorId,
  height,
  printoutPath,
  width,
}: {
  floorId: string
  height: string
  printoutPath: string
  width: string
}) {
  return `${printoutPath}/floors/${floorId}/printouts/headers.svg?height=${height}&width=${width}`
}
export const makeFloorPlanImageUrl = ({
  id,
  magnificationFactor,
}: {
  id: number | string
  magnificationFactor: number
}) =>
  `${window.location.origin}/floor_plan_images/${id}?filename=${id}@${magnificationFactor}x.png`
type Config = {
  forceRegular?: boolean
}
export const makeFloorPlanImageUrlFromFloor = (
  selectedFloor: any,
  config: Config | null | undefined,
) => {
  const floorId = selectedFloor?.get('id')

  if (!floorId) {
    return
  }

  const floorPlanImages = selectedFloor.getIn(['floorPlan', 'floorPlanImages'])

  if (!floorPlanImages) {
    return null
  }

  const {
    imageType: suggestedImageType,
    suffix: suggestedMagnificationFactor,
  } = Definitions.background
  const imageType = config?.forceRegular ? 'regular' : suggestedImageType
  // Try to find the floorPlan with exact magnificationFactor
  // else fallback to highest magnificationFactor available
  const floorPlanImage =
    floorPlanImages.find(
      /* @ts-expect-error auto-src: strict-conversion */
      (i) =>
        i.get('imageType') === imageType &&
        i.get('magnificationFactor') === suggestedMagnificationFactor,
    ) ||
    floorPlanImages
      /* @ts-expect-error auto-src: strict-conversion */
      .filter((i) => i.get('imageType') === imageType)
      /* @ts-expect-error auto-src: strict-conversion */
      .maxBy((i) => i.get('magnificationFactor'))

  if (!floorPlanImage) {
    return null
  }

  const magnificationFactor = floorPlanImage.get('magnificationFactor')
  const id = floorPlanImage.get('id')

  return makeFloorPlanImageUrl({
    id,
    magnificationFactor,
  })
}
export const makeVisualDirectorySeatPath = ({
  floorId,
  seatId,
}: {
  floorId: string
  seatId: string
}) => `/visual-directory/floors/${floorId}/seats/${seatId}`
export const makeVisualDirectoryNeighborhoodPath = (
  floorId: string,
  neighborhoodId: string,
) => `/visual-directory/floors/${floorId}/neighborhoods/${neighborhoodId}`
