import React, { useState, useMemo } from 'react'
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'
import { List } from '@material-ui/core'
import type { NavItemsProps } from '../types'
import NavMenuItem from '../../NavMenuItem'
import { isSelectedItem } from '../lib'
import useStyles from '../styles'

const SubMenuProductItem = ({
  name,
  Icon,
  submenu,
  isOpen = false,
}: NavItemsProps): JSX.Element | null => {
  const [open, setOpen] = useState<boolean>(isOpen)
  const styles = useStyles()

  if (!submenu) {
    return null
  }

  const selectedProductItem = (item: string) => {
    const isSelected = isSelectedItem(item)

    if (isSelected) {
      setOpen(true)
    }

    return isSelected
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const subMenuItem = useMemo(
    () => (
      <div key={name} data-testid="home-menu">
        <List className={styles.submenu}>
          {Object.keys(submenu).map((key) => (
            <NavMenuItem
              key={key}
              component="a"
              href={submenu[key]}
              isBold={false}
              isSelected={selectedProductItem(submenu[key])}
            >
              {key}
            </NavMenuItem>
          ))}
        </List>
      </div>
    ),
    [name, styles, submenu],
  )

  return (
    <List component="div" disablePadding>
      <NavMenuItem
        isSelected={false}
        LeftIcon={Icon}
        onClick={() => setOpen(!open)}
        RightIcon={open ? ExpandLessIcon : ExpandMoreIcon}
      >
        {name}
      </NavMenuItem>
      {open && subMenuItem}
    </List>
  )
}

export default SubMenuProductItem
