// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useMemo, useCallback } from 'react'

import moment from 'moment-timezone'
import VDTimeContext from './VDTimeContext'
import type { DateTime } from './types'

type Props = {
  children: React.ReactNode
}
export const TIME_ZONE_AGNOSTIC_FORMAT = 'YYYY-MM-DDTHH:mm'
const NOW = null

const VDTimeProvider = ({ children }: Props) => {
  const [dateTime, setDateTime] = useState<DateTime>(NOW)
  const [loading, setLoading] = useState<boolean>(false)
  const resetDateTime = useCallback(() => {
    setDateTime(NOW)
  }, [setDateTime])
  const isTimeInPastInTimezone = useCallback(
    (timezone) => {
      const dateTimeInTimezone = moment.tz(
        /* @ts-expect-error src: types added to moment-timezone */
        dateTime,
        TIME_ZONE_AGNOSTIC_FORMAT,
        timezone,
      )

      return moment().isAfter(dateTimeInTimezone)
    },
    [dateTime],
  )
  const contextValue = useMemo(
    () => ({
      dateTime,
      changeDateTime: setDateTime,
      resetDateTime,
      loading,
      setLoading,
      isTimeInPastInTimezone,
    }),
    [dateTime, resetDateTime, loading, isTimeInPastInTimezone],
  )

  return (
    <VDTimeContext.Provider value={contextValue}>
      {children}
    </VDTimeContext.Provider>
  )
}

export default VDTimeProvider
