// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import useStyles from './styles'

type Props = {
  product: string // FIXME: Convert this to an exact object
}
export default ({ product }: Props) => {
  const classes = useStyles()

  return (
    <SvgIcon classes={classes} viewBox="0 0 150 18">
      <g fill="#FFF" fillRule="evenodd">
        <text
          dx={Array(product.length).fill('0').fill('1', 1).join(' ')}
          fontFamily="Open Sans"
          fontSize="21"
          fontWeight="700"
          transform="translate(0 -5)"
        >
          <tspan x="26" y="22">
            {product.toUpperCase()}
          </tspan>
        </text>
        <path
          d="M4.269 10.733l-.034 4.62 4.204-2.261.032-4.621zm4.763-2.648L9 12.706l4.204-2.262.031-4.62zm-.001 5.294L9 18l4.204-2.262.031-4.62z"
          fillOpacity=".5"
        />
        <path d="M0 2.364l4.21 2.401L8.471 2.4 4.259 0zm4.765 2.648l4.21 2.4 4.26-2.365-4.21-2.4zM0 7.658l4.211 2.401 4.26-2.365-4.212-2.4z" />
      </g>
    </SvgIcon>
  )
}
