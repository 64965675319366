import React from 'react'
import type { DayPickerProps } from 'react-day-picker'
import BaseDayPicker from 'react-day-picker'

import { useTranslation } from 'react-i18next'

export * from 'react-day-picker'

const DayPicker = (props: DayPickerProps): JSX.Element => {
  const { t, i18n } = useTranslation('common', { keyPrefix: 'common.calendar' })

  const componentProps = {
    locale: i18n.language,
    months: t('months', { returnObjects: true }) as string[],
    weekdaysLong: t('weekdays', {
      context: 'long',
      returnObjects: true,
    }) as string[],
    weekdaysShort: t('weekdays', {
      context: 'short',
      returnObjects: true,
    }) as string[],
    ...props,
  }

  return <BaseDayPicker {...componentProps} />
}

export default DayPicker
