// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Range } from 'immutable'

import moment from 'moment-timezone'
import type { Moment } from 'moment-timezone'

const MINS_IN_HOUR = 60
const HOURS_IN_DAY = 24

export const DATE_FORMAT = 'ddd, MMM D'
export const TIME_FORMAT = 'h:mm A'
export const DATETIME_FORMAT = `${DATE_FORMAT}, ${TIME_FORMAT}`
export const TIME_INTERVAL = 0.25
export type LabelledTime = {
  disabled?: boolean
  formattedDuration?: string
  label: string
  tinted?: boolean
  value: number // FIXME: Convert this to an exact object
}
export const hourToTime = (hour: number, referenceTime?: Moment | null) =>
  (referenceTime || moment()).clone().startOf('day').add(hour, 'hours')

export const timeToHour = (time?: Moment | null): number =>
  time ? (time.hours() * MINS_IN_HOUR + time.minutes()) / MINS_IN_HOUR : NaN
export const timeStringToHour = (timeString?: string): number => {
  const time = timeString && new Date(`2000-01-01T${timeString}`)

  return time
    ? (time.getHours() * MINS_IN_HOUR + time.getMinutes()) / MINS_IN_HOUR
    : NaN
}
export const formatTime = (time: Moment) => time.format('LT')
export const formatToMilitaryTime = (momentTime: Moment): string =>
  momentTime.format('HH:mm')
export const defaultRange: ReadonlyArray<LabelledTime> = Range(
  0,
  HOURS_IN_DAY,
  TIME_INTERVAL,
)
  .toArray()
  .map((value) => ({
    value,
    label: formatTime(hourToTime(value)),
    __typename: 'Times',
  }))
export const timeStringToTime = (time: string) =>
  hourToTime(timeStringToHour(time))
