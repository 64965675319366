// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SvgIcon from '@material-ui/core/SvgIcon'

import NavMenuItem from 'common/components/navmenu/NavMenuItem'
import { bookARoomPath } from 'visual_directory/lib/roomBooking/pathHelpers'

type StateToProps = {
  floorBookARoomPath: string
}
type Props = {
  onClick: (arg0: any) => void
  isGuest?: boolean
  showImprovedNavigation?: boolean
} & StateToProps
type State = {
  selectedFloor: any
}

/* eslint-disable max-len */
const RoomIcon = () => (
  <svg
    fill="#9e9e9e"
    height="20"
    style={{
      marginLeft: 6,
    }}
    viewBox="0 0 24 24"
    width="20"
  >
    <g transform="translate(4)">
      <path d="M10,2.5 L15,2.5 L15,21.5 L10,21.5 L10,23.5 L0,21.5943718 L0,2.43048878 L10,0.5 L10,2.5 Z M10,4.75 L10,19.25 L13,19.25 L13,4.75 L10,4.75 Z" />
    </g>
  </svg>
)

const RoomLegacyIcon = () => (
  <SvgIcon
    style={{
      marginLeft: 6,
    }}
  >
    <g transform="translate(4)">
      <path d="M10,2.5 L15,2.5 L15,21.5 L10,21.5 L10,23.5 L0,21.5943718 L0,2.43048878 L10,0.5 L10,2.5 Z M10,4.75 L10,19.25 L13,19.25 L13,4.75 L10,4.75 Z" />
    </g>
  </SvgIcon>
)

/* eslint-enable max-len */
const BookARoom = ({
  floorBookARoomPath,
  onClick,
  isGuest,
  showImprovedNavigation,
}: Props) => {
  const { t } = useTranslation('visual_directory', {
    keyPrefix: 'visual_directory.home_menu',
  })

  const Icon = showImprovedNavigation ? RoomIcon : RoomLegacyIcon
  const shouldChangeStyle = !showImprovedNavigation || isGuest

  return (
    <NavMenuItem
      component={Link}
      isBold={shouldChangeStyle}
      LeftIcon={shouldChangeStyle && Icon}
      onClick={onClick}
      to={floorBookARoomPath}
    >
      {t('book_a_room')}
    </NavMenuItem>
  )
}

export default connect(
  ({ selectedFloor }: State): StateToProps => ({
    floorBookARoomPath: bookARoomPath({ floorId: selectedFloor.get('id') }),
  }),
  {},
)(BookARoom)
