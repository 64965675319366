import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

import { sanitizeHTML } from 'common/lib/stripHTMLTags'
import { addNewLines } from 'common/lib/resource'

import Layout from '../Layout'
import useAdjustedCardPosition from '../useAdjustedCardPosition'

import ImageFallback from './ImageFallback'
import useData from './useData'

type Props = {
  id: string
}

const RoomCard = ({ id }: Props): JSX.Element => {
  const data = useData(id)

  useAdjustedCardPosition({ skip: data.isLoading })

  if (data.isLoading) {
    return (
      <Layout imageFallback={<ImageFallback />} title={<Skeleton />}>
        <Skeleton />
      </Layout>
    )
  }

  const { imageUrl, label, description = '' } = data

  const safeDescription = description
    ? sanitizeHTML(addNewLines(description))
    : 'No information available'

  return (
    <Layout imageFallback={<ImageFallback />} imageUrl={imageUrl} title={label}>
      <div dangerouslySetInnerHTML={{ __html: safeDescription }} />
    </Layout>
  )
}

export default RoomCard
