// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import useFlags from 'common/hooks/useFlags'
import useStyles from './styles'

export type Props = {
  children?: any
  component?: any
  email?: string
  href?: string
  isBold?: boolean
  isDisabled?: boolean
  isSelected?: boolean
  isSubMenu?: boolean
  LeftIcon?: any
  onClick?: ((arg0: any) => void) | null | undefined
  RightIcon?: any
  target?: string
  to?: string
}

const NavMenuItem = ({
  children,
  component,
  email,
  href,
  isBold = true,
  isDisabled,
  isSelected,
  isSubMenu,
  LeftIcon,
  onClick,
  RightIcon,
  target,
  to,
}: Props) => {
  const {
    'hamburger_menu.improved_navigation.enable': showImprovedNavigation,
  } = useFlags()

  const classes = useStyles({ isBold, showImprovedNavigation })()

  const menuItemClasses = isSubMenu
    ? classes.subMenuListItemStyles
    : classes.listItemStyles
  const unselectedListItemTextClasses = classes.unselectedListItemTextStyles
  const selectedListItemTextClasses = classes.selectedListItemTextStyles
  const selectedItemClasses = isSelected ? selectedListItemTextClasses : null
  const submenuItemClasses = isSubMenu
    ? classes.listSubMenuItemTextStyles
    : null
  const listItemTextClasses =
    selectedItemClasses || submenuItemClasses || unselectedListItemTextClasses

  // These two props (to and href) should be mutually exclusive
  const destinationProp = {
    ...((to && !href
      ? {
          to,
        }
      : {}) as Partial<{
      to: Props['to']
    }>),
    ...((href && !to
      ? {
          href,
        }
      : {}) as Partial<{
      href: Props['href']
    }>),
  }

  return (
    <ListItem
      button
      classes={{ root: menuItemClasses }}
      component={component}
      data-testid="nav-menu-item"
      disabled={isDisabled}
      email={email}
      onClick={onClick}
      role="button"
      selected={isSelected}
      target={target}
      {...destinationProp}
    >
      <ListItemIcon classes={{ root: classes.leftListItemIconStyles }}>
        {LeftIcon && (
          <LeftIcon
            className={`${
              isSelected && showImprovedNavigation ? classes.iconSelected : ''
            } ${classes.iconStyles}`}
          />
        )}
      </ListItemIcon>
      <ListItemText
        classes={{ primary: listItemTextClasses }}
        primary={children}
      />
      <ListItemIcon classes={{ root: classes.rightListItemIconStyles }}>
        {RightIcon && <RightIcon classes={{ root: classes.iconStyles }} />}
      </ListItemIcon>
    </ListItem>
  )
}

export default NavMenuItem
