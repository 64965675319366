import type { CSSProperties } from 'react'
import StyleDefinitions from './definitions'

const { avatarBorder } = StyleDefinitions.colorPalette // These are not consistent with the default material avatar.

const styles: { [key: string]: CSSProperties } = {
  container: {
    borderRadius: '50%',
    boxShadow: `0 0 1px 0px ${avatarBorder} inset, 0 0 0px 1px ${avatarBorder}`,
    display: 'inline-block',
    overflow: 'hidden',
  },
  placeholderContainer: {
    borderRadius: '50%',
    boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.24)',
    display: 'inline-block',
    overflow: 'hidden',
  },
  innerWrapper: {
    border: 0,
    borderRadius: '50%',
    float: 'left',
    margin: 0,
    opacity: 0,
    overflow: 'hidden',
    transition: 'opacity 1s',
  },
  image: {
    borderRadius: 'unset',
    border: 0,
    height: 'auto',
    width: 'auto',
  },
}

export default styles
