import React, { useState, useMemo } from 'react'

import useCenterFloor from 'visual_directory/hooks/useCenterFloor'
import useSelectResource from 'visual_directory/components/explorer/RenderedFloor/useSelectResource'

import type { RosterItem as Item } from './useData'
import Subheader from './Subheader'
import Footer from './Footer'
import GroupedList, { ListItem, groupByLastName } from './GroupedList'
import useStyles from './useStyles'

type Props = {
  items: ReadonlyArray<Item>
  checkInEnabled: boolean
  checkedInCount: number
  expectedCount: number
  header: JSX.Element
  loading: boolean
  loadMore: () => void
  searchTerm: string | null
  onSearchTermChange: (searchTerm: string | null) => void
}

const itemId = (item: Item) =>
  [item.lastName, item.firstName, item.seat.id].join()

const Layout = ({
  items,
  checkInEnabled,
  checkedInCount,
  expectedCount,
  header,
  loading,
  loadMore,
  searchTerm,
  onSearchTermChange,
}: Props): JSX.Element => {
  const classes = useStyles()
  const centerFloor = useCenterFloor()

  const selectResource = useSelectResource()

  const [selectedItemId, setSelectedItemId] = useState<
    string | null | undefined
  >(null)

  const { groupNames, groupCounts, sortedRosterItems } = useMemo(
    () => groupByLastName(items),
    [items],
  )

  const initialLoad = loading && expectedCount === 0
  const isSearch = Boolean(searchTerm)
  const hasFooter = !isSearch && (initialLoad || items.length > 0)

  const handleItemClick = (item: Item) => {
    centerFloor()
    setSelectedItemId(itemId(item))

    selectResource({
      id: item.seat.id,
      type: 'seat',
      floorId: item.seat.floor.id,
    })
  }

  return (
    <div className={classes.root} data-testid="who-is-in-roster">
      {header}
      <Subheader
        onSearchTermChange={onSearchTermChange}
        searchTerm={searchTerm}
      />
      <GroupedList
        groupCounts={groupCounts}
        groupNames={groupNames}
        initialLoad={initialLoad}
        loading={loading}
        loadMore={loadMore}
        renderItem={(item: Item) => (
          <ListItem
            bookingTime={item.bookingTime}
            checkedIn={item.hasCheckedIn}
            floorLabel={item.seat.floor.label}
            fullName={`${item.firstName} ${item.lastName}`}
            highlightedText={searchTerm}
            onClick={() => handleItemClick(item)}
            photo={item.photo}
            seatLabel={item.seat.label}
            selected={itemId(item) === selectedItemId}
          />
        )}
        rosterItems={sortedRosterItems}
      />
      {hasFooter && (
        <Footer
          checkedInCount={checkedInCount}
          checkInEnabled={checkInEnabled}
          expectedCount={expectedCount}
          loading={initialLoad}
        />
      )}
    </div>
  )
}

export default Layout
