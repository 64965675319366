import { medium } from 'common/constants/viewport'
import useWindowResize from 'common/hooks/useWindowResize'

import navbarHeight from 'visual_directory/constants/navbarHeight'

const getNavbarHeight = ({ innerWidth }: { innerWidth: number }) =>
  innerWidth >= medium.min ? navbarHeight.desktop : navbarHeight.mobile

const useNavbarHeight = (): number => useWindowResize<number>(getNavbarHeight)

export default useNavbarHeight
