// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import cond from 'lodash/fp/cond'
import identity from 'lodash/fp/identity'
import reverse from 'lodash/fp/reverse'
import stubTrue from 'lodash/fp/stubTrue'
import { Point, Polygon } from 'pixi.js-legacy'
import type { Points } from 'common/types/points'

export function createLines(points: Points) {
  // Append the initial point to close the shape
  const shapePoints = points.concat([points[0]])

  return shapePoints.map<Points>((element, index) =>
    shapePoints.slice(index, index + 2),
  )
}
export function createPolygon(points: Points) {
  return new Polygon(points.map((point) => new Point(point[0], point[1])))
}

/* @ts-expect-error auto-src: strict-conversion */
const outermostCoordinate = (mapper, predicate) => (room) =>
  room.get('points').map(mapper).reduce(predicate)

export const leftMostCoordinate = outermostCoordinate(
  /* @ts-expect-error auto-src: strict-conversion */
  (point) => point.get(0),
  /* @ts-expect-error auto-src: strict-conversion */
  (x, accumulative) => (x < accumulative ? x : accumulative),
)
export const rightMostCoordinate = outermostCoordinate(
  /* @ts-expect-error auto-src: strict-conversion */
  (point) => point.get(0),
  /* @ts-expect-error auto-src: strict-conversion */
  (x, accumulative) => (x > accumulative ? x : accumulative),
)
export const topMostCoordinate = outermostCoordinate(
  /* @ts-expect-error auto-src: strict-conversion */
  (point) => point.get(1),
  /* @ts-expect-error auto-src: strict-conversion */
  (y, accumulative) => (y < accumulative ? y : accumulative),
)
export const bottomMostCoordinate = outermostCoordinate(
  /* @ts-expect-error auto-src: strict-conversion */
  (point) => point.get(1),
  /* @ts-expect-error auto-src: strict-conversion */
  (y, accumulative) => (y > accumulative ? y : accumulative),
)
/* @ts-expect-error auto-src: strict-conversion */
export const boundingBox = (room) => {
  const left = leftMostCoordinate(room)
  const top = topMostCoordinate(room)

  return {
    height: bottomMostCoordinate(room) - top,
    left,
    top,
    width: rightMostCoordinate(room) - left,
  }
}

// Adapted from
// https://stackoverflow.com/questions/451426/how-do-i-calculate-the-area-of-a-2d-polygon/717367#717367
/* @ts-expect-error auto-src: strict-conversion */
const areClockwise = (points) => {
  const MINIMUM_POINTS = 3
  const pointsLength = points.length

  if (pointsLength < MINIMUM_POINTS) {
    return 0
  }

  const closedPoints = points.concat([points[0], points[1]])
  let twiceDeterminant = 0

  for (let i = 1; i <= pointsLength; i += 1) {
    twiceDeterminant +=
      closedPoints[i][0] * (closedPoints[i + 1][1] - closedPoints[i - 1][1])
  }

  return twiceDeterminant > 0
}

export const reversePointsIfClockwise = cond([
  /* @ts-expect-error auto-src: non-strict-conversion */
  [areClockwise, reverse],
  [stubTrue, identity],
])
export const reversePointsIfNotClockwise = cond([
  /* @ts-expect-error auto-src: non-strict-conversion */
  [areClockwise, identity],
  [stubTrue, reverse],
])
