import StyleConstants from 'common/components/styles/definitions'

export default {
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  highlightedText: {
    '& > mark': {
      backgroundColor: StyleConstants.highlightedSearchResult,
    },
    '& > span': {
      fontWeight: 600,
    },
  },
}
