export const aspectRatios = {
  LANDSCAPE: 'LANDSCAPE',
  PORTRAIT: 'PORTRAIT',
}
export const xsmall = {
  min: 0,
  max: 600,
}
export const small = {
  min: xsmall.max,
  max: 850,
}
export const medium = {
  min: small.max,
  max: 1280,
}
export const large = {
  min: medium.max,
  max: 1600,
}
export const xlarge = {
  min: large.max,
  max: Infinity,
}
export const portraitHandset = {
  aspectRatio: aspectRatios.PORTRAIT,
  min: 0,
  max: 600,
}
export const portraitTablet = {
  aspectRatio: aspectRatios.PORTRAIT,
  min: portraitHandset.max,
  max: 850,
}
export const landscapeHandset = {
  aspectRatio: aspectRatios.LANDSCAPE,
  min: 480,
  max: 960,
}
export const landscapeTablet = {
  aspectRatio: aspectRatios.LANDSCAPE,
  min: landscapeHandset.max,
  max: 1280,
}
