import { useMemo } from 'react'

import useAppSelector from 'visual_directory/hooks/useAppSelector'
import type { Site } from './types'

const useSite = (siteId: string | number): Site | undefined => {
  const sites = useAppSelector((state) => state.floorListStore.get('sites'))

  return useMemo(() => {
    const siteIdInt = Number(siteId)
    const resultSite = sites
      .find((site) => site.get('id') === siteIdInt)
      ?.toJS()

    return resultSite && { ...resultSite, id: resultSite.id.toString() }
  }, [sites, siteId])
}

export default useSite
