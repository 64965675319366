import React from 'react'
import Chip from '@material-ui/core/Chip'
import PeopleIcon from '@material-ui/icons/People'

import useStyles from './useStyles'

type Props = {
  capacity: number
}

const Capacity = ({ capacity }: Props): JSX.Element => {
  const classes = useStyles()

  return <Chip classes={classes} icon={<PeopleIcon />} label={capacity} />
}

export default Capacity
