import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { Map } from 'immutable'

import useAppDispatch from 'visual_directory/hooks/useAppDispatch'
import { selectResource } from 'visual_directory/actions/resource'
import useSelectedResource from 'visual_directory/hooks/useSelectedResource'
import { makeResourcePath } from 'visual_directory/lib/paths'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectResourceFn = (resource: any) => void

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const resourceMapToObj = (resourceMap: any) => {
  if (resourceMap instanceof Map) {
    // @ts-expect-error auto-src: non-strict-conversion
    return resourceMap.toJS()
  }

  return resourceMap
}

const useSelectResource = (): SelectResourceFn => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { resource: selectedResource } = useSelectedResource()

  return useCallback(
    (resourceMap) => {
      const resourceObj = resourceMapToObj(resourceMap)
      const resourceId = resourceObj.timedIdentifier || resourceObj.id || -1

      const resource = {
        resourceId: parseInt(resourceId, 10),
        resourceType: resourceObj.type,
      }

      const resourcePath = makeResourcePath(resourceMap)

      // Selection of a resource should not create an entry in the history.
      // However, we need to preserve the path which was in history before
      // the first selection in order to make backward navigation work properly.
      if (selectedResource) {
        history.replace(resourcePath)
      } else {
        history.push(resourcePath)
      }

      dispatch(selectResource(resource))
    },
    [dispatch, history, selectedResource],
  )
}

export default useSelectResource
