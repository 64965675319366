// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
type Dimension = {
  height: number
  width: number // FIXME: Convert this to an exact object
}
type Options = {
  floorDimensions: Dimension
  windowDimensions: Dimension
}
export default function scaleFloorToWindow({
  floorDimensions,
  windowDimensions,
}: Options) {
  if (
    windowDimensions.width / windowDimensions.height >
    floorDimensions.width / floorDimensions.height
  ) {
    return windowDimensions.height / floorDimensions.height
  }

  return windowDimensions.width / floorDimensions.width
}
