// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ActionTypes from '../constants/actionTypes'

/* @ts-expect-error auto-src: noflow */
export function setFlowSelectorState(open) {
  return {
    type: ActionTypes.FlowSelector.SET,
    open,
  }
}
export default function toggleFlowSelector() {
  /* @ts-expect-error auto-src: noflow */
  return (dispatch, getState) => {
    const open = !getState().flowSelector.get('open')

    dispatch(setFlowSelectorState(open))
  }
}
export function hideFlowSelector() {
  return {
    type: ActionTypes.FlowSelector.HIDE,
  }
}
export function showFlowSelector() {
  return {
    type: ActionTypes.FlowSelector.SHOW,
  }
}
