import React from 'react'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'

import useStyles from './useStyles'

type Props = {
  onClick: () => void
}

const TodayButton = ({ onClick }: Props): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation('common', { keyPrefix: 'common.calendar' })

  return (
    <Button
      classes={classes}
      data-testid="today-button"
      onClick={onClick}
      variant="outlined"
    >
      {t('today')}
    </Button>
  )
}

export default TodayButton
