// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any */
import isFinite from 'lodash/isFinite'
import { createSelector } from 'reselect'
import type { OutputSelector } from 'reselect'
import { List } from 'immutable'
import scaleFloorToWindow from 'common/lib/scaleFloorToWindow'
import {
  unsetFloorPlanBoundingBox,
  selectorZoomStatuses,
} from 'common/constants/renderedFloor'
import type * as state from 'visual_directory/reducers'

const emptyList = List<any>()

/* @ts-expect-error auto-src: strict-conversion */
const getShouldShowOverlayLabels = ({ settings }) =>
  settings.showFloorOverlayLabels

/* @ts-expect-error auto-src: strict-conversion */
const getOverlayLabels = ({ selectedFloor }) =>
  selectedFloor.get('overlayLabels')

/* @ts-expect-error auto-src: non-strict-conversion */
type TState = $ObjMap<typeof state, () => any>
export const floorOverlayLabelsSelector: OutputSelector<
  {
    settings: any
    selectedFloor: any
  },
  any,
  any
> = createSelector(
  [getShouldShowOverlayLabels, getOverlayLabels],
  (shouldShowFloorOverlayLabels, overlayLabels) =>
    shouldShowFloorOverlayLabels ? overlayLabels : emptyList,
)
const INITIAL_MAX_ZOOM = 3

export const calculateZoomSelector: OutputSelector<TState, any, any> =
  createSelector(
    ({ floorStore }) => floorStore.get('floorPlanBoundingBox'),
    ({ floorStore }) => floorStore.get('iconScale'),
    ({ floorStore }) => floorStore.get('labelZoomThreshold'),
    ({ floorStore }) => floorStore.get('stageHeight'),
    ({ floorStore }) => floorStore.get('stageWidth'),
    ({ floorStore }) => floorStore.get('stageLeft'),
    ({ zoomIngredients }) =>
      zoomIngredients.getIn(['zoomIngredients', 'buttonDeltas']),
    ({ zoomIngredients }) =>
      zoomIngredients.getIn(['zoomIngredients', 'centerToDeltas']),
    ({ zoomIngredients }) =>
      zoomIngredients.getIn(['zoomIngredients', 'pinchDeltas']),
    ({ zoomIngredients }) =>
      zoomIngredients.getIn(['zoomIngredients', 'pinFocusDelta']),
    ({ zoomIngredients }) =>
      zoomIngredients.getIn(['zoomIngredients', 'boxFocusDeltas']),
    ({ zoomIngredients }) =>
      zoomIngredients.getIn(['zoomIngredients', 'snapBackDeltas']),
    ({ zoomIngredients }) =>
      zoomIngredients.getIn(['zoomIngredients', 'wheelZoomDeltas']),
    /* @ts-expect-error auto-src: non-strict-conversion */
    ({ zoomIngredients }) =>
      zoomIngredients.getIn(['zoomIngredients', 'doubleClickDeltas']),
    /* @ts-expect-error auto-src: strict-conversion */
    ({ zoomIngredients }) =>
      zoomIngredients.getIn(['zoomIngredients', 'resizeCorrections']),
    (
      /* @ts-expect-error auto-src: strict-conversion */
      floorPlanBoundingBox,
      /* @ts-expect-error auto-src: strict-conversion */
      iconScale,
      /* @ts-expect-error auto-src: strict-conversion */
      labelZoomThreshold,
      /* @ts-expect-error auto-src: strict-conversion */
      stageHeight,
      /* @ts-expect-error auto-src: strict-conversion */
      stageWidth,
      /* @ts-expect-error auto-src: strict-conversion */
      stageLeft,
      /* @ts-expect-error auto-src: strict-conversion */
      buttonDeltas,
      /* @ts-expect-error auto-src: strict-conversion */
      centerToDeltas,
      /* @ts-expect-error auto-src: strict-conversion */
      pinchDeltas,
      /* @ts-expect-error auto-src: strict-conversion */
      pinFocusDelta,
      /* @ts-expect-error auto-src: strict-conversion */
      boxFocusDeltas,
      /* @ts-expect-error auto-src: strict-conversion */
      snapBackDeltas,
      /* @ts-expect-error auto-src: strict-conversion */
      wheelZoomDeltas,
      /* @ts-expect-error auto-src: strict-conversion */
      doubleClickDeltas,
      /* @ts-expect-error auto-src: strict-conversion */
      resizeCorrections,
    ) => {
      const percentToDecimalDivisor = 100
      const defaultMinZoomFitToVisibleAreaPercent = 80
      const minZoomFitToVisibleAreaFactor =
        floorPlanBoundingBox === unsetFloorPlanBoundingBox
          ? 1
          : defaultMinZoomFitToVisibleAreaPercent / percentToDecimalDivisor
      const fullScreenZoomLevel = scaleFloorToWindow({
        floorDimensions: floorPlanBoundingBox,
        windowDimensions: {
          height: stageHeight || 0,
          width: stageWidth - stageLeft || 0,
        },
      })
      const minZoom = minZoomFitToVisibleAreaFactor * fullScreenZoomLevel
      const iconScaleMaxZoom = INITIAL_MAX_ZOOM / iconScale
      // `fullScreenZoomLevel` and `labelZoomThreshold` may not be set yet:
      const maxZoom =
        !isFinite(labelZoomThreshold) || !isFinite(fullScreenZoomLevel)
          ? iconScaleMaxZoom
          : Math.max(iconScaleMaxZoom, fullScreenZoomLevel * labelZoomThreshold)

      return {
        min: minZoom,
        max: maxZoom,
        current:
          minZoom +
          buttonDeltas +
          centerToDeltas +
          pinchDeltas +
          pinFocusDelta +
          boxFocusDeltas +
          snapBackDeltas +
          wheelZoomDeltas +
          doubleClickDeltas +
          resizeCorrections,
      }
    },
  )
type TCenteringSelectorProps = {
  zoomWhenCentered: number
}
/* @ts-expect-error auto-src: non-strict-conversion */
export const calculateCenteringOffset: OutputSelector<
  TState,
  TCenteringSelectorProps,
  any
> = createSelector(
  ({ floorStore }) => floorStore.get('stageWidth'),
  ({ floorStore }) => floorStore.get('stageHeight'),
  ({ floorStore }) => floorStore.get('stageLeft'),
  ({ floorStore }) => floorStore.get('floorPlanBoundingBox'),
  /* @ts-expect-error auto-src: strict-conversion */
  (_, { zoomWhenCentered }) => zoomWhenCentered,
  (
    stageWidth,
    stageHeight,
    stageLeft,
    floorPlanBoundingBox,
    zoomWhenCentered,
  ) => {
    const halfBoxWidth = floorPlanBoundingBox.width / 2
    const halfBoxHeight = floorPlanBoundingBox.height / 2
    const boundingBoxCenter = {
      x: floorPlanBoundingBox.xOffset + halfBoxWidth,
      y: floorPlanBoundingBox.yOffset + halfBoxHeight,
    }
    const boundingBoxCenterOnStage = {
      x: boundingBoxCenter.x * zoomWhenCentered,
      y: boundingBoxCenter.y * zoomWhenCentered,
    }
    const stageCenter = {
      x: (stageLeft + stageWidth) / 2,
      y: stageHeight / 2,
    }

    return {
      x: stageCenter.x - boundingBoxCenterOnStage.x,
      y: stageCenter.y - boundingBoxCenterOnStage.y,
    }
  },
)
type TPositionSelectorProps = {
  centeringOffset: any
}
/* @ts-expect-error auto-src: non-strict-conversion */
export const calculatePositionSelector: OutputSelector<
  TState,
  TPositionSelectorProps,
  any
> = createSelector(
  ({ positionIngredients }) =>
    positionIngredients.getIn(['positionIngredients', 'panDeltas']),
  ({ positionIngredients }) =>
    positionIngredients.getIn(['positionIngredients', 'pinchDeltas']),
  ({ positionIngredients }) =>
    positionIngredients.getIn(['positionIngredients', 'pinFocusOffset']),
  ({ positionIngredients }) =>
    positionIngredients.getIn(['positionIngredients', 'boxFocusOffset']),
  ({ positionIngredients }) =>
    positionIngredients.getIn(['positionIngredients', 'zoomCorrections']),
  ({ positionIngredients }) =>
    positionIngredients.getIn(['positionIngredients', 'resizeCorrections']),
  ({ positionIngredients }) =>
    positionIngredients.getIn(['positionIngredients', 'centerToDeltas']),
  ({ positionIngredients }) =>
    positionIngredients.getIn(['positionIngredients', 'revealDeltas']),
  /* @ts-expect-error auto-src: strict-conversion */
  (_, { centeringOffset }) => centeringOffset,
  (
    panDeltas,
    pinchDeltas,
    pinFocusOffset,
    boxFocusOffset,
    zoomCorrections,
    centerToDeltas,
    resizeCorrections,
    revealDeltas,
    centeringOffset,
  ) => ({
    x:
      centeringOffset.x +
      panDeltas.x +
      pinchDeltas.x +
      pinFocusOffset.x +
      boxFocusOffset.x +
      centerToDeltas.x +
      zoomCorrections.x +
      resizeCorrections.x +
      revealDeltas.x,
    y:
      centeringOffset.y +
      panDeltas.y +
      pinchDeltas.y +
      pinFocusOffset.y +
      boxFocusOffset.y +
      centerToDeltas.y +
      zoomCorrections.y +
      resizeCorrections.y +
      revealDeltas.y,
  }),
)

export const isResourceSelectorZoomCompleted = createSelector<
  TState,
  string,
  boolean
>(
  ({ floorStore }) => floorStore.get('selectorZoomStatus'),
  (status) =>
    status === selectorZoomStatuses.ALMOST_FINISHED ||
    status === selectorZoomStatuses.FINISHED,
)
