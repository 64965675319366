import moment from 'moment-timezone'

import { timeZoneAgnosticDateFormat } from 'common/constants/time'

import type { Options } from '../types'

import type { Booking } from './types'

const BOOKING_STATUS_PENDING = 'pending'

export const isPending = (status?: string): boolean =>
  status === BOOKING_STATUS_PENDING

export const timeZoneAgnosticMoment = (time: string): moment.Moment =>
  moment(time, timeZoneAgnosticDateFormat)

export const isPhotoVisible = (
  booking: Booking | null,
  isOneToOneRestricted: boolean,
): boolean =>
  Boolean(booking && !booking?.concealEmployeeInfo) || isOneToOneRestricted

export const isToday = (
  time: string | null | undefined,
  { currentTime, timeZone }: Options,
): boolean => {
  if (!time) {
    return true
  }

  /* @ts-expect-error src: types added to moment-timezone */
  const timeInTimeZone = timeZoneAgnosticMoment(time).tz(timeZone)
  /* @ts-expect-error src: types added to moment-timezone */
  const currentTimeInTimeZone = moment(currentTime).tz(timeZone)

  return timeInTimeZone.isSame(currentTimeInTimeZone, 'day')
}
