// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
import React from 'react'
import { SvgIcon } from '@material-ui/core'

type Props = {
  className: string
}

const CreateRequestIcon = (props: Props) => (
  <SvgIcon {...props} viewBox="0 0 30 24">
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-165.000000, -337.000000)">
        <g transform="translate(165.000000, 337.000000)">
          <path
            d="M27,12 C27,10.35 28.35,9 30,9 L30,3 C30,1.35 28.65,0 27,0 L3,0 C1.35,0 0.015,1.35 0.015,3 L0.015,9 C1.665,9 3,10.35 3,12 C3,13.65 1.665,15 0,15 L0,21 C0,22.65 1.35,24 3,24 L27,24 C28.65,24 30,22.65 30,21 L30,15 C28.35,15 27,13.65 27,12 L27,12 Z"
            fill="currentColor"
          />
          <g
            fill="#FFFFFF"
            opacity="0.9"
            transform="translate(8.387695, 3.504878)"
          >
            <polyline
              fillOpacity="0.65"
              points="4.32297677 14.6015127 8.38729336 12.3773736 8.41786554 7.83209325 4.35546827 10.0570296 4.32297677 14.6015127"
            />
            <polyline
              fillOpacity="0.65"
              points="8.85053717 12.0217877 12.9136199 9.79751573 12.944055 5.25236828 8.88165772 7.47664026 8.85053717 12.0217877"
            />
            <polyline
              fillOpacity="0.65"
              points="8.81119092 17.0915439 12.874822 14.8667404 12.9055313 10.3214601 8.84107762 12.5462636 8.81119092 17.0915439"
            />
            <polyline points="0.173524211 2.30802162 4.19767199 4.59740462 8.26952881 2.34230457 4.24428427 0.0529215689 0.173524211 2.30802162" />
            <polygon points="8.67286213 7.1255723 12.7443077 4.87047224 8.72002278 2.58108925 4.64857725 4.8361893" />
            <polyline points="0.0648079231 7.40940317 4.08922989 9.69945057 8.15944157 7.44368611 4.1351567 5.15430312 0.0648079231 7.40940317" />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default CreateRequestIcon
