import type { RecordOf } from 'immutable'
import type { match as Match } from 'react-router'
import createReducer from 'common/lib/createReducer'
import CommonActionTypes from 'common/constants/actionTypes'
import SelectedResource from 'visual_directory/records/selectedResource'
import type { SelectedResourceShape } from 'visual_directory/records/selectedResource'
import singularizeResourceType from 'visual_directory/lib/singularizeResourceType'
import ActionTypes from 'visual_directory/constants/actionTypes'

export const nullResourceId = -1

export const initialState = SelectedResource()

interface NewSelectedResource {
  floorGroupId: string
  resourceId: number
  resourceType: string
}

export type SelectedResourceState = RecordOf<SelectedResourceShape>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Action = any

export default createReducer<SelectedResourceState, Action>(initialState, {
  [ActionTypes.Resource.SELECTED](
    selectedResource,
    newResource: NewSelectedResource,
  ) {
    const { resourceId, resourceType, floorGroupId } = newResource

    return initialState.mergeWith(
      (defaultVal, newVal) => (newVal === undefined ? defaultVal : newVal),
      {
        id: resourceId,
        type: resourceType,
        floorGroupId,
      },
    )
  },

  [ActionTypes.Resource.BOOKING_FAILED](state) {
    return state.set('processing', false)
  },

  [CommonActionTypes.Resource.IMAGE_FAILED](state) {
    return state.set('imageFailed', true)
  },

  [ActionTypes.Resource.BOOKING_REQUEST_STARTED](state) {
    return state.set('processing', true)
  },

  [ActionTypes.Resource.BOOKING_REQUEST_FINISHED](state) {
    return state.set('processing', false)
  },

  [ActionTypes.Resource.CLICKED_IN_SHAPE](state, { resourceId, x, y }) {
    return state.set('lastClickInShape', {
      resourceId: Number(resourceId),
      x,
      y,
    })
  },

  [ActionTypes.Resource.RESET]() {
    return initialState
  },

  [ActionTypes.FloorPicker.ITEM_SELECTED]() {
    return initialState
  },

  [ActionTypes.BrowsePanel.ITEM_SELECTED](_, { resource }) {
    return SelectedResource(resource.toJS())
  },

  [ActionTypes.Route.RECEIVE_MATCH](
    state,
    {
      match: {
        params: { resourceId, resourceType } = {} as {
          resourceId: string
          resourceType: string
        },
      },
    }: { match: Match<{ resourceId: string; resourceType: string }> },
  ) {
    if (
      parseInt(resourceId, 10) !== state.id ||
      singularizeResourceType(resourceType) !== state.type
    ) {
      return initialState
    }

    return state
  },
})
