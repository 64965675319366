import { useState, useEffect } from 'react'
import { StatusCodes } from 'http-status-codes'

export default function useIsSessionExpired(): boolean | null | undefined {
  const [isSessionExpired, setIsSessionExpired] = useState<
    boolean | null | undefined
  >(null)

  useEffect(() => {
    fetch('/ping/session', {
      credentials: 'same-origin',
    }).then((data) => {
      setIsSessionExpired(data.status === StatusCodes.UNAUTHORIZED)
    })
  }, [])

  return isSessionExpired
}
