import React from 'react'
import {
  Today as CalendarIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'

import classNames from 'classnames'
import useStyles from './useStyles'

type StyledElements = 'button'
type Props = {
  isDisabled?: boolean
  isOpen?: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
  children: React.ReactNode
  classes?: Record<StyledElements, string>
}

const DropdownButton = ({
  onClick,
  isOpen,
  isDisabled = false,
  children,
  classes: overwrittenClasses,
}: Props): JSX.Element => {
  const classes = useStyles({ isDisabled })
  const Icon = isOpen ? ExpandLessIcon : ExpandMoreIcon

  return (
    <div className={classes.root}>
      <button
        className={classNames([classes.button, overwrittenClasses?.button])}
        data-disabled={isDisabled}
        data-testid="input-button"
        disabled={isDisabled}
        onClick={onClick}
        type="button"
      >
        <CalendarIcon className={classes.icon} data-testid="calendar-icon" />
        <div className={classes.content}>{children}</div>
        {!isDisabled && (
          <Icon
            className={`${classes.icon} ${classes.expandIcon}`}
            data-testid="caret-icon"
          />
        )}
      </button>
    </div>
  )
}

export default DropdownButton
