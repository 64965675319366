import React from 'react'
import Definitions from '../../constants/definitions'
import StyleConstants from '../styles/definitions'
import Avatar from './Avatar'
import type { Props as AvatarProps } from './Avatar'

type Props = Pick<AvatarProps, 'containerStyle' | 'innerWrapperOverrides'> & {
  size?: AvatarProps['size']
  photo?: AvatarProps['src']
  fallbackPhotoSize: 'large' | 'thumb'
}

const EmployeeAvatar = ({
  containerStyle,
  fallbackPhotoSize,
  innerWrapperOverrides,
  size = StyleConstants.employeePhoto.default.size,
  photo,
}: Props): JSX.Element => (
  <Avatar
    containerStyle={containerStyle}
    fallbackSrc={Definitions.missingEmployeeImage(fallbackPhotoSize)}
    innerWrapperOverrides={innerWrapperOverrides}
    size={size}
    src={photo}
  />
)

export default EmployeeAvatar
