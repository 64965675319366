// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import moment from 'moment'
import { Record } from 'immutable'

export const BookingRequestRecord = Record<any>({
  status: null,
  resolvedAt: null,
  resolvedBy: null,
})
export const bookingRequestRecordFromJson = (json: any) => {
  if (!json) {
    return null
  }

  /* @ts-expect-error auto-src: non-strict-conversion */
  return BookingRequestRecord<any>({
    ...json,
    resolvedAt: json.resolvedAt && moment(json.resolvedAt),
  })
}
/* @ts-expect-error auto-src: non-strict-conversion */
const bookingRequestInstance = BookingRequestRecord<any>()

export type BookingRequestInstance = typeof bookingRequestInstance
