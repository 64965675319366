// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import Immutable, { isImmutable } from 'immutable'
import findKey from 'lodash/findKey'
import {
  addNewLines,
  isBookableAssignment,
  isRoom,
  isSeat,
} from 'common/lib/resource'
import { LOCATION_RESOURCE_KEY } from '../constants/requestForm'
import { canShowDeskBookingForm } from '../records/deskBookingUserPermissions'

// we use a different form of the type in the path or url
const MAP_TYPE_TO_PATH_TYPE_STRING = {
  employee: 'employees',
  neighborhood: 'neighborhoods',
  pin: 'pins',
  room: 'rooms',
  seat: 'seats',
  share: 'shares',
  utility: 'utilities',
}

/* @ts-expect-error auto-src: noflow */
export function resourcePath(type, id) {
  /* @ts-expect-error auto-src: noflow */
  if (!MAP_TYPE_TO_PATH_TYPE_STRING[type]) {
    throw new Error(`No ${type} entry`)
  }

  /* @ts-expect-error auto-src: noflow */
  const pathType = MAP_TYPE_TO_PATH_TYPE_STRING[type]

  return `/${pathType}/${id}`
}

/* @ts-expect-error auto-src: noflow */
export function resourceType(path) {
  const type = findKey(MAP_TYPE_TO_PATH_TYPE_STRING, (value) => value === path)

  if (!type) {
    throw new Error(`No entry for "${path}"`)
  }

  return type
}

/* @ts-expect-error auto-src: noflow */
export function resourceSecondaryText(resource) {
  const floorLabel =
    resource.getIn(['floor', 'label']) || resource.get('floorLabel')
  const roomLabel =
    resource.getIn(['room', 'label']) || resource.get('roomLabel')
  const roomConstruct =
    resource.getIn(['room', 'construct']) || resource.get('roomConstruct')

  // Skip room label for seats or utilities in a share
  // because their label is always the same as the share's label.
  if (
    roomLabel &&
    roomConstruct !== 'SHARE' &&
    (resource.get('type') === 'utility' || resource.get('type') === 'seat')
  ) {
    return `${roomLabel} (${floorLabel})`
  }

  return floorLabel
}

/* @ts-expect-error auto-src: noflow */
const getResourceType = (resource) =>
  isImmutable(resource) ? resource.get('type') : resource.type

/* @ts-expect-error auto-src: noflow */
export const getResourceId = (resource) =>
  isImmutable(resource) ? resource.get('id') : resource.id
/* @ts-expect-error auto-src: noflow */
export function getDeepLinkKey(resource) {
  switch (getResourceType(resource)) {
    case 'room':
    case 'share':
      return 'rid'

    case 'seat':
    case 'utility':
      return 'sid'

    case 'neighborhood':
      return 'nid'

    default:
      throw new Error(
        `Resource type "${getResourceType(
          resource,
        )}" not supported by deep-linking`,
      )
  }
}

/* @ts-expect-error auto-src: noflow */
const getLocationLabelFromPin = (pin) => {
  if (pin.get('seat')) {
    return pin.get('seat').get('label')
  }

  if (pin.get('room')) {
    const label = pin.get('room').get('label')

    return `Dropped Pin in Room: ${label}`
  }

  return 'Dropped Pin'
}

// Returns the location resource form fields used by the Rails
// controller when creating a new request.
/* @ts-expect-error auto-src: noflow */
export function toFormFields(resource) {
  const ids = {
    floorId: resource.getIn(['floor', 'id']),
    siteId: resource.getIn(['site', 'id']),
  }

  switch (resource.get('type')) {
    case 'pin':
      /* @ts-expect-error auto-src: noflow */
      ids.locationId = resource.get('id')
      /* @ts-expect-error auto-src: noflow */
      ids.locationType = 'Pin'
      /* @ts-expect-error auto-src: noflow */
      ids.locationLabel = getLocationLabelFromPin(resource)
      break

    case 'neighborhood':
      /* @ts-expect-error auto-src: noflow */
      ids.locationId = resource.get('id')
      /* @ts-expect-error auto-src: noflow */
      ids.locationType = 'Neighborhood'
      /* @ts-expect-error auto-src: noflow */
      ids.locationLabel = resource.get('name')
      break

    case 'room':
    case 'share':
      /* @ts-expect-error auto-src: noflow */
      ids.locationId = resource.get('id')
      /* @ts-expect-error auto-src: noflow */
      ids.locationType = 'Room'
      /* @ts-expect-error auto-src: noflow */
      ids.locationLabel = resource.get('label')
      break

    case 'seat':
    case 'utility':
      /* @ts-expect-error auto-src: noflow */
      ids.locationId = resource.get('id')
      /* @ts-expect-error auto-src: noflow */
      ids.locationType = 'Seat'
      /* @ts-expect-error auto-src: noflow */
      ids.locationLabel = resource.get('label')
      break

    default:
      throw new Error(`Unknown resource type "${resource.get('type')}"`)
  }

  return {
    floor_id: ids.floorId,
    /* @ts-expect-error auto-src: noflow */
    location_id: ids.locationId,
    /* @ts-expect-error auto-src: noflow */
    location_type: ids.locationType,
    /* @ts-expect-error auto-src: noflow */
    location_label: ids.locationLabel,
    site_id: ids.siteId,
  }
}

/* @ts-expect-error auto-src: noflow */
function getSeatEmployee(employeeResource) {
  const { seat, ...employee } = employeeResource

  return { ...seat, employee }
}

/* @ts-expect-error auto-src: noflow */
export function addResourceToForm(resource, changeForm) {
  changeForm(
    LOCATION_RESOURCE_KEY,
    Immutable.fromJS(
      resource.type === 'employee' ? getSeatEmployee(resource) : resource,
    ),
  )
}

export const removeImageTags = (string: string): string =>
  string.replace(/<img[^>]*>/g, '')

export const cleanResourceDescription = (
  /* @ts-expect-error auto-src: noflow */
  resource,
  description = isImmutable(resource)
    ? resource.get('description')
    : resource.description,
) => {
  const descriptionWithNewLines = addNewLines(description || '')
  const shouldRemoveImageTags =
    isImmutable(resource) &&
    isRoom(resource) &&
    resource.get('images').first() &&
    !resource.get('imageFailed')

  return shouldRemoveImageTags
    ? removeImageTags(descriptionWithNewLines)
    : descriptionWithNewLines
}

/* @ts-expect-error auto-src: noflow */
export const shouldRenderOnScenario = ({ isScenario }) => isScenario

/* @ts-expect-error auto-src: noflow */
export const shouldRenderTabs = ({ currentUser, resource }) => {
  if (!isBookableAssignment(resource.get('assignmentMethod'))) {
    return false
  }

  if (isSeat(resource) && resource.get('isInactive')) {
    return false
  }

  const permissions = currentUser.get('deskBookingUserPermissions') || {}

  return canShowDeskBookingForm(permissions)
}

export const getResourceBody = (resource: any) => {
  if (isImmutable(resource)) {
    return {
      type: resource.get('type'),
      id: resource.get('id'),
      floorId: resource.get('floorId'),
      label: resource.get('label', ''),
      isInactive: resource.getIn(['isInactive', undefined]),
    }
  }

  return { ...resource, floorId: resource.floor.id, id: Number(resource.id) }
}
