import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import type { Map as ImmutableMap } from 'immutable'

import { useVDTime } from 'visual_directory/components/VDTimeProvider'
import type { CardTypeEnum } from 'visual_directory/components/FloatingCardContainer/Card'

import FreeAddressDeskCard from './FreeAddressDeskCard'
import MoveSchedulingDeskCard from './MoveSchedulingDeskCard'
import InactiveDeskCard from './InactiveDeskCard'
import BookableDeskCard from './BookableDeskCard'
import BookableRoomCard from './BookableRoomCard'
import RoomCard from './RoomCard'
import NeighborhoodCard from './NeighborhoodCard'
import UtilityCard from './UtilityCard'

type OwnProps = {
  resourceId: string
  resourceType: CardTypeEnum
}

type StateProps = {
  timeZone: string | null | undefined
}

type Props = OwnProps & StateProps

const FloatingCardUnconnected = ({
  resourceId,
  resourceType,
  timeZone,
}: Props): JSX.Element | null => {
  const { dateTime: atTime } = useVDTime()
  const options = useMemo(
    () => ({
      atTime,
      timeZone,
      currentTime: new Date().toISOString(),
    }),
    [atTime, timeZone],
  )

  switch (resourceType) {
    case 'bookableDesk':
      return <BookableDeskCard id={resourceId} options={options} />

    case 'freeAddressDesk':
      return <FreeAddressDeskCard id={resourceId} options={options} />

    case 'moveSchedulingDesk':
      return <MoveSchedulingDeskCard id={resourceId} />

    case 'inactiveDesk':
      return <InactiveDeskCard id={resourceId} />

    case 'bookableRoom':
      return <BookableRoomCard id={resourceId} options={options} />

    case 'room':
    case 'share':
      return <RoomCard id={resourceId} />

    case 'neighborhood':
      return <NeighborhoodCard id={resourceId} />

    case 'utility':
      return <UtilityCard id={resourceId} />

    default:
      return null
  }
}

interface FloorStore {
  timeZone: string | null | undefined
}

type FloorStoreProps = keyof FloorStore

type State = {
  floorStore: ImmutableMap<FloorStoreProps, FloorStore[FloorStoreProps]>
}

export { FloatingCardUnconnected }

export default connect<StateProps, never, OwnProps, State>(
  ({ floorStore }) => ({
    timeZone: floorStore.get('timeZone'),
  }),
)(FloatingCardUnconnected)
