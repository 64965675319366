import { connect } from 'react-redux'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SafeguardIcon from '@material-ui/icons/VerifiedUser'

import { addNotification } from 'common/actions/notifications'
import NotificationConstants from 'common/constants/notification'
import NavMenuItem from 'common/components/navmenu/NavMenuItem'

type Props = {
  openSafeguardForm: (arg0: { siteId?: string }) => void
  isGuest?: boolean
  showImprovedNavigation?: boolean
}

const SafeguardFormLink = ({
  openSafeguardForm,
  isGuest,
  showImprovedNavigation,
}: Props) => {
  const { t } = useTranslation('visual_directory', {
    keyPrefix: 'visual_directory.home_menu.safeguard',
  })

  const shouldChangeStyle = !showImprovedNavigation || isGuest

  return (
    <NavMenuItem
      isBold={shouldChangeStyle}
      LeftIcon={shouldChangeStyle && SafeguardIcon}
      onClick={openSafeguardForm}
    >
      {t('form')}
    </NavMenuItem>
  )
}

export default connect(null, {
  /* @ts-expect-error auto-src: strict-conversion */
  openSafeguardForm: ({ siteId }) =>
    addNotification(NotificationConstants.Safeguard.FORM_DIALOG, {
      siteId,
    }),
  /* @ts-expect-error auto-src: Removed connect generics */
})(SafeguardFormLink)
