// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react'
import type { ContextValue } from './types'

const defaultContextValue = {
  changeDateTime: () => {},
  resetDateTime: () => {},
  dateTime: null,
  isTimeInPastInTimezone: () => false,
  loading: false,
  setLoading: () => {},
}

export default createContext<ContextValue>(defaultContextValue)
