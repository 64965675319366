import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import NavMenuItem from 'common/components/navmenu/NavMenuItem'
import SmartPhoneIcon from 'common/icons/SmartPhoneIcon'

import QRCode from './QRCode'
import {
  actionsStyles,
  dialogStyles,
  titleStyles,
  contentStyles,
} from './styles'
import { IOS_APP_LINK_ITUNES, ANDROID_APP_LINK_GOOGLE_PLAY } from './constants'

const useActionsStyles = makeStyles(actionsStyles)
const useDialogStyles = makeStyles(dialogStyles)
const useTitleStyles = makeStyles(titleStyles)
const useContentStyles = makeStyles(contentStyles)

const MobileAppDialog = ({
  customIOSAppLink,
  isIOSAppEnabled,
  isUserAnonymous,
  isOpen,
  setIsOpen,
}: {
  customIOSAppLink: string | null | undefined
  isIOSAppEnabled?: boolean
  isUserAnonymous: boolean | null | undefined
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}): JSX.Element => {
  const { t } = useTranslation('common', {
    keyPrefix: 'common.home_menu',
  })

  const actionsClasses = useActionsStyles()
  const dialogClasses = useDialogStyles()
  const titleClasses = useTitleStyles()
  const contentClasses = useContentStyles()

  const handleIOSAppClick = () => setIsOpen(true)

  const handleIOSAppModalClose = () => setIsOpen(false)

  const isAppMobileVisible = isIOSAppEnabled && !isUserAnonymous

  return (
    <>
      {isAppMobileVisible && (
        <NavMenuItem
          component="a"
          isBold
          LeftIcon={SmartPhoneIcon}
          onClick={handleIOSAppClick}
        >
          {t('mobile_app')}
        </NavMenuItem>
      )}

      <Dialog
        classes={dialogClasses}
        onClose={handleIOSAppModalClose}
        open={isOpen}
      >
        <DialogTitle classes={titleClasses}>
          Try the OfficeSpace iOS or Android Apps
        </DialogTitle>
        <DialogContent classes={contentClasses}>
          <DialogContentText classes={contentClasses}>
            1. Download the OfficeSpace app from the{' '}
            <a
              href={customIOSAppLink || IOS_APP_LINK_ITUNES}
              rel="noopener noreferrer"
              target="_blank"
            >
              App Store
            </a>
            {' or '}
            <a
              href={ANDROID_APP_LINK_GOOGLE_PLAY}
              rel="noopener noreferrer"
              target="_blank"
            >
              Google Play
            </a>
            .
          </DialogContentText>
          <DialogContentText classes={contentClasses}>
            2. Open the app and choose “Scan QR Code”.
          </DialogContentText>
          <DialogContentText classes={contentClasses}>
            3. Point your phone to this screen and capture the code:
          </DialogContentText>
          <QRCode />
        </DialogContent>
        <DialogActions classes={actionsClasses}>
          <Button color="primary" onClick={handleIOSAppModalClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MobileAppDialog
