import React from 'react'

import useStyles from './useStyles'

type Props = {
  color: string
  children: React.ReactNode
}

const Availability = ({ color, children }: Props): JSX.Element => {
  const classes = useStyles({ color })

  return <div className={classes.root}>{children}</div>
}

export default Availability
