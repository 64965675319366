// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import useStyles from './useStyles'

/* @ts-expect-error auto-src: non-strict-conversion */
const LightTooltip = React.forwardRef<any, Tooltip>(
  (tooltipProps: any, ref) => {
    const classes = useStyles()

    return <Tooltip ref={ref} {...tooltipProps} classes={classes} />
  },
)

export default LightTooltip
