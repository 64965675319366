import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router'
import { Drawer as MuiDrawer } from '@material-ui/core'

import useAppDispatch from 'visual_directory/hooks/useAppDispatch'
import { calculateStageDimensions as calculateDimensions } from 'visual_directory/actions/floor'
import Roster from '../Roster'
import EmptyRoster from '../Roster/EmptyRoster'

import useStyles from './useStyles'

const TRANSITION_DURATION = 450

type Props = {
  isOpen: boolean
  onClose: () => void
}
type Params = {
  floorId?: string
}

const Drawer = ({ isOpen, onClose }: Props): JSX.Element => {
  const classes = useStyles()
  const { floorId } = useParams<Params>()
  const dispatch = useAppDispatch()

  const calculateStageDimensions = useCallback(
    async () => dispatch(calculateDimensions()),
    [dispatch],
  )

  useEffect(() => {
    const timeout = isOpen ? TRANSITION_DURATION : 0
    const timeoutId = setTimeout(calculateStageDimensions, timeout)

    return () => clearTimeout(timeoutId)
  }, [calculateStageDimensions, isOpen])

  return (
    <MuiDrawer
      anchor="right"
      classes={classes}
      open={isOpen}
      PaperProps={{
        elevation: 1,
        className: isOpen ? 'dockingLayer' : '',
      }}
      transitionDuration={TRANSITION_DURATION}
      variant="persistent"
    >
      {isOpen && floorId && (
        <Roster currentFloorId={floorId} onClose={onClose} />
      )}
      {isOpen && !floorId && <EmptyRoster onClose={onClose} />}
    </MuiDrawer>
  )
}

export default Drawer
