import React from 'react'
import { Types } from 'common/types/seatAvailability'
import type { SeatAvailability } from 'common/types/seatAvailability'
import AlwaysLabel from './AlwaysLabel'
import DaysOfWeekLabel from './DaysOfWeekLabel'
import NeverLabel from './NeverLabel'
import WeeksOfYearLabel from './WeeksOfYearLabel'

export type Props = {
  availability: SeatAvailability
  days?: 'short' | 'long'
  includeName?: boolean
  longWeeksFormat?: 'colon' | 'parenthesis'
  longWeeksLimit?: number
  weeks?: 'short' | 'medium' | 'long' | 'none'
}
const LABELS = {
  '': () => null,
  [Types.ALWAYS]: AlwaysLabel,
  [Types.DAYS_OF_WEEK]: DaysOfWeekLabel,
  [Types.NEVER]: NeverLabel,
  [Types.WEEKS_OF_YEAR]: WeeksOfYearLabel,
}

const SeatAvailabilityLabel = ({
  availability,
  ...props
}: Props): JSX.Element => {
  const Label = LABELS[availability.type || '']

  return <Label availability={availability} {...props} />
}

export default SeatAvailabilityLabel
