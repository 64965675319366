export default {
  Browser: {
    UNSUPPORTED: 'BROWSER_UNSUPPORTED',
  },
  Dialog: {
    CONFIRM_ACTION_DIALOG: 'CONFIRM_ACTION_DIALOG',
    REDIRECT_DIALOG: 'REDIRECT_DIALOG',
    SIMPLE_DIALOG: 'GENERAL_SIMPLE_DIALOG',
  },
  HotDesk: {
    BOOKING_COMPLETE: 'BOOKING_COMPLETE',
    CANCEL_BOOKING: 'CANCEL_BOOKING',
    CONFLICTING_BOOKINGS: 'CONFLICTING_BOOKINGS',
    DELETE_BOOKING_SERIES: 'DELETE_BOOKING_SERIES',
    DISCARD_CHANGES: 'DISCARD_BOOKING_CHANGES',
    END_BOOKING: 'END_BOOKING',
    REQUEST_BOOKING: 'REQUEST_BOOKING',
  },
  RequestCreation: {
    SOMETHING_WENT_WRONG: 'REQUEST_CREATION_SOMETHING_WENT_WRONG',
    SUBMISSION_SUCCESSFUL: 'REQUEST_CREATION_SUBMISSION_SUCCESSFUL',
  },
  SiteFavorites: {
    SIGN_IN_AGAIN: 'SITE_FAVORITES_SIGN_IN_AGAIN',
  },
  MoveRequest: {
    NEW_MOVE_NOTICE: 'NEW_MOVE_NOTICE',
  },
  RoomBooking: {
    BOOKING_COMPLETE: 'ROOM_BOOKING/COMPLETE',
    BOOKING_ERROR: 'ROOM_BOOKING/ERROR',
    CANCEL_DIALOG: 'ROOM_BOOKING/CANCEL',
    END_DIALOG: 'ROOM_BOOKING/END',
    EXTEND_DIALOG: 'ROOM_BOOKING/EXTEND',
  },
  RoomBookingSearchFlow: {
    BOOK_DIALOG: 'ROOM_BOOKING_SEARCH_FLOW/BOOK_DIALOG',
  },
  Safeguard: {
    FORM_DIALOG: 'SAFEGUARD/FORM_DIALOG',
  },
}
