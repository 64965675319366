// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import CheckIcon from '@material-ui/icons/Check'
import useStyles from './useStyles'

const CheckedInIcon = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CheckIcon className={classes.icon} />
    </div>
  )
}

export default CheckedInIcon
