// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import mediumOrGreater from 'common/selectors/viewport/mediumOrGreater'

export default ({
  settings: {
    employeeDirectory: { enable },
  },
  view = window,
}: any) => enable === true && mediumOrGreater(view)
