// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react'
import DayPicker, { DateUtils } from 'common/components/DayPicker'
import type { DayModifiers, DayPickerProps } from 'common/components/DayPicker'

import moment from 'moment-timezone'
import type { Moment } from 'moment-timezone'
import Navbar from './Navbar'
import useStyles from './useStyles'

export type Props = {
  selectedDay?: Date
  now?: Moment
  untilDay?: Date
  isDayAvailable?: (day: Date) => boolean
  onChange: (day: Date) => void
  firstDayOfWeek?: number
}

const DatePicker = ({
  isDayAvailable = () => true,
  selectedDay = new Date(),
  now = moment(),
  untilDay,
  onChange,
  firstDayOfWeek = 0,
}: Props) => {
  const classes = useStyles()
  const [viewedMonth, setViewedMonth] = useState<Date>(selectedDay)
  const today = new Date(now.year(), now.month(), now.date())

  const disabledDate = (date: Date) =>
    DateUtils.isDayBefore(date, today) ||
    (untilDay && DateUtils.isDayAfter(date, untilDay)) ||
    !isDayAvailable(date)

  const modifiers = {
    [classes.disabled]: disabledDate,
    [classes.today]: today,
  }

  const renderDay = (day: Date, dayModifiers: DayModifiers) => {
    const isToday = Boolean(dayModifiers[classes.today])

    return (
      <>
        <div className="ossVD-dayDate" data-today={isToday}>
          {day.getDate()}
        </div>
        <div className="ossVD-dayDesks">42 desks</div>
      </>
    )
  }

  const handleDayClick = (day: Date, dayModifiers: DayModifiers) => {
    if (dayModifiers[classes.disabled] || dayModifiers[classes.selected]) {
      return
    }

    onChange(day)
  }

  const handleTodayClick = () => {
    const floorTime = now.toDate()

    setViewedMonth(floorTime)
    onChange(floorTime)
  }

  const handleMonthChange = (month: Date) => setViewedMonth(month)

  return (
    <div data-testid="calendar">
      <DayPicker
        classNames={classes as DayPickerProps['classNames']}
        firstDayOfWeek={firstDayOfWeek}
        fromMonth={now.toDate()}
        modifiers={modifiers}
        month={viewedMonth}
        navbarElement={<Navbar onTodayClick={handleTodayClick} />}
        onDayClick={handleDayClick}
        onMonthChange={handleMonthChange}
        renderDay={renderDay}
        selectedDays={selectedDay}
        showOutsideDays
        toMonth={untilDay}
      />
    </div>
  )
}

export default DatePicker
