import React from 'react'

import Layout from '../Layout'
import useAdjustedCardPosition from '../useAdjustedCardPosition'

import useData from './useData'

type Props = {
  id: string
}

const UtilityCard = ({ id }: Props): JSX.Element => {
  const { utility, loading } = useData({ ids: [id] })

  useAdjustedCardPosition({ skip: loading })

  if (loading) {
    return <div>loading...</div>
  }

  return <Layout title={utility.label} />
}

export default UtilityCard
