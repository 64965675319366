// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const defaultWaitMs = 250

export default class StabilizationMonitor {
  /* @ts-expect-error auto-src: noflow */
  constructor({ areValuesEqual, onStabilization, waitMs = defaultWaitMs }) {
    /* @ts-expect-error auto-src: noflow */
    this.areValuesEqual = areValuesEqual
    /* @ts-expect-error auto-src: noflow */
    this.onStabilization = onStabilization
    /* @ts-expect-error auto-src: noflow */
    this.waitMs = waitMs
    this.setTimeout()
  }

  /* @ts-expect-error auto-src: noflow */
  setNewValue(newValue) {
    /* @ts-expect-error auto-src: noflow */
    if (this.areValuesEqual(newValue, this.previousValue)) {
      return
    }

    /* @ts-expect-error auto-src: noflow */
    this.previousValue = newValue
    this.setTimeout()
  }

  setTimeout() {
    /* @ts-expect-error auto-src: noflow */
    clearTimeout(this.timer)
    /* @ts-expect-error auto-src: noflow */
    this.timer = setTimeout(this.onStabilization, this.waitMs)
  }
}
