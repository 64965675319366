import React from 'react'
import { DropdownButton } from 'visual_directory/components/DateTimePicker'

import useStyles from './useStyles'

type Props = {
  children: React.ReactNode
  isDisabled?: boolean
  isOpen?: boolean
  onClick: () => void
}

const Button = ({
  children,
  isDisabled,
  isOpen,
  onClick,
}: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <DropdownButton
      classes={classes}
      isDisabled={isDisabled}
      isOpen={isOpen}
      onClick={onClick}
    >
      {children}
    </DropdownButton>
  )
}

export default Button
