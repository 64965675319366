import React from 'react'
import CardMedia from '@material-ui/core/CardMedia'
import useStyles from './useStyles'

type Props = {
  url?: string | null | undefined
  fallback?: React.ReactNode
}

const Image = ({ url, fallback }: Props): JSX.Element | null => {
  const classes = useStyles()

  if (!url && !fallback) {
    return null
  }

  if (url) {
    return <CardMedia className={classes.root} image={url} />
  }

  return <div className={classes.root}>{fallback}</div>
}

export default Image
