import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  root: {
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    fontSize: 10,
    fontWeight: 600,
    color: '#616161',
  },
  chip: {
    marginTop: 4,
    color: '#424242',
    fontSize: 10,
    backgroundColor: '#f5f5f5',
  },
})
