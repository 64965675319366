import React from 'react'
import { Map as VisualDirectoryIcon } from '@material-ui/icons'

import NavMenuItem from 'common/components/navmenu/NavMenuItem'
import Products from 'common/constants/products'

import useStyles from '../styles'

interface Props {
  isGuest: boolean
  isOpen: boolean
  onClick: () => void
}

const VDMainBrowseByFloor = ({
  isGuest,
  isOpen,
  onClick,
}: Props): JSX.Element | null => {
  const styles = useStyles()

  if (!isGuest) {
    return null
  }

  return (
    <NavMenuItem
      component="a"
      href="/visual-directory"
      isBold
      isSelected={isOpen}
      LeftIcon={VisualDirectoryIcon}
      onClick={onClick}
    >
      {Products.VISUAL_DIRECTORY}
      <span className={styles.superscript}>®</span>
    </NavMenuItem>
  )
}

export default VDMainBrowseByFloor
