import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles<Theme, { color?: string }>({
  root: {
    alignItems: 'center',
    backgroundColor: ({ color }) => color || '#d5d5d5',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  icon: {
    color: 'white',
    height: 34,
    width: 56,
  },
})
