// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import merge from 'lodash/merge'
import { grey } from '@material-ui/core/colors'
import { miniCardHeight } from 'common/components/styles/infoCards'
import StyleConstants from './definitions'
import { resourceSheetNavbarPaddingRight } from './navbar'

const verticalPadding = 15
const horizontalPaddingOneColumn = 24
const horizontalPaddingTwoColumn = 15

export const ResourceSheetStyleConstants = {
  detailFieldIconColor: StyleConstants.color.primary,
  detailFieldIconSize: 20,
  /* @ts-expect-error auto-src: non-strict-conversion */
  detailFieldLinkColor: StyleConstants.color.link,
  maximizedDetailFieldIconSize: 24,
}
export const resourceSheet = {
  miniCard: {
    container: {
      backgroundColor: '#FFFFFF',
      boxShadow: '0px -1px 5px #d4d4d4',
      height: miniCardHeight,
      position: 'relative',
      width: '100%',
      zIndex: 100,
    },
    oneColumn: {
      padding: `${verticalPadding}px ${horizontalPaddingOneColumn}px`,
    },
    twoColumn: {
      leftColumn: {
        padding: `${verticalPadding}px 0 ${verticalPadding}px ${horizontalPaddingTwoColumn}px`,
      },
      rightColumn: {
        flex: 1,
        padding: `${verticalPadding}px ${horizontalPaddingTwoColumn}px`,
      },
    },
    seatLabel: {
      color: grey[800],
      lineHeight: '1.5',
      margin: 0,
    },
    resourceHeading: {
      color: StyleConstants.font.color.heading,
      fontSize: StyleConstants.font.size.prominent,
      fontWeight: 600,
      lineHeight: '1.5',
      marginBottom: 5,
    },
    // TODO: deprecate when legacy info cards are turned off
    resourceSubheading: {
      color: StyleConstants.font.color.minorHeading,
      fontSize: 12,
      fontWeight: StyleConstants.font.weight.normal,
      lineHeight: '1.5',
    },
    resourceSmallText: {
      color: StyleConstants.font.color.minorHeading,
      fontSize: StyleConstants.font.size.formLabelFocus,
      fontWeight: StyleConstants.font.weight.normal,
      lineHeight: '1.5',
    },
    resourceText: {
      color: StyleConstants.font.color.minorHeading,
      fontSize: StyleConstants.font.size.standard,
      fontWeight: StyleConstants.font.weight.normal,
      lineHeight: '1.5',
    },
    shareEmployee: {
      color: StyleConstants.font.color.heading,
      fontSize: StyleConstants.font.size.standard,
      lineHeight: '1.5',
      textDecoration: 'none',
    },
    viewMore: {
      wrapper: {
        marginTop: 18,
      },
      link: {
        color: StyleConstants.font.color.link,
        fontSize: StyleConstants.font.size.standard,
        fontWeight: StyleConstants.font.weight.light,
        textDecoration: 'none',
      },
    },
  },
  // side-docked sheet on rendered floor
  detailsCard: {
    container: {
      paddingTop: 0,
      position: 'relative',
    },
    detailsTop: {
      padding: `22px ${StyleConstants.infoCards.details.horizontalPadding}px 12px`,
      textAlign: 'center',
    },
    seatLabel: {
      color: grey[800],
      fontSize: 12,
      lineHeight: '1.5',
      margin: 0,
    },
    // TODO: remove .closeButton when legacy info cards are turned off
    closeButton: {
      style: {
        height: 36,
        padding: 0,
        position: 'absolute',
        right: 8,
        top: 8,
        width: 36,
        zIndex: 1,
        color: '#9E9E9E',
      },
      iconStyle: {
        height: StyleConstants.icon.iconSize,
        width: StyleConstants.icon.iconSize,
        color: '#FFFFFF',
      },
    },
    resourceIconFrameStyle: {
      display: 'block',
      margin: '1px auto',
    },
    resourceHeading: {
      color: StyleConstants.font.color.heading,
      fontSize: StyleConstants.font.size.detailHeading,
      fontWeight: 600,
      margin: '5px 0px 0px 0px',
      textAlign: 'center',
    },
    resourceSecondaryHeading: {
      color: StyleConstants.font.color.heading,
      fontSize: '14px',
      fontWeight: 600,
      textAlign: 'center',
    },
    resourceSubheading: {
      color: StyleConstants.font.color.minorHeading,
      fontSize: StyleConstants.font.size.standard,
      lineHeight: '1.5',
    },
    // hot desks label
    resourceThinHeading: {
      color: StyleConstants.font.color.heading,
      fontSize: StyleConstants.font.size.detailHeading,
      fontWeight: StyleConstants.font.weight.normal,
      textAlign: 'center',
    },
    resourceThinnerHeading: {
      fontSize: StyleConstants.font.size.smallHeading,
      margin: '0px 51px',
    },
    resourceThinnestHeading: {
      fontSize: StyleConstants.font.size.smallHeading,
      margin: '8px 12px',
    },
    resourceDescription: {
      color: '#424242',
      fontSize: StyleConstants.font.size.standard,
      lineHeight: '1.5',
      textAlign: 'left',
    },
    detailsList: {
      paddingTop: 0,
    },
    fieldInnerDivStyle: {
      paddingBottom: 10,
      paddingLeft: StyleConstants.infoCards.details.horizontalPadding,
      paddingRight: StyleConstants.infoCards.details.horizontalPadding,
      paddingTop: 9,
      alignItems: 'start',
    },
    listItemText: {
      marginTop: 2,
      marginBottom: 0,
    },
    fieldValue: {
      color: StyleConstants.font.color.heading,
      fontSize: StyleConstants.font.size.standard,
      /* @ts-expect-error auto-src: non-strict-conversion */
      fontWeight: StyleConstants.font.weight.standard,
      height: null,
      minHeight: 18,
      overflow: null,
      textDecoration: 'none',
      textOverflow: null,
      whiteSpace: null,
      fontFamily: 'Open Sans',
      marginTop: 4,
      marginBottom: 1,
      display: 'inline-block',
      width: '100%',
    },
    fieldLabel: {
      color: StyleConstants.font.color.fieldLabel,
      fontSize: StyleConstants.font.size.fieldLabel,
      lineHeight: '17px',
      margin: '0 auto',
      fontFamily: 'Open Sans',
      marginBottom: 4,
    },
    employeeAsFieldPrimary: {
      color: StyleConstants.font.color.heading,
      fontSize: StyleConstants.font.size.standard,
      fontWeight: StyleConstants.font.weight.normal,
    },
    employeeAsFieldSecondary: {
      color: StyleConstants.font.color.heading,
      fontSize: StyleConstants.font.size.standard,
      fontWeight: StyleConstants.font.weight.light,
    },
    icon: {
      // this applies to the svg tag
      iconStyle: {
        color: ResourceSheetStyleConstants.detailFieldIconColor,
        height: ResourceSheetStyleConstants.detailFieldIconSize,
        width: ResourceSheetStyleConstants.detailFieldIconSize,
      },
      // this applies to the button tag
      style: {
        boxSizing: 'content-box',
        height: ResourceSheetStyleConstants.detailFieldIconSize,
        paddingRight: 0,
        right: resourceSheetNavbarPaddingRight,
        // we want this the icon to align with the navbar
        top: '5.5px',
        // distance between icon and line above: this value + 12px padding = 17.5px
        width: ResourceSheetStyleConstants.detailFieldIconSize,
      },
    },
  },
}
// some differences between a maximized resource sheet in compact view and a side-docked sheet
const maximizedDetailsCard = merge({}, resourceSheet.detailsCard, {
  detailsTop: {
    padding: `20px ${StyleConstants.infoCards.maximizedDetails.horizontalPadding}px 15px`,
  },
  resourceHeading: {
    fontSize: StyleConstants.font.size.maxDetailHeading,
    paddingTop: 4,
  },
  resourceSubHeader: {
    fontSize: StyleConstants.font.size.maxDetailHeading,
    paddingTop: 4,
  },
  resourceDescription: {
    fontSize: StyleConstants.font.size.prominent,
  },
  resourceThinHeading: {
    fontSize: StyleConstants.font.size.maxDetailHeading,
  },
  resourceThinnerHeading: {
    fontSize: StyleConstants.font.size.prominent,
    marginTop: '38px',
  },
  fieldInnerDivStyle: {
    paddingLeft: StyleConstants.infoCards.maximizedDetails.horizontalPadding,
    paddingRight: StyleConstants.infoCards.maximizedDetails.horizontalPadding,
  },
  fieldLabel: {
    fontSize: StyleConstants.font.size.standard,
    lineHeight: '16px',
  },
  fieldValue: {
    fontSize: StyleConstants.font.size.prominent,
  },
  icon: {
    // this applies to the svg tag
    iconStyle: {
      height: ResourceSheetStyleConstants.maximizedDetailFieldIconSize,
      width: ResourceSheetStyleConstants.maximizedDetailFieldIconSize,
    },
    // this applies to the button tag
    style: {
      height: ResourceSheetStyleConstants.maximizedDetailFieldIconSize,
      right: StyleConstants.infoCards.maximizedDetails.horizontalPadding,
      width: ResourceSheetStyleConstants.maximizedDetailFieldIconSize,
    },
  },
})

/* @ts-expect-error auto-src: strict-conversion */
const detailsBranding = (options) => ({
  icon: {
    iconStyle: {
      color: options.branding.resourceSheet.iconColor,
    },
  },
})

/* @ts-expect-error auto-src: strict-conversion */
const defaultResourceSheet = (options) =>
  merge({}, resourceSheet, {
    miniCard: {
      viewMore: {
        link: {
          color: options.branding.resourceSheet.linkColor,
        },
      },
    },
    detailsCard: merge({}, detailsBranding(options), {
      detailsCard: {
        detailsTop: {
          padding: `0 ${StyleConstants.infoCards.details.horizontalPadding}px 0px`,
        },
      },
      resourceIconFrameStyle: {
        margin: '0 auto 15px',
      },
      resourceLabel: {
        display: 'flex',
        fontSize: '13px',
        marginTop: 16,
      },
    }),
    maximizedDetailsCard: merge(
      {},
      maximizedDetailsCard,
      detailsBranding(options),
    ),
  })

/* @ts-expect-error auto-src: strict-conversion */
const roomResourceSheet = (options) =>
  merge({}, defaultResourceSheet(options), {
    detailsCard: {
      // TODO: remove .closeButton when legacy info cards are turned off
      closeButton: {
        iconStyle: {
          color: '#FFFFFF',
        },
        darkIconStyle: {
          height: 24,
          width: 24,
        },
      },
    },
  })

/* @ts-expect-error auto-src: strict-conversion */
const utilityResourceSheet = (options) =>
  merge({}, defaultResourceSheet(options), {
    detailsCard: {
      detailsTop: {
        padding: `20px ${StyleConstants.infoCards.details.horizontalPadding}px 15px`,
      },
      resourceIconFrameStyle: {
        boxShadow:
          `0 0 1px 0px ${StyleConstants.color.avatarBorder} inset,` +
          `0px 2px 2px ${StyleConstants.color.avatarShadow}`,
        margin: '15px auto 15px',
      },
    },
    maximizedDetailsCard: {
      detailsTop: {
        resourceIconFrameStyle: {
          boxShadow:
            `0 0 1px 0px ${StyleConstants.color.avatarBorder} inset,` +
            `0px 2px 2px ${StyleConstants.color.avatarShadow}`,
          margin: '20px auto 0',
        },
      },
    },
  })

/* @ts-expect-error auto-src: strict-conversion */
const shareResourceSheet = (options) =>
  merge({}, roomResourceSheet(options), {
    detailsCard: {
      resourceIconFrameStyle: {
        margin: '36px auto 15px',
      },
      fieldInnerDivStyle: {
        paddingBottom: 5,
      },
      listItemText: {
        marginTop: 0,
        marginBottom: 0,
      },
      employeeAsFieldPrimary: {
        color: grey[900],
      },
      employeeAsFieldSecondary: {
        color: grey[600],
      },
      occupantsList: {
        paddingBottom: 12,
        paddingTop: 3,
      },
    },
    maximizedDetailsCard: {
      occupantsList: {
        paddingBottom: 12,
        paddingTop: 3,
      },
    },
  })

export const brandedResourceSheet = (options: any) => ({
  default: defaultResourceSheet(options),
  room: roomResourceSheet(options),
  seat: defaultResourceSheet(options),
  share: shareResourceSheet(options),
  utility: utilityResourceSheet(options),
})
export default resourceSheet
