import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

import { useWhoIsIn } from 'visual_directory/components/who_is_in'
import useAppSelector from 'visual_directory/hooks/useAppSelector'
import { roomStatus } from 'visual_directory/lib/roomBooking/roomStatus'

import ImageFallback from '../RoomCard/ImageFallback'
import Layout, { PrimaryButton, SecondaryButton } from '../Layout'
import type { Options } from '../types'
import useAdjustedCardPosition from '../useAdjustedCardPosition'

import Availability from './Availability'
import Capacity from './Capacity'
import MeetingDetails from './MeetingDetails'
import useData from './useData'

type Props = {
  id: string
  options: Options
}

const BookableRoomCard = ({ id, options }: Props): JSX.Element => {
  const data = useData({ id, atTime: options.atTime })
  const { close: closeWhoIsIn } = useWhoIsIn()

  const {
    roomBookingLeadTimeThreshold,
    minimumMeetingDuration,
    roomBookingBranding,
  } = useAppSelector(({ settings, branding }) => ({
    roomBookingLeadTimeThreshold: settings.roomBookingLeadTimeThreshold,
    minimumMeetingDuration: settings.minimumMeetingDuration,
    roomBookingBranding: branding.config.roomBookingStatus,
  }))

  useAdjustedCardPosition({ skip: data.isLoading })

  if (data.isLoading) {
    return (
      <Layout imageFallback={<ImageFallback />} title={<Skeleton />}>
        <Skeleton />
      </Layout>
    )
  }

  const {
    availableAt,
    capacity,
    nextScheduledBooking,
    scheduledBooking,
    isBookable,
    imageUrl,
    label,
    site,
  } = data

  const roomBookingStatus = roomStatus({
    availableAt,
    timeFormat: 'h:mm A',
    timeZone: site.timeZone,
    settings: {
      roomBookingLeadTimeThreshold,
      minimumMeetingDuration,
      roomBookingBranding,
    },
    nextBookingToday: nextScheduledBooking,
    isBookable,
    vdTime: options.atTime,
    currentTime: options.currentTime,
    currentBooking: scheduledBooking,
  })

  const meeting = roomBookingStatus.meeting || roomBookingStatus.upcomingMeeting

  const openInfoCard = closeWhoIsIn

  return (
    <Layout
      footerLeft={
        roomBookingStatus.isAvailableForBooking ? (
          <PrimaryButton onClick={openInfoCard}>Book Room</PrimaryButton>
        ) : (
          <SecondaryButton onClick={openInfoCard}>View More</SecondaryButton>
        )
      }
      imageFallback={<ImageFallback />}
      imageUrl={imageUrl}
      title={label}
    >
      {capacity !== null && <Capacity capacity={capacity} />}
      <Availability color={roomBookingStatus.color}>
        {roomBookingStatus.title}
      </Availability>
      {meeting && (
        <MeetingDetails
          period={roomBookingStatus.floatingCard?.formattedPeriod}
          status={roomBookingStatus.floatingCard?.title}
          title={meeting.title}
        />
      )}
    </Layout>
  )
}

export default BookableRoomCard
