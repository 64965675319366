// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import DOMPurify from 'dompurify'

/* @ts-expect-error auto-src: strict-conversion */
function decodeEntity(value) {
  const divElement = document.createElement('div')

  divElement.innerHTML = value
  const decodedValue = divElement.firstChild?.nodeValue || value

  divElement.remove()

  return decodedValue
}

export const stripAllHTMLTags = (htmlString: string, decodeValue = false) => {
  const strippedValue = DOMPurify.sanitize(htmlString, {
    USE_PROFILES: {
      html: false,
    },
  })

  return decodeValue ? decodeEntity(strippedValue) : strippedValue
}
// Strips html tags from strings but preserves <br>
// tags, to emulate how Chrome implements `Element.innerText`.
// In firefox, `Element.innerText` ignores <br> tags
// and returns a string with no line-breaks.
export const stripHTMLTags = (htmlString: string): string => {
  if (!htmlString) {
    return ''
  }

  const div = document.createElement('div')
  const textContents = htmlString.split(/<br *\/? *>/).map((flatHTMLString) => {
    div.innerHTML = flatHTMLString

    return div.textContent || div.innerText || ''
  })

  return textContents.join('\n')
}
export const stripHTMLTagsAndSplit = (
  htmlString: string,
): ReadonlyArray<string> => {
  const strippedInnerText = stripHTMLTags(htmlString)

  return strippedInnerText.split('\n').filter((line) => Boolean(line))
}

export const sanitizeHTML = (string: string) =>
  DOMPurify.sanitize(string, {
    ALLOWED_TAGS: [
      'br',
      'a',
      'b',
      'strong',
      'u',
      'i',
      'em',
      'u',
      'ul',
      'ol',
      'li',
    ],
  })
