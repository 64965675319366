// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Record } from 'immutable'

export type TEmployee = {
  fullName?: string
  email?: string
  photo?: string | null
}
const emptyEmployee: TEmployee = {
  fullName: 'Unknown Employee',
  email: 'unknown_employee@example.com',
  photo: 'no photo',
}

export type TUser = {
  anonymous: boolean | null | undefined
  defaultCompletedPresetId?: any
  defaultScheduledPresetId?: any
  email?: string | null | undefined
  fullName: string | null | undefined
  id: (number | null | undefined) | (string | null | undefined)
  role: string | null | undefined
}
const emptyUser: TUser = {
  anonymous: false,
  defaultCompletedPresetId: undefined,
  defaultScheduledPresetId: undefined,
  email: 'unknown_user@example.com',
  fullName: 'Unknown User',
  id: '-1',
  /* @ts-expect-error auto-src: non-strict-conversion */
  linkedEmployee: emptyEmployee,
  role: 'guest',
}
const User = Record<TUser>(emptyUser)

export default User
