import { makeStyles } from '@material-ui/styles'

export default makeStyles({
  submenu: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  superscript: {
    fontSize: '61.54%',
    lineHeight: '61.54%',
    paddingLeft: '2px',
    position: 'relative',
    verticalAlign: 'super',
    marginBottom: 6,
  },
})
