import WebpackerReact from 'webpacker-react'
import 'isomorphic-fetch'
import Logo from 'common/components/icons/Logo'
import MenuItemWithDropDown from './components/nav_items/MenuItemWithDropDown'
import StandaloneNavMenu from './components/StandaloneNavMenu'

WebpackerReact.setup({
  Logo,
})
WebpackerReact.setup({
  MenuItemWithDropDown,
})
WebpackerReact.setup({
  StandaloneNavMenu,
})
