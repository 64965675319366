// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import merge from 'lodash/merge'
import { navbarHeight } from 'common/components/styles/navbar'
import { miniCardHeight } from 'common/components/styles/infoCards'
import { width as dockedCardWidth } from 'common/constants/dockedCard'
import StyleConstants from 'common/components/styles/definitions'
import Definitions from '../../constants/definitions'
// This has to be greater than material-ui's AppCard value of 1100
const commonZIndex = Definitions.zIndex.dockedCard
const portraitHandsetOrTabletZIndex = 100
const topBoxShadow = '0px -1px 5px rgba(153,153,153,0.75)'
const leftSideBoxShadow = StyleConstants.dockedCard.boxShadow

export const commonDockedCard = {
  container: {
    boxShadow: topBoxShadow,
    zIndex: commonZIndex,
  },
}
export default ({ viewportCategories: { portraitHandsetOrTablet } }: any) =>
  merge({}, commonDockedCard, {
    container: {
      backgroundColor: '#ffffff',
      bottom: 0,
      boxShadow: portraitHandsetOrTablet ? topBoxShadow : leftSideBoxShadow,
      height: portraitHandsetOrTablet ? miniCardHeight : undefined,
      maxWidth: portraitHandsetOrTablet ? undefined : dockedCardWidth,
      overflow: portraitHandsetOrTablet ? undefined : 'auto',
      position: 'fixed',
      right: 0,
      top: portraitHandsetOrTablet ? undefined : navbarHeight,
      width: portraitHandsetOrTablet ? '100%' : '50%',
      zIndex: portraitHandsetOrTablet
        ? portraitHandsetOrTabletZIndex
        : commonZIndex,
    },
  })
