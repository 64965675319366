// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import portraitHandsetOrTablet from 'common/selectors/viewport/portraitHandsetOrTablet'

type RoomBookingEventPathProps = {
  id: string | number
  floorId?: string | number
}
type RoomBookingPathProps = {
  roomId?: string | number
  floorId?: string | number
}
const maxPath = () => (portraitHandsetOrTablet(window) ? '/max' : '')

export const bookARoomPath = ({ floorId }: RoomBookingPathProps) =>
  floorId ? `/floors/${floorId}/room-booking/new` : '/room-booking/new'
export const roomBookingPath = ({ floorId, roomId }: RoomBookingPathProps) =>
  floorId && roomId
    ? `/floors/${floorId}/rooms/${roomId}${maxPath()}`
    : '/room-booking/new'
export const roomBookingEventPath = ({
  id,
  floorId,
}: RoomBookingEventPathProps) =>
  portraitHandsetOrTablet(window) || !floorId
    ? `/meetings/${id}`
    : `/floors/${floorId}/meetings/${id}`
export const editRoomBookingEventPath = ({
  id,
  floorId,
}: RoomBookingEventPathProps) =>
  portraitHandsetOrTablet(window) || !floorId
    ? `/meetings/${id}/edit`
    : `/floors/${floorId}/meetings/${id}/edit`
