// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import { compose } from 'redux'
import floatEquals from 'common/lib/floatEquals'
import constant from 'lodash/constant'
import add from 'lodash/fp/add'
import mapValues from 'lodash/fp/mapValues'
import multiply from 'lodash/fp/multiply'
import { fromJS, mergeDeep, Map, Seq } from 'immutable'
import type { OrderedMap } from 'immutable'
import 'core-js/stable'
import getFocusDeltas from 'common/lib/getFocusDeltas'
/* @ts-expect-error auto-src: non-strict-conversion */
import NeighborhoodFocus from 'common/lib/getFocusDeltas/NeighborhoodFocus.fragment.graphql'
/* @ts-expect-error auto-src: non-strict-conversion */
import RoomFocus from 'common/lib/getFocusDeltas/RoomFocus.fragment.graphql'
import StabilizationMonitor from 'common/lib/StabilizationMonitor'
import {
  unsetFloorPlanBoundingBox,
  zoomConstants,
} from 'common/constants/renderedFloor'
import isKioskBrowse from 'visual_directory/selectors/isKioskBrowse'
import { isNeighborhood, isRoom, isSeat, isUtility } from 'common/lib/resource'
import { map } from 'common/lib/functionalImmutableHelpers'
import createTweenValuesByUpdateDifference from 'common/lib/tweenValuesByUpdateDifference'
import isMediumOrGreater from 'common/selectors/viewport/mediumOrGreater'
import makeMapStageToFloorPoint from 'common/lib/makeMapStageToFloorPoint'
import tweenViewIngredientsByDeltas from 'common/lib/tweenViewIngredientsByDeltas'
import Definitions from 'common/constants/definitions'
import { makeFloorPlanImageUrlFromFloor } from 'common/lib/paths'
import VDAnimationConstants from 'visual_directory/constants/animations'
import iconFocusModes, {
  iconPadding,
} from 'visual_directory/constants/iconFocusModes'
import * as resourceSeatOrUtilityQueries from 'visual_directory/actions/resourceQueries'
import ActionTypes from '../constants/actionTypes'
import AnimationConstants from '../constants/animations'
// TODO: There should be a class to handle managing tweens rather than an export of an object
export const runningAnimations = {}
const tweenValuesByUpdateDifference = createTweenValuesByUpdateDifference({
  runningAnimations,
})
const mapValuesToZero = mapValues(constant(0))

export const resetAnimation = (type: string) => {
  /* @ts-expect-error auto-src: strict-conversion */
  const animation = runningAnimations[type]

  // Generic kill and set to undefined
  if (animation) {
    if (animation.totalProgress() !== 1 && animation.vars.onComplete) {
      animation.vars.onComplete()
    }

    animation.kill()
    /* @ts-expect-error auto-src: strict-conversion */
    runningAnimations[type] = undefined
  }
}
export const resetAllAnimations = () =>
  Object.keys(runningAnimations).forEach(resetAnimation)

const makeToLocal = (
  /* @ts-expect-error auto-src: strict-conversion */
  calculateCenteringOffset,
  /* @ts-expect-error auto-src: strict-conversion */
  calculatePositionSelector,
  /* @ts-expect-error auto-src: strict-conversion */
  calculateZoomSelector,
) =>
  makeMapStageToFloorPoint({
    calculateCenteringOffset,
    calculatePositionSelector,
    calculateZoomSelector,
  })

// Scale the zoom delta to the distance between the floor's min zoom and max zoom.
// This indirectly scales delta to the Floor table's icon scale value.
//
// numberOfIncrements is the number of delta=1 incremental zooms it takes to get
// from maxZoom to minZoom.
const scaleDeltaToFloor =
  /* @ts-expect-error auto-src: strict-conversion */


    (numberOfIncrements, calculateZoomSelector) =>
    /* @ts-expect-error auto-src: strict-conversion */
    (unscaledDelta) =>
    /* @ts-expect-error auto-src: strict-conversion */
    (dispatch, getState) => {
      const state = getState()
      const {
        max: maxZoom,
        min: minZoom,
        current,
      } = calculateZoomSelector(state)
      // Solving this: minZoom * ((1 + incrementFactor)^numberOfIncrements) = maxZoom
      // Gives this: incrementFactor = (maxZoom / minZoom)^(1 / numberOfIncrements) - 1
      const incrementFactor =
        (maxZoom / minZoom) ** (1 / numberOfIncrements) - 1

      return unscaledDelta * incrementFactor * (current || 1)
    }

type Dimension = {
  height: number
  width: number
}
type StageDimension = Dimension & {
  left: number
  top: number
}
const amountToDivideByAtOverZoomPercent = 100
const twiceAmountToDivideByAtOverZoomPercent =
  2 * amountToDivideByAtOverZoomPercent
// - This factor was calculated to ensure that the zoom delta is divided by the
//   amountToDivideByAtOverZoomPercent when the user has zoomed to the overZoomPercent.
// - It is used in the boundZoomDelta function.
const overZoomFalloffFactor =
  Math.log2(twiceAmountToDivideByAtOverZoomPercent - 1) /
  (Definitions.overZoomPercent * Definitions.overZoomPercent)

// Restricts the delta (difference between current zoom and desired zoom) to prevent zoom from going on forever.
// We do allow users to go a defined percentage beyond the zoom limits.
/* @ts-expect-error auto-src: strict-conversion */
const boundZoomDelta = ({ minZoom, maxZoom, currentZoom, delta }) => {
  const desiredZoom = currentZoom + delta

  // No change if we are within the bounds
  if (desiredZoom >= minZoom && desiredZoom <= maxZoom) {
    return delta
  }

  // Calculate how much zoom to actually allow when user tries to zoom beyond limits
  /* @ts-expect-error auto-src: strict-conversion */
  const calculateDeltaForLimit = (limit) => {
    const percentBeyondLimit = desiredZoom / limit
    const percentBeyondLimitMinusOne = percentBeyondLimit - 1
    // The bounding equation is f(x) = 2 / (1 + 2^((log2(2 * b - 1) / o^2) * (x - 1)^2))
    //   o is the total allowed percent over the zoom limit
    //   x is the current percent beyond the limit (that is, how much user tries to zoom)
    //   b is the amount we want to divide the delta by when x === o
    //
    // This equation was chosen since it has horizonal asymptotes at 0,
    // it contains the point (1, 1) so that it does not change the delta when desiredZoom === limit,
    // and has a slope of zero when x = 1 to ensure that the bounding behavior isn't too abrupt.
    //
    // Any other equation with these properties could be used.
    const deltaReductionFactor =
      2 /
      (1 +
        2 **
          (overZoomFalloffFactor *
            percentBeyondLimitMinusOne *
            percentBeyondLimitMinusOne))

    return deltaReductionFactor * delta
  }

  if (desiredZoom < minZoom) {
    return calculateDeltaForLimit(minZoom)
  }

  // if (desiredZoom > maxZoom) { <- last remaining option, assuming maxZoom >= minZoom
  return calculateDeltaForLimit(maxZoom)
}

export const receiveExpectedFloorPlanUrl = (
  url: string | null | undefined,
) => ({
  type: ActionTypes.Floor.RECEIVE_EXPECTED_FLOOR_PLAN_URL,
  url,
})
//
// fetching a single floor
//
export const makeReceiveSelectedFloor =
  (
    calculateCenteringOffset: any,
    calculatePositionSelector: any,
    calculateZoomSelector: any,
  ) =>
  (data: any) =>
  (dispatch: any, getState: any, { apolloClient }: any) => {
    dispatch({
      type: ActionTypes.Floor.RECEIVE_SELECTED,
      data,
    })
    dispatch(
      /* @ts-expect-error auto-src: non-strict-conversion */
      receiveExpectedFloorPlanUrl(makeFloorPlanImageUrlFromFloor(data.floor)),
    )
    const state = getState()
    const centerToRequest = state.zoomIngredients.get('centerToRequest')

    if (centerToRequest) {
      const { max: maxZoom, min: minZoom } = calculateZoomSelector(state)
      const centeringOffset = calculateCenteringOffset(state, {
        zoomWhenCentered: minZoom,
      })
      const boundedCenterToZoom = Math.max(
        Math.min(centerToRequest.zoom, maxZoom),
        minZoom,
      )
      const halfStageHeight = state.floorStore.get('stageHeight') / 2
      const halfStageWidth = state.floorStore.get('stageWidth') / 2
      const centerToPositionScaledToStage = {
        x: -centerToRequest.get('x') * boundedCenterToZoom,
        y: -centerToRequest.get('y') * boundedCenterToZoom,
      }
      const centerToPositionOnStage = {
        x: centerToPositionScaledToStage.x + halfStageWidth,
        y: centerToPositionScaledToStage.y + halfStageHeight,
      }

      dispatch({
        type: ActionTypes.Floor.CENTER_TO_ENDED,
        centerToPositionDeltas: {
          x: centerToPositionOnStage.x - centeringOffset.x,
          y: centerToPositionOnStage.y - centeringOffset.y,
        },
        centerToZoomDelta: boundedCenterToZoom - minZoom,
      })
    } else if (
      state.selectedResource &&
      state.selectedResource.get('id') !== -1 &&
      !isKioskBrowse(state)
    ) {
      if (
        /* @ts-expect-error auto-src: non-strict-conversion */
        runningAnimations.zoomTween &&
        /* @ts-expect-error auto-src: non-strict-conversion */
        runningAnimations.zoomTween.isActive()
      ) {
        /* @ts-expect-error auto-src: non-strict-conversion */
        runningAnimations.zoomTween.kill()
      }

      const {
        current: currentZoom,
        max: maxZoom,
        min: minZoom,
      } = calculateZoomSelector(state)
      const position = calculatePositionSelector(state, {
        centeringOffset: calculateCenteringOffset(state, {
          zoomWhenCentered: minZoom,
        }),
      })
      const isSelectedRoom = isRoom(state.selectedResource)

      if (isSelectedRoom || isNeighborhood(state.selectedResource)) {
        const resourceType = isSelectedRoom ? 'Room' : 'Neighborhood'
        const fragment = isSelectedRoom ? RoomFocus : NeighborhoodFocus
        const resourceFragment = apolloClient.readFragment({
          id: `${resourceType}:${state.selectedResource.get('id')}`,
          fragment,
        })
        const modePostfix = isMediumOrGreater(window) ? 'Desktop' : 'Compact'
        const padding = state.settings.get(`vdRoomFocusPadding${modePostfix}`)
        const resourceFocusDeltas = getFocusDeltas({
          currentViewIngredients: Map({
            positionIngredients: state.positionIngredients.get(
              'positionIngredients',
            ),
            zoomIngredients: state.zoomIngredients.get('zoomIngredients'),
          }),
          currentZoom,
          maxZoom,
          minZoom,
          padding,
          points: resourceFragment.points,
          position,
          stageHeight: state.floorStore.get('stageHeight'),
          stageWidth: state.floorStore.get('stageWidth'),
          yOffset: state.settings.floorYOffset,
        })

        dispatch({
          type: ActionTypes.Floor.private.BOX_FOCUS_TWEEN_STEP,
          positionIngredients: {
            /* @ts-expect-error auto-src: non-strict-conversion */
            positionIngredients: resourceFocusDeltas.positionIngredients,
          },
          zoomIngredients: {
            /* @ts-expect-error auto-src: non-strict-conversion */
            zoomIngredients: resourceFocusDeltas.zoomIngredients,
          },
        })
      } else if (
        (isSeat(state.selectedResource) || isUtility(state.selectedResource)) &&
        ((isMediumOrGreater(window) &&
          state.settings.vdIconFocusModeDesktop === iconFocusModes.IN) ||
          (!isMediumOrGreater(window) &&
            state.settings.vdIconFocusModeCompact === iconFocusModes.IN))
      ) {
        const {
          resource: [resource],
        } = apolloClient.readQuery({
          // auto-src: ts-eslint eslint-disable-next-line import/namespace
          query:
            /* @ts-expect-error auto-src: strict-conversion */
            resourceSeatOrUtilityQueries[state.selectedResource.get('type')],
          variables: {
            ids: state.selectedResource.get('id'),
          },
        })
        const resourceX =
          resource.x == null
            ? resource.center && resource.center[0]
            : resource.x
        const rawResourceY =
          resource.y == null
            ? resource.center && resource.center[1]
            : resource.y
        const resourceFocusDeltas = getFocusDeltas({
          currentViewIngredients: Map({
            positionIngredients: state.positionIngredients.get(
              'positionIngredients',
            ),
            zoomIngredients: state.zoomIngredients.get('zoomIngredients'),
          }),
          currentZoom,
          maxZoom,
          minZoom,
          padding: iconPadding,
          position,
          points: [[resourceX, rawResourceY]],
          stageHeight: state.floorStore.get('stageHeight'),
          stageWidth: state.floorStore.get('stageWidth'),
          yOffset: state.settings.floorYOffset,
        })

        dispatch({
          type: ActionTypes.Floor.private.BOX_FOCUS_TWEEN_STEP,
          positionIngredients: {
            /* @ts-expect-error auto-src: non-strict-conversion */
            positionIngredients: resourceFocusDeltas.positionIngredients,
          },
          zoomIngredients: {
            /* @ts-expect-error auto-src: non-strict-conversion */
            zoomIngredients: resourceFocusDeltas.zoomIngredients,
          },
        })
      }
    }
  }
export const setSelectedFloorRenderStatus = (isRendered: boolean) => ({
  type: ActionTypes.Floor.SET_SELECTED_FLOOR_RENDER_STATUS,
  isRendered,
})
// This only resets the floor position & zoom.
export const resetFloor = () => ({
  type: ActionTypes.Floor.RESET,
})
export const selectFloor =
  (id: number, floorGroupId?: number | null) =>
  (dispatch: any, getState: any) => {
    const { selectedFloor } = getState()

    if (selectedFloor.get('id') === id) {
      return
    }

    dispatch(resetFloor())
    dispatch({
      type: ActionTypes.Floor.SELECT_FLOOR,
      id,
      floorGroupId,
    })
  }
export const clearFloor = (id: number) => ({
  type: ActionTypes.Floor.FLOOR_CLEARED,
  id,
})

//
// changing the state of the rendered floor.
//
const isInsideFloorBoundaries = ({
  floorPlanBoundingBox: {
    xOffset,
    yOffset,
    height,
    width,
  } = unsetFloorPlanBoundingBox,
  /* @ts-expect-error auto-src: strict-conversion */
  x,
  /* @ts-expect-error auto-src: strict-conversion */
  y,
}) => {
  const xMin = xOffset
  const xMax = width + xOffset
  const yMin = yOffset
  const yMax = height + yOffset

  return x >= xMin && x <= xMax && y >= yMin && y <= yMax
}

export const makeZoomAndCenter =
  (
    calculateZoomSelector: any,
    calculatePositionSelector: any,
    calculateCenteringOffset: any,
  ) =>
  ({ x, y, zoom: targetZoom }: { x: number; y: number; zoom: number }) =>
  (dispatch: any, getState: any) => {
    dispatch({
      type: ActionTypes.Floor.DOUBLE_CLICK,
      x,
      y,
    })
    const state = getState()
    const { current: currentZoom, min: minZoom } = calculateZoomSelector(state)
    const zoomDelta = targetZoom - currentZoom
    const position = calculatePositionSelector(state, {
      centeringOffset: calculateCenteringOffset(state, {
        zoomWhenCentered: minZoom,
      }),
    })
    const halfStageWidth = (state.floorStore.get('stageWidth') || 0) / 2
    const halfStageHeight = (state.floorStore.get('stageHeight') || 0) / 2
    const stageCenter = {
      x: halfStageWidth - position.x,
      y: halfStageHeight - position.y,
    }
    const zoomedResourceX = x * targetZoom
    const zoomedResourceY = y * targetZoom
    const zoomCorrections = {
      x: stageCenter.x - zoomedResourceX,
      y: stageCenter.y - zoomedResourceY,
    }
    const tweenValues = {
      positionIngredients: {
        positionIngredients: {
          zoomCorrections,
        },
      },
      zoomIngredients: {
        zoomIngredients: {
          doubleClickDeltas: zoomDelta,
        },
      },
    }

    resetAllAnimations()
    /* @ts-expect-error auto-src: non-strict-conversion */
    tweenViewIngredientsByDeltas({
      dispatch,
      dispatchType: ActionTypes.Floor.private.DOUBLE_CLICK_TWEEN_STEP,
      stateSlices: {
        positionIngredients: state.positionIngredients,
        zoomIngredients: state.zoomIngredients,
      },
      runningAnimations,
      deltas: {
        positionIngredients: tweenValues.positionIngredients,
        zoomIngredients: tweenValues.zoomIngredients,
      },
    })
  }

const makeDeltaBounder = (
  /* @ts-expect-error auto-src: strict-conversion */
  calculateCenteringOffset,
  /* @ts-expect-error auto-src: strict-conversion */
  calculatePositionSelector,
  /* @ts-expect-error auto-src: strict-conversion */
  calculateZoomSelector,
) => {
  const toLocalFactory = makeToLocal(
    calculateCenteringOffset,
    calculatePositionSelector,
    calculateZoomSelector,
  )

  /* @ts-expect-error auto-src: strict-conversion */
  return (state) =>
    /* @ts-expect-error auto-src: strict-conversion */
    ({ deltaX, deltaY }) => {
      const { floorStore } = state
      const toLocal = toLocalFactory(state)
      const stageTopLeft = toLocal({
        x: -deltaX,
        y: -deltaY,
      })
      const stageBottomRight = toLocal({
        x: (floorStore.get('stageWidth') || 0) - deltaX,
        y: (floorStore.get('stageHeight') || 0) - deltaY,
      })
      const floorPlanBoundingBox = floorStore.get('floorPlanBoundingBox')
      const { current: currentZoom } = calculateZoomSelector(state)

      /* @ts-expect-error auto-src: strict-conversion */
      const calcuateLocalBoundModifier = (dimension) => {
        const offsetMap = {
          x: 'xOffset',
          y: 'yOffset',
        }
        const sizeMap = {
          x: 'width',
          y: 'height',
        }
        /* @ts-expect-error auto-src: strict-conversion */
        const stageMin = stageTopLeft[dimension]
        /* @ts-expect-error auto-src: strict-conversion */
        const stageMax = stageBottomRight[dimension]
        const paddingPercent = 0.1
        const boundingBoxMin =
          /* @ts-expect-error auto-src: strict-conversion */
          floorPlanBoundingBox[offsetMap[dimension]] +
          /* @ts-expect-error auto-src: strict-conversion */
          paddingPercent * floorPlanBoundingBox[sizeMap[dimension]]
        const hasPannedTooFarOverLimit = boundingBoxMin > stageMax

        if (hasPannedTooFarOverLimit) {
          return boundingBoxMin - stageMax
        }

        const boundingBoxMax =
          /* @ts-expect-error auto-src: strict-conversion */
          floorPlanBoundingBox[offsetMap[dimension]] +
          /* @ts-expect-error auto-src: strict-conversion */
          (1 - paddingPercent) * floorPlanBoundingBox[sizeMap[dimension]]
        const hasPannedTooFarUnderLimit = boundingBoxMax < stageMin

        if (hasPannedTooFarUnderLimit) {
          return boundingBoxMax - stageMin
        }

        // When we are within the limits, there is no need to modify the pan delta
        return 0
      }

      /* @ts-expect-error auto-src: strict-conversion */
      const calculateBoundModifier = (dimension) =>
        currentZoom * calcuateLocalBoundModifier(dimension)

      const boundedDeltaX = deltaX - calculateBoundModifier('x')
      const boundedDeltaY = deltaY - calculateBoundModifier('y')

      return {
        deltaX: boundedDeltaX,
        deltaY: boundedDeltaY,
      }
    }
}

export const makeDoubleClick = (
  calculateCenteringOffset: any,
  calculatePositionSelector: any,
  calculateZoomSelector: any,
  maxZoomFactor = 1,
) => {
  const deltaBounderFactory = makeDeltaBounder(
    calculateCenteringOffset,
    calculatePositionSelector,
    calculateZoomSelector,
  )

  return ({ x, y }: { x: number; y: number }) =>
    (dispatch: any, getState: any) => {
      dispatch({
        type: ActionTypes.Floor.DOUBLE_CLICK,
        x,
        y,
      })
      const state = getState()
      const {
        min: minZoom,
        max: maxZoom,
        current: currentZoom,
      } = calculateZoomSelector(state)
      const maxDoubleClickZoom = maxZoom * maxZoomFactor
      const zoomOutWhenGreaterThanPercent = 0.98
      const triggerZoom = zoomOutWhenGreaterThanPercent * maxDoubleClickZoom
      const shouldZoomIn =
        currentZoom < triggerZoom &&
        isInsideFloorBoundaries({
          floorPlanBoundingBox: state.floorStore.get('floorPlanBoundingBox'),
          x,
          y,
        })
      const targetZoom = shouldZoomIn ? maxDoubleClickZoom : minZoom
      const zoomDelta = targetZoom - currentZoom
      const xCorrection = x * -zoomDelta
      const yCorrection = y * -zoomDelta
      const zoomIngredientsAfterZoom = state.zoomIngredients.mergeDeepWith(
        add,
        {
          zoomIngredients: {
            doubleClickDeltas: zoomDelta,
          },
        },
      )
      const boundDeltas = deltaBounderFactory({
        ...state,
        zoomIngredients: zoomIngredientsAfterZoom,
      })
      const correctionDeltas = boundDeltas({
        deltaX: xCorrection,
        deltaY: yCorrection,
      })
      const tweenValues = shouldZoomIn
        ? {
            positionIngredients: {
              positionIngredients: {
                zoomCorrections: {
                  x: correctionDeltas.deltaX,
                  y: correctionDeltas.deltaY,
                },
              },
            },
            zoomIngredients: {
              zoomIngredients: {
                doubleClickDeltas: zoomDelta,
              },
            },
          }
        : {
            positionIngredients: state.positionIngredients
              .update(
                'positionIngredients',
                compose(map(compose(map(multiply(-1)), Seq.Keyed)), Seq.Keyed),
              )
              /* @ts-expect-error auto-src: strict-conversion */
              .filter((_, key) => key === 'positionIngredients')
              .toJS(),
            zoomIngredients: state.zoomIngredients
              .update('zoomIngredients', compose(map(multiply(-1)), Seq.Keyed))
              /* @ts-expect-error auto-src: strict-conversion */
              .filter((_, key) => key === 'zoomIngredients')
              .toJS(),
          }

      resetAllAnimations()
      /* @ts-expect-error auto-src: non-strict-conversion */
      tweenViewIngredientsByDeltas({
        dispatch,
        dispatchType: ActionTypes.Floor.private.DOUBLE_CLICK_TWEEN_STEP,
        stateSlices: {
          positionIngredients: state.positionIngredients,
          zoomIngredients: state.zoomIngredients,
        },
        runningAnimations,
        deltas: {
          positionIngredients: tweenValues.positionIngredients,
          zoomIngredients: tweenValues.zoomIngredients,
        },
      })
    }
}
export const zoomOutAndCenter = () => (dispatch: any, getState: any) => {
  const state = getState()
  const tweenValues = {
    positionIngredients: state.positionIngredients
      .update(
        'positionIngredients',
        compose(map(compose(map(multiply(-1)), Seq.Keyed)), Seq.Keyed),
      )
      /* @ts-expect-error auto-src: strict-conversion */
      .filter((_, key) => key === 'positionIngredients')
      .toJS(),
    zoomIngredients: state.zoomIngredients
      .update('zoomIngredients', compose(map(multiply(-1)), Seq.Keyed))
      /* @ts-expect-error auto-src: strict-conversion */
      .filter((_, key) => key === 'zoomIngredients')
      .toJS(),
  }

  resetAllAnimations()
  /* @ts-expect-error auto-src: non-strict-conversion */
  tweenViewIngredientsByDeltas({
    dispatch,
    dispatchType: ActionTypes.Floor.private.DOUBLE_CLICK_TWEEN_STEP,
    stateSlices: {
      positionIngredients: state.positionIngredients,
      zoomIngredients: state.zoomIngredients,
    },
    runningAnimations,
    deltas: {
      positionIngredients: tweenValues.positionIngredients,
      zoomIngredients: tweenValues.zoomIngredients,
    },
  })
}
export const makePan = (
  calculateCenteringOffset: any,
  calculatePositionSelector: any,
  calculateZoomSelector: any,
) => {
  const deltaBounderFactory = makeDeltaBounder(
    calculateCenteringOffset,
    calculatePositionSelector,
    calculateZoomSelector,
  )

  return ({ deltaX, deltaY }: { deltaX: number; deltaY: number }) =>
    (dispatch: any, getState: any) => {
      const state = getState()
      const boundDeltas = deltaBounderFactory(state)
      const boundedDeltas = boundDeltas({
        deltaX,
        deltaY,
      })

      dispatch({
        type: ActionTypes.Floor.PAN,
        ...boundedDeltas,
      })
    }
}
export const makePanEnd = (
  calculateCenteringOffset: any,
  calculatePositionSelector: any,
  calculateZoomSelector: any,
) => {
  const deltaBounderFactory = makeDeltaBounder(
    calculateCenteringOffset,
    calculatePositionSelector,
    calculateZoomSelector,
  )

  return ({ velocityX, velocityY }: { velocityX: number; velocityY: number }) =>
    (dispatch: any, getState: any) => {
      resetAnimation('panInertia')
      const fromValues = {
        deltaX: 0,
        deltaY: 0,
      }
      const boundDeltas = deltaBounderFactory(getState())
      // 150 is just a magic number that seems to give a reasonable amount of distance
      const magicPanMultiplicationFactor = 150
      const toValues = boundDeltas({
        deltaX: velocityX * magicPanMultiplicationFactor,
        deltaY: velocityY * magicPanMultiplicationFactor,
      })

      tweenValuesByUpdateDifference({
        animationKey: 'panInertia',
        dispatch,
        dispatchType: ActionTypes.Floor.PAN,
        duration: AnimationConstants.floorPlanInertia.duration,
        ease: AnimationConstants.floorPlanInertia.ease,
        fromValues,
        toValues,
      })
    }
}
export const makePinch =
  (calculateZoomSelector: any) =>
  ({
    center,
    deltaRelativeScale,
    deltaX,
    deltaY,
  }: {
    center: {
      x: number
      y: number
    }
    deltaRelativeScale: number
    deltaX: number
    deltaY: number
  }) =>
  (dispatch: any, getState: any) => {
    resetAnimation('panInertia')
    resetAnimation('zoomTween')
    resetAnimation('snapBack')
    const {
      current: currentZoom,
      max: maxZoom,
      min: minZoom,
    } = calculateZoomSelector(getState())
    const magicPinchAdjustment = 1.5
    const scaledZoom = currentZoom * (deltaRelativeScale - 1)
    const pinchDelta = scaledZoom / magicPinchAdjustment
    const boundedDelta = boundZoomDelta({
      delta: pinchDelta,
      currentZoom,
      maxZoom,
      minZoom,
    })
    const xCorrection = -center.x * boundedDelta
    const yCorrection = -center.y * boundedDelta

    dispatch({
      type: ActionTypes.Floor.PINCH,
      deltaZoom: boundedDelta,
      deltaX,
      deltaY,
      xCorrection,
      yCorrection,
    })
  }
/* @ts-expect-error auto-src: strict-conversion */
let snapbackTimeout = null

// Checks whether current zoom exceeds max or min zoom and starts a tween to get back to an acceptable zoom
const checkZoomBoundaries =
  ({
    center: { x, y },
    currentZoom,
    maxZoom,
    minZoom,
  }: {
    center: {
      x: number
      y: number
    }
    currentZoom: number
    maxZoom: number
    minZoom: number
  }) =>
  (dispatch: any) => {
    const snapbackDelay = 20

    /* @ts-expect-error auto-src: strict-conversion */
    const snapBack = (zoomLevel) => {
      /* @ts-expect-error auto-src: strict-conversion */
      if (snapbackTimeout) {
        /* @ts-expect-error auto-src: strict-conversion */
        clearTimeout(snapbackTimeout)
        snapbackTimeout = null
      }

      snapbackTimeout = setTimeout(() => {
        const animationKey = 'snapBack'
        const { duration, ease } = AnimationConstants.zoomFloorPlan
        const delta = zoomLevel - currentZoom

        resetAllAnimations()
        const xCorrection = -x * delta
        const yCorrection = -y * delta
        const toValues = {
          xCorrection,
          yCorrection,
          delta,
        }
        const fromValues = mapValuesToZero(toValues)

        tweenValuesByUpdateDifference({
          animationKey,
          dispatch,
          dispatchType: ActionTypes.Floor.private.SNAP_BACK_TWEEN_STEP,
          duration,
          ease,
          fromValues,
          toValues,
        })
      }, snapbackDelay)
    }

    if (currentZoom > maxZoom) {
      snapBack(maxZoom)
    } else if (currentZoom < minZoom) {
      snapBack(minZoom)
    }
  }

export const makePinchEnd =
  (calculateZoomSelector: any) =>
  ({
    center,
  }: {
    center: {
      x: number
      y: number
    }
  }) =>
  (dispatch: any, getState: any) => {
    const {
      current: currentZoom,
      min: minZoom,
      max: maxZoom,
    } = calculateZoomSelector(getState())

    checkZoomBoundaries({
      center,
      currentZoom,
      minZoom,
      maxZoom,
    })(dispatch)
  }
let blockZoomBouncebackUntil = 0

const makeBoundedIncrementalZoom = (
  calculateCenteringOffset: any,
  calculatePositionSelector: any,
  calculateZoomSelector: any,
) => {
  const deltaBounderFactory = makeDeltaBounder(
    calculateCenteringOffset,
    calculatePositionSelector,
    calculateZoomSelector,
  )

  /* @ts-expect-error auto-src: strict-conversion */
  return ({ dispatch, dispatchType, scaledDelta, stagePosition, state }) => {
    const zoom = calculateZoomSelector(state)
    const isAlreadyOverMaxZoom =
      !floatEquals(zoom.current, zoom.max) && zoom.current > zoom.max
    const isAlreadyUnderMinZoom =
      !floatEquals(zoom.current, zoom.min) && zoom.current < zoom.min

    if (
      /* @ts-expect-error auto-src: non-strict-conversion */
      runningAnimations.zoomTween &&
      (isAlreadyUnderMinZoom || isAlreadyOverMaxZoom)
    ) {
      return
    }

    const toLocal = makeToLocal(
      calculateCenteringOffset,
      calculatePositionSelector,
      calculateZoomSelector,
    )(state)
    const localScreenCenter = toLocal(stagePosition)
    const isAtMaxAndGoingOver =
      floatEquals(zoom.current, zoom.max) && scaledDelta > 0
    const isAtMinAndGoingUnder =
      floatEquals(zoom.current, zoom.min) && scaledDelta < 0
    const isGoingOverMaxZoom =
      !floatEquals(zoom.current, zoom.max) &&
      zoom.current < zoom.max &&
      zoom.current + scaledDelta > zoom.max
    const isGoingUnderMinZoom =
      !floatEquals(zoom.current, zoom.min) &&
      zoom.current > zoom.min &&
      zoom.current + scaledDelta < zoom.min

    /* @ts-expect-error auto-src: strict-conversion */
    const possiblyOverrideByOverZoom = (originalDelta) => {
      const percentToOverZoom = 1.05 // Determined experimentally with Shamim

      if (isAtMaxAndGoingOver) {
        return zoom.max * (percentToOverZoom - 1)
      }

      if (isAtMinAndGoingUnder) {
        const inversePercentToZoom = 1 / percentToOverZoom

        return zoom.min * (inversePercentToZoom - 1)
      }

      return originalDelta
    }

    /* @ts-expect-error auto-src: strict-conversion */
    const possiblyOverrideByZoomLimit = (originalDelta) => {
      if (isGoingOverMaxZoom || isAlreadyOverMaxZoom) {
        return zoom.max - zoom.current
      }

      if (isGoingUnderMinZoom || isAlreadyUnderMinZoom) {
        return zoom.min - zoom.current
      }

      return originalDelta
    }

    const delta = compose(
      possiblyOverrideByOverZoom,
      possiblyOverrideByZoomLimit,
    )(scaledDelta)
    const xCorrection = -localScreenCenter.x * delta
    const yCorrection = -localScreenCenter.y * delta
    const animationKey = 'zoomTween'

    /* @ts-expect-error auto-src: strict-conversion */
    const possiblyOverrideByOverZoomDuration = (originalDuration) => {
      if (isAtMaxAndGoingOver || isAtMinAndGoingUnder) {
        return AnimationConstants.preventOverZoom.duration
      }

      return originalDuration
    }

    /* @ts-expect-error auto-src: strict-conversion */
    const possiblyOverrideByZoomLimitDuration = (originalDuration) => {
      if (isGoingOverMaxZoom || isGoingUnderMinZoom) {
        return (
          AnimationConstants.toZoomBound.maxDuration *
          (1 - 1 / (1 + delta * delta))
        )
      }

      return originalDuration
    }

    const duration = compose(
      possiblyOverrideByZoomLimitDuration,
      possiblyOverrideByOverZoomDuration,
    )(AnimationConstants.zoomFloorPlan.duration)

    /* @ts-expect-error auto-src: strict-conversion */
    const possiblyOverrideByOverZoomEase = (originalEase) => {
      if (isAtMaxAndGoingOver || isAtMinAndGoingUnder) {
        return AnimationConstants.preventOverZoom.ease
      }

      return originalEase
    }

    /* @ts-expect-error auto-src: strict-conversion */
    const possiblyOverrideByZoomLimitEase = (originalEase) => {
      if (isGoingOverMaxZoom || isGoingUnderMinZoom) {
        return AnimationConstants.toZoomBound.ease
      }

      return originalEase
    }

    const ease = compose(
      possiblyOverrideByZoomLimitEase,
      possiblyOverrideByOverZoomEase,
    )(AnimationConstants.zoomFloorPlan.ease)

    resetAllAnimations()
    const zoomIngredientsAfterZoom = state.zoomIngredients.mergeDeepWith(add, {
      zoomIngredients: {
        wheelZoomDeltas: delta,
      },
    })
    const boundDeltas = deltaBounderFactory({
      ...state,
      zoomIngredients: zoomIngredientsAfterZoom,
    })
    const deltas = boundDeltas({
      deltaX: xCorrection,
      deltaY: yCorrection,
    })
    const toValues = {
      xCorrection: deltas.deltaX,
      yCorrection: deltas.deltaY,
      delta,
    }
    const fromValues = mapValuesToZero(toValues)

    if (isAtMaxAndGoingOver || isAtMinAndGoingUnder) {
      const now = Date.now()

      if (now > blockZoomBouncebackUntil) {
        const repeatOptions = {
          repeat: 1,
          yoyo: true,
        }

        const onComplete = () => {
          blockZoomBouncebackUntil = Date.now() + zoomConstants.ignoreBounceMs
        }

        tweenValuesByUpdateDifference({
          animationKey,
          dispatch,
          dispatchType,
          duration,
          ease,
          onComplete,
          repeatOptions,
          fromValues,
          toValues,
        })
      } else {
        blockZoomBouncebackUntil = now + zoomConstants.ignoreBounceMs
      }
    } else {
      blockZoomBouncebackUntil = 0
      tweenValuesByUpdateDifference({
        animationKey,
        dispatch,
        dispatchType,
        duration,
        ease,
        fromValues,
        toValues,
      })
    }
  }
}

export const makeWheel =
  (
    calculateCenteringOffset: any,
    calculatePositionSelector: any,
    calculateZoomSelector: any,
  ) =>
  ({ x, y, delta: unscaledDelta }: { x: number; y: number; delta: number }) =>
  (dispatch: any, getState: any) => {
    const state = getState()
    const scaledDelta = scaleDeltaToFloor(
      zoomConstants.numberOfZoomIncrements.mouseWheel,
      calculateZoomSelector,
    )(unscaledDelta)(dispatch, getState)
    const stagePosition = {
      x,
      y,
    }

    makeBoundedIncrementalZoom(
      calculateCenteringOffset,
      calculatePositionSelector,
      calculateZoomSelector,
    )({
      dispatch,
      dispatchType: ActionTypes.Floor.private.WHEEL_TWEEN_STEP,
      scaledDelta,
      stagePosition,
      state,
    })
  }
export const makeZoomButtonClick =
  (type: string, unscaledDelta: number) =>
  (
    calculateCenteringOffset: any,
    calculatePositionSelector: any,
    calculateZoomSelector: any,
  ) =>
  () =>
  (dispatch: any, getState: any) => {
    dispatch({
      type,
    })
    const state = getState()
    const scaledDelta = scaleDeltaToFloor(
      zoomConstants.numberOfZoomIncrements.zoomButton,
      calculateZoomSelector,
    )(unscaledDelta)(dispatch, getState)
    const stagePosition = {
      x: (getState().floorStore.get('stageWidth') || 0) / 2,
      y: (getState().floorStore.get('stageHeight') || 0) / 2,
    }

    makeBoundedIncrementalZoom(
      calculateCenteringOffset,
      calculatePositionSelector,
      calculateZoomSelector,
    )({
      dispatch,
      dispatchType: ActionTypes.Floor.private.ZOOM_BUTTON_TWEEN_STEP,
      scaledDelta,
      stagePosition,
      state,
    })
  }
const ZOOM_IN_DELTA = 2

export const makeZoomInButtonClick = makeZoomButtonClick(
  ActionTypes.Floor.ZOOM_IN_BUTTON_CLICKED,
  ZOOM_IN_DELTA,
)
const ZOOM_OUT_DELTA = -2

export const makeZoomOutButtonClick = makeZoomButtonClick(
  ActionTypes.Floor.ZOOM_OUT_BUTTON_CLICKED,
  ZOOM_OUT_DELTA,
)
export const navbarHeight = (): number => {
  const navbar = document.getElementById('navbar')

  return navbar ? navbar.clientHeight : 0
}

const browsePanelWidth = (): number => {
  const browsePanel = document.getElementsByClassName('browse-panel')[0]

  return browsePanel ? browsePanel.clientWidth : 0
}

const canvasDimensions = (): Dimension => {
  const bodyElementStyle = getComputedStyle(
    document.getElementsByTagName('body')[0],
  )
  const width = window.innerWidth

  /* eslint-disable no-useless-escape */
  const height =
    window.innerHeight -
    navbarHeight() -
    parseFloat(bodyElementStyle.marginTop.replace(/[^\d\.]/g, '')) -
    parseFloat(bodyElementStyle.marginBottom.replace(/[^\d\.]/g, ''))

  /* eslint-enable no-useless-escape */
  return {
    width,
    height,
  }
}

const dockingLayerDimensions = (): StageDimension =>
  Array.from(document.getElementsByClassName('dockingLayer'))
    .map((dockingLayer: Element): StageDimension => {
      const dockingLayerRect: DOMRect = dockingLayer.getBoundingClientRect()

      // if width of dockingLayer is full width of window, that means bottom-docked sheet
      if (Math.round(dockingLayerRect.width) >= window.innerWidth) {
        return {
          height: dockingLayerRect.height,
          left: 0,
          top: 0,
          width: 0,
        }
      }

      return {
        height: 0,
        left: dockingLayerRect.left <= 0 ? dockingLayerRect.width : 0,
        top: 0,
        width: dockingLayerRect.left > 0 ? dockingLayerRect.width : 0,
      }
    })
    .reduce(
      (accumulative: StageDimension, dockingLayerSize: StageDimension) => ({
        height: accumulative.height + dockingLayerSize.height,
        left: accumulative.left + dockingLayerSize.left,
        top: accumulative.top + dockingLayerSize.top,
        width: accumulative.width + dockingLayerSize.width,
      }),
      {
        height: 0,
        left: 0,
        top: 0,
        width: 0,
      },
    )

export const stageDimensions = (
  canvas: Dimension = canvasDimensions(),
  dockingLayer: StageDimension = dockingLayerDimensions(),
) => ({
  stageHeight: canvas.height - dockingLayer.height,
  stageLeft: dockingLayer.left,
  stageTop: dockingLayer.top,
  stageWidth: canvas.width - dockingLayer.width - browsePanelWidth(),
})
/* @ts-expect-error auto-src: strict-conversion */
let stageDimensionsStabilizationMonitor

/* @ts-expect-error auto-src: strict-conversion */
const areStageDimensionsEqual = (newDimensions, prevDimensions) =>
  newDimensions &&
  prevDimensions &&
  newDimensions.stageHeight === prevDimensions.stageHeight &&
  newDimensions.stageWidth === prevDimensions.stageWidth

const makeStageDimensionsChanged =
  (
    calculateCenteringOffset: any,
    calculateShouldTweenToNewCenterAndZoom: any,
    calculateZoomSelector: any,
  ) =>
  /* @ts-expect-error auto-src: strict-conversion */
  ({ next, previous }) =>
  /* @ts-expect-error auto-src: strict-conversion */
  (dispatch, getState) => {
    const state = getState()
    const { current: previousZoom, min: previousMinZoom } =
      calculateZoomSelector(state)
    const previousCenteringOffset = calculateCenteringOffset(state, {
      zoomWhenCentered: previousMinZoom,
    })
    const stateWithNewStageDimensions = mergeDeep(
      state,
      fromJS({
        floorStore: {
          stageLeft: next.left,
          stageWidth: next.width,
          stageHeight: next.height,
        },
      }) as any as OrderedMap<any, any>,
    )
    const { min: newMinZoom } = calculateZoomSelector(
      stateWithNewStageDimensions,
    )
    const centeringOffset = calculateCenteringOffset(
      stateWithNewStageDimensions,
      {
        zoomWhenCentered: newMinZoom,
      },
    )
    const shouldTweenToNewCenterAndZoom =
      calculateShouldTweenToNewCenterAndZoom({
        next: { ...next, minZoom: newMinZoom },
        previous: { ...previous, minZoom: previousMinZoom, zoom: previousZoom },
      })
    const amountUnderMinZoom = previousZoom - newMinZoom
    const minZoomBoundCorrection = shouldTweenToNewCenterAndZoom
      ? 0
      : Math.min(amountUnderMinZoom, 0)
    const minZoomCorrection =
      previousMinZoom - newMinZoom - minZoomBoundCorrection
    const centeringOffsetPositionCorrection = {
      x: previousCenteringOffset.x - centeringOffset.x,
      y: previousCenteringOffset.y - centeringOffset.y,
    }

    dispatch({
      type: ActionTypes.Floor.STAGE_DIMENSIONS_CHANGED_PRESERVING_VIEW,
      nextStageDimensions: next,
      centeringOffsetPositionCorrection:
        minZoomBoundCorrection && !shouldTweenToNewCenterAndZoom
          ? {
              x: 0,
              y: 0,
            }
          : centeringOffsetPositionCorrection,
      minZoomCorrection,
    })

    if (shouldTweenToNewCenterAndZoom) {
      const stateAfterChange = getState()
      const tweenValues = {
        positionIngredients: stateAfterChange.positionIngredients
          .update(
            'positionIngredients',
            compose(map(compose(map(multiply(-1)), Seq.Keyed)), Seq.Keyed),
          )
          /* @ts-expect-error auto-src: strict-conversion */
          .filter((_, key) => key === 'positionIngredients')
          .toJS(),
        zoomIngredients: stateAfterChange.zoomIngredients
          .update('zoomIngredients', compose(map(multiply(-1)), Seq.Keyed))
          /* @ts-expect-error auto-src: strict-conversion */
          .filter((_, key) => key === 'zoomIngredients')
          .toJS(),
      }

      resetAllAnimations()
      /* @ts-expect-error auto-src: non-strict-conversion */
      tweenViewIngredientsByDeltas({
        dispatch,
        dispatchType: ActionTypes.Floor.private.STAGE_LEFT_CHANGED_TWEEN_STEP,
        stateSlices: {
          positionIngredients: stateAfterChange.positionIngredients,
          zoomIngredients: stateAfterChange.zoomIngredients,
        },
        runningAnimations,
        deltas: {
          positionIngredients: tweenValues.positionIngredients,
          zoomIngredients: tweenValues.zoomIngredients,
        },
      })
    }
  }

export const checkForNewStageDimensions = (dispatch: any, getState: any) => {
  const state = getState()
  const oldStageHeight = state.floorStore.get('stageHeight')
  const oldStageWidth = state.floorStore.get('stageWidth')
  const oldStageLeft = state.floorStore.get('stageLeft')
  const oldStageTop = state.floorStore.get('stageTop')
  const areDockingLayersFullScreen = Array.prototype.reduce.call(
    document.getElementsByClassName('dockingLayer'),
    (accumulator, dockingLayer) =>
      accumulator ||
      (dockingLayer.style.width === '100%' &&
        dockingLayer.style.height === '100%'),
    false,
  )
  const ignoredDockingLayerDimensions = {
    height: 0,
    left: 0,
    top: 0,
    width: 0,
  }
  const dimensions = stageDimensions(
    canvasDimensions(),
    areDockingLayersFullScreen
      ? ignoredDockingLayerDimensions
      : dockingLayerDimensions(),
  )

  if (
    dimensions.stageWidth !== oldStageWidth ||
    dimensions.stageHeight !== oldStageHeight ||
    dimensions.stageLeft !== oldStageLeft ||
    dimensions.stageTop !== oldStageTop
  ) {
    dispatch({
      type: ActionTypes.Floor.STAGE_DIMENSIONS_CHANGED,
      next: {
        left: dimensions.stageLeft,
        height: dimensions.stageHeight,
        top: dimensions.stageTop,
        width: dimensions.stageWidth,
      },
      previous: {
        left: oldStageLeft,
        height: oldStageHeight,
        top: oldStageTop,
        width: oldStageWidth,
      },
    })
  }
}

const setStageDimensionsAreStable = () => ({
  type: ActionTypes.Floor.SET_STAGE_DIMENSIONS_ARE_STABLE,
})

// visible dimensions of rendered floor, not including docked sheets
export const makeCalculateStageDimensions =
  (
    calculateCenteringOffset: any,
    calculateZoomSelector: any,
    /* @ts-expect-error auto-src: strict-conversion */
    calculateShouldTweenToNewCenterAndZoom: any = ({ previous, next }) =>
      floatEquals(previous.zoom, previous.minZoom) ||
      (!floatEquals(previous.zoom, next.minZoom) &&
        previous.zoom < next.minZoom),
  ) =>
  () =>
  (dispatch: any, getState: any) => {
    /* @ts-expect-error auto-src: strict-conversion */
    if (!stageDimensionsStabilizationMonitor) {
      stageDimensionsStabilizationMonitor = new StabilizationMonitor({
        areValuesEqual: areStageDimensionsEqual,
        onStabilization: () => dispatch(setStageDimensionsAreStable()),
      })
    }

    const dimensions = stageDimensions()
    const state = getState()
    const oldStageHeight = state.floorStore.get('stageHeight')
    const oldStageLeft = state.floorStore.get('stageLeft')
    const oldStageTop = state.floorStore.get('stageTop')
    const oldStageWidth = state.floorStore.get('stageWidth')

    if (
      dimensions.stageHeight > 0 &&
      dimensions.stageWidth > 0 &&
      (dimensions.stageHeight !== oldStageHeight ||
        dimensions.stageLeft !== oldStageLeft ||
        dimensions.stageTop !== oldStageTop ||
        dimensions.stageWidth !== oldStageWidth)
    ) {
      dispatch(
        makeStageDimensionsChanged(
          calculateCenteringOffset,
          calculateShouldTweenToNewCenterAndZoom,
          calculateZoomSelector,
        )({
          next: {
            height: dimensions.stageHeight,
            left: dimensions.stageLeft,
            top: dimensions.stageTop,
            width: dimensions.stageWidth,
          },
          previous: {
            height: oldStageHeight || 0,
            left: oldStageLeft || 0,
            top: oldStageTop || 0,
            width: oldStageWidth || 0,
          },
        }),
      )
    }

    /* @ts-expect-error auto-src: strict-conversion */
    stageDimensionsStabilizationMonitor.setNewValue(dimensions)
  }
export const mountNavbar = () => checkForNewStageDimensions
export const pixiRendererReady = () => checkForNewStageDimensions

const updateStageDimensions =
  (calculateCenteringOffset: any, calculateZoomSelector: any) =>
  () =>
  (dispatch: any, getState: any) =>
    dispatch(
      getState().selectedFloor.get('isRendered')
        ? makeCalculateStageDimensions(
            calculateCenteringOffset,
            calculateZoomSelector,
          )()
        : checkForNewStageDimensions,
    )

export const makeMountDockingLayer = updateStageDimensions
export const makeUnmountDockingLayer = updateStageDimensions
export const makeUpdateDockingLayer = updateStageDimensions
export const makeWindowResize = updateStageDimensions
// FIXME: The action here should receive the resource type and id then figure out distance and whether to
// actually slide by itself rather than being passed distance.
// eslint-disable-next-line camelcase
export const UNSAFE_revealResource =
  (distance: { x: number; y: number }) => (dispatch: any) => {
    // We don't want to trigger a resource reveal if we are already tweening to that resource
    /* @ts-expect-error auto-src: non-strict-conversion */
    if (runningAnimations.zoomTween) {
      return
    }

    resetAllAnimations()
    const toValues = {
      deltaX: distance.x,
      deltaY: distance.y,
    }
    const fromValues = mapValuesToZero(toValues)

    tweenValuesByUpdateDifference({
      animationKey: 'revealResource',
      dispatch,
      dispatchType: ActionTypes.Floor.private.REVEAL_RESOURCE_TWEEN_STEP,
      duration: VDAnimationConstants.slideFloorPlan.duration,
      ease: VDAnimationConstants.slideFloorPlan.ease,
      fromValues,
      toValues,
    })
  }
export const makeCenterPin =
  (calculateZoomSelector: any) =>
  ({ x, y }: { x: number; y: number }) =>
  (dispatch: any, getState: any) => {
    const state = getState()
    const zoom = calculateZoomSelector(state)
    const floorPlanBoundingBox = state.floorStore.get('floorPlanBoundingBox')
    const allowedZoom = zoom.max
    const deltaZoom = allowedZoom - zoom.min
    const halfFloorHeight = floorPlanBoundingBox.height / 2
    const halfFloorWidth = floorPlanBoundingBox.width / 2
    const floorCenter = {
      x: floorPlanBoundingBox.xOffset + halfFloorWidth,
      y: floorPlanBoundingBox.yOffset + halfFloorHeight,
    }
    const zoomedFloorCenter = mapValues(multiply(deltaZoom))(floorCenter)
    const localPinOffsetFromCenter = {
      x: x - floorCenter.x,
      y: y - floorCenter.y,
    }
    const zoomedPinOffsetFromCenter = mapValues(multiply(allowedZoom))(
      localPinOffsetFromCenter,
    )
    const pinFocusOffset = {
      x: -zoomedPinOffsetFromCenter.x - zoomedFloorCenter.x,
      y: -zoomedPinOffsetFromCenter.y - zoomedFloorCenter.y,
    }

    dispatch({
      type: ActionTypes.Floor.CENTER_PIN,
      deltaZoom,
      xOffsetFromCenter: pinFocusOffset.x,
      yOffsetFromCenter: pinFocusOffset.y,
    })
  }
//
// Directly update floor state
//
// Update Redux immediately instead of waiting for the huddle update call
// and then the huddle load-floors call.
export const requestUpdateFavoriteSite = ({
  siteId,
  newFavoriteValue,
}: {
  siteId: number
  newFavoriteValue: boolean
}) => ({
  type: ActionTypes.Floor.REQUEST_UPDATE_FAVORITE_SITE,
  newFavoriteValue,
  siteId,
})
export const setShouldCenter = () => ({
  type: ActionTypes.Floor.SET_SHOULD_CENTER,
})
export const clearShouldCenter = () => ({
  type: ActionTypes.Floor.CLEAR_SHOULD_CENTER,
})
export const backgroundReady = (
  currentlyLoadedFloorPlanUrl: string | null,
) => ({
  type: ActionTypes.Floor.BACKGROUND_READY,
  currentlyLoadedFloorPlanUrl,
})
export const tweenSelectorScaleAlmostEnded = () => ({
  type: ActionTypes.Floor.SELECTOR_ZOOM_ALMOST_ENDED,
})
export const tweenSelectorScaleStarted = () => ({
  type: ActionTypes.Floor.SELECTOR_ZOOM_STARTED,
})
export const tweenSelectorScaleEnded = () => ({
  type: ActionTypes.Floor.SELECTOR_ZOOM_ENDED,
})
export const tweenSelectorScaleRequested = () => ({
  type: ActionTypes.Floor.SELECTOR_ZOOM_REQUESTED,
})
export const filterFocused = () => ({
  type: ActionTypes.Floor.FILTER_FOCUSED,
})
export const downBeyondFilter = () => ({
  type: ActionTypes.Floor.FILTER_LEAVE_DOWN,
})
export const leaveList = () => ({
  type: ActionTypes.Floor.LIST_LEAVE,
})
export const upBeyondFloorList = () => ({
  type: ActionTypes.Floor.LIST_LEAVE_UP,
})
export const setRoomStatusTimer = (newTime: Date) => ({
  type: ActionTypes.Floor.SET_ROOM_STATUS_TIMER,
  newTime,
})
