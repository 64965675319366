import UAParser from 'ua-parser-js'
import includes from 'lodash/includes'

/* @ts-expect-error auto-src: strict-conversion */
function isBrowserSupported(browserData) {
  const { name } = browserData.browser
  const supportedBrowsers = [
    'Mobile Safari',
    'Safari',
    'Chrome Headless',
    'Chrome',
    'Chrome WebView',
    'Chromium',
    'Chromium Headless',
    'Firefox',
    'IE',
    'Edge',
  ]

  if (includes(supportedBrowsers, name)) {
    return true
  }

  const engineName = browserData.engine.name
  const osName = browserData.os.name

  /* This is a fallback because the browser opened by the homescreen bookmark on iOS does not
     return a browser name in the UA string, but we do not want to show the browser warning */
  if (engineName === 'WebKit' && osName === 'iOS') {
    return true
  }

  return false
}

const parserData = new UAParser().getResult()

type Browser = {
  name: string | null | undefined
  type: string
  version: string | null | undefined
  supported: boolean | null | undefined
}
const browser: Browser = Object.freeze({
  name: parserData.browser.name,
  type: parserData.device.type || '',
  version: parserData.browser.version,
  supported: isBrowserSupported(parserData),
})

export default browser
