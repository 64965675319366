import React from 'react'
import type { ReactNode } from 'react'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import PersonClockIcon from 'common/icons/PersonClockIcon'

import useStyles from './useStyles'

type Props = {
  dateTimePicker: ReactNode
  onCloseButtonClick: () => void
}

const Header = ({ dateTimePicker, onCloseButtonClick }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.root} data-testid="header">
      <div className={classes.heading}>
        <PersonClockIcon className={classes.icon} />
        <span className={classes.title}>Who’s In</span>
        <IconButton
          className={classes.closeButton}
          data-testid="close-button"
          onClick={onCloseButtonClick}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </div>
      <div className={classes.subheading}>
        See who’s in or planning to come in
      </div>
      {dateTimePicker && (
        <div className={classes.dateFilterWrapper}>{dateTimePicker}</div>
      )}
    </div>
  )
}

export default Header
