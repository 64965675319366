import { useSiteDateTimePickerDataQuery as useQuery } from 'generated/graphql'

import type { Result } from './types'

type Args = {
  siteId: string
  skip: boolean
}

const useSiteData = ({ siteId, skip }: Args): Result => {
  const { data, loading } = useQuery({ variables: { siteId }, skip })
  const site = data?.sites[0]

  if (!site) {
    return { data: undefined, loading }
  }

  return {
    data: {
      ...site,
      limitToOperatingHours: site.deskBookingSettings.limitToOperatingHours,
    },
    loading,
  }
}

export default useSiteData
