import React from 'react'

import type { Moment } from 'moment-timezone'
import { useTranslation } from 'react-i18next'

import TimeField from 'common/components/DateTimeFields/TimePicker'
import Tooltip from 'common/components/LightTooltip/LightTooltip'
import { formatToMilitaryTime } from 'common/types/time'

import useStyles from './useStyles'

import type { TimeSlot } from './types'

type Props = {
  now?: Moment
  onChange: (time: string) => void
  value?: Moment | null
  timeSlots: TimeSlot[]
  placeholder?: string | null
}

const TimePicker = ({
  now,
  onChange,
  value,
  timeSlots,
  placeholder,
}: Props): JSX.Element => {
  const classes = useStyles()

  const { t } = useTranslation('common', {
    keyPrefix: 'common.calendar',
  })

  const handleChange = (momentTime: Moment): void => {
    onChange(formatToMilitaryTime(momentTime))
  }

  const timeSlotsAvailable = timeSlots.length > 0

  return (
    <div className={classes.root}>
      <div className={classes.label}>Time:</div>
      <Tooltip
        disableFocusListener={timeSlotsAvailable}
        disableHoverListener={timeSlotsAvailable}
        placement="bottom"
        title={t('time_picker.disabled_reason')}
      >
        <div className={classes.field}>
          <TimeField
            disabled={!timeSlotsAvailable}
            now={now}
            onChange={handleChange}
            options={timeSlots}
            placeholder={placeholder || t('now')}
            value={value}
          />
        </div>
      </Tooltip>
    </div>
  )
}

export default TimePicker
