import React from 'react'
import type { ReactNode } from 'react'
import { GroupedVirtuoso } from 'react-virtuoso'

import type { RosterItem as Item } from '../useData'
import GroupHeader from './GroupHeader'
import ItemWrapper from './ItemWrapper'
import GroupHeaderWrapper from './GroupHeaderWrapper'
import LoadingState from './LoadingState'
import LoadingMore from './LoadingState/LoadingMore'
import EmptyState from './EmptyState'

type Props = {
  rosterItems: ReadonlyArray<Item>
  groupCounts: ReadonlyArray<number>
  groupNames: ReadonlyArray<string>
  loadMore: () => void
  loading: boolean
  initialLoad: boolean
  renderItem: (arg0: Item) => ReactNode
}

const overscanPixels = 500

const GroupedList = ({
  rosterItems,
  groupCounts,
  groupNames,
  initialLoad,
  loadMore,
  loading,
  renderItem,
}: Props): JSX.Element => {
  const itemsCount = rosterItems.length
  const noResults = !loading && itemsCount === 0

  if (initialLoad) {
    return <LoadingState />
  }

  if (noResults) {
    return (
      <EmptyState
        subtitle={
          <>
            Please try a different date/time
            <br />
            or another location
          </>
        }
        title="No bookings"
      />
    )
  }

  const Footer = () => <LoadingMore loading={loading} />

  return (
    <GroupedVirtuoso
      components={{
        Group: GroupHeaderWrapper,
        Footer,
        Item: ItemWrapper,
      }}
      data-testid="roster-items"
      endReached={loadMore}
      groupContent={(index) => <GroupHeader>{groupNames[index]}</GroupHeader>}
      /* @ts-expect-error auto-src: non-strict-conversion */
      groupCounts={groupCounts}
      itemContent={(index) => renderItem(rosterItems[index])}
      overscan={overscanPixels}
      style={{
        flexGrow: 1,
      }}
      totalCount={itemsCount}
    />
  )
}

export default GroupedList
