import { makeStyles } from '@material-ui/styles'

export default makeStyles({
  root: {
    fontSize: 10,
    padding: '0px 12px',
    height: 24,
    minWidth: 64,
    lineHeight: '24px',
    borderRadius: 2,
    backgroundColor: '#f5f5f5',
    flex: 'none',
  },
})
