import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette }) => ({
  root: {
    color: palette.grey[600],
    fontSize: 10,
    marginTop: 8,
  },
  status: {
    fontWeight: 600,
  },
  details: {
    fontWeight: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))
