// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const feedbackEmailBody = () =>
  'Hello OfficeSpace Software!\n\n' +
  `I was at ${window.location.href} and I would like to share the following feedback:` +
  '\n\n\n\n\n\n\n' +
  'Some technical details:\n' +
  `    Agent: ${navigator.userAgent}\n` +
  /* @ts-expect-error auto-src: non-strict-conversion */
  `    OS: ${navigator.oscpu}\n` +
  `    App: ${navigator.appName}\n` +
  `    Ver: ${navigator.appVersion}`

export default (email?: string) => {
  const feedbackAddress = email || 'support@officespacesoftware.com'
  const subject = encodeURIComponent('Mobile Visual Directory feedback')
  const body = encodeURIComponent(feedbackEmailBody())

  window.location.href = `mailto:${feedbackAddress}?subject=${subject}&body=${body}`
}
