import { useQuery } from '@apollo/client'
/* @ts-expect-error auto-src: non-strict-conversion */
import SeatDataQuery from './SeatData.graphql'

export type Seat = {
  id: string
  label: string
  employee: null | {
    id: string
    fullName: string
    photo: string
    title: string
  }
  seatAvailability: null | {
    name: string
    type: string
    firstDayOfWeek?: string
    id: string
    schedule?: ReadonlyArray<number> | null | undefined
  }
}

type Data = {
  seats: ReadonlyArray<Seat>
}

type Variables = {
  ids: ReadonlyArray<string>
}

type Result = {
  loading: boolean
  seat: Seat
}

const useData = (variables: Variables): Result => {
  const { loading, data } = useQuery<Data, Variables>(SeatDataQuery, {
    variables,
    fetchPolicy: 'network-only',
  })

  const seat = (data?.seats || [])[0]

  return {
    loading,
    seat,
  }
}

export default useData
