// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
// methods extracted from /visual_directory/lib/hotDesking.js
import compact from 'lodash/compact'
import moment from 'moment'
import uniq from 'lodash/uniq'
import type { Employee } from 'common/types/employee'
import type { SeatOpenBooking } from 'common/types/seatOpenBooking'
import zeroPad from './zeroPad'

export const bookingActionName = (action = '') => {
  const dictionary = {
    canceled: 'cancelled',
  }

  /* @ts-expect-error auto-src: strict-conversion */
  return dictionary[action] || action
}
export const isAnonymous = (name: string | null | undefined) =>
  name && name.toLowerCase() === 'anonymous'
export const bookingActionPerformerName = (
  performerName: string | null | undefined,
  currentUserName: string,
  occupantName: string,
): string | null | undefined => {
  const nameToShow = isAnonymous(performerName)
    ? `${occupantName} (unverified)`
    : performerName
  const performedByCurrentUser = currentUserName === nameToShow

  return performedByCurrentUser ? 'You' : nameToShow
}

/* @ts-expect-error auto-src: strict-conversion */
const getFirstName = (fullName) =>
  fullName && fullName.match(/^[a-zA-Z]+(\w|$)/)[0]

/* @ts-expect-error auto-src: strict-conversion */
const excludeName = (names, nameToExclude) =>
  /* @ts-expect-error auto-src: strict-conversion */
  names.filter((name) => name !== nameToExclude)

const ownersNames = (booking: SeatOpenBooking) => {
  const occupantFullName = [
    booking.employee?.firstName,
    booking.employee?.lastName,
  ].join(' ')
  const createdByFullName = booking.checkInBy || booking.createdBy
  const createdByDisplayName = isAnonymous(createdByFullName)
    ? occupantFullName
    : createdByFullName

  return uniq(compact([occupantFullName, createdByDisplayName]))
}

export const bookingUpdateMessageRecipientNames = (
  booking: SeatOpenBooking,
  currentUserName?: string,
) =>
  excludeName(ownersNames(booking), currentUserName)
    .map(getFirstName)
    .join(' and ')
export const dateParts = (date: any, forText = false) => {
  const nativeDate = moment.isMoment(date) ? date.toDate() : date

  return [
    nativeDate.getFullYear(),
    zeroPad(nativeDate.getMonth() + (forText ? 1 : 0)),
    zeroPad(nativeDate.getDate()),
  ]
}
export const timeParts = (time: any): ReadonlyArray<number> => {
  const nativeDate = moment.isMoment(time) ? time.toDate() : time

  return [
    nativeDate.getHours(),
    nativeDate.getMinutes(),
    nativeDate.getSeconds(),
  ].map(zeroPad)
}
export const bookingDurationHours = ({
  checkInTime,
  checkOutTime,
  checkOutScheduled,
}: SeatOpenBooking) => {
  const startTime = moment(checkInTime)
  const endTime = moment(checkOutTime || checkOutScheduled)

  return endTime.diff(startTime, 'hours')
}
/* @ts-expect-error auto-src: non-strict-conversion */
export const timeTo = (dateTime: moment) => {
  const dateDiff = dateTime.diff(moment())

  return moment.duration(dateDiff)
}
export const messageRecipientNames = (
  employee?: Employee,
  checkInBy?: string | null,
) => {
  const names =
    employee?.fullName === checkInBy
      ? [employee?.firstName]
      : [employee?.firstName, (checkInBy || '').split(' ')[0]]

  return names.filter((name) => name && name !== 'anonymous').join(' and ')
}
export const cancelMessage = (recipients: string) =>
  `Tell ${recipients} why you are canceling this booking`
export const declineMessage = (recipients: string) =>
  `Tell ${recipients} why you are declining this request`
export const editMessage = (recipients: string) =>
  `Tell ${recipients} why you are changing this booking`
export const endMessage = (recipients: string) =>
  `Tell ${recipients} why you are ending this booking`
export const replyMessage = (recipients: string) => `Reply to ${recipients}`
