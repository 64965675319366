import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import useStyles from './useStyles'

type Props = {
  children: React.ReactNode
  onClick: React.MouseEventHandler<HTMLLIElement>
}

const ContextMenuItem = (
  { children, onClick }: Props,
  ref: React.ForwardedRef<HTMLLIElement>,
) => {
  const classes = useStyles()

  return (
    <MenuItem ref={ref} classes={classes} onClick={onClick}>
      {children}
    </MenuItem>
  )
}

export default React.forwardRef<HTMLLIElement, Props>(ContextMenuItem)
