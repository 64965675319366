import React from 'react'
import { connect } from 'react-redux'
import { destroy } from 'redux-form'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import NavMenuItem from 'common/components/navmenu/NavMenuItem'
import { createRequestPath } from 'visual_directory/lib/paths'
import { REQUEST_FORM_NAME } from 'visual_directory/constants/requestForm'
import CreateRequestIcon from 'visual_directory/components/icons/CreateRequestIcon'

type Props = {
  createFloorRequestPath: string
  destroyRequestCreationForm: () => void
  isGuest?: boolean
  onClick?: () => void
  showImprovedNavigation?: boolean
}

const UnconnectedCreateARequest = ({
  createFloorRequestPath,
  destroyRequestCreationForm,
  isGuest,
  onClick,
  showImprovedNavigation,
}: Props) => {
  const { t } = useTranslation('visual_directory', {
    keyPrefix: 'visual_directory.home_menu',
  })

  const handleClick = () => {
    if (onClick) {
      onClick()
    }

    destroyRequestCreationForm()
  }

  const shouldChangeStyle = !showImprovedNavigation || isGuest

  return (
    <NavMenuItem
      component={Link}
      isBold={shouldChangeStyle}
      LeftIcon={shouldChangeStyle && CreateRequestIcon}
      onClick={handleClick}
      to={createFloorRequestPath}
    >
      {t('create_a_request')}
    </NavMenuItem>
  )
}

export default connect(
  /* @ts-expect-error auto-src: strict-conversion */
  ({ selectedFloor }) => ({
    createFloorRequestPath: createRequestPath(selectedFloor.get('id')),
  }),
  {
    destroyRequestCreationForm: () => destroy(REQUEST_FORM_NAME),
  },
)(UnconnectedCreateARequest)
