import UAParser from 'ua-parser-js'
import BoundingBox from 'common/records/boundingBox'
import type { ValuesType } from 'utility-types'

export const DESATURATED_FLOOR_MAP_ALPHA = 0.7
export const ZOOM_SELECTOR_ALMOST_ENDED_PERCENT = 0.6
export const SEAT_RADIUS_PX = 14
export const FINGER_RADIUS_PX = 10
export const FLOOR_NAME_LABEL_MAX_ZOOM = 4
export const selectorZoomStatuses = {
  ALMOST_FINISHED: 'ALMOST_FINISHED',
  FINISHED: 'FINISHED',
  REQUESTED: 'REQUESTED',
  STARTED: 'STARTED',
} as const
export type SelectorZoomStatuses = ValuesType<typeof selectorZoomStatuses>
const userAgent = new UAParser().getResult()
const eventBatchingDelayMs = 50
const bounceDelayMultiple = 4
const defaultIncrements = 10
const macOsIncrements = 50

export const zoomConstants = {
  eventBatchingDelayMs,
  ignoreBounceMs: bounceDelayMultiple * eventBatchingDelayMs,
  numberOfZoomIncrements: {
    zoomButton: 10,
    mouseWheel:
      userAgent.os.name === 'Mac OS' ? macOsIncrements : defaultIncrements,
  },
}
const unsetFloorHeight = 768.0

export const unsetFloorWidth = 1024.0
// This is later destructured in a function argument but Flow couldn't figure
// out that these properties existed, so we cast to a regular object

export const unsetFloorPlanBoundingBox = BoundingBox({
  height: unsetFloorHeight,
  width: unsetFloorWidth,
  xOffset: 0,
  yOffset: 0,
})
