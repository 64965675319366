import React from 'react'

import timePeriodFormatter from 'visual_directory/lib/timePeriodFormatter'
import { timeZoneAgnosticMoment } from '../lib'

type Props = {
  concealEmployeeInfo: boolean
  isPending: boolean
  isToday: boolean
  localCheckInTime: string
  localCheckOutScheduled: string
}

const Booked = ({
  concealEmployeeInfo,
  isPending,
  isToday,
  localCheckInTime,
  localCheckOutScheduled,
}: Props): JSX.Element => (
  <div>
    {isPending && !concealEmployeeInfo && <i>Pending booking request:</i>}
    <div>
      {timePeriodFormatter({
        timePeriod: {
          start: timeZoneAgnosticMoment(localCheckInTime),
          end: timeZoneAgnosticMoment(localCheckOutScheduled),
        },
        variant: isToday ? 'time' : 'datetime',
      })}
    </div>
  </div>
)

export default Booked
