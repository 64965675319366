import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import useIsSessionExpired from 'common/hooks/useIsSessionExpired'
import CenteredSpinner from 'common/components/CenteredSpinner'
import styles from './styles'
import {
  QR_CODE_SIZE,
  MAGIC_LINK_PATH,
  MAGIC_LINK_LIFESPAN_MS,
} from '../constants'

const qrCodePath = (timestamp: number) =>
  `${MAGIC_LINK_PATH}?size=${QR_CODE_SIZE}&t=${timestamp}`

/* @ts-expect-error auto-src: non-strict-conversion */
const useStyles = makeStyles(styles)

const QRCode = (): JSX.Element => {
  const isSessionExpired = useIsSessionExpired()
  const [timestamp, setTimestamp] = useState<number>(Date.now())
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true)
  const QRStyles = useStyles()

  useEffect(() => {
    const timer = setInterval(() => {
      setTimestamp(Date.now())
    }, MAGIC_LINK_LIFESPAN_MS)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const handleImageLoad = () => {
    setIsImageLoading(false)
  }

  if (isSessionExpired) {
    window.location.reload(true)
  }

  return (
    <div className={QRStyles.qrCodeContainer}>
      <img
        alt="QR code"
        className={QRStyles.qrCode}
        height={QR_CODE_SIZE}
        onLoad={handleImageLoad}
        src={qrCodePath(timestamp)}
        width={QR_CODE_SIZE}
      />
      {isImageLoading && <CenteredSpinner />}
    </div>
  )
}

export default QRCode
