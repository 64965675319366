// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { createSelector } from 'reselect'
import type { OutputSelector } from 'reselect'
import { large } from '../../constants/viewport'

const selector: OutputSelector<any, any, any> = createSelector(
  ({ innerWidth }) => innerWidth,
  (width) => width < large.max,
)

export default ({ innerWidth, innerHeight }: any) =>
  selector({
    innerWidth,
    innerHeight,
  })
