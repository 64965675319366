// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { useMemo } from 'react'
import { createMuiTheme } from '@material-ui/core/styles'
import StyleConstants from 'visual_directory/components/styles/definitions'

const removeEmpty = (obj: Record<string, any>): any =>
  Object.entries(obj)
    .filter(([, value]) => value != null && value !== '')
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value === Object(value) ? removeEmpty(value) : value,
      }),
      {},
    )

export const defaultBrandingTheme = {
  palette: {
    branding: {
      navbar: {
        text: StyleConstants.navbar.details.fontColor,
        icon: StyleConstants.navbar.details.iconColor,

        dateTimePicker: {
          iconColor: StyleConstants.navbar.details.iconColor,
        },
      },
      home: {
        tileIcon: '#42A5F5',
      },
    },
  },
}
type BrandingConfig = Record<string, any>
export default (config: BrandingConfig) =>
  useMemo(() => {
    if (!config) {
      return createMuiTheme()
    }

    return createMuiTheme(
      /* @ts-expect-error auto-src: non-strict-conversion */
      defaultBrandingTheme,
      removeEmpty({
        palette: {
          branding: {
            navbar: {
              text: config.navbar.textColor,
              icon: config.navbar.iconColor,

              dateTimePicker: {
                iconColor: config.navbar.dateTimePicker.iconColor,
              },
            },
            home: {
              tileIcon: config.home.tileIconColor,
            },
          },
        },
      }),
    )
  }, [config])
