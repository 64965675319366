import React from 'react'

import SearchField from './SearchField'
import useStyles from './useStyles'

type Props = {
  searchTerm: string | null
  onSearchTermChange: (searchTerm: string | null) => void
}

const Subheader = ({ searchTerm, onSearchTermChange }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.root} data-testid="subheader">
      <SearchField onChange={onSearchTermChange} searchTerm={searchTerm} />
    </div>
  )
}

export default Subheader
