import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  actions: {
    padding: 0,
  },
  content: {
    padding: 0,
  },
  header: {
    padding: 0,
  },
})

export default useStyles
