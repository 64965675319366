import merge from 'lodash/merge'
import SharedStyles from './shared'
import StyleConstants from './definitions'

const leftPadding = 22

export const browsePanelSubheaderStyles = {
  backgroundColor: StyleConstants.color.headingBackground,
  color: StyleConstants.font.color.majorHeading,
  fontFamily: StyleConstants.font.family,
  fontWeight: StyleConstants.font.weight.semibold,
  lineHeight: '30px',
  paddingLeft: '20px',
}
const listStyles = {
  container: {
    paddingBottom: 0,
  },
  subheader: {
    root: {
      backgroundColor: StyleConstants.color.headingBackground,
      color: StyleConstants.font.color.majorHeading,
      fontFamily: StyleConstants.font.family,
      fontSize: StyleConstants.font.size.heading,
      fontWeight: StyleConstants.font.weight.bold,
      lineHeight: '30px',
      paddingLeft: leftPadding,
    },
  },
  placesSubheader: {
    ...browsePanelSubheaderStyles,
    fontSize: StyleConstants.font.size.standard,
  },
  listItem: {
    style: {
      color: StyleConstants.font.color.majorHeading,
      fontSize: StyleConstants.font.size.heading,
      /* @ts-expect-error auto-src: non-strict-conversion */
      fontWeight: StyleConstants.font.weight.standard,
      paddingLeft: 22,
      paddingTop: 0,
      paddingBottom: 0,
      minHeight: 48,
    },
    innerDivStyle: {
      overflow: 'hidden',
      paddingLeft: leftPadding,
    },
  },
  listItemText: {
    primary: { ...SharedStyles.ellipsis },
  },
  menuItem: {
    whiteSpace: null,
  },
  stars: {
    favorite: {
      color: StyleConstants.color.favoriteFill,
      height: 20,
      width: 20,
      WebkitTextStroke: `1px ${StyleConstants.color.favoriteBorder}`,
    },
    nonfavorite: {
      color: StyleConstants.color.nonfavoriteFill,
      height: 20,
      width: 20,
    },
    style: {
      flex: 'none',
      height: 20,
      padding: 0,
      width: 48,
    },
  },
  deskBookingFloorItem: {
    innerDivStyle: {
      paddingTop: '12px',
      paddingBottom: '12px',
      height: '36.5px',
    },
    secondaryText: {
      fontSize: StyleConstants.font.size.standard,
    },
  },
}

export const extendedListStyles = merge({}, listStyles, {
  listItem: {
    innerDivStyle: {
      paddingBottom: '4px',
      paddingLeft: '10px',
      paddingTop: '4px',
    },
    style: {
      fontSize: StyleConstants.font.size.standard,
      lineHeight: '18px',
      minHeight: '60px',
    },
  },
  listItemText: {
    primary: {
      fontSize: '13px',
    },
  },
  subheader: {
    root: {
      fontSize: '14px',
      fontWeight: StyleConstants.font.weight.semibold,
    },
  },
})
export const floorPickerListStyles = merge({}, listStyles, {
  stars: {
    style: {
      position: 'static',
      // Set to default due to Chrome bug with relative position and animations
      width: 24,
      height: 24,
      zIndex: null, // Set to null due to Chrome bug with zIndex and animations
    },
  },
  subheader: {
    root: {
      fontSize: '13px',
      fontWeight: StyleConstants.font.weight.semibold,
      height: 30,
      lineHeight: '18px',
      paddingBottom: 5,
      paddingLeft: 16,
      paddingRight: 10,
      paddingTop: 5,
      width: '100%',
      backgroundColor: 'rgb(245, 245, 245)',
      boxSizing: 'border-box',
    },
  },
  listItemText: {
    primary: {
      fontSize: '13px',
    },
  },
  listItem: {
    style: {
      paddingLeft: 16,
      width: 285,
    },
    innerDivStyle: {
      boxSizing: 'border-box',
      fontSize: '13px',
      height: 34,
      lineHeight: '18px',
      paddingBottom: 8,
      paddingLeft: 16,
      paddingTop: 8,
      position: 'static', // Set to default due to Chrome bug with relative position and animations
    },
  },
})
export default listStyles
