// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState, useEffect } from 'react'
import mediumOrGreater from 'common/selectors/viewport/mediumOrGreater'
import portraitHandsetOrTablet from 'common/selectors/viewport/portraitHandsetOrTablet'

export default () => {
  const [inMobileMode, setInMobileMode] = useState(
    portraitHandsetOrTablet(window),
  )
  const ignoreOrientationChange = mediumOrGreater(window)

  useEffect(() => {
    const handleOrientationChange = () => {
      if (ignoreOrientationChange) {
        return
      }

      setInMobileMode(window.matchMedia('(orientation: landscape)').matches)
    }

    window.addEventListener('orientationchange', handleOrientationChange)

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange)
    }
  }, [ignoreOrientationChange])

  return inMobileMode
}
