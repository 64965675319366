const AnimationConstants = {
  crosshairRotation: {
    period: 12,
    ease: 'none',
  },
  crosshairFade: {
    duration: 0.5,
  },
  selectorZoom: {
    duration: 2,
    ease: 'power4.out',
  },
  selectorSpin: {
    duration: 1,
    ease: 'back.out',
  },
  selectorPop: {
    duration: 0.75,
    ease: 'power4.out',
  },
  selectorContainerFade: {
    duration: 0.3,
  },
  centerFloorPlan: {
    duration: 0.75,
    ease: 'power4.out',
  },
  zoomFloorPlan: {
    duration: 0.75,
    ease: 'power4.out',
  },
  toZoomBound: {
    maxDuration: 0.75,
    ease: 'power4.out',
  },
  preventOverZoom: {
    duration: 0.2,
    ease: 'power1.inOut',
  },
  floorPlanInertia: {
    duration: 0.5,
    ease: 'expo.out',
  },
  fadeTextLabels: {
    duration: 1,
    ease: 'expo.inOut',
  },
  textLabelHover: {
    mouseOver: {
      tweenMultiplier: 1.3,
      duration: 0.25,
      ease: 'back.out',
    },
    mouseOut: {
      tweenMultiplier: 1.15,
      duration: 0.3,
      ease: 'expo.inOut',
    },
  },
  floorNameLabel: {
    duration: 0.4,
  },
  roomFadeout: {
    duration: 0.2,
    ease: 'power4.in',
  },
}

export default AnimationConstants
