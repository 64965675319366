// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import merge from 'lodash/merge'
/* @ts-expect-error auto-src: strict-conversion */
import zIndex from 'material-ui-old/styles/zIndex'
import { fade } from '@material-ui/core/styles/colorManipulator'
import SearchStyles from './search'
import SharedStyles from './shared'
import StyleConstants from './definitions'
import unbrandedSearchBar from './searchBar'
import { navInputStyles } from './navInput'

const floorPickerMaxWidth = 340

export const errorStyles = merge({}, SearchStyles.searchResults.noResults, {
  containerStyle: {
    backgroundColor: StyleConstants.navbar.details.background,
    boxSizing: 'border-box',
    height: 264,
    paddingTop: 28,
  },
  iconStyle: {
    color: StyleConstants.floorPicker.icon,
  },
})
const componentWidth = 285

const iconButtonStyles = ({
  branding: {
    navbar: {
      /* @ts-expect-error auto-src: strict-conversion */
      floorPicker: { iconColor },
    },
  },
}) => ({
  color: iconColor,
  height: 20,
  paddingBottom: 5,
  paddingLeft: 7,
  paddingTop: 5,
  width: 20,
})

export default (options: any) => {
  const { floorPicker } = options.branding.navbar
  const { portraitHandsetOrTablet, mediumOrGreater } =
    options.viewportCategories

  return merge({}, navInputStyles, {
    container: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
    },
    floorFilterForm: merge({}, unbrandedSearchBar(), {
      inputDiv: {
        border: `1px solid  ${fade(
          floorPicker.inputBorderColor,
          floorPicker.inputBorderAlpha,
        )}`,
        boxShadow: null,
      },
      selectedFloor: {
        color: floorPicker.selectedTextColor,
      },
      siteSearchWrapper: {
        flex: '1 1 0px',
        marginLeft: 16,
        display: 'flex',
        alignItems: 'center',
      },
    }),
    headerText: {
      ...SharedStyles.ellipsis,
      color: floorPicker.textColor,
      fontSize: 12,
      fontWeight: StyleConstants.font.weight.semibold,
      lineHeight: '17px',
      paddingTop: 6,
      width: 197,
    },
    inputDiv: {
      backgroundColor: floorPicker.backgroundColor,
      border: `1px solid  ${fade(
        floorPicker.borderColor,
        floorPicker.borderAlpha,
      )}`,
      boxShadow: null,
      width: null,
      // TODO: Remove `|| mediumOrGreater` when Desktop Enhancements is implemented fully
      maxWidth:
        portraitHandsetOrTablet || mediumOrGreater
          ? undefined
          : floorPickerMaxWidth,
    },
    mapsLayersWrapperStyle: {
      flex: '0 0 auto',
      width: 35,
    },
    mapsLayersStyle: { ...iconButtonStyles(options), paddingRight: 8 },
    navigationExpandLessWrapperStyle: {
      cursor: 'pointer',
      flex: '0 0 auto',
      width: 41,
    },
    navigationExpandLessStyle: {
      ...iconButtonStyles(options),
      paddingBottom: 11,
      paddingLeft: 11,
      paddingRight: 10,
      paddingTop: 11,
    },
    navigationExpandMoreWrapperStyle: {
      cursor: 'pointer',
      flex: '0 0 auto',
      width: 33,
    },
    navigationExpandMoreStyle: {
      ...iconButtonStyles(options),
      paddingRight: 6,
    },
    popover: {
      root: {
        width: componentWidth,
        transform: 'none', // This disables the sliding part of the popover animation.
      },
      header: {
        container: {
          backgroundColor: floorPicker.backgroundColor,
          boxShadow: '0 2px 2px rgba(0, 0, 0, 0.24)',
          display: 'flex',
          flex: '0 0 auto',
          height: StyleConstants.navbar.height,
          position: 'relative',
          width: componentWidth,
          zIndex: zIndex.popover,
        },
      },
    },
  })
}
