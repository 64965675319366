import { makeStyles } from '@material-ui/core/styles'
import type { Theme } from '@material-ui/core/styles'

type Props = {
  truncatePrimaryOverflow: boolean
  truncateSecondaryOverflow: boolean
}

export default makeStyles<Theme, Props>({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  primary: {
    overflow: ({ truncatePrimaryOverflow: truncate }: Props) =>
      truncate ? 'hidden' : 'visible',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  secondary: {
    overflow: ({ truncateSecondaryOverflow: truncate }: Props) =>
      truncate ? 'hidden' : 'visible',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
})
