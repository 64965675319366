import { makeStyles } from '@material-ui/styles'

export default makeStyles({
  formControlRoot: {
    width: '100%',
  },
  input: {
    /* @ts-expect-error auto-src: non-strict-conversion */
    marginTop: ({ label }) => (label ? '6px' : 0),
  },
  select: {
    paddingRight: '8px !important',
  },
})
