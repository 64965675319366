import React from 'react'

import formatTime from './formatTime'

type Props = {
  isToday: boolean
  untilTime?: string
}

const Available = ({ isToday, untilTime }: Props): JSX.Element | null => {
  if (untilTime) {
    return <div>Available until {formatTime(untilTime, isToday)}</div>
  }

  return <div>Available</div>
}

export default Available
