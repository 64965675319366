import React from 'react'

import { PrimaryButton, SecondaryButton } from '../../Layout'

type Props = {
  canBook: boolean
  isRequestRequired: boolean
  onClick: () => void
}

const ActionButton = ({
  canBook,
  isRequestRequired,
  onClick,
}: Props): JSX.Element | null => {
  if (canBook) {
    return (
      <PrimaryButton onClick={onClick}>
        {isRequestRequired ? 'Request Desk' : 'Book Desk'}
      </PrimaryButton>
    )
  }

  return <SecondaryButton onClick={onClick}>View More</SecondaryButton>
}

export default ActionButton
