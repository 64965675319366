import React from 'react'
import type { ReactNode } from 'react'
import withStyles from '@material-ui/styles/withStyles'
import classnames from 'classnames'
import highlightText from './highlightText'
import styles from './styles'

type Props = {
  bold?: boolean
  classes: { [key in keyof typeof styles]?: string }
  hideEllipsis?: boolean
  filterText: string
  text: string
}

const HighlightedTextResult = ({
  bold,
  classes,
  hideEllipsis,
  filterText,
  text,
}: Props): ReactNode => (
  <span
    className={classnames(classes.highlightedText, {
      /* @ts-expect-error auto-src: strict-conversion */
      [classes.ellipsis]: !hideEllipsis,
    })}
    dangerouslySetInnerHTML={{
      __html: highlightText({
        text,
        filterText,
        bold,
      }),
    }}
  />
)

/* @ts-expect-error auto-src: non-strict-conversion */
export default withStyles(styles)(HighlightedTextResult)
