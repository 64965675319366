import type { KioskState } from 'visual_directory/reducers/kiosk'

export const kioskHasFloorId = (kiosk: KioskState): boolean =>
  !!(kiosk && kiosk.youAreHere.floorId)

export const isKioskConfigCompatibleWithDeskBooking = (
  kiosk: KioskState,
): boolean => !kioskHasFloorId(kiosk) || kiosk.enableDeskBooking

export const isKiosk = !!document.cookie.match(/\bkiosk_id=[^;$]+\b/)
