import React from 'react'
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core'

import HighlightedTextResult from 'common/components/HighlightedTextResult'
import CheckedInIcon from 'visual_directory/components/who_is_in/Roster/CheckedInIcon'
import Location from './Location'

import useStyles from './useStyles'

type Props = {
  bookingTime: string | null
  floorLabel: string | null
  fullName: string
  photo: string
  checkedIn: boolean
  seatLabel: string
  selected: boolean
  highlightedText: string | null
  onClick: () => void
}

const Item = ({
  bookingTime,
  floorLabel,
  fullName,
  photo,
  checkedIn,
  seatLabel,
  selected,
  highlightedText,
  onClick,
}: Props): JSX.Element => {
  const classes = useStyles({
    selected,
  })

  return (
    <ListItem
      className={classes.root}
      component="div"
      data-testid="list-item"
      disableGutters
      onClick={onClick}
    >
      <ListItemAvatar className={classes.avatarWrapper}>
        <>
          <Avatar alt={fullName} className={classes.avatar} src={photo} />
          {checkedIn && (
            <div className={classes.badge}>
              <CheckedInIcon />
            </div>
          )}
        </>
      </ListItemAvatar>
      <ListItemText
        classes={{
          root: classes.text,
        }}
        primary={
          <HighlightedTextResult
            bold
            filterText={highlightedText}
            text={fullName}
          />
        }
        primaryTypographyProps={{
          className: classes.primaryText,
          component: 'div',
        }}
        secondary={
          <>
            <Location
              floorLabel={floorLabel}
              highlightedText={highlightedText}
              seatLabel={seatLabel}
            />
            <div>{bookingTime}</div>
          </>
        }
        secondaryTypographyProps={{
          className: classes.secondaryText,
          component: 'div',
        }}
      />
    </ListItem>
  )
}

export default Item
