import moment from 'moment-timezone'
import { DATE_FORMAT } from 'common/types/time'
import timePeriodFormatter from 'visual_directory/lib/timePeriodFormatter'

import { STATUS_COLORS } from './const'
import type { RoomStatus, RoomStatusParams } from './types'

/* @ts-expect-error auto-src: strict-conversion */
const meetingInTimeZone = (meeting, timeZone) => ({
  ...meeting,
  endTime: moment.tz(meeting.endTime, timeZone).format(),
  startTime: moment.tz(meeting.startTime, timeZone).format(),
  timeZone,
})

const timeZoneAgnosticMoment = (time: string): moment.Moment =>
  moment(time, 'YYYY-MM-DDTHH:mm:ss')

export const roomStatus = (params: RoomStatusParams): RoomStatus => {
  const defaultParams = {
    currentTime: moment(),
    isBookable: false,
    timeZone: moment.tz.guess(true),
    timeFormat: 'LT',
  }

  const paramsWithDefaults = { ...defaultParams, ...params }

  const { availableAt, isBookable, settings, timeZone, vdTime, timeFormat } =
    paramsWithDefaults

  const viewedNow = !vdTime

  const currentTime = moment
    .tz(viewedNow ? paramsWithDefaults.currentTime : vdTime, timeZone)
    .seconds(0)
    .milliseconds(0)

  const tzNow = moment().tz(timeZone)
  const isFutureDay = currentTime.isAfter(tzNow, 'day')

  if (!isBookable) {
    return {
      title: '',
      color: STATUS_COLORS.disabled,
      label: '',
      isAvailableForBooking: false,
      availableAt: null,
    }
  }

  const tzAvailableAt = moment
    .tz(availableAt, timeZone)
    .seconds(0)
    .milliseconds(0)

  const getCurrentBooking = () => {
    const { currentBooking, nextBookingToday } = params

    if (
      currentBooking &&
      moment.tz(currentBooking.endTime, timeZone).isAfter(currentTime)
    ) {
      return currentBooking
    }

    if (
      nextBookingToday &&
      moment
        .tz(nextBookingToday.startTime, timeZone)
        .isSameOrBefore(currentTime) &&
      moment.tz(nextBookingToday.endTime, timeZone).isAfter(currentTime)
    ) {
      return nextBookingToday
    }

    return null
  }

  const getNextBookingToday = () => {
    const { nextBookingToday } = params

    if (
      nextBookingToday &&
      moment.tz(nextBookingToday.startTime, timeZone).isAfter(currentTime)
    ) {
      return nextBookingToday
    }

    return null
  }

  const currentBooking = getCurrentBooking()
  const nextBookingToday = getNextBookingToday()

  if (currentBooking) {
    const currentBookingInTimeZone = meetingInTimeZone(currentBooking, timeZone)

    const floatingCard = {
      title: viewedNow ? 'Meeting now:' : 'In progress:',
      formattedPeriod: timePeriodFormatter({
        timePeriod: {
          start: timeZoneAgnosticMoment(currentBookingInTimeZone.startTime),
          end: timeZoneAgnosticMoment(currentBookingInTimeZone.endTime),
        },
        variant: 'time',
      }),
    }

    if (!availableAt) {
      const title = isFutureDay
        ? `Busy on ${currentTime.format(DATE_FORMAT)}`
        : 'Busy today'

      return {
        title,
        meeting: currentBookingInTimeZone,
        color: settings.roomBookingBranding.reserved.color,
        label: 'In progress',
        isAvailableForBooking: false,
        availableAt: null,
        detailsModal: {
          subtitle: title,
        },
        miniCard: {
          title: '',
          label: 'In progress:',
        },
        floatingCard,
      }
    }

    const isEndingSoon = tzAvailableAt.isBefore(
      /* @ts-expect-error src: types added to moment-timezone */
      currentTime.clone().add(settings.roomBookingLeadTimeThreshold, 'minutes'),
    )

    if (viewedNow && isEndingSoon) {
      return {
        title: `Available at ${tzAvailableAt.format(timeFormat)}`,
        meeting: currentBookingInTimeZone,
        color: settings.roomBookingBranding.availableSoon.color,
        label: 'Ending soon',
        isAvailableForBooking: true,
        availableAt: tzAvailableAt.format(),
        detailsModal: {
          subtitle: `Available at ${tzAvailableAt.format(timeFormat)}`,
        },
        miniCard: {
          title: '',
          label: 'Ending soon:',
        },
        floatingCard: {
          ...floatingCard,
          title: 'Ending soon:',
        },
      }
    }

    return {
      title: `Available at ${tzAvailableAt.format(timeFormat)}`,
      meeting: currentBookingInTimeZone,
      color: settings.roomBookingBranding.reserved.color,
      label: 'In progress',
      isAvailableForBooking: false,
      availableAt: tzAvailableAt.format(),
      detailsModal: {
        subtitle: `Available at ${tzAvailableAt.format(timeFormat)}`,
      },
      miniCard: {
        title: '',
        label: viewedNow ? 'Meeting now:' : 'In progress:',
      },
      floatingCard,
    }
  }

  if (nextBookingToday) {
    const nextBookingTodayInTimeZone = meetingInTimeZone(
      nextBookingToday,
      timeZone,
    )
    const tzNextBookingTodayStartTime = moment.tz(
      nextBookingToday.startTime,
      timeZone,
    )
    const minutesTillNextBookingToday = tzNextBookingTodayStartTime.diff(
      currentTime,
      'minutes',
    )
    const nextBookingStartingSoon =
      /* @ts-expect-error auto-src: strict-conversion */
      minutesTillNextBookingToday < settings.minimumMeetingDuration

    const floatingCard = {
      title: viewedNow ? 'Next meeting:' : 'Upcoming:',
      formattedPeriod: timePeriodFormatter({
        timePeriod: {
          start: timeZoneAgnosticMoment(nextBookingTodayInTimeZone.startTime),
          end: timeZoneAgnosticMoment(nextBookingTodayInTimeZone.endTime),
        },
        variant: 'time',
      }),
    }

    if (nextBookingStartingSoon && !availableAt) {
      const title = isFutureDay
        ? `Busy on ${currentTime.format(DATE_FORMAT)}`
        : 'Busy today'

      return {
        title,
        meeting: nextBookingTodayInTimeZone,
        color: settings.roomBookingBranding.reserved.color,
        label: 'Meeting soon',
        isAvailableForBooking: false,
        availableAt: null,
        detailsModal: {
          subtitle: title,
        },
        miniCard: {
          title: '',
          label: viewedNow ? 'Meeting soon:' : 'Upcoming:',
        },
        floatingCard,
      }
    }

    if (nextBookingStartingSoon && availableAt) {
      return {
        title: `Available at ${tzAvailableAt.format(timeFormat)}`,
        meeting: nextBookingTodayInTimeZone,
        color: settings.roomBookingBranding.reserved.color,
        label: 'Meeting soon',
        isAvailableForBooking: false,
        availableAt: tzAvailableAt.format(),
        detailsModal: {
          subtitle: `Available at ${tzAvailableAt.format(timeFormat)}`,
        },
        miniCard: {
          title: '',
          label: viewedNow ? 'Meeting soon:' : 'Upcoming:',
        },
        floatingCard,
      }
    }

    return {
      title: `Available until ${tzNextBookingTodayStartTime.format(
        timeFormat,
      )}`,
      color: settings.roomBookingBranding.available.color,
      label: '',
      isAvailableForBooking: true,
      upcomingMeeting: nextBookingTodayInTimeZone,
      availableAt: currentTime.format(),
      availableUntil: tzNextBookingTodayStartTime.format(),
      // TODO: specs
      detailsModal: {
        title: 'Available',
        subtitle: `until ${tzNextBookingTodayStartTime.format(timeFormat)}`,
      },
      miniCard: {
        title: 'Available Room',
        label: `Next meeting: ${tzNextBookingTodayStartTime.format(
          timeFormat,
        )}`,
      },
      floatingCard,
    }
  }

  return {
    title: 'Available',
    color: settings.roomBookingBranding.available.color,
    label: '',
    isAvailableForBooking: true,
    availableAt: currentTime.format(),
    detailsModal: {
      title: 'Available',
    },
    miniCard: {
      title: 'Available Room',
      label: 'No scheduled meetings',
    },
  }
}
export default roomStatus
