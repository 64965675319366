// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
const mediumOrGreaterAvatarSize = 44
const defaultAvatarSize = 50

// TODO: remove this file when the legacy info cards are turned off
// in production.

export default ({ viewportCategories: { mediumOrGreater } }: any) => ({
  avatarContainerStyle: {
    left: mediumOrGreater ? '20px' : '16px',
    position: 'absolute',
    top: mediumOrGreater ? '7px' : '2px',
  },
  listItemInnerDivStyle: {
    paddingLeft: mediumOrGreater ? '78px' : '90px',
  },
  avatarSize: mediumOrGreater ? mediumOrGreaterAvatarSize : defaultAvatarSize,
})
