import { useMemo } from 'react'
import useAppSelector from 'visual_directory/hooks/useAppSelector'
import useAppDispatch from 'visual_directory/hooks/useAppDispatch'
import type { SelectedResourceShape } from 'visual_directory/records/selectedResource'
import { resetResource as resetResourceAction } from 'visual_directory/actions/resource'
import { nullResourceId } from 'visual_directory/reducers/selectedResource'

type Result = {
  resource: SelectedResourceShape | null
  resetResource: () => void
}

const validResource = (resource: SelectedResourceShape | null) => {
  const isResourceValid = Boolean(resource && resource.id && resource.type)

  return isResourceValid ? resource : null
}

const useSelectedResource = (): Result => {
  const dispatch = useAppDispatch()
  const selectedReduxResource = useAppSelector(
    (state) => state.selectedResource,
  )
  const selectedResource: SelectedResourceShape = useMemo(
    () => selectedReduxResource.toJS(),
    [selectedReduxResource],
  )

  const resource =
    selectedResource.id === nullResourceId
      ? null
      : validResource(selectedReduxResource)

  const resetResource = () => dispatch(resetResourceAction())

  return { resource, resetResource }
}

export default useSelectedResource
