// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import moment from 'moment'
import { Record } from 'immutable'

export const BookingActionRecord = Record<any>({
  message: null,
  name: null,
  createdAt: null,
  performedBy: null,
})
export const bookingActionRecordFromJson = (json: any) => {
  if (!json) {
    return null
  }

  /* @ts-expect-error auto-src: non-strict-conversion */
  return BookingActionRecord<any>({
    ...json,
    createdAt: json.createdAt && moment(json.createdAt),
  })
}
/* @ts-expect-error auto-src: non-strict-conversion */
const bookingActionInstance = BookingActionRecord<any>()

export type BookingActionInstance = typeof bookingActionInstance
