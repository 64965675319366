import type { ValuesType } from 'utility-types'

export const resourceTypes = {
  EMPLOYEE: 'employee',
  FLOOR_LABEL: 'floorLabel',
  FLOOR_PLAN: 'floorPlan',
  NEIGHBORHOOD: 'neighborhood',
  ROOM: 'room',
  SEAT: 'seat',
  SHARE: 'share',
  UTILITY: 'utility',
} as const

export type ResourceTypes = ValuesType<typeof resourceTypes>

export const resourceTypesPluralMapping = {
  [resourceTypes.ROOM]: 'rooms',
  [resourceTypes.NEIGHBORHOOD]: 'neighborhoods',
  [resourceTypes.SEAT]: 'seats',
  [resourceTypes.UTILITY]: 'utilities',
  [resourceTypes.FLOOR_LABEL]: 'editedOverlayLabels',
  [resourceTypes.FLOOR_PLAN]: 'draftFloorPlan',
} as const

export const pluralResourceTypes = {
  ROOMS: resourceTypesPluralMapping[resourceTypes.ROOM],
  SEATS: resourceTypesPluralMapping[resourceTypes.SEAT],
  UTILITIES: resourceTypesPluralMapping[resourceTypes.UTILITY],
  FLOOR_LABELS: resourceTypesPluralMapping[resourceTypes.FLOOR_LABEL],
  FLOOR_PLANS: resourceTypesPluralMapping[resourceTypes.FLOOR_PLAN],
  NEIGHBORHOODS: resourceTypesPluralMapping[resourceTypes.NEIGHBORHOOD],
}

export const assignmentMethods: {
  BOOKABLE_BY_REQUEST: 'bookableByRequest'
  BOOKABLE_BY_SELF_SERVICE: 'bookableBySelfService'
  FREE_ADDRESS: 'freeAddress'
  MOVE_SCHEDULING: 'moveScheduling'
} = {
  BOOKABLE_BY_REQUEST: 'bookableByRequest',
  BOOKABLE_BY_SELF_SERVICE: 'bookableBySelfService',
  FREE_ADDRESS: 'freeAddress',
  MOVE_SCHEDULING: 'moveScheduling',
}

type AssignmentMethodKeys = keyof typeof assignmentMethods

export type AssignmentMethodEnum =
  typeof assignmentMethods[AssignmentMethodKeys]

export const roomConstructs: {
  ROOM: 'ROOM'
  SPACE: 'SPACE'
  SHARE: 'SHARE'
  CUBE: 'CUBE'
  BOOKABLE: 'BOOKABLE'
} = {
  ROOM: 'ROOM',
  SPACE: 'SPACE',
  SHARE: 'SHARE',
  CUBE: 'CUBE',
  BOOKABLE: 'BOOKABLE',
}

export const assignmentMethodLabels = {
  [assignmentMethods.MOVE_SCHEDULING]: 'Move Scheduling',
  [assignmentMethods.BOOKABLE_BY_SELF_SERVICE]: 'Bookable Desk (Self-service)',
  [assignmentMethods.BOOKABLE_BY_REQUEST]: 'Bookable Desk (By Request)',
  [assignmentMethods.FREE_ADDRESS]: 'Free Address',
}

export const assignmentMethodPluralizedLabels = {
  [assignmentMethods.MOVE_SCHEDULING]: 'Move Scheduling Desks',
  [assignmentMethods.BOOKABLE_BY_SELF_SERVICE]: 'Bookable Desks (Self-service)',
  [assignmentMethods.BOOKABLE_BY_REQUEST]: 'Bookable Desks (By Request)',
  [assignmentMethods.FREE_ADDRESS]: 'Free Address Desks',
}
