import type { RosterItem } from 'visual_directory/components/who_is_in/Roster/useData'

type Return = {
  groupNames: ReadonlyArray<string>
  groupCounts: ReadonlyArray<number>
  sortedRosterItems: ReadonlyArray<RosterItem>
}

const compareLastName = (rosterItemA: RosterItem, rosterItemB: RosterItem) =>
  rosterItemA.lastName.localeCompare(rosterItemB.lastName)

const groupByLastName = (rosterItem: ReadonlyArray<RosterItem>): Return => {
  const sortedRosterItems = [...rosterItem].sort(compareLastName)
  /* @ts-expect-error auto-src: strict-conversion */
  const groupNames = []
  /* @ts-expect-error auto-src: strict-conversion */
  const groupCounts = []
  /* @ts-expect-error auto-src: strict-conversion */
  let prevGroupName = null

  sortedRosterItems.forEach(({ lastName }) => {
    const groupName = lastName.charAt(0).toLocaleUpperCase()

    /* @ts-expect-error auto-src: strict-conversion */
    if (groupName !== prevGroupName) {
      groupNames.push(groupName)
      groupCounts.push(0)
      prevGroupName = groupName
    }

    /* @ts-expect-error auto-src: strict-conversion */
    groupCounts[groupCounts.length - 1] += 1
  })

  return {
    /* @ts-expect-error auto-src: strict-conversion */
    groupNames,
    /* @ts-expect-error auto-src: strict-conversion */
    groupCounts,
    sortedRosterItems,
  }
}

export default groupByLastName
