import { setShouldCenter } from 'common/actions/floor'

import useAppDispatch from 'visual_directory/hooks/useAppDispatch'

const useCenterFloor = (): (() => void) => {
  const dispatch = useAppDispatch()

  return () => {
    dispatch(setShouldCenter())
  }
}

export default useCenterFloor
