// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any,max-len */
/* auto-src: ts-eslint eslint-disable max-len */
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export default function StackPlanIcon({ color, ...rest }: any) {
  return (
    <SvgIcon {...rest}>
      <path d="M2 15.5H12V20.5H2V15.5ZM2 9.5H8V14.5H2V9.5ZM9 9.5H15V14.5H9V9.5ZM16 9.5H22V14.5H16V9.5ZM13 15.5H18V20.5H13V15.5ZM2 3.5H11V8.5H2V3.5Z" />
    </SvgIcon>
  )
}
