import React from 'react'
import RoomIcon from 'common/icons/RoomIcon'

import useStyles from './useStyles'

const ImageFallback = (): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <RoomIcon className={classes.icon} />
    </div>
  )
}

export default ImageFallback
