import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    minWidth: 73,
    textTransform: 'none',
    color: '#212121',
    fontSize: 14,
    lineHeight: '20px',
  },
  outlined: {
    borderColor: '#E0E0E0',
  },
})

export default useStyles
