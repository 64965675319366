import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles({
  root: {
    padding: 8,
    boxSizing: 'border-box',
    borderTop: '1px solid #E0E0E0',
    textAlign: 'right',
    width: '100%',
  },
  submitButton: {
    backgroundColor: '#42A5F5',
    minWidth: 52,
    marginLeft: 8,
    '&:hover': {
      backgroundColor: '#3484c4',
    },
  },
})
