import moment from 'moment-timezone'

import type { Moment } from 'moment-timezone'
import type Immutable from 'immutable'

import { brieflyHumanizeDuration } from 'common/lib/timeUtilities'

export type PeriodT = {
  startTime: Moment | string
  endTime: Moment | string
}

// Asks momentjs to return 'an hour' as '1 hour'
export const useExactRelativeTimeHour = (momentInstance: Moment): unknown =>
  /* @ts-expect-error src: types added to moment-timezone */
  momentInstance.updateLocale('en', {
    relativeTime: {
      h: '1 hour', // eslint-disable-line id-length
    },
  })

/* eslint-disable react-hooks/rules-of-hooks */
/* @ts-expect-error src: types added to moment-timezone */
useExactRelativeTimeHour(moment)
/* eslint-enable react-hooks/rules-of-hooks */

export const formatDuration = (startTime: Moment, endTime: Moment): string => {
  const duration = moment.duration(endTime.diff(startTime))

  return brieflyHumanizeDuration(duration)
}

type ValidationError = {
  attribute: string
  errors: ReadonlyArray<string>
}

export const mapErrors = (
  errors: ReadonlyArray<ValidationError>,
): Record<string, string> =>
  errors.reduce(
    (acc, error) => ({ ...acc, [error.attribute]: error.errors.join(', ') }),
    {},
  )

type NodeWithLabel = {
  label: string // FIXME: Convert this to an exact object
}

export const compareCaseInsensitively = (
  inputA: NodeWithLabel,
  inputB: NodeWithLabel,
): number => {
  const a = inputA.label
  const b = inputB.label

  return a.toLowerCase().localeCompare(b.toLowerCase())
}

export const buildDefaultTitleForUser = (
  user: Immutable.Map<string, unknown>,
): string | null => {
  const name = user.getIn(['linkedEmployee', 'firstName'])

  if (!name) {
    return null
  }

  return `${name}'s meeting`
}

export const overlapping = (
  bookings: ReadonlyArray<PeriodT>,
  from: Moment | string,
  to: Moment | string,
): ReadonlyArray<PeriodT> =>
  bookings.filter(
    ({ startTime, endTime }) =>
      moment(startTime).isBefore(to) && moment(endTime).isAfter(from),
  )

export const hasOverlapping = (
  bookings: ReadonlyArray<PeriodT>,
  from: Moment | string,
  to: Moment | string,
): boolean => overlapping(bookings, from, to).length > 0
