// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import React, { useRef } from 'react'
import type { ElementRef } from 'react'
import { ThemeProvider } from '@material-ui/styles'
import Fade from '@material-ui/core/Fade'
import Popover from '@material-ui/core/Popover'
import BrandingThemeProvider from 'visual_directory/components/BrandingThemeProvider'
import { greyFormTheme } from 'visual_directory/config'
import Tooltip from 'common/components/LightTooltip/LightTooltip'
import useStyles from './useStyles'

type Props = {
  children: React.ReactNode
  inputButton: React.ReactNode
  isOpen?: boolean
  onClose: () => void
  tooltipText?: string
}

const Layout = ({
  children,
  inputButton,
  tooltipText = '',
  onClose,
  isOpen,
}: Props) => {
  const anchorElementRef: {
    current: HTMLElement | null | undefined
  } = useRef()
  const popoverRef: {
    current: ElementRef<any> | null | undefined
  } = useRef()
  const classes = useStyles()
  const anchorOrigin = {
    horizontal: 'center',
    vertical: 'bottom',
  }
  const transformOrigin = {
    horizontal: 'center',
    vertical: 'top',
  }
  const handleClosePopover = onClose

  return (
    <BrandingThemeProvider>
      <ThemeProvider theme={greyFormTheme}>
        <div className={classes.root} data-testid="date-time-picker">
          <Tooltip
            disableHoverListener={!tooltipText}
            placement="bottom"
            title={tooltipText}
          >
            <div
              ref={(anchorElement) => {
                anchorElementRef.current = anchorElement
              }}
            >
              {inputButton}
            </div>
          </Tooltip>
          <Popover
            ref={popoverRef}
            anchorEl={anchorElementRef.current}
            /* @ts-expect-error auto-src: non-strict-conversion */
            anchorOrigin={anchorOrigin}
            className={classes.popover}
            data-testid="date-time-picker-popover"
            elevation={2}
            marginThreshold={0}
            onClose={handleClosePopover}
            /* @ts-expect-error auto-src: strict-conversion */
            open={isOpen}
            /* @ts-expect-error auto-src: non-strict-conversion */
            transformOrigin={transformOrigin}
            TransitionComponent={Fade}
          >
            {children}
          </Popover>
        </div>
      </ThemeProvider>
    </BrandingThemeProvider>
  )
}

export default Layout
