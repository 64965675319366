import { makeStyles } from '@material-ui/styles'

/* @ts-expect-error auto-src: non-strict-conversion */
export default makeStyles(({ palette }) => ({
  root: {
    height: 32,
    padding: '6px 16px',
    lineHeight: '20px',
    fontSize: 14,
    fontWeight: 600,
    background: palette.grey[100],
    color: palette.grey[80],
    boxSizing: 'border-box',
  },
}))
