// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import LabelContainer from 'common/components/LabelContainer'
import { getLongDaysOfWeek, getShortDaysOfWeek } from '../lib'
import type { Props } from '..'

const DaysOfWeekLabel = ({
  availability,
  includeName,
  days = 'short',
}: Props) => {
  const getDays = () => {
    switch (days) {
      case 'short':
        return [getShortDaysOfWeek(availability).join(', ')]

      case 'long':
        return getLongDaysOfWeek(availability)

      default:
        return []
    }
  }

  const labels =
    includeName && availability.name
      ? [`${availability.name || ''}:`, ...getDays()]
      : getDays()
  const displayLabels = days === 'long' ? labels : [labels.join(' ')]

  return (
    <>
      {displayLabels.map((line, index) => (
        <LabelContainer key={index}>{line}</LabelContainer>
      ))}
    </>
  )
}

export default DaysOfWeekLabel
