import React, { useState } from 'react'
import { Input, InputAdornment, IconButton } from '@material-ui/core'
import { Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons'

import useDebounce from 'common/hooks/useDebounce'
import useStyles from './useStyles'

const MIN_SEARCH_TERM_LENGTH = 2

const normalizeSearchTerm = (value: string): string | null => {
  const result = value.trim()

  return result.length >= MIN_SEARCH_TERM_LENGTH ? result : null
}

type Props = {
  searchTerm: string | null
  onChange: (searchTerm: string | null) => void
}

const SearchField = ({ searchTerm, onChange }: Props): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>(searchTerm || '')
  const { icon: iconClassName, ...classes } = useStyles()

  const onChangeDebounced = useDebounce(onChange)

  const updateValue = (nextValue: string) => {
    setInputValue(nextValue)
    onChangeDebounced(normalizeSearchTerm(nextValue))
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateValue(event.target.value)
  }

  const handleClear = () => {
    updateValue('')
  }

  return (
    <Input
      classes={classes}
      data-testid="search-field"
      disableUnderline
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            data-testid="clear-button"
            onClick={handleClear}
            size="small"
          >
            <CloseIcon className={iconClassName} />
          </IconButton>
        </InputAdornment>
      }
      fullWidth
      onChange={handleChange}
      placeholder="Search by name"
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon className={iconClassName} />
        </InputAdornment>
      }
      value={inputValue}
    />
  )
}

export default SearchField
