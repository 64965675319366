// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import add from 'lodash/fp/add'
import { compose } from 'redux'
import mapValues from 'lodash/fp/mapValues'
import multiply from 'lodash/fp/multiply'
import { addYOffsetToPoints } from 'common/selectors/selectedFloor'
import { boundingBox } from 'common/lib/roomGeometry'
import { fromJS } from 'immutable'
import type { Map } from 'immutable'
import { map } from 'common/lib/functionalImmutableHelpers'
import type { Point } from 'common/lib/shapes'

const getBoundingBox = compose(boundingBox, fromJS)

type Args<CurrentViewIngredients> = {
  points: ReadonlyArray<[number, number]>
  currentViewIngredients: CurrentViewIngredients
  currentZoom: number
  maxZoom: number
  minZoom: number
  padding?: string
  position: Point
  stageHeight: number
  stageWidth: number
  yOffset: number
}
export default <V, CurrentViewIngredients extends Map<string, V>>({
  points,
  currentViewIngredients,
  currentZoom,
  maxZoom,
  minZoom,
  padding = '0%',
  position,
  stageHeight,
  stageWidth,
  yOffset,
}: Args<CurrentViewIngredients>) => {
  const pointsWithYOffset = addYOffsetToPoints(points, yOffset)
  const box = getBoundingBox({
    points: pointsWithYOffset,
  })
  const halfBoxHeight = box.height / 2
  const halfBoxWidth = box.width / 2
  const boxCenter = {
    x: box.left + halfBoxWidth,
    y: box.top + halfBoxHeight,
  }
  const paddingPercent = parseFloat(padding) / 100
  const stageDimensions = {
    height: stageHeight,
    width: stageWidth,
  }
  const boxAspectRatio = box.width / box.height
  const stageAspectRatio = stageDimensions.width / stageDimensions.height
  const dominantDimension =
    boxAspectRatio > stageAspectRatio ? 'width' : 'height'
  const desiredZoom =
    (stageDimensions[dominantDimension] * (1 - paddingPercent)) /
    box[dominantDimension]
  const allowedZoom = Math.max(minZoom, Math.min(maxZoom, desiredZoom))
  const deltaZoom = allowedZoom - currentZoom || 0
  const scaledBoxCenter = mapValues(multiply(currentZoom))(boxCenter)
  const stageBoxCenter = {
    x: scaledBoxCenter.x + position.x,
    y: scaledBoxCenter.y + position.y,
  }
  const stageCenter = {
    x: stageDimensions.width / 2,
    y: stageDimensions.height / 2,
  }
  const boxCenteringCorrection = {
    x: stageCenter.x - stageBoxCenter.x,
    y: stageCenter.y - stageBoxCenter.y,
  }
  const zoomPositionCorrection = mapValues(multiply(-deltaZoom))(boxCenter)
  const boxCenteringOffset = {
    x: boxCenteringCorrection.x + zoomPositionCorrection.x,
    y: boxCenteringCorrection.y + zoomPositionCorrection.y,
  }

  return currentViewIngredients
    .update('positionIngredients', map(map(multiply(-1))))
    .update('zoomIngredients', map(multiply(-1)))
    .mergeDeepWith(add, {
      /* @ts-expect-error auto-src: non-strict-conversion */
      positionIngredients: {
        boxFocusOffset: boxCenteringOffset,
      },
      /* @ts-expect-error auto-src: non-strict-conversion */
      zoomIngredients: {
        boxFocusDeltas: deltaZoom,
      },
    })
    .toJS()
}
