// The following eslint-disable was automated from the ts conversion
import { createSelector } from 'reselect'

export default createSelector(
  ({ floorStore }) => floorStore.get('iconScale'),
  ({ settings }) => settings.get('labelZoomThreshold'),
  /* @ts-expect-error auto-src: strict-conversion */
  (_, { currentZoom }) => currentZoom,
  /* @ts-expect-error auto-src: strict-conversion */
  (_, { minZoom }) => minZoom,
  /* @ts-expect-error auto-src: strict-conversion */
  (_, { maxZoom }) => maxZoom,
  (iconScale, labelZoomThreshold, currentZoom, minZoom, maxZoom) => {
    const inverseIconScale = 1 / iconScale
    const iconScaleAdjustedLabelZoomThreshold =
      labelZoomThreshold * inverseIconScale
    const halfOfMinZoom = minZoom / 2
    const halfOfMaxZoom = maxZoom / 2
    const halfwayBetweenMinAndMaxZoom = halfOfMinZoom + halfOfMaxZoom
    const thresholdForThisFloor =
      minZoom > inverseIconScale
        ? halfwayBetweenMinAndMaxZoom
        : iconScaleAdjustedLabelZoomThreshold

    return currentZoom > thresholdForThisFloor
  },
)
