import { isImmutable } from 'immutable'
import type { Collection, RecordOf } from 'immutable'
import { makeFloorPath } from 'common/lib/paths'
import { getResourceId, resourcePath, getDeepLinkKey } from './resource'

type ID = number | string

export type EnrutableObjectResource = {
  id: string | number
  type: string
  floorId?: string | number | void | null
  floor?: { id: string | void | number }
}

export type EnrutableResource =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | RecordOf<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | Collection<string, any>
  | EnrutableObjectResource

type NativeRecordOrImmutableResource =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | Record<string, any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | Collection<string, any>

export { logoutPath } from 'common/lib/paths'

export const makeShortSavedPinUrl = (pinId: ID): string =>
  `${window.location.origin}/pins/${pinId}`

export const makeLongSavedPinPath = (floorId: ID, pinId: ID): string =>
  `/floors/${floorId}/pins/${pinId}`

export const makeResourceDetailsPath = (
  resource: NativeRecordOrImmutableResource,
): string => {
  if (isImmutable(resource)) {
    const floorPath = makeFloorPath(resource.get('floorId')?.toString())
    const floorResourcePath = resourcePath(
      resource.get('type'),
      resource.get('id'),
    )

    return `${floorPath}${floorResourcePath}`
  }

  const floorPath = makeFloorPath(resource.floorId || resource.floor?.id)
  const floorResourcePath = resourcePath(resource.type, resource.id)

  return `${floorPath}${floorResourcePath}`
}

export const makeMaximizedResourceDetailsPath = (
  resource: NativeRecordOrImmutableResource,
): string => `${makeResourceDetailsPath(resource)}/max`

export const makeMaybeMaxizedResourceDetailsPath = (
  resource: NativeRecordOrImmutableResource,
  maximized?: boolean,
): string =>
  maximized
    ? makeMaximizedResourceDetailsPath(resource)
    : makeResourceDetailsPath(resource)

export const makeCalendarDetailsPath = (
  resource: NativeRecordOrImmutableResource,
): string => `${makeResourceDetailsPath(resource)}/calendar`

export const makeMaximizedCalendarDetailsPath = (
  resource: NativeRecordOrImmutableResource,
): string => `${makeMaximizedResourceDetailsPath(resource)}/calendar`

export const makeMaybeMaximizedCalendarDetailsPath = (
  resource: NativeRecordOrImmutableResource,
  maximized?: boolean,
): string =>
  maximized
    ? makeMaximizedCalendarDetailsPath(resource)
    : makeCalendarDetailsPath(resource)

export const makeEmployeePath = (
  employee: { id: string } | Collection<string, string>,
): string => {
  if (isImmutable(employee)) {
    return `/employees/${employee.get('id')}`
  }

  return `/employees/${employee.id}`
}

export const makeShareSeatPath = (
  seat: NativeRecordOrImmutableResource,
): string =>
  `${makeFloorPath(seat.get('floorId'))}` +
  `/shares/${seat.get('roomId')}${resourcePath(
    seat.get('type'),
    seat.get('id'),
  )}`

export const makeResourcePath = (resource: EnrutableResource): string => {
  if (isImmutable(resource)) {
    if (resource.get('type') === 'employee' && !resource.get('seat')) {
      return makeEmployeePath(resource)
    }

    const floorResource = resource.get('seat') || resource

    return `${makeFloorPath(
      floorResource.get('floorId') || floorResource.get('floor')?.id,
    )}${resourcePath(floorResource.get('type'), floorResource.get('id'))}`
  }

  return `${makeFloorPath(
    'floorId' in resource ? resource.floorId : resource.floor?.id,
  )}${resourcePath(resource.type, resource.id)}`
}

export const makeLocationSpecificRequestFormPath = (
  resourceType: string,
  resourceId: ID,
  floorId: ID,
): string =>
  `/floors/${floorId}${resourcePath(resourceType, resourceId)}/requests/new`

export const makeLocationSpecificPinRequestFormPath = (
  pinId: ID,
  pinX: number,
  pinY: number,
  floorId: ID,
): string => `/floors/${floorId}/pins/${pinId}/${pinX}/${pinY}/requests/new`

export const makeDeepLinkUrl = (
  resource: NativeRecordOrImmutableResource,
): string =>
  `${window.location.origin}/vd/vd.jsp?${getDeepLinkKey(
    resource,
  )}=${getResourceId(resource)}`

export const searchPath = '/search'

export const floorsPath = '/floors'

export const createRequestPath = (floorId?: ID): string =>
  floorId ? `/floors/${floorId}/requests/new` : '/requests/new'

export const bookDeskPath = (floorId?: ID): string =>
  floorId ? `/floors/${floorId}/bookings/new` : '/bookings/new'

export const bookingPath = (
  floorId?: ID,
  key?: string | null | undefined,
): string =>
  floorId
    ? `/floors/${floorId}/bookings/${key || ''}`
    : `/bookings/${key || ''}`

export const editBookingPath = (
  floorId?: ID,
  key?: string | null | undefined,
): string => `${bookingPath(floorId, key)}/edit`

export const cancelBookingPath = (
  floorId?: ID,
  key?: string | null | undefined,
): string => `${bookingPath(floorId, key)}/cancel`

export const myBookingsPath = (floorId?: ID): string =>
  floorId ? `/floors/${floorId}/bookings` : '/bookings'

export const directoryPath = '/dir'

export const makeDirectoryEmployeePath = (employeeId: ID): string =>
  `${directoryPath}/employees/${employeeId}`

export const makeDirectorySearchPath = (queryString: string): string =>
  `/visual-directory-api/directory_search?${queryString}`

export const makeDirectoryDownloadPath = (queryString: string): string =>
  `/visual-directory-api/directory_search.csv?${queryString}`

export const whoIsInPath = '/who-is-in'

export const whoIsInFloorsPath = `${whoIsInPath}/floors`
export const whoIsInFloorRosterPath = `${whoIsInPath}/floors/:floorId`

export const whoIsInSitesPath = `${whoIsInPath}/sites`
export const whoIsInSiteRosterPath = `${whoIsInPath}/sites/:siteId`

export const whoIsInNeighborhoodRosterPath = `${whoIsInPath}/neighborhoods/:neighborhoodId`
