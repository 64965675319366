import React from 'react'
import { ThemeProvider } from '@material-ui/styles'
import Fade from '@material-ui/core/Fade'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as DialogHeader,
} from '@material-ui/core'

import { greyFormTheme } from 'visual_directory/config'
import Infobar from './Infobar'
import Navbar from './Navbar'
import useStyles from './useStyles'

type Props = {
  actions: React.ReactNode
  children: React.ReactNode
  inputButton: React.ReactNode
  title?: string
  InfoIcon?: React.ComponentType<unknown>
  infoText?: string
  isOpen: boolean
  onClose: () => void
  tooltipText?: string
}

const Layout = ({
  actions,
  children,
  inputButton,
  InfoIcon,
  infoText,
  title,
  onClose: handleCloseDialog,
  isOpen = false,
}: Props): React.ReactElement => {
  const classes = useStyles()
  const hasHeader = title || infoText

  return (
    <ThemeProvider theme={greyFormTheme}>
      <div data-testid="date-time-picker">
        {inputButton}
        <Dialog
          data-testid="date-time-picker-popover"
          fullScreen
          onClose={handleCloseDialog}
          open={isOpen}
          TransitionComponent={Fade}
        >
          {hasHeader && (
            <DialogHeader className={classes.header} disableTypography>
              {title && <Navbar title={title} />}
              {infoText && <Infobar Icon={InfoIcon}>{infoText}</Infobar>}
            </DialogHeader>
          )}
          <DialogContent className={classes.content}>{children}</DialogContent>
          <DialogActions className={classes.actions}>{actions}</DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  )
}

export default Layout
