import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

import type { Options } from '../types'
import Layout from '../Layout'
import useAdjustedCardPosition from '../useAdjustedCardPosition'
import useData from './useData'

type Props = {
  id: string
  options: Options
}

const FreeAddressDeskCard = ({ id, options }: Props): JSX.Element | null => {
  const { loading, seat } = useData({
    ids: [id],
    atTime: options.atTime,
  })

  useAdjustedCardPosition({ skip: loading })

  if (loading) {
    return (
      <Layout label={<Skeleton />} title={<Skeleton />}>
        <Skeleton />
      </Layout>
    )
  }

  return (
    <Layout label={seat.label} title="Free Address Desk">
      {seat.sensorStatus !== 'Neutral' && !options?.atTime
        ? seat.sensorStatus
        : null}
    </Layout>
  )
}

export default FreeAddressDeskCard
