import merge from 'lodash/merge'
import StyleConstants from './definitions'
import ListStyles from './list'

const SearchStyles = {
  searchResults: {
    listItem: {
      style: { ...ListStyles.listItem.style, minHeight: 60, height: 60 },
      innerDivStyle: {
        ...ListStyles.listItem.innerDivStyle,
        lineHeight: '18px',
        paddingBottom: 0,
        paddingLeft: 10,
        paddingTop: 1,
      },
    },
    // pass into IconButton iconStyle attribute
    resultLocationIconInternalStyle: {
      color: StyleConstants.icon.iconColor,
    },
    // pass into IconButton style attribute
    resultLocationIconButtonStyle: {
      padding: 6,
      top: 6,
    },
    noResults: {
      containerStyle: {
        paddingTop: '38px',
        textAlign: 'center',
      },
      headerStyle: {
        color: StyleConstants.font.color.minorHeading,
        fontWeight: StyleConstants.font.weight.semibold,
        marginTop: '16px',
      },
      iconStyle: {
        color: StyleConstants.icon.iconColor,
        height: '48px',
        width: '48px',
      },
      secondaryTextStyle: {
        color: StyleConstants.font.color.minorHeading,
        fontSize: StyleConstants.font.size.heading,
        fontWeight: StyleConstants.font.weight.light,
        marginTop: '16px',
      },
    },
  },
}

export const filterNoResultsStyles = merge(
  {},
  SearchStyles.searchResults.noResults,
  {
    containerStyle: {
      paddingTop: 50,
    },
    headerStyle: {
      marginTop: 23,
    },
  },
)
export default SearchStyles
