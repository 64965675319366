import moment from 'moment-timezone'
import React from 'react'

import { DATETIME_FORMAT, TIME_FORMAT } from 'common/types/time'

type Props = {
  isToday: boolean
  nextAvailableTime: string
}

const NextAvailable = ({ isToday, nextAvailableTime }: Props): JSX.Element => {
  const timeFormat = isToday ? TIME_FORMAT : DATETIME_FORMAT
  const formattedTime = moment(nextAvailableTime).format(timeFormat)

  return (
    <div>
      <em>Next available: {formattedTime}</em>
    </div>
  )
}

export default NextAvailable
