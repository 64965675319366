// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { fromJS, List } from 'immutable'
import moment from 'moment-timezone'
import type { Moment } from 'moment-timezone'
import { agnosticNowInZone } from 'common/lib/timeUtilities'
import { dateParts, timeParts } from 'common/lib/hotDesking'
import { isBookableBySelfServiceAssignment } from 'common/lib/resource'
import type { AssignmentMethod } from 'common/types/seat'
import { bookingRecordFromJson } from '../records/booking'

type MomentArgument = string | Date | Moment | undefined

export const isBookingRequestRequired = (seat: {
  assignmentMethod: AssignmentMethod
  canManageBookings: boolean
}): boolean => {
  if (isBookableBySelfServiceAssignment(seat.assignmentMethod)) {
    return false
  }

  return !seat.canManageBookings
}

/* @ts-expect-error auto-src: noflow */
export const differenceInDays = (startDate, endDate) => {
  const start = moment(startDate)
  const end = moment(endDate)
  const roundedStart = moment([start.year(), start.month(), start.date()])
  const roundedEnd = moment([end.year(), end.month(), end.date()])

  return roundedEnd.diff(roundedStart, 'days')
}

/* @ts-expect-error auto-src: noflow */
const nearestPastMinutes = (interval, dateTime) => {
  const baseMoment = moment(dateTime)
  const roundedMinutes = Math.floor(baseMoment.minute() / interval) * interval

  return baseMoment.minute(roundedMinutes).second(0)
}

/* @ts-expect-error auto-src: noflow */
export function formatDateAndTime(bookingDate, bookingTime) {
  if (bookingDate) {
    const formattedDate = dateParts(bookingDate, true).join('-')

    if (bookingTime) {
      const formattedTime =
        typeof bookingTime === 'string'
          ? bookingTime
          : timeParts(bookingTime).join(':')

      return `${formattedDate}T${formattedTime}`
    }

    return formattedDate
  }

  return null
}

/* @ts-expect-error auto-src: noflow */
export const bookingsAt = (baseDesk, checkIn, checkOut) => {
  const desk = fromJS(baseDesk)
  const checkInMoment = moment(checkIn)
  const checkOutMoment = moment(checkOut)

  /* @ts-expect-error auto-src: noflow */
  return desk.get('bookings', List()).filter((booking) => {
    const bookingIn = booking.get('checkInTime')
    const bookingOut =
      booking.get('checkOutTime') || booking.get('checkOutScheduled')
    const bookingInMoment = moment(bookingIn, 'YYYY-MM-DD HH:mm:ss')
    const bookingOutMoment = moment(bookingOut, 'YYYY-MM-DD HH:mm:ss')

    return (
      checkInMoment.isBefore(bookingOutMoment) &&
      checkOutMoment.isAfter(bookingInMoment)
    )
  })
}

/* @ts-expect-error auto-src: noflow */
export const toSeatMoment = (seat, date) => {
  const isoDate = moment(date).format('YYYY-MM-DDTHH:mm:ss')
  const zone = seat.get('utcOffset') || ''

  return moment(`${isoDate}${zone}`)
}

/* @ts-expect-error auto-src: noflow */
export const timeAtSeat = (seat, date) => {
  /* @ts-expect-error auto-src: noflow */
  const seatDiff = moment(date).diff(toSeatMoment(seat), 'minutes')

  return moment(date).add(seatDiff, 'minutes')
}

const nextBooking = (
  /* @ts-expect-error auto-src: noflow */
  seat,
  dateTime?: MomentArgument,
) => {
  /* @ts-expect-error auto-src: noflow */
  const reference = dateTime ? moment(dateTime) : timeAtSeat(seat)

  /* @ts-expect-error auto-src: noflow */
  const checkIn = (booking) => moment(booking.get('localCheckInTime'))

  /* @ts-expect-error auto-src: noflow */
  const isSameDayUpcoming = (booking) =>
    checkIn(booking) >= reference &&
    differenceInDays(checkIn(booking), reference) === 0

  const bookings = seat.get('todaysBookings') || seat.get('bookings')
  const booking = bookings?.sortBy(checkIn)?.find(isSameDayUpcoming)

  return booking
}

export const nextBookingRecord = (
  /* @ts-expect-error auto-src: noflow */
  seat,
  dateTime?: MomentArgument,
) => {
  const booking = nextBooking(seat, dateTime)

  return booking ? bookingRecordFromJson(booking.toJS()) : null
}

/* @ts-expect-error auto-src: noflow */
export function monthsDifference(dateA, dateB) {
  const dateAMonthStart = moment(dateA).startOf('month')
  const dateBMonthStart = moment(dateB).startOf('month')

  return dateAMonthStart.diff(dateBMonthStart, 'month')
}

/* @ts-expect-error auto-src: noflow */
export const isDateAllowed = (date, referenceDate, limit) => {
  const monthsDiff = monthsDifference(date, referenceDate)

  if (monthsDiff < -1) {
    return false
  }

  const daysDiff = differenceInDays(referenceDate, date)

  return daysDiff < limit
}

// If date is in the current month, it's valid
// If date is in the previous month, it's valid
// If date is before the previous month, it's not valid
// If date is future, allow only if the first day of its month is allowed
/* @ts-expect-error auto-src: noflow */
export const isMonthAllowed = (date, referenceDate, limit) => {
  const diff = monthsDifference(date, referenceDate)

  if (diff === 0 || diff === -1) {
    return true
  }

  if (diff < -1) {
    return false
  }

  return isDateAllowed(moment(date).startOf('month'), referenceDate, limit)
}

/* @ts-expect-error auto-src: noflow */
export const overThreshold = (time, limit) =>
  time && limit && time.asMinutes() < limit

const quarterOfAnHour = 15

export const availableUntil = (
  /* @ts-expect-error auto-src: noflow */
  seat,
  dateTime?: MomentArgument,
) => {
  const booking = nextBooking(seat, dateTime)

  if (!booking) {
    return
  }

  const nextCheckIn = moment(booking.toJS().localCheckInTime)

  return nearestPastMinutes(quarterOfAnHour, nextCheckIn)
}

/* @ts-expect-error auto-src: noflow */
export const minDate = (first, second) => {
  if (first && second) {
    return new Date(Math.min(first, second))
  }

  return first || second
}

export const isToday = (
  date: Moment | string | undefined,
  timeZone: string | null | undefined,
) => {
  if (!date) {
    return true
  }

  return moment(date).isSame(agnosticNowInZone(timeZone), 'day')
}
