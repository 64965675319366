// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Browser from 'common/constants/browser'
import isWebGLSupported from 'common/lib/isWebGLSupported'

const highResDPR = 2

const devicePixelRatio = (() => {
  const isChrome = Browser.name === 'Chrome'
  const chromePixelLimit = 4500000
  const couldExceedPixelLimit =
    window.screen.width * window.screen.height > chromePixelLimit

  if (isChrome && couldExceedPixelLimit) {
    return 1
  }

  return 2
})()

export default {
  canvasDebounceInterval: 50,
  devicePixelRatio,
  isWebGLSupported,
  maxTextureScaleFactor: 4,
  missingEmployeeImage: (style: string) =>
    `/photos/employees/${style}/missing.png`,
  mouseIdleInterval: 4000,
  overZoomPercent: 0.3,
  zoomAndSpinSelectorDelay: 700,
  zIndex: {
    dockedCard: 101,
    drawerContainer: 20,
    drawerToggler: 100,
    floorPlanSpinner: 12,
    floorPlanZoomButtons: 12,
    ghostIcon: 21,
    navbar: 1000,
    printBorder: 11,
    printFormBoxShadow: 1120,
    printLegend: 11,
    printSettingsButtonBar: 1130,
    printSettingsContainer: 1111,
    resourceInspector: 23,
  },
  background: {
    imageType: isWebGLSupported ? 'regular' : 'fallback',
    suffix: (() => {
      const deviceType = Browser.type

      if (
        (deviceType === 'mobile' || deviceType === 'tablet') &&
        devicePixelRatio < highResDPR
      ) {
        return 2
      }

      return 4 // eslint-disable-line no-magic-numbers
    })(),
  },
}
/*
// Notes on specific definitions:
maxTextureScaleFactor:
  The icons on the sprite sheet are 4x larger than the expected 'floorplan' size
  of width 1024px. We must scale down by 4x before proceeding
overZoomPercent:
  This is the percent that we allow a user to zoom beyond maxZoom or minZoom. For example, if
  maxZoom for a floor is 3, and overZoomPercent is 0.30, the user will actually be allowed to zoom
  to 3.9 with an increasing resistance as they approach 3.9.
*/
