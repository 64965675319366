import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

import Layout from '../Layout'
import useAdjustedCardPosition from '../useAdjustedCardPosition'
import useData from './useData'

type Props = {
  id: string
}

const InactiveDeskCard = ({ id }: Props): JSX.Element | null => {
  const { loading, seat } = useData({ ids: [id] })

  useAdjustedCardPosition({ skip: loading })

  if (loading) {
    return (
      <Layout label={<Skeleton />} title={<Skeleton />}>
        <Skeleton />
      </Layout>
    )
  }

  return <Layout label={seat.label} title="Inactive Location" />
}

export default InactiveDeskCard
