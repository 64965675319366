import { useQuery } from '@apollo/client'
/* @ts-expect-error auto-src: non-strict-conversion */
import RoomDataQuery from './RoomData.graphql'

type Variables = {
  ids: ReadonlyArray<string>
}

type Loading = {
  isLoading: true
}

type Loaded = {
  description: string | null
  isLoading: false
  imageUrl?: string
  label: string
}

type Result = Loading | Loaded

type Image = {
  id: string
  url: string
}

export type Room = {
  id: string
  description: string | null
  images: ReadonlyArray<Image>
  label: string
}

type Data = {
  rooms: ReadonlyArray<Room>
}

const useData = (id: string): Result => {
  const { loading, data } = useQuery<Data, Variables>(RoomDataQuery, {
    variables: { ids: [id] },
  })

  if (loading) {
    return { isLoading: true }
  }

  const room = (data?.rooms || [])[0]

  const { description, images, label } = room

  const imageUrl = images[0]?.url

  return {
    description,
    label,
    imageUrl,
    isLoading: false,
  }
}

export default useData
