import { useMemo } from 'react'
import useAppSelector from 'visual_directory/hooks/useAppSelector'
import type { Floor } from './types'

const useFloor = (floorId: string | number): Floor | undefined => {
  const sites = useAppSelector((state) => state.floorListStore.get('sites'))

  return useMemo(() => {
    const floorIdInt = Number(floorId)
    const resultFloor = sites
      .flatMap((site) => site.get('floors'))
      .find((floor) => floor.get('id') === floorIdInt)
      ?.toJS()

    return resultFloor && { ...resultFloor, id: resultFloor.id.toString() }
  }, [sites, floorId])
}

export default useFloor
