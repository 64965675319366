// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Record } from 'immutable'

export default Record<any>({
  align: 'right',
  color: 0,
  fontSize: 12,
  id: null,
  label: null,
  rotation: 0,
  x: null,
  y: null,
})
