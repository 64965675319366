// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import PropTypes from 'prop-types'
import branch from 'recompose/branch'
import renderNothing from 'recompose/renderNothing'
import EmployeeAvatar from '../avatar/EmployeeAvatar'

/* @ts-expect-error auto-src: strict-conversion */
const Avatar = ({ photo }) => (
  <EmployeeAvatar fallbackPhotoSize="large" photo={photo} size={50} />
)

Avatar.propTypes = {
  photo: PropTypes.string,
}
/* @ts-expect-error auto-src: non-strict-conversion */
const ConditionalAvatar = branch<any, any>(
  ({ shouldShowAvatar }) => !shouldShowAvatar,
  renderNothing,
)(Avatar)

export default ConditionalAvatar
