// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { People as DirectoryIcon } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'

import NavMenuItem from 'common/components/navmenu/NavMenuItem'
import { directoryPath } from 'visual_directory/lib/paths'
import * as MenuActions from 'visual_directory/selectors/menuActions'

type OwnProps = {
  onClick: () => void
  isGuest?: boolean
  showImprovedNavigation?: boolean
}
type StateProps = {
  disabled: boolean
}
type Props = OwnProps & StateProps

const BrowseDirectoryList = ({
  disabled,
  isGuest,
  onClick,
  showImprovedNavigation,
}: Props) => {
  const { t } = useTranslation('visual_directory', {
    keyPrefix: 'visual_directory.home_menu',
  })

  const shouldChangeStyle = !showImprovedNavigation || isGuest

  const menuItem = (
    <NavMenuItem
      component="a"
      href={`/visual-directory${directoryPath}`}
      isBold={shouldChangeStyle}
      isDisabled={disabled}
      LeftIcon={shouldChangeStyle && DirectoryIcon}
      onClick={onClick}
    >
      {t('browse_directory_list')}
    </NavMenuItem>
  )

  if (disabled) {
    return (
      <Tooltip
        placement="bottom"
        // t<string>(...) to make typescript happy
        title={t<string>('browse_directory_list_unavailable')}
      >
        <span>{menuItem}</span>
      </Tooltip>
    )
  }

  return menuItem
}

/* @ts-expect-error auto-src: non-strict-conversion */
export default connect<Props, OwnProps, StateProps, {}, _, _>(
  /* @ts-expect-error auto-src: strict-conversion */
  (state): StateProps => ({
    disabled: MenuActions.shouldDisableDirectoryList(state),
  }),
  Object.freeze({}),
)(BrowseDirectoryList)
